import i18n from 'i18next';

import { createToasts } from '@legalfly/ui/toast';

export const notificationToasts = createToasts({
  markNotificationAsSeen: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.markNotificationAsSeen.description'),
    }),
  }),
  markAllNotificationsAsSeen: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.markAllNotificationsAsSeen.description'),
    }),
  }),
  deleteNotification: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deleteNotification.description'),
    }),
  }),
  deleteAllNotifications: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deleteAllNotifications.description'),
    }),
  }),
});
