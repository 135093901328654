import i18n from 'i18next';

import { createToasts } from '@legalfly/ui/toast';

export const anonymizationToasts = createToasts({
  editName: () => ({
    successToast: () => ({
      title: i18n.t('toast.success.editAnonymizationName.title'),
      description: i18n.t('toast.success.editAnonymizationName.description'),
    }),
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.editAnonymizationName.description'),
    }),
  }),
  delete: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deleteAnonymization.description'),
    }),
  }),
});
