import { createContext, type PropsWithChildren, useContext, useMemo } from 'react';

import type { ApiDocument } from '@legalfly/api/documents';
import type { ApiPlaybook } from '@legalfly/api/playbooks';

import type { PlaybookItemActions, PlaybookItemState } from './types';
import { usePlaybookItem } from './usePlaybookItem';

interface PlaybookItemStateContextValue extends PlaybookItemState {
  playbookUuid: ApiPlaybook['uuid'];
  selectedDocument: ApiDocument | undefined;
}

const PlaybookItemStateContext = createContext<PlaybookItemStateContextValue | null>(null);
const PlaybookItemActionsContext = createContext<PlaybookItemActions | null>(null);

interface PlaybookItemProviderProps {
  playbookUuid: ApiPlaybook['uuid'];
}

export function PlaybookItemProvider({
  children,
  playbookUuid,
}: PropsWithChildren<PlaybookItemProviderProps>) {
  const { actions, ...state } = usePlaybookItem(playbookUuid);

  const stateValue = useMemo(() => ({ ...state, playbookUuid }), [state, playbookUuid]);

  return (
    <PlaybookItemStateContext.Provider value={stateValue}>
      <PlaybookItemActionsContext.Provider value={actions}>
        {children}
      </PlaybookItemActionsContext.Provider>
    </PlaybookItemStateContext.Provider>
  );
}

export function usePlaybookItemState() {
  const context = useContext(PlaybookItemStateContext);

  if (context === null) {
    throw new Error('usePlaybookItemState must be used within PlaybookItemProvider');
  }

  return context;
}

export function usePlaybookItemActions() {
  const context = useContext(PlaybookItemActionsContext);

  if (context === null) {
    throw new Error('usePlaybookItemActions must be used within PlaybookItemProvider');
  }

  return context;
}
