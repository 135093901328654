import type { ComponentProps, MouseEventHandler, PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import type { ChainedCommands, Editor } from '@tiptap/react';
import { useCurrentEditor } from '@tiptap/react';

import { cn } from 'utils';

import { IconButton } from '../button';
import type { IconName } from '../icon';

export interface ToolbarProps extends PropsWithChildren {
  className?: string;
}

type Command = 'bold' | 'italic' | 'underline' | 'strike' | 'bulletList' | 'orderedList';

type CommandMethod = {
  [K in Command as `toggle${Capitalize<K>}`]: () => ChainedCommands;
};

interface CommandButtonProps extends Omit<ComponentProps<typeof IconButton>, 'name'> {
  command: Command;
  iconName: IconName;
}

function applyCommand(command: Command, editor: Editor | null): void {
  if (!editor) return;
  const method =
    `toggle${command.charAt(0).toUpperCase() + command.slice(1)}` as keyof CommandMethod;
  editor.chain().focus()[method]().run();
}

const CommandButton = ({ command, iconName, className, ...props }: CommandButtonProps) => {
  const { editor } = useCurrentEditor();
  const active = editor?.isActive(command);

  const onMouseDown: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    applyCommand(command, editor);
  };

  return (
    <IconButton
      data-active={active}
      variant='tertiary'
      className={cn(className, 'h-6 w-6 p-1 data-[active=true]:bg-fill-hover-strongest')}
      onMouseDown={onMouseDown}
      tabIndex={-1}
      name={iconName}
      {...props}
    />
  );
};

export const Toolbar = forwardRef<HTMLDivElement, ToolbarProps>(({ className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={cn('flex gap-1 border border-stroke-weaker bg-fill-maximal p-3', className)}
    >
      <CommandButton command='bold' iconName='bold-01' />
      <CommandButton command='italic' iconName='italic-01' />
      <CommandButton command='underline' iconName='underline-01' />
      <CommandButton command='strike' iconName='strikethrough-02' />
      <CommandButton command='bulletList' iconName='dotpoints-01' />
      <CommandButton command='orderedList' iconName='numberpoints-01' />
      {children}
    </div>
  );
});

Toolbar.displayName = 'Toolbar';
