import { queryOptions } from '@tanstack/react-query';

import type { ApiUser, UsersApi } from '@legalfly/api/users';

export const usersQueryOptions = ({ usersApi }: { usersApi: UsersApi }) => ({
  users: () =>
    queryOptions({
      queryKey: ['users'],
      queryFn: () => usersApi.getUsers(),
    }),
  user: (uuid: ApiUser['uuid']) =>
    queryOptions({
      queryKey: ['users', uuid],
      queryFn: () => usersApi.getUser({ uuid }),
    }),
  currentUser: () =>
    queryOptions({
      queryKey: ['users', 'current'],
      queryFn: () => usersApi.getCurrentUser(),
    }),
  updateAvatar: () =>
    queryOptions({
      queryKey: ['users', 'update', 'avatar'],
      queryFn: () => usersApi.updateUserAvatar,
    }),
});
