/* istanbul ignore file -- @preserve */
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import type { DetectorOptions } from 'i18next-browser-languagedetector';
import LanguageDetector from 'i18next-browser-languagedetector';
import type { HttpBackendOptions } from 'i18next-http-backend';
import Backend from 'i18next-http-backend';

import { legalFlyConfig } from '@legalfly/config';

export const initI18n = () =>
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init<HttpBackendOptions & DetectorOptions>({
      supportedLngs: legalFlyConfig.supportedLanguages,
      fallbackLng: legalFlyConfig.defaultLanguage,
      defaultNS: 'translation',
      debug: false,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: {
        loadPath: '/locales/{{lng}}.json',
      },
      appendNamespaceToCIMode: true,
    });
