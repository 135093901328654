import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Row, RowSelectionState, Updater } from '@tanstack/react-table';

import { type ApiDocument, DocumentStatus } from '@legalfly/api/documents';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { DataTable } from 'components/common/table/DataTable';
import { useSelectedDocuments } from 'components/documents/documentPicker/SelectedDocumentsProvider';
import { isDocumentFolder } from 'core/modules/documents/helpers';

import { useDocumentPickerColumns } from './columns';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type DocumentsProps = {
  documents: ApiDocument[];
  onSelectFolder?: (document: ApiDocument) => void;
  onDoubleClickFolder?: (document: ApiDocument) => void;
  onBackPress?: () => void;
  allowMultiple?: boolean;
  allowSelection?: boolean;
  allowFolderSelection?: boolean;
  allowDelete?: boolean;
  allowContextMenu?: boolean;
  onDropDocument?: (params: { sourceUuid: string; targetUuid: string }) => void;
};

const DocumentPicker = ({
  documents,
  onSelectFolder,
  onDoubleClickFolder,
  onDropDocument,
  onBackPress,
  allowMultiple = true,
  allowSelection = true,
  allowFolderSelection = false,
  allowDelete = true,
  allowContextMenu = false,
}: DocumentsProps) => {
  const { t } = useTranslation();
  const { selectedDocuments, setDocuments, clearSelectedDocuments, toggleSelectedDocument } =
    useSelectedDocuments();

  const handleDocumentClick = useCallback(
    (document: ApiDocument) => {
      if (isDocumentFolder(document)) {
        if (onSelectFolder) {
          onSelectFolder(document);
          clearSelectedDocuments();
          return;
        }

        toggleSelectedDocument(document);
        return;
      }

      if (document.status !== DocumentStatus.COMPLETE) {
        return;
      }

      if (allowMultiple) {
        toggleSelectedDocument(document);
      } else {
        setDocuments([document]);
      }
    },
    [onSelectFolder, clearSelectedDocuments, toggleSelectedDocument, allowMultiple, setDocuments],
  );

  const handleDocumentDoubleClick = useCallback(
    (document: ApiDocument) => {
      if (isDocumentFolder(document)) {
        onDoubleClickFolder?.(document);
      }
    },
    [onDoubleClickFolder],
  );

  const columns = useDocumentPickerColumns({
    onDocumentClick: handleDocumentClick,
    onDocumentDoubleClick: handleDocumentDoubleClick,
    allowMultiple,
    allowSelection,
    allowDelete,
    allowContextMenu,
  });

  // Tanstack table just needs a record of selected documents
  const rowSelection = useMemo(() => {
    return selectedDocuments.reduce((acc, document) => {
      acc[document.uuid] = true;
      return acc;
    }, {} as RowSelectionState);
  }, [selectedDocuments]);

  const handleRowSelectionChange = useCallback(
    (valueFn: Updater<RowSelectionState>) => {
      if (typeof valueFn !== 'function') {
        return;
      }

      const updatedDocumentIds = valueFn(rowSelection);

      const updatedDocuments = Object.keys(updatedDocumentIds)
        .map((uuid) => {
          return documents.find((document) => document.uuid === uuid);
        })
        .filter(Boolean) as ApiDocument[];

      setDocuments(allowMultiple ? updatedDocuments : updatedDocuments.slice(-1));
    },
    [rowSelection, documents, setDocuments, allowMultiple],
  );

  const shouldEnableRowSelection = useCallback(
    (row: Row<ApiDocument>) => {
      if (isDocumentFolder(row.original)) {
        return allowFolderSelection;
      }

      return row.original.status === DocumentStatus.COMPLETE;
    },
    [allowFolderSelection],
  );

  const handleDropDocument = (params: { sourceUuid: string; targetUuid: string }) => {
    trackEvent({
      action: 'move',
      category: 'documents',
      label: 'drop',
    });
    onDropDocument?.(params);
  };

  return (
    <div className='h-full'>
      {typeof onBackPress === 'function' && (
        <Button className='mb-4' onClick={onBackPress} renderLeft={<Icon name='chevron-left' />}>
          {t('action.back')}
        </Button>
      )}
      <DataTable
        columns={columns}
        data={documents}
        state={{
          rowSelection,
        }}
        onRowSelectionChange={handleRowSelectionChange}
        enableRowSelection={shouldEnableRowSelection}
        getRowId={(row) => row.uuid}
        isDroppable={isDocumentFolder}
        onDropDocument={handleDropDocument}
      />
    </div>
  );
};

export default DocumentPicker;
