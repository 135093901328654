import { PlaybookItemTypeEnum } from '@legalfly/api/playbooks';
import { type ApiReviewItem, ReviewPlaybookItemStatus } from '@legalfly/api/reviews';

export const riskOrder: Record<string, number> = {
  high: 1,
  medium: 2,
  low: 3,
  neutral: 4,
};

export const sortReviewItemsByRisk = (items: ApiReviewItem[]): ApiReviewItem[] => {
  return [...items].sort(
    (a, b) => (riskOrder[a.answer?.level ?? ''] ?? 5) - (riskOrder[b.answer?.level ?? ''] ?? 5),
  );
};

export const getCompletedItemsCount = (items: ApiReviewItem[]) =>
  items.filter((item) => item.status === ReviewPlaybookItemStatus.COMPLETED).length;

export const RISK_RELATED_PLAYBOOK_ITEM_TYPES = [
  PlaybookItemTypeEnum.RISK_ASSESSMENT,
  PlaybookItemTypeEnum.ANOMALY,
];
