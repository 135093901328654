import { type PropsWithChildren, useState } from 'react';

import { initAnalytics } from '@legalfly/reporting/tracking';
import { env } from 'core/env';
import { initI18n } from 'core/i18n';

const AppInitializer = ({ children }: PropsWithChildren) => {
  const [isInitialized] = useState(() => {
    initAnalytics({
      environment: env.environment,
      token: env.posthog.token,
    });
    initI18n();
    return true;
  });

  return isInitialized ? children : null;
};

export default AppInitializer;
