import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from '@tanstack/react-router';

import { Button } from '@legalfly/ui/button';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@legalfly/ui/hoverCard';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { NavigationMenu } from 'components/common/navigationMenu';
import { useFeatureFlag } from 'core/analytics';

import { getIconClasses, getItemClasses, getTextClasses } from '../helpers';

export const ManagementNavigationItem = ({ isExpanded }: { isExpanded: boolean }) => {
  const { t } = useTranslation();
  const { pathname: currentPath } = useLocation();
  const [cardOpen, setCardOpen] = useState(false);
  const isArchiveEnabled = useFeatureFlag('archive');

  const iconClasses = getIconClasses(cardOpen);
  const textClasses = getTextClasses(cardOpen, isExpanded);

  const items = (
    <NavigationMenu.Content>
      <NavigationMenu.Item
        asChild
        isActive={currentPath.startsWith('/documents')}
        className={getItemClasses(currentPath.startsWith('/documents'), cardOpen, isExpanded)}
      >
        <Link to='/documents/folder'>
          <Icon name='folder' className={iconClasses} />
          <Text className={textClasses}>{t('nav.documents')}</Text>
        </Link>
      </NavigationMenu.Item>
      <NavigationMenu.Item
        asChild
        isActive={currentPath.startsWith('/playbooks')}
        className={getItemClasses(currentPath.startsWith('/playbooks'), cardOpen, isExpanded)}
      >
        <Link to='/playbooks'>
          <Icon name='book-open' className={iconClasses} />
          <Text className={textClasses}>{t('nav.playbooks')}</Text>
        </Link>
      </NavigationMenu.Item>
      {isArchiveEnabled && (
        <NavigationMenu.Item
          asChild
          isActive={currentPath.startsWith('/archive')}
          className={getItemClasses(currentPath.startsWith('/archive'), cardOpen, isExpanded)}
        >
          <Link to='/archive'>
            <Icon name='archive' className={iconClasses} />
            <Text className={textClasses}>{t('nav.archive')}</Text>
          </Link>
        </NavigationMenu.Item>
      )}
    </NavigationMenu.Content>
  );

  if (isExpanded) {
    return items;
  }

  return (
    <HoverCard openDelay={100} open={cardOpen} onOpenChange={setCardOpen}>
      <HoverCardTrigger asChild>
        <NavigationMenu.Item
          asChild
          isActive={
            currentPath.startsWith('/documents') ||
            currentPath.startsWith('/playbooks') ||
            currentPath.startsWith('/archive')
          }
        >
          <Button variant='unstyled'>
            <Icon name='folder' className='size-5' />
          </Button>
        </NavigationMenu.Item>
      </HoverCardTrigger>
      <HoverCardContent className='w-40' align='start'>
        {items}
      </HoverCardContent>
    </HoverCard>
  );
};
