import { type ComponentProps } from 'react';

import { UserAvatar } from '@legalfly/ui/avatar';
import { useCurrentUser } from 'core/modules/users';

export const CurrentUserAvatar = (
  props: Omit<ComponentProps<typeof UserAvatar>, 'avatarUrl' | 'firstName' | 'lastName'>,
) => {
  const { currentUser } = useCurrentUser();

  return (
    <UserAvatar
      firstName={currentUser.firstName}
      lastName={currentUser.lastName}
      avatarUrl={currentUser.avatarUrl}
      {...props}
    />
  );
};
