import type { ComponentProps } from 'react';

import type { ApiAgent } from '@legalfly/api/agents';
import type { ApiDocument } from '@legalfly/api/documents';
import type { FavoriteTypeEnum } from '@legalfly/api/favorites';
import { IconButton } from '@legalfly/ui/button';
import { cn } from '@legalfly/ui/utils';
import { useFavorite } from 'core/modules/favorites';

interface FavoriteEntityMap {
  [FavoriteTypeEnum.DOCUMENT]: ApiDocument;
  [FavoriteTypeEnum.AGENT]: ApiAgent;
}

interface Props<T extends FavoriteTypeEnum>
  extends Omit<ComponentProps<typeof IconButton>, 'name'> {
  entityType: T;
  entity: FavoriteEntityMap[T];
}

export const FavoriteButton = <T extends FavoriteTypeEnum>({
  className,
  entityType,
  entity,
  onClick,
  ...props
}: Props<T>) => {
  const { favoriteEntity } = useFavorite();

  const handleFavoriteClick = async () => {
    await favoriteEntity({
      body: {
        entityUuid: entity.uuid,
        entityType,
        favorited: !entity.favorited,
      },
      metadata: 'parent' in entity ? { parentUuid: entity.parent?.uuid } : undefined,
    });
  };

  return (
    <IconButton
      variant='tertiary'
      name='star'
      size='sm'
      onClick={(e) => {
        onClick?.(e);
        handleFavoriteClick();
      }}
      className={cn(
        entity.favorited ? 'fill-icon-strongest' : 'fill-transparent stroke-icon-strongest',
        className,
      )}
      {...props}
    />
  );
};
