import { calculateContrastingTextColor } from '@legalfly/ui/theme';

import type { OptionValues } from './PlaybookMultipleChoiceOptionsDialog';

interface Props {
  option: OptionValues;
}

export const PlaybookItemMultipleChoiceOption = ({ option }: Props) => {
  return (
    <div
      className='mb-1 px-4 py-2'
      style={{
        backgroundColor: option.color,
        color: option.color,
      }}
    >
      <div
        style={{
          color: calculateContrastingTextColor(option.color),
        }}
        className='text-body'
      >
        {option.option}
      </div>
    </div>
  );
};
