import { createSettingsModule } from '@legalfly/modules/settings';

import { configApi, settingsApi } from '../../di';

export const { useUserSettings, useUpdateUserSettings, settingsQueryOptions } =
  createSettingsModule({
    settingsApi,
    configApi,
  });

export * from './toasts';
