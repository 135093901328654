import { legalFlyConfig } from '@legalfly/config';

import type { ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';

export const createWebSocketApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const authenticate = () => {
    return client(api.websocket.authenticate, { method: 'GET' });
  };

  const polling = () => {
    return client(api.websocket.polling, { method: 'GET' });
  };

  return {
    authenticate,
    polling,
  };
};

export type WebSocketApi = ReturnType<typeof createWebSocketApi>;
export type WebSocketMessageType = ResponseOf<'ws/polling/get'>[number];
