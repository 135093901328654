import { useTranslation } from 'react-i18next';

import { Button } from '@legalfly/ui/button';
import ErrorDialog from 'components/common/errors/ErrorDialog';

interface Props {
  onClose: () => void;
  actionText?: string;
}

export const FileTypeErrorDialog = ({ onClose, actionText }: Props) => {
  const { t } = useTranslation();

  return (
    <ErrorDialog title={t('errors.fileType.title')} message={t('errors.fileType.message')}>
      <Button onClick={onClose}>{actionText || t('errors.fileType.action')}</Button>
    </ErrorDialog>
  );
};
