import type { CSSProperties } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { DragEndEvent } from '@dnd-kit/core';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import type { Column, SortingState, TableOptions } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useTableHeight } from 'common/hooks/useTableHeight';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@legalfly/ui/table';
import { cn } from '@legalfly/ui/utils';

import { DraggableTableRow } from './DraggableTableRow';
import './styles.css';

const getPinningStyles = <TData,>(column: Column<TData>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px gray inset'
      : isFirstRightPinnedColumn
        ? '4px 0 4px -4px gray inset'
        : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize() === 0 ? undefined : column.getSize(),
    zIndex: isPinned ? 1 : 0,
  };
};

interface Props<TData> extends Omit<TableOptions<TData>, 'getCoreRowModel'> {
  onRowClick?: (rowData: TData) => void;
  isDroppable: (rowData: TData) => boolean;
  onDropDocument?: (p: { sourceUuid: string; targetUuid: string }) => void;
}

export function DataTable<TData>({
  getRowId,
  columns,
  data,
  onRowSelectionChange,
  isDroppable,
  onDropDocument,
  ...props
}: Props<TData>) {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const { tableHeight, tableRef } = useTableHeight();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange,
    getRowId,
    ...props,
    state: {
      sorting,
      ...props.state,
    },
    defaultColumn: {
      minSize: 0,
      size: 0,
      enableResizing: false,
      ...props.defaultColumn,
    },
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    if (event.over?.data.current?.isDroppable && event.active.id !== event.over.id) {
      onDropDocument?.({ sourceUuid: String(event.active.id), targetUuid: String(event.over.id) });
    }
  };

  const isDndEnabled = Boolean(onDropDocument);
  const Row = isDndEnabled ? DraggableTableRow : TableRow;

  return (
    <DndContext collisionDetection={closestCenter} sensors={sensors} onDragEnd={handleDragEnd}>
      <Table
        parentClassName='table-container h-full'
        className='table-fixed'
        ref={tableRef}
        style={{ maxHeight: tableHeight }}
      >
        <TableHeader className='sticky top-0 z-10 bg-fill-maximal'>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} className='shadow-table-border'>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    colSpan={header.colSpan}
                    className={cn('text-content-body-weak', header.id === 'select' && 'px-0')}
                    style={{ ...getPinningStyles(header.column) }}
                  >
                    <div className='whitespace-nowrap'>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                    {header.column.getCanResize() && (
                      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                      <div
                        onDoubleClick={() => header.column.resetSize()}
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                      />
                    )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <Row
                key={row.id}
                id={row.id}
                data-state={row.getIsSelected() && 'selected'}
                className={cn(row.getIsSelected() && 'bg-fill-pressed-weak')}
                {...(isDndEnabled && { isDroppable: isDroppable(row.original) })}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    style={{
                      width: `${cell.column.getSize()}px`,
                      padding: 0,
                      ...getPinningStyles(cell.column),
                    }}
                    className={cn(cell.column.id === 'select' && 'px-0')}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </Row>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className='h-24 text-center'>
                {t('label.noResults')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </DndContext>
  );
}
