import { useTranslation } from 'react-i18next';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useKeywordFilter } from 'common/hooks/useKeywordFilter';

import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { SearchInputForm } from '@legalfly/ui/form';
import { Icon } from '@legalfly/ui/icon';
import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { ContentSidebar } from 'components/common/content/ContentSidebar';
import { DraftsInsights } from 'components/drafting/drafts/DraftsInsights';
import { DraftsList } from 'components/drafting/drafts/DraftsList';
import { draftingQueryOptions, useDrafts, useTemplates } from 'core/modules/drafting';

import { DraftingTabs } from '../-tabs/DraftingTabs';

export const Route = createFileRoute('/_auth/_layout/drafting/drafts/')({
  component: DraftingDraftsRoute,
  loader({ context }) {
    return Promise.all([
      context.queryClient.ensureQueryData(draftingQueryOptions.drafts()),
      context.queryClient.ensureQueryData(draftingQueryOptions.templates()),
    ]);
  },
});

function DraftingDraftsRoute() {
  const { t } = useTranslation();

  const { drafts, stats } = useDrafts();
  const { templates } = useTemplates();
  const { filteredItems: filteredDrafts, setKeyword } = useKeywordFilter(drafts, 'title');

  const navigate = useNavigate();

  return (
    <div className='flex h-full gap-3'>
      <div className='flex w-auto flex-1 flex-col gap-3'>
        <DraftingTabs />
        <Content>
          <ContentHeader>
            <ContentHeader.Title>{t('drafting.drafts.cta.title')}</ContentHeader.Title>
            <ContentHeader.SubTitle>{t('drafting.drafts.cta.subTitle')}</ContentHeader.SubTitle>
            <ContentHeader.Actions>
              <SearchInputForm
                placeholder={t('label.search')}
                onKeywordChange={setKeyword}
                onBlur={() =>
                  trackEvent({
                    action: 'blur',
                    category: 'drafts',
                    label: 'search',
                  })
                }
              />
              {templates.length > 0 && (
                <Button
                  variant='soft'
                  renderLeft={<Icon name='file-2' className='text-icon-strong' />}
                  onClick={() => {
                    trackEvent({
                      action: 'click',
                      category: 'drafts',
                      label: 'createDraft',
                    });
                    navigate({ to: '/drafting/drafts/choose-template' });
                  }}
                >
                  {t('action.createDraft')}
                </Button>
              )}
            </ContentHeader.Actions>
          </ContentHeader>
          <DraftsList filteredDrafts={filteredDrafts} hasAvailableDrafts={drafts.length > 0} />
        </Content>
      </div>
      {drafts.length > 0 && (
        <ContentSidebar>
          <DraftsInsights stats={stats} />
        </ContentSidebar>
      )}
    </div>
  );
}
