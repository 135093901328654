import { createFileRoute, useRouter } from '@tanstack/react-router';

import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { DocumentActions } from 'components/documents/detail/DocumentActions';
import { DocumentProvider } from 'components/documents/detail/DocumentProvider';
import { DocumentViewer } from 'components/documents/detail/DocumentViewer';
import { DocumentsDetailPane } from 'components/documents/detailPane/DocumentsDetailPane';
import { SelectedDocumentsProvider } from 'components/documents/documentPicker/SelectedDocumentsProvider';
import { documentsQueryOptions, useFile } from 'core/modules/documents';
import { getDocumentIcon } from 'core/modules/documents/helpers';

export const Route = createFileRoute('/_auth/_layout/documents/file/$uuid')({
  loader: ({ context, params }) =>
    context.queryClient.ensureQueryData(documentsQueryOptions.file(params.uuid)),
  component: FileRoute,
});

function FileRoute() {
  const { uuid } = Route.useParams();
  const { file } = useFile(uuid);
  const { history } = useRouter();

  return (
    <div className='flex gap-3'>
      <DocumentProvider document={file}>
        <div className='flex flex-1 flex-col gap-3'>
          <DocumentActions>
            <DocumentActions.BackButton onClick={() => history.go(-1)} />
            <DocumentActions.Container className='justify-end'>
              <DocumentActions.AnonymousButton />
            </DocumentActions.Container>
          </DocumentActions>
          <div className='h-content-with-row overflow-auto bg-fill-maximal px-content-x py-content-y'>
            <DocumentViewer />
          </div>
        </div>
      </DocumentProvider>
      <SelectedDocumentsProvider>
        <div className='h-content w-side-pane-sm bg-fill-maximal p-6'>
          <div className='flex gap-2'>
            <Icon name={getDocumentIcon(file)} />
            <Text as='h3'>{file?.name}</Text>
          </div>
          <DocumentsDetailPane documents={[file]} showOpenDocument={false} />
        </div>
      </SelectedDocumentsProvider>
    </div>
  );
}
