import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '@tanstack/react-form';

import type { ApiPlaybookItemContextMultipleChoiceOption } from '@legalfly/api/playbooks';
import { Button } from '@legalfly/ui/button';
import { ColorPickerPopover } from '@legalfly/ui/colorPickerPopover';
import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@legalfly/ui/dialog';
import { Icon } from '@legalfly/ui/icon';
import { Input } from '@legalfly/ui/input';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';

const DEFAULT_NEW_OPTION = {
  option: '',
  color: ColorPickerPopover.defaultColor,
};

export interface OptionValues {
  option: string;
  color: string;
}

interface FormValues {
  options: OptionValues[];
  newOption: OptionValues;
}

interface Props {
  onChange: (values: OptionValues[]) => void;
  options: ApiPlaybookItemContextMultipleChoiceOption;
}

export const PlaybookMultipleChoiceOptionsDialog = ({ onChange, options }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const form = useForm<FormValues>({
    defaultValues: {
      options: options && options.length > 0 ? options : [DEFAULT_NEW_OPTION],
      newOption: DEFAULT_NEW_OPTION,
    },
    onSubmit: async ({ value }) => {
      onChange(value.options);
      form.reset();
      setIsOpen(false);
    },
  });

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          form.reset();
        }
        setIsOpen(open);
      }}
    >
      <DialogTrigger asChild>
        <Button variant='soft' className='mb-4 pl-2' renderLeft={<Icon name='plus' />}>
          {t('action.addOption', { count: 2 })}
        </Button>
      </DialogTrigger>
      <DialogContent className='max-h-[80vh]'>
        <VisuallyHidden>
          <DialogDescription>{t('dialog.addOption.description')}</DialogDescription>
        </VisuallyHidden>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
        >
          <DialogHeader>
            <DialogTitle className='ml-2'>{t('dialog.addOption.title')}</DialogTitle>
          </DialogHeader>
          <DialogBody className='z-10 mb-6 overflow-y-auto'>
            <form.Field name='options' mode='array'>
              {(optionsField) => (
                <>
                  <div className='flex max-h-80 flex-col gap-4'>
                    {optionsField.state.value.map((option, index) => (
                      <form.Field name={`options[${index}].option`} key={index}>
                        {(_) => {
                          return (
                            <div className='flex items-center gap-3'>
                              <ColorPickerPopover
                                value={option.color || ColorPickerPopover.defaultColor}
                                onChange={(color) =>
                                  optionsField.replaceValue(index, { ...option, color: color.hex })
                                }
                              />
                              <Input
                                id={`${optionsField.name}[${index}].option`}
                                name={`${optionsField.name}[${index}].option`}
                                value={option.option}
                                containerClassName='flex-grow'
                                onChange={(e) =>
                                  optionsField.replaceValue(index, {
                                    ...option,
                                    option: e.target.value,
                                  })
                                }
                              />
                              <Button
                                variant='soft'
                                onClick={() => optionsField.removeValue(index)}
                                className='m-2 h-12'
                              >
                                <Icon name='trash-2' />
                              </Button>
                            </div>
                          );
                        }}
                      </form.Field>
                    ))}
                  </div>
                </>
              )}
            </form.Field>
          </DialogBody>
          <DialogFooter className='flex justify-between gap-2'>
            <Button
              variant='soft'
              onClick={() =>
                form.setFieldValue('options', [
                  ...form.getFieldValue('options'),
                  DEFAULT_NEW_OPTION,
                ])
              }
              className='m-2'
            >
              <Icon name='plus' />
              {t('action.addOption', { count: 1 })}
            </Button>
            <div className='flex gap-2'>
              <DialogClose asChild>
                <Button variant='soft' onClick={() => form.reset()}>
                  {t('action.cancel')}
                </Button>
              </DialogClose>
              <form.Subscribe selector={(state) => state.values.options}>
                {(options) => (
                  <Button
                    type='button'
                    onClick={() => form.handleSubmit()}
                    disabled={options.some((v) => !v.option.trim())}
                  >
                    {t('action.save')}
                  </Button>
                )}
              </form.Subscribe>
            </div>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
