import { useTranslation } from 'react-i18next';

import type { ApiStatistics } from '@legalfly/api/statistics';
import { Text } from '@legalfly/ui/text';

interface Props {
  statistics: ApiStatistics;
}

export function DashboardStats({ statistics }: Props) {
  const { t } = useTranslation();

  return (
    <div className='z-10 row-span-2 -mt-12 overflow-hidden bg-surface-nav p-8 pb-16'>
      <Text
        variant='captionLight'
        className='mb-5 w-fit bg-fill-lemon px-3 py-2 text-content-body shadow-glow-lemon dark:text-content-body-invert'
      >
        {t('dashboard.stats.badge')}
      </Text>
      <Text className='font-sans text-heading2'>{t('dashboard.stats.title')}</Text>
      <div className='mt-3'>
        {Object.entries(statistics).map(([name, count]) => (
          <div
            key={name}
            className='flex w-full flex-row justify-between border-b border-surface-white/10 px-2 py-5 last:border-b-0'
          >
            <Text variant='bodyLargeLight' className='text-content-body-strong'>
              {t(`dashboard.stats.${name as keyof ApiStatistics}`)}
            </Text>
            <Text variant='bodyLargeLight' className='text-content-body-strong'>
              {count}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
}
