import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import type { ApiNotification, NotificationsApi } from '@legalfly/api/notifications';

import { notificationsQueryOptions } from './NotificationsQueryOptions';

export const createNotificationsModule = ({
  notificationsApi,
}: {
  notificationsApi: NotificationsApi;
}) => {
  const queryOptions = notificationsQueryOptions({ notificationsApi });

  const useNotifications = () => {
    const { data, refetch, isLoading, error } = useSuspenseQuery(queryOptions.getNotifications());

    return {
      notifications: data ?? [],
      refetch,
      isLoading,
      error,
    };
  };

  const useMarkNotificationAsSeen = () => {
    const queryClient = useQueryClient();

    const { mutateAsync } = useMutation({
      mutationKey: ['notifications', 'markAsSeen'],
      mutationFn: (uuid: ApiNotification['uuid']) => notificationsApi.markNotificationAsSeen(uuid),
      onSuccess: () => {
        queryClient.invalidateQueries(queryOptions.getNotifications());
      },
    });

    return {
      markNotificationAsSeen: mutateAsync,
    };
  };

  const useMarkAllNotificationsAsSeen = () => {
    const queryClient = useQueryClient();

    const { mutateAsync } = useMutation({
      mutationKey: ['notifications', 'markAllAsSeen'],
      mutationFn: () => notificationsApi.markAllNotificationsAsSeen(),
      onSuccess: () => {
        queryClient.invalidateQueries(queryOptions.getNotifications());
      },
    });

    return {
      markAllNotificationsAsSeen: mutateAsync,
    };
  };

  const useDeleteNotification = (uuid: ApiNotification['uuid']) => {
    const queryClient = useQueryClient();

    const { mutateAsync } = useMutation({
      mutationKey: ['notifications', 'delete'],
      mutationFn: () => notificationsApi.deleteNotification(uuid),
      onSuccess: () => {
        queryClient.invalidateQueries(queryOptions.getNotifications());
      },
    });

    return {
      deleteNotification: mutateAsync,
    };
  };

  const useDeleteAllNotifications = () => {
    const queryClient = useQueryClient();

    const { mutateAsync } = useMutation({
      mutationKey: ['notifications', 'deleteAll'],
      mutationFn: () => notificationsApi.deleteAllNotifications(),
      onSuccess: () => {
        queryClient.invalidateQueries(queryOptions.getNotifications());
      },
    });

    return {
      deleteAllNotifications: mutateAsync,
    };
  };

  return {
    useNotifications,
    useMarkNotificationAsSeen,
    useMarkAllNotificationsAsSeen,
    useDeleteNotification,
    useDeleteAllNotifications,
    notificationsQueryOptions: queryOptions,
  };
};
