import { useTranslation } from 'react-i18next';

import { Card, CardFooter, CardIcon, CardSubtitle, CardTitle } from '@legalfly/ui/card';
import { cn } from '@legalfly/ui/utils';

import { CreateAgentDialog } from './create/CreateAgentDialog';

export const AgentsEmptyState = () => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'relative flex w-full flex-1 flex-grow flex-col overflow-hidden',
        'border border-dashed border-stroke-weak',
        'z-0 from-dropzone-start to-dropzone-stop/20 mask-blocked-background after:animate-dropzone',
      )}
    >
      <div className='flex flex-1 flex-col items-center justify-center'>
        <Card>
          <CardIcon name='placeholder' />
          <CardTitle className='mb-2'>{t('agents.empty.card.title')}</CardTitle>
          <CardSubtitle className='max-w-[40ch] text-center'>
            {t('agents.empty.card.description')}
          </CardSubtitle>
          <CardFooter className='gap-3'>
            <CreateAgentDialog />
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};
