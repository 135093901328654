import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

interface DialogContextType {
  open: (entity: ReactNode) => void;
  close: () => void;
}

const DialogContext = createContext<DialogContextType | null>(null);

export const DialogProvider = ({ children }: { children: ReactNode }) => {
  const [entity, setEntity] = useState<ReactNode>();

  const value = useMemo(() => ({ open: setEntity, close: () => setEntity(undefined) }), []);

  return (
    <DialogContext.Provider value={value}>
      <>
        {entity}
        {children}
      </>
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  const context = useContext(DialogContext);

  if (context === null) {
    throw new Error('useDialog must be used within a DialogProvider');
  }

  return context;
};
