import { queryOptions } from '@tanstack/react-query';

import type { DocumentCategoryKey } from '@legalfly/api/documents';
import type { ApiPlaybook, PlaybooksApi } from '@legalfly/api/playbooks';

export const playbookQueryOptions = ({ playbooksApi }: { playbooksApi: PlaybooksApi }) => ({
  playbooks: (query?: Parameters<PlaybooksApi['getPlaybooks']>[0]) =>
    queryOptions({
      queryKey: ['playbooks', query].filter(Boolean),
      queryFn: () => playbooksApi.getPlaybooks(query),
    }),
  playbook: ({ playbookUuid }: { playbookUuid: ApiPlaybook['uuid'] }) =>
    queryOptions({
      queryKey: ['playbook', playbookUuid],
      queryFn: () => playbooksApi.getPlaybook({ playbookUuid }),
    }),
  systemPlaybooks: (category: DocumentCategoryKey) =>
    queryOptions({
      queryKey: ['system', 'playbooks', category],
      queryFn: () => playbooksApi.getSystemPlaybooks({ category }),
      enabled: Boolean(category),
    }),
});
