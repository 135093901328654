import { createFileRoute } from '@tanstack/react-router';

import { ConversationTypeEnum } from '@legalfly/api/conversations';
import { AgentConversation } from 'components/agents/conversation/AgentConversation';
import { agentsQueryOptions, useAgent, useAgentConversations } from 'core/modules/agents';
import { conversationsQueryOptions } from 'core/modules/conversations';

export const Route = createFileRoute(
  '/_auth/_layout/agents/$agentUuid/conversations/$conversationUuid',
)({
  async loader({ context, params }) {
    const agent = await context.queryClient.ensureQueryData(
      agentsQueryOptions.agent({ uuid: params.agentUuid }),
    );

    const conversations = await context.queryClient.ensureQueryData(
      agentsQueryOptions.agentConversations({ uuid: params.agentUuid }),
    );

    const conversation = await context.queryClient.ensureQueryData(
      conversationsQueryOptions.conversation({
        type: ConversationTypeEnum.AGENT,
        uuid: params.conversationUuid,
      }),
    );

    return {
      agent,
      conversations,
      conversation,
    };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { agentUuid, conversationUuid } = Route.useParams();

  const { agent } = useAgent({ uuid: agentUuid });
  const { conversations } = useAgentConversations({ uuid: agentUuid });

  return (
    <AgentConversation
      agent={agent}
      conversations={conversations}
      activeConversationUuid={conversationUuid}
    />
  );
}
