import { type PropsWithChildren } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

import { env } from 'core/env';

export const AuthProvider = ({ children }: PropsWithChildren) => {
  return (
    <Auth0Provider
      domain={env.auth0.domain}
      clientId={env.auth0.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: env.auth0.audience,
      }}
      cacheLocation={
        window.IS_PLAYWRIGHT || env.environment === 'local' || env.environment === 'development'
          ? 'localstorage'
          : 'memory'
      }
    >
      {children}
    </Auth0Provider>
  );
};
