import { queryOptions } from '@tanstack/react-query';

import type { AnonymizationApi, ApiAnonymization } from '@legalfly/api/anonymization';

export const anonymizationQueryOptions = ({
  anonymizationApi,
}: {
  anonymizationApi: AnonymizationApi;
}) => ({
  anonymizations: () =>
    queryOptions({
      queryKey: ['anonymizations'],
      queryFn: () => anonymizationApi.getAnonymizations(),
    }),
  anonymization: ({ uuid }: { uuid: ApiAnonymization['uuid'] }) =>
    queryOptions({
      queryKey: ['anonymizations', uuid],
      queryFn: () => anonymizationApi.getAnonymization({ uuid }),
    }),
});
