import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';

import { trackEvent } from '@legalfly/reporting/tracking';
import { DashboardCard } from 'components/dashboard/DashboardCard';

export function DashboardCards() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleStartReview = () => {
    trackEvent({
      action: 'click',
      category: 'dashboard',
      label: 'review',
    });
    navigate({
      to: '/review/start',
    });
  };

  const handleStartDueDiligence = () => {
    trackEvent({
      action: 'click',
      category: 'dashboard',
      label: 'multi-review',
    });
    navigate({
      to: '/multi-review/start',
    });
  };

  const handleStartDrafting = () => {
    trackEvent({
      action: 'click',
      category: 'dashboard',
      label: 'drafting',
    });
    navigate({
      to: '/drafting/drafts',
    });
  };

  const handleStartAnonymisation = () => {
    trackEvent({
      action: 'click',
      category: 'dashboard',
      label: 'anonymisation',
    });
    navigate({
      to: '/anonymisation',
    });
  };

  const handleStartDiscovery = () => {
    trackEvent({
      action: 'click',
      category: 'dashboard',
      label: 'discovery',
    });
    navigate({
      to: '/discovery',
    });
  };

  const handleStartManagement = () => {
    trackEvent({
      action: 'click',
      category: 'dashboard',
      label: 'documents',
    });
    navigate({
      to: '/documents/folder',
    });
  };

  return (
    <>
      <DashboardCard
        title={t('dashboard.cards.contractReview.title')}
        subtitle={t('dashboard.cards.contractReview.subtitle')}
        iconName='check'
        actionText={t('dashboard.cards.contractReview.action')}
        onActionClick={handleStartReview}
        imageType='review'
      />
      <DashboardCard
        title={t('dashboard.cards.dueDiligence.title')}
        subtitle={t('dashboard.cards.dueDiligence.subtitle')}
        iconName='check'
        actionText={t('dashboard.cards.dueDiligence.action')}
        onActionClick={handleStartDueDiligence}
        imageType='multi-review'
      />
      <DashboardCard
        title={t('dashboard.cards.drafting.title')}
        subtitle={t('dashboard.cards.drafting.subtitle')}
        iconName='file-2'
        actionText={t('dashboard.cards.drafting.action')}
        onActionClick={handleStartDrafting}
        imageType='drafting'
      />
      <DashboardCard
        title={t('dashboard.cards.anonymisation.title')}
        subtitle={t('dashboard.cards.anonymisation.subtitle')}
        iconName='shield-tick'
        actionText={t('dashboard.cards.anonymisation.action')}
        onActionClick={handleStartAnonymisation}
        imageType='anonymisation'
      />
      <DashboardCard
        title={t('dashboard.cards.discovery.title')}
        subtitle={t('dashboard.cards.discovery.subtitle')}
        iconName='plus'
        actionText={t('dashboard.cards.discovery.action')}
        onActionClick={handleStartDiscovery}
        imageType='discovery'
      />
      <DashboardCard
        title={t('dashboard.cards.documents.title')}
        subtitle={t('dashboard.cards.documents.subtitle')}
        iconName='folder'
        actionText={t('dashboard.cards.documents.action')}
        onActionClick={handleStartManagement}
        imageType='documents'
      />
    </>
  );
}
