import type * as Sentry from '@sentry/react';

import type { FetcherError } from '@legalfly/api/fetcher';
import type { WebSocketError } from '@legalfly/api/websocket';

export function handleFetcherError(event: Sentry.ErrorEvent, error: FetcherError) {
  event.tags = {
    ...event.tags,
    errorType: 'FetcherError',
    errorCode: error.response.code,
    errorName: error.response.name,
  };

  event.extra = {
    ...event.extra,
    requestUrl: error.request.url,
    requestMethod: error.request.method,
    responseMessage: error.response.message,
    responseDescription: error.response.description,
    responseCode: error.response.code,
    responseMeta: error.response.meta,
    requestOptions: error.options,
  };

  if (event.exception?.values?.[0]) {
    const exceptionValue = event.exception.values[0];
    const code = `(Code: ${error.response.code})`;
    exceptionValue.value = error.response.name ? `${error.response.name} ${code}` : code;

    if (error.response.stack) {
      exceptionValue.stacktrace = {
        frames: error.response.stack.split('\n').map((line) => ({ filename: line })),
      };
    }
  }

  event.fingerprint = ['{{ default }}', error.response.code];
  if (error.response.name) {
    event.fingerprint.unshift(error.response.name);
  }

  if (error.response.cause) {
    event.extra.errorCause = error.response.cause;
  }

  return event;
}

export function handleWebSocketError(event: Sentry.ErrorEvent, error: WebSocketError) {
  event.tags = {
    ...event.tags,
    errorType: 'WebSocketError',
    code: error.code,
  };

  event.extra = {
    ...event.extra,
    url: error.url,
    reason: error.reason,
  };

  if (event.exception?.values?.[0]) {
    const exceptionValue = event.exception.values[0];
    exceptionValue.value = `${error.name ?? error.message} (Code: ${error.code})`;
  }

  event.fingerprint = ['{{ default }}', 'WebSocketError', error.code];

  return event;
}
