import type React from 'react';

import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';

interface Props {
  label?: string;
  children: React.ReactNode;
  className?: string;
  subtitle?: string;
}

export const UserFormItem = ({ label, subtitle, children, className }: Props) => {
  return (
    <div
      className={cn(
        "relative flex flex-row items-center gap-4 px-8 py-4 after:absolute after:bottom-0 after:left-8 after:right-8 after:h-px after:bg-stroke-weaker after:content-[''] last:after:content-none",
        className,
      )}
    >
      {label && (
        <div className='w-1/2 flex-shrink-0'>
          <Text variant='bodyLight'>{label}</Text>
          {subtitle && (
            <Text variant='captionLight' className='mt-1 text-content-body-weak'>
              {subtitle}
            </Text>
          )}
        </div>
      )}
      <div className='flex-grow'>{children}</div>
    </div>
  );
};
