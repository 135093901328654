import { type SVGProps } from 'react';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from 'utils';

import type { IconName } from './icons';
import { iconNames } from './icons';
import iconsHref from './sprite.svg';

const iconVariants = cva('inline self-center', {
  variants: {
    size: {
      // 16
      xs: 'h-4 min-h-4 w-4 min-w-4',
      // 20
      sm: 'h-5 min-h-5 w-5 min-w-5',
      // 24
      md: 'h-6 min-h-6 w-6 min-w-6',
      // 32
      lg: 'h-8 min-h-8 w-8 min-w-8',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

interface Props extends SVGProps<SVGSVGElement>, VariantProps<typeof iconVariants> {
  name: IconName;
}

export const Icon = ({ name, size = 'sm', className, ...props }: Props) => {
  return (
    <svg {...props} className={cn(iconVariants({ size, className }))} aria-hidden='true'>
      <use href={`${iconsHref}#${name}`} />
    </svg>
  );
};

export { iconsHref, iconNames };
export type { IconName };
