import type { ComponentProps, HTMLAttributes } from 'react';
import { Slot } from '@radix-ui/react-slot';

import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';

export const ContentHeader = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return <div className={cn('mb-6 flex flex-col', className)} {...props} />;
};

const Title = ({ className, ...props }: ComponentProps<typeof Text>) => {
  return (
    <Text
      as='h1'
      variant='heading2'
      className={cn('mb-2 text-content-heading', className)}
      {...props}
    />
  );
};

interface SubTitleProps extends ComponentProps<typeof Text> {
  asChild?: boolean;
}

const SubTitle = ({ children, asChild, className, ...props }: SubTitleProps) => {
  if (asChild) {
    return <Slot {...props}>{children}</Slot>;
  }

  return (
    <Text
      as='h2'
      variant='bodyLarge'
      className={cn('text-content-body-strong', className)}
      {...props}
    >
      {children}
    </Text>
  );
};

const Actions = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return <div className={cn('mt-6 flex gap-4', className)} {...props} />;
};

ContentHeader.Title = Title;
ContentHeader.SubTitle = SubTitle;
ContentHeader.Actions = Actions;
