import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from 'utils';

import { Icon } from '../icon';

const checkVariants = cva(
  cn(
    'peer shrink-0 rounded-none border border-stroke-strong bg-fill-strongest',
    'hover:bg-fill-weak',
    'active:bg-fill-weaker',
    'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
    'disabled:cursor-not-allowed disabled:opacity-50',
    'data-[state=checked]:border-transparent data-[state=checked]:bg-fill-lemon data-[state=checked]:text-icon-constant',
  ),
  {
    variants: {
      size: {
        sm: 'h-4 w-4',
        lg: 'h-5 w-5',
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  },
);

interface Props
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
    VariantProps<typeof checkVariants> {}

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, Props>(
  ({ className, size, ...props }, ref) => (
    <CheckboxPrimitive.Root ref={ref} className={cn(checkVariants({ size, className }))} {...props}>
      <CheckboxPrimitive.Indicator className='flex items-center justify-center'>
        <Icon name='check' className='h-4 min-h-4 w-4 min-w-4' />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  ),
);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
