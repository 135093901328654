import { type ComponentProps } from 'react';

import { BaseCopilotMessages } from '@legalfly/components/copilot';

import CopilotMessage from './CopilotMessage';
import { useCopilotContext } from './CopilotProvider';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type Props = {
  offsetTop?: number;
} & Pick<
  ComponentProps<typeof CopilotMessage>,
  'onClickDocumentSnippet' | 'renderActions' | 'analyticsMeta'
>;

export const CopilotMessages = ({
  offsetTop = 0,
  onClickDocumentSnippet,
  renderActions,
  analyticsMeta,
}: Props) => {
  const {
    conversationUuid,
    conversationType,
    containerRef,
    isConversationLoaded,
    isLoadingConversation,
    conversation,
  } = useCopilotContext();

  if (!conversationUuid) {
    return null;
  }

  return (
    <BaseCopilotMessages
      containerRef={containerRef}
      isConversationLoaded={isConversationLoaded}
      isLoadingConversation={isLoadingConversation}
      conversation={conversation}
    >
      {({ scrollHeight }) => (
        <>
          {conversation?.messages.map((message, idx) => (
            <CopilotMessage
              key={message.uuid}
              minHeight={
                idx === conversation?.messages.length - 1 ? `${scrollHeight - offsetTop}px` : 'auto'
              }
              conversationUuid={conversationUuid}
              message={message}
              conversationType={conversationType}
              renderActions={renderActions}
              onClickDocumentSnippet={onClickDocumentSnippet}
              analyticsMeta={analyticsMeta}
            />
          ))}
        </>
      )}
    </BaseCopilotMessages>
  );
};
