import type { ReactNode } from 'react';
import { useState } from 'react';

import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@legalfly/ui/dialog';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';

interface Props {
  title: string;
  message: string;
  children: ReactNode;
}

const ErrorDialog = ({ title, message, children }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent
        onPointerDownOutside={(e) => {
          e.preventDefault();
        }}
      >
        <VisuallyHidden>
          <DialogDescription>{message}</DialogDescription>
        </VisuallyHidden>
        <DialogHeader closeButtonEnabled={false}>
          <Icon name='info-circle' className='text-content-body-error' />
          <DialogTitle className='text-subheading-semibold text-content-body-error'>
            {title}
          </DialogTitle>
        </DialogHeader>
        <DialogBody className='mb-6'>
          <Text>{message}</Text>
        </DialogBody>
        <DialogFooter>{children}</DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorDialog;
