import { useEffect, useRef, useState } from 'react';

import { useWindowSize } from '@legalfly/utils/hooks';

import { getCssVariable } from '../utils/getCssVariable';

export const useTableHeight = () => {
  const tableRef = useRef<HTMLTableElement>(null!);
  const size = useWindowSize();
  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    setTableHeight(
      size.height -
        tableRef.current?.getBoundingClientRect().top -
        getCssVariable('body-padding') -
        getCssVariable('content-padding-y'),
    );
  }, [size.height]);

  return { tableHeight, tableRef };
};
