import { useTranslation } from 'react-i18next';

import { Button } from '@legalfly/ui/button';
import { Card, CardIcon } from '@legalfly/ui/card';
import { Icon } from '@legalfly/ui/icon';
import { cn } from '@legalfly/ui/utils';

interface Props {
  onAddClause: () => void;
}

export const DraftEmptyClausesState = ({ onAddClause }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={cn('flex h-full flex-col items-center justify-center')}>
      <Card className='max-w-96'>
        <CardIcon name='paragraph-wrap' />
        <Button
          variant='soft'
          renderLeft={<Icon name='file-2' className='text-icon-strong' />}
          onClick={onAddClause}
        >
          {t('drafting.clauses.empty.addClause')}
        </Button>
      </Card>
    </div>
  );
};
