import { useTranslation } from 'react-i18next';
import { Link } from '@tanstack/react-router';

import type { ApiDocument } from '@legalfly/api/documents';
import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuSeparator,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
} from '@legalfly/ui/contextMenu';
import { Icon } from '@legalfly/ui/icon';
import { Spinner } from '@legalfly/ui/spinner';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';
import { getDocumentIcon } from 'core/modules/documents/helpers';
import { useFavorite } from 'core/modules/favorites';

import { useDetailPaneActions } from '../detailPane/useDetailPaneActions';
import { useSelectedDocuments } from '../documentPicker/SelectedDocumentsProvider';

interface Props {
  document: ApiDocument;
  selectedDocuments: ApiDocument[];
}

const DocumentsContextMenu = ({ document, selectedDocuments }: Props) => {
  const { t } = useTranslation();
  const { clearSelectedDocuments } = useSelectedDocuments();

  const documents = selectedDocuments?.length > 1 ? selectedDocuments : [document];

  const {
    isSingleFile,
    isSingleFolder,
    isSingleSelection,
    allowFolderActions,
    handleAnonymisation,
    handleDiscovery,
    handleReview,
    handleMultiReview,
    handleDownloadAnonimized,
    handleDownloadOriginal,
    handleDeleteDocuments,
    handleDeleteFile,
    handleDeleteFolder,
    isDownloadingAnonimized,
    isDownloadingOriginal,
  } = useDetailPaneActions(documents);

  const { favoriteDocument } = useFavorite();

  return (
    <ContextMenuContent className='w-64'>
      {!isSingleSelection && (
        <>
          <ContextMenuLabel className='flex items-center gap-3'>
            <Icon name='list' />
            {t('documents.documentPicker.amount_selected', {
              count: documents.length,
            })}
          </ContextMenuLabel>
          <ContextMenuSeparator />
        </>
      )}
      {isSingleSelection && (
        <ContextMenuItem>
          <Link
            to={isSingleFolder ? '/documents/folder/$uuid' : '/documents/file/$uuid'}
            params={{ uuid: documents[0].uuid }}
            onClick={clearSelectedDocuments}
            className='flex items-center gap-3'
          >
            <Icon name={getDocumentIcon(documents[0])} />
            <Text className='truncate' as='span'>
              {isSingleFolder
                ? t('documents.detailPane.openFolder')
                : t('documents.detailPane.open')}
            </Text>
          </Link>
        </ContextMenuItem>
      )}
      <ContextMenuItem
        onSelect={handleAnonymisation}
        disabled={!allowFolderActions}
        className='flex items-center gap-3'
      >
        <Icon name='shield-tick' />
        {t('nav.anonymisation')}
      </ContextMenuItem>
      {isSingleFile ? (
        <ContextMenuItem onSelect={handleReview} className='flex items-center gap-3'>
          <Icon name='check-done' />
          {t('nav.review')}
        </ContextMenuItem>
      ) : (
        <ContextMenuItem
          className='flex items-center gap-3'
          disabled={!allowFolderActions}
          onSelect={handleMultiReview}
        >
          <Icon name='table' />
          {t('nav.multi-review')}
        </ContextMenuItem>
      )}
      <ContextMenuItem
        onSelect={handleDiscovery}
        disabled={!allowFolderActions}
        className='flex items-center gap-3'
      >
        <Icon name='file-search' />
        {t('nav.discovery')}
      </ContextMenuItem>

      {isSingleFile && (
        <ContextMenuSub>
          <ContextMenuSubTrigger className='flex items-center gap-3'>
            <Icon name='download-1' />
            {t('documents.detailPane.download')}
          </ContextMenuSubTrigger>
          <ContextMenuSubContent className='w-60'>
            <ContextMenuItem
              className='flex items-center gap-3'
              onSelect={(e) => {
                e.preventDefault();
                handleDownloadAnonimized();
              }}
            >
              {isDownloadingAnonimized
                ? t('action.cancel')
                : t('documents.detailPane.download', {
                    count: 1,
                  })}
              {isDownloadingAnonimized && <Spinner />}
            </ContextMenuItem>
            <ContextMenuItem
              className='flex items-center gap-3'
              onSelect={(e) => {
                e.preventDefault();
                handleDownloadOriginal();
              }}
            >
              {isDownloadingOriginal
                ? t('action.cancel')
                : t('documents.detailPane.downloadOriginal')}
              {isDownloadingOriginal && <Spinner />}
            </ContextMenuItem>
          </ContextMenuSubContent>
        </ContextMenuSub>
      )}

      <ContextMenuSeparator />
      <ContextMenuItem
        onSelect={() => favoriteDocument(document)}
        className='flex items-center gap-3'
      >
        <Icon
          name='star'
          size='md'
          className={cn(
            document.favorited ? 'fill-icon-strongest' : 'fill-transparent stroke-icon-strongest',
          )}
        />
        {t(document.favorited ? 'action.unfavorite' : 'action.favorite')}
      </ContextMenuItem>
      {documents.length > 1 && (
        <ContextMenuItem onSelect={handleDeleteDocuments} className='flex items-center gap-3'>
          <Icon name='trash-2' />
          {t('action.delete')}
        </ContextMenuItem>
      )}
      {isSingleFile && (
        <ContextMenuItem onSelect={handleDeleteFile} className='flex items-center gap-3'>
          <Icon name='trash-2' />
          {t('action.deleteFile')}
        </ContextMenuItem>
      )}
      {isSingleFolder && (
        <ContextMenuItem onSelect={handleDeleteFolder} className='flex items-center gap-3'>
          <Icon name='trash-2' />
          {t('action.deleteFolder')}
        </ContextMenuItem>
      )}
    </ContextMenuContent>
  );
};

export default DocumentsContextMenu;
