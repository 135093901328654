import { useState } from 'react';

import { useDebounceCallback } from './useDebounceCallback';

/**
 * Hook that manages a value with debounced updates
 *
 * @param initialValue - The initial value
 * @param delay - Debounce delay in milliseconds
 */
export function useDebounceValue<T>(initialValue: T, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  const setValue = useDebounceCallback((newValue: T) => {
    setDebouncedValue(newValue);
  }, delay);

  return [debouncedValue, setValue] as const;
}
