import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDefaultJurisdiction } from 'common/hooks/useDefaultJurisdiction';

import { ConversationTypeEnum } from '@legalfly/api/conversations';
import type { ApiReviewDetail, ApiReviewItem } from '@legalfly/api/reviews';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@legalfly/ui/tabs';
import { withToasts } from '@legalfly/ui/toast';
import { Copilot } from 'components/conversations/copilot/Copilot';
import { useCreateConversationMessage } from 'core/modules/conversations';
import { conversationToasts } from 'core/modules/conversations/toasts';

import ReviewDetailItems from './ReviewDetailItems';
import { ReviewDetailPrompts } from './ReviewDetailPrompts';

type TabValues = 'copilot' | 'items';

interface Props {
  review: ApiReviewDetail;
  onHighlightItem: (snippetId: string, riskLevel: string) => void;
  activeSnippetTextId: string;
}

const ReviewDetailTabs = ({ review, onHighlightItem, activeSnippetTextId }: Props) => {
  const { t } = useTranslation();
  const defaultJurisdiction = useDefaultJurisdiction();

  const [tab, setTab] = useState<TabValues>('items');
  const { createConversationMessage } = useCreateConversationMessage({
    type: ConversationTypeEnum.REVIEW,
  });

  const conversationUuid = review.conversations[0]?.uuid;

  const handleSendMessage = async (msg: string) => {
    await withToasts(
      createConversationMessage({
        uuid: conversationUuid,
        context: {
          documentUuids: [review.document.uuid],
        },
        question: msg,
        jurisdiction: review.jurisdiction ?? defaultJurisdiction,
      }),
    )(conversationToasts.createConversation());
  };

  const handleClickExplainItem = (item: ApiReviewItem) => {
    trackEvent({
      action: 'click',
      category: 'reviewDetail',
      label: 'explain',
    });
    setTab('copilot');

    const prompt = `
      ${t('copilot.prompt.explain.preText')}
      <div class='bg-fill-weak p-4 my-4'>
        <p>${item.text}</p>
        <br/><br/>
        <p>${item.answer.text}</p>
      </div>
      ${t('copilot.prompt.explain.postText')}
    `.trim();
    handleSendMessage(prompt);
  };

  const handleClickRedraftItem = (item: ApiReviewItem) => {
    trackEvent({
      action: 'click',
      category: 'reviewDetail',
      label: 'redraft',
    });
    setTab('copilot');

    const prompt = `
      ${t('copilot.prompt.redraft.preText')}
      <div class='bg-fill-weak p-4 my-4'>
        <p>${item.text}</p>
        <br/><br/>
        <p>${item.answer.text}</p>
      </div>
      ${t('copilot.prompt.redraft.postText')}
    `.trim();
    handleSendMessage(prompt);
  };

  return (
    <Tabs
      onValueChange={(v) => {
        trackEvent(
          {
            action: 'click',
            category: 'reviewDetail',
            label: 'tab',
          },
          { tab: v },
        );
        setTab(v as TabValues);
      }}
      value={tab}
    >
      <div className='sticky top-0 z-50 bg-fill-maximal pb-4 pt-6'>
        <TabsList>
          <TabsTrigger value='copilot'>{t('review.detail.tabs.copilot')}</TabsTrigger>
          <TabsTrigger value='items'>{t('review.detail.tabs.items')}</TabsTrigger>
        </TabsList>
      </div>
      <TabsContent
        value='copilot'
        style={{
          height:
            // tabs padding top - 24px
            // tabs padding bottom - 16px
            // list padding bottom - 24px
            'calc(var(--lf-content-height) - var(--lf-tabs-list-height) - 24px - 16px - 24px)',
        }}
      >
        <Copilot
          conversationHasMessages
          onSendMessage={handleSendMessage}
          conversationType={ConversationTypeEnum.REVIEW}
          conversationUuid={conversationUuid}
        >
          <Copilot.ScrollContainer stickyClassName='hidden'>
            <ReviewDetailPrompts onSendMessage={handleSendMessage} />
            <Copilot.Messages
              onClickDocumentSnippet={onHighlightItem}
              analyticsMeta={{ category: 'reviewDetail' }}
            />
          </Copilot.ScrollContainer>
          <Copilot.Footer className='mt-auto w-full pt-4'>
            <Copilot.StopResponding />
            <Copilot.Input className='mb-0' />
          </Copilot.Footer>
        </Copilot>
      </TabsContent>
      <TabsContent value='items'>
        <ReviewDetailItems
          review={review}
          onClickExplain={handleClickExplainItem}
          onClickRedraft={handleClickRedraftItem}
          onClickHighlight={onHighlightItem}
          activeSnippetTextId={activeSnippetTextId}
        />
      </TabsContent>
    </Tabs>
  );
};

export default memo(ReviewDetailTabs);
