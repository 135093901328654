import { memo, useCallback, useEffect, useState } from 'react';
import type { DragEndEvent } from '@dnd-kit/core';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import type { ApiClause } from '@legalfly/api/drafting';

import SortableClauseItem from './SortableClauseItem';

interface SortableClausesListProps {
  clauses: ApiClause[];
  onAddClause: (sequence: number) => void;
  onUpdateClause: (clause: ApiClause) => Promise<ApiClause>;
  onDeleteClause: (clauseUuid: ApiClause['uuid']) => void;
  onMoveClause: (clauseUuid: string, newSequence: number) => void;
}

export const SortableClausesList = memo(
  ({
    clauses,
    onAddClause,
    onUpdateClause,
    onDeleteClause,
    onMoveClause,
  }: SortableClausesListProps) => {
    const [clausesOrder, setClausesOrder] = useState<ApiClause['uuid'][]>([]);

    useEffect(() => {
      setClausesOrder(clauses.sort((a, b) => a.sequence - b.sequence).map((clause) => clause.uuid));
    }, [clauses]);

    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      }),
    );

    const handleDragEnd = useCallback(
      (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
          const oldIndex = clausesOrder.findIndex((clause) => clause === active.id);
          const newIndex = clausesOrder.findIndex((clause) => clause === over?.id);

          setClausesOrder((prevOrder) => arrayMove(prevOrder, oldIndex, newIndex));
          onMoveClause(active.id.toString(), newIndex);
        }
      },
      [clausesOrder, onMoveClause],
    );

    const sortedClauses = clauses.sort(
      (a, b) => clausesOrder.indexOf(a.uuid) - clausesOrder.indexOf(b.uuid),
    );

    return (
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={clausesOrder} strategy={verticalListSortingStrategy}>
          <div className='flex flex-col gap-2'>
            {sortedClauses.map((clause) => (
              <SortableClauseItem
                key={clause.uuid}
                clause={clause}
                onAddClause={onAddClause}
                onUpdateClause={onUpdateClause}
                onDeleteClause={onDeleteClause}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    );
  },
);

SortableClausesList.displayName = 'SortableClausesList';
