import type * as React from 'react';
import type { AvatarImageProps } from '@radix-ui/react-avatar';

import { Avatar, AvatarFallback, AvatarImage } from './Avatar';

const getFirstLetter = (value: string) => value?.[0] ?? '';

interface Props extends React.ComponentProps<typeof Avatar> {
  avatarUrl: AvatarImageProps['src'] | null;
  firstName: string | undefined;
  lastName: string | undefined;
}

export const UserAvatar = ({ avatarUrl, firstName, lastName, ...props }: Props) => {
  return (
    <Avatar {...props}>
      {avatarUrl && <AvatarImage src={avatarUrl} alt={`${firstName} ${lastName}`} />}
      <AvatarFallback>
        {firstName && lastName ? `${getFirstLetter(firstName)}${getFirstLetter(lastName)}` : 'LF'}
      </AvatarFallback>
    </Avatar>
  );
};
