import { cn } from '@legalfly/ui/utils';

export const getItemClasses = (isActive: boolean, cardOpen: boolean, isExpanded: boolean) =>
  cn(
    'justify-start border-l-4 border-transparent px-4',
    cardOpen && 'gap-3',
    isActive && !isExpanded && 'border-fill-lemon bg-fill-hover-strongest',
  );

export const getIconClasses = (cardOpen: boolean) =>
  cn(
    'size-6 text-icon-weak group-[.open]:size-5 group-[.open]:min-h-5 group-[.open]:min-w-5',
    cardOpen && 'text-icon-weak',
  );

export const getTextClasses = (cardOpen: boolean, isExpanded: boolean) =>
  cn('hidden truncate', (cardOpen || isExpanded) && 'inline');
