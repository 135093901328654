import { useTranslation } from 'react-i18next';

import type { ApiAgentDetail } from '@legalfly/api/agents';
import { trackEvent } from '@legalfly/reporting/tracking';
import { type IconName } from '@legalfly/ui/icon';
import { withToasts } from '@legalfly/ui/toast';
import { agentToasts, useUpdateAgent } from 'core/modules/agents';

import { AgentForm } from '../AgentForm';

interface Props {
  agent: ApiAgentDetail;
  afterSubmit: () => void;
}

interface FormValues {
  name: string;
  icon: IconName;
}

export const EditAgentDialogForm = ({ agent, afterSubmit }: Props) => {
  const { t } = useTranslation();
  const { updateAgent, isUpdatingAgent } = useUpdateAgent();

  const defaultValues: FormValues = {
    name: agent.name,
    icon: agent.icon,
  };

  const handleUpdateAgent = async (values: FormValues) => {
    trackEvent({
      action: 'submit',
      category: 'agents',
      label: 'editAgent',
    });

    await withToasts(
      updateAgent({
        uuid: agent.uuid,
        body: {
          name: values.name,
          icon: values.icon,
        },
      }),
    )(agentToasts.createAgent());

    afterSubmit();
  };

  return (
    <AgentForm
      defaultValues={defaultValues}
      onSubmit={handleUpdateAgent}
      onCancel={afterSubmit}
      isLoading={isUpdatingAgent}
      submitLabel={t('action.updateAgent')}
    />
  );
};
