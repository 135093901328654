export class WebSocketError extends Error {
  constructor(
    message: string,
    public code: string,
    public reason: string,
    public url: string,
  ) {
    super(message);
    this.name = 'WebSocketError';
  }
}
