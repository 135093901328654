import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import { forwardRef, useImperativeHandle } from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';

import { cn } from 'utils';

import { useTabAnimation } from './useTabAnimation';
import './styles.css';

const Tabs = TabsPrimitive.Root;

const TabsList = forwardRef<
  ElementRef<typeof TabsPrimitive.List>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.List> & { noAnimation?: boolean }
>(({ className, noAnimation = false, ...props }, ref) => {
  const { listRef, backgroundRef } = useTabAnimation(noAnimation);
  useImperativeHandle(ref, () => listRef.current!, [listRef]);

  return (
    <TabsPrimitive.List
      ref={listRef}
      className={cn(
        'h-[var(--lf-tabs-list-height)] p-2',
        'relative top-0 z-10 w-full bg-fill-strongest',
        'flex items-center justify-start gap-2',
        className,
      )}
      {...props}
    >
      {props.children}
      {!noAnimation && <div ref={backgroundRef} className='lf-tab-background' />}
    </TabsPrimitive.List>
  );
});
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    tabIndex={0}
    role='button'
    className={cn(
      'z-10 flex w-full flex-1 items-center justify-center whitespace-nowrap px-4 py-2',
      'focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-4 focus-visible:outline-stroke-weak',
      className,
    )}
    {...props}
  />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = forwardRef<
  ElementRef<typeof TabsPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
      className,
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
