import i18n from 'i18next';

import { createToasts } from '@legalfly/ui/toast';

export const draftingToasts = createToasts({
  createDraftingTemplate: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.createDraftingTemplate.description'),
    }),
  }),
  createDraftingDraft: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.createDraftingDraft.description'),
    }),
  }),
  downloadDraftAsPdf: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.downloadDraftAsPdf.description'),
    }),
  }),
  downloadDraftAsDocx: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.downloadDraftAsDocx.description'),
    }),
  }),
  deleteDraftingTemplate: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deleteDraftingTemplate.description'),
    }),
  }),
  updateDraftingTemplate: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.updateDraftingTemplate.description'),
    }),
  }),
  updateDraft: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.updateDraft.description'),
    }),
  }),
  deleteDraft: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deleteDraft.description'),
    }),
  }),
  createDraftClause: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.createDraftClause.description'),
    }),
  }),
  updateDraftClause: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.updateDraftClause.description'),
    }),
  }),
  moveDraftClause: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.moveDraftClause.description'),
    }),
  }),
  deleteDraftClause: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deleteDraftClause.description'),
    }),
  }),
  createTemplateClause: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.createTemplateClause.description'),
    }),
  }),
  updateTemplateClause: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.updateTemplateClause.description'),
    }),
  }),
  moveTemplateClause: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.moveTemplateClause.description'),
    }),
  }),
  deleteTemplateClause: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deleteTemplateClause.description'),
    }),
  }),
});
