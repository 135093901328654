export enum ReviewStatus {
  RUNNING = 'running',
  IN_PROGRESS = 'in progress',
  ANOMALIES = 'anomalies',
  COMPLETED = 'completed',
}

export enum ReviewPlaybookItemStatus {
  CREATED = 'created',
  IN_PROGRESS = 'in progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}
