import * as React from 'react';

import { cn } from 'utils';

import { Icon } from '../icon';
import { Text } from '../text';

const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'flex flex-col items-center border border-stroke-frail bg-fill-maximal p-8 text-center',
        className,
      )}
      {...props}
    />
  ),
);
Card.displayName = 'Card';

const CardIcon = ({ className, ...props }: React.ComponentProps<typeof Icon>) => (
  <Icon size='lg' className={cn('mb-4 flex', className)} {...props} />
);
CardIcon.displayName = 'CardIcon';

const CardTitle = ({ className, ...props }: React.ComponentProps<typeof Text>) => (
  <Text variant='subheadingSemiBold' className={cn('flex', className)} {...props} />
);
CardTitle.displayName = 'CardTitle';

const CardSubtitle = ({ className, ...props }: React.ComponentProps<typeof Text>) => (
  <Text variant='bodyLight' className={cn('flex text-content-body-strong', className)} {...props} />
);
CardSubtitle.displayName = 'CardSubtitle';

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('flex pt-3', className)} {...props} />
  ),
);
CardFooter.displayName = 'CardFooter';

export { Card, CardFooter, CardIcon, CardSubtitle, CardTitle };
