import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiConversationMessage } from '@legalfly/api/conversations';
import type { ApiDocument, ApiDocumentSnippetEntity } from '@legalfly/api/documents';
import type { TrackingEventMeta } from '@legalfly/reporting/tracking';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button, IconButton } from '@legalfly/ui/button';
import { DialogTitle } from '@legalfly/ui/dialog';
import { Icon } from '@legalfly/ui/icon';
import { Sheet, SheetContent, SheetTrigger } from '@legalfly/ui/sheet';
import { Text } from '@legalfly/ui/text';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';
import { DocumentSnippetsViewerSheetContent } from 'components/documents/DocumentSnippetsViewerSheetContent';
import { useFile } from 'core/modules/documents';

export const InlineSource = ({
  question,
  element,
  sources,
  analyticsMeta,
  onClickDocumentSnippet,
}: {
  question: string;
  element: Element;
  sources: ApiConversationMessage['sources'];
  analyticsMeta: TrackingEventMeta;
  onClickDocumentSnippet?: (textId: string, riskLevel: string) => void;
}) => {
  const webUrl = element.getAttribute('data-web-url');

  if (webUrl) {
    return <InlineSourceWeb webUrl={webUrl} analyticsMeta={analyticsMeta} />;
  }

  const swissCaseLawId = element.getAttribute('data-swiss-case-law-id');

  if (swissCaseLawId) {
    return (
      <InlineSourceSwissCaseLaw
        swissCaseLawId={swissCaseLawId}
        sources={sources}
        analyticsMeta={analyticsMeta}
      />
    );
  }

  const documentUuid = element.getAttribute('data-document-uuid') ?? '';
  const snippetUuid = element.getAttribute('data-snippet-uuid') ?? '';

  const answer = element.parentElement
    ? element.parentElement.outerHTML.replace(element.outerHTML, '')
    : '';

  if (documentUuid && snippetUuid) {
    return (
      <Suspense>
        <InlineSourceDocument
          question={question}
          answer={answer}
          documentUuid={documentUuid}
          snippetUuid={snippetUuid}
          onClickDocumentSnippet={onClickDocumentSnippet}
          analyticsMeta={analyticsMeta}
        />
      </Suspense>
    );
  }

  return null;
};

const InlineSourceWeb = ({
  webUrl,
  analyticsMeta,
}: {
  webUrl: string;
  analyticsMeta: TrackingEventMeta;
}) => {
  const onClick = () => {
    trackEvent({
      action: 'click',
      category: analyticsMeta.category,
      label: 'inline',
    });

    window.open(webUrl, '_blank');
  };

  return (
    <IconButton
      name='globe-1'
      size='xs'
      variant='unstyled'
      className='relative bottom-[-2px] inline-flex'
      onClick={onClick}
    />
  );
};

const InlineSourceDocument = ({
  question,
  answer,
  documentUuid,
  snippetUuid,
  analyticsMeta,
  onClickDocumentSnippet,
}: {
  question: string;
  answer: string;
  documentUuid: ApiDocument['uuid'];
  snippetUuid: ApiDocumentSnippetEntity['uuid'];
  analyticsMeta: TrackingEventMeta;
  onClickDocumentSnippet?: (textId: string, riskLevel: string) => void;
}) => {
  const { file } = useFile(documentUuid);
  const onClick = () => {
    trackEvent({
      action: 'click',
      category: analyticsMeta.category,
      label: 'inline',
    });

    if (onClickDocumentSnippet) {
      onClickDocumentSnippet(snippetUuid, 'neutral');
    }
  };

  if (onClickDocumentSnippet) {
    return (
      <IconButton
        name='file-search'
        size='xs'
        variant='unstyled'
        onClick={onClick}
        className='relative bottom-[-2px] inline-flex'
      />
    );
  }

  return (
    <Sheet>
      <SheetTrigger asChild>
        <IconButton
          name='file-search'
          size='xs'
          variant='unstyled'
          className='relative bottom-[-2px] inline-flex'
        />
      </SheetTrigger>
      <DocumentSnippetsViewerSheetContent
        document={file}
        snippetIds={[snippetUuid]}
        activeSnippetTextId={snippetUuid}
        question={question}
        answer={answer}
      />
    </Sheet>
  );
};

const InlineSourceSwissCaseLaw = ({
  swissCaseLawId,
  sources,
  analyticsMeta,
}: {
  swissCaseLawId: string;
  sources: ApiConversationMessage['sources'];
  analyticsMeta: TrackingEventMeta;
}) => {
  const swissSource = sources
    .find((s) => s.type === 'swiss case law')
    ?.results.find((r) => r.id === Number(swissCaseLawId));

  const { t } = useTranslation();

  if (!swissSource) return null;

  const onClick = () => {
    trackEvent({
      action: 'click',
      category: analyticsMeta.category,
      label: 'inline',
    });
  };

  const onClickDetails = (url: URL) => {
    trackEvent({
      action: 'click',
      category: analyticsMeta.category,
      label: 'details',
    });

    window.open(url.href, '_blank');
  };

  return (
    <Sheet>
      <SheetTrigger asChild onClick={onClick}>
        <IconButton
          name='file-search'
          size='xs'
          variant='unstyled'
          className='relative bottom-[-2px] inline-flex'
        />
      </SheetTrigger>
      <SheetContent className='flex h-full w-auto flex-col gap-0 p-8 sm:max-w-[50%]'>
        <VisuallyHidden>
          <DialogTitle>{swissSource?.court}</DialogTitle>
        </VisuallyHidden>
        <Text as='h2' className='pb-4' variant='subheadingSemiBold'>
          {swissSource?.court}
        </Text>
        <Text>{swissSource?.snippet}</Text>
        {swissSource?.source &&
          (() => {
            const url = new URL(swissSource.source);
            return (
              <Button
                variant='hard'
                className='mt-4 flex justify-center gap-2'
                onClick={() => onClickDetails(url)}
              >
                <Icon name='flag-switzerland' />
                {t('action.visitWebPage', {
                  url: url.origin.replace(/^https?:\/\//, ''),
                })}
              </Button>
            );
          })()}
      </SheetContent>
    </Sheet>
  );
};
