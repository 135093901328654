export const setCssVariable = (
  variable:
    | 'tabs-list-height'
    | 'sidebar-width'
    | 'body-padding'
    | 'content-width'
    | 'content-header-height',
  value: string,
) => {
  document.documentElement.style.setProperty(`--lf-${variable}`, value);
};
