import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@legalfly/ui/dialog';
import { Icon } from '@legalfly/ui/icon';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';

import { FeedbackForm } from './FeedbackForm';

export const FeedbackDialog = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          variant='soft'
          size='sm'
          renderLeft={<Icon name='thumbs-up' />}
          onClick={() =>
            trackEvent({
              action: 'click',
              category: 'feedback',
              label: 'open',
            })
          }
        >
          {t('label.feedback')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <VisuallyHidden>
          <DialogDescription>{t('dialog.feedback.description')}</DialogDescription>
        </VisuallyHidden>
        <DialogHeader>
          <DialogTitle>{t('dialog.feedback.title')}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <FeedbackForm afterSubmit={() => setIsOpen(false)} />
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};
