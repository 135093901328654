import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiDocument } from '@legalfly/api/documents';
import { Button } from '@legalfly/ui/button';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@legalfly/ui/dialog';
import { Icon } from '@legalfly/ui/icon';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';

import CreateFolderForm from './CreateFolderForm';

const CreateFolderDialog = ({ uuid }: { uuid?: ApiDocument['uuid'] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button renderLeft={<Icon name='folder-plus' />} size='sm' variant='soft'>
          {t('action.createFolder')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <VisuallyHidden>
          <DialogDescription>{t('dialog.createFolder.description')}</DialogDescription>
        </VisuallyHidden>
        <DialogHeader>
          <Icon name='folder-plus' />
          <DialogTitle>{t('dialog.createFolder.title')}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <CreateFolderForm afterSubmit={() => setIsOpen(false)} uuid={uuid} />
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};

export default CreateFolderDialog;
