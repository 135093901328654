import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'common/utils/groupBy';

import { PlaybookItemTypeEnum } from '@legalfly/api/playbooks';
import type { ApiReviewDetail, ApiReviewItem } from '@legalfly/api/reviews';
import { ReviewStatus } from '@legalfly/api/reviews';
import { Button } from '@legalfly/ui/button';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@legalfly/ui/collapsible';
import { ProgressCard } from '@legalfly/ui/progress';
import { Text } from '@legalfly/ui/text';
import { useListenToReviewItem } from 'core/modules/reviews';

import { getCompletedItemsCount, sortReviewItemsByRisk } from './helpers';
import ReviewDetailItem from './ReviewDetailItem';

interface Props {
  review: ApiReviewDetail;
  onClickExplain: (item: ApiReviewItem) => void;
  onClickRedraft: (item: ApiReviewItem) => void;
  onClickHighlight: (snippetId: string, riskLevel: string) => void;
  activeSnippetTextId: string;
}

const ReviewItemListener = ({
  reviewUuid,
  itemUuid,
}: {
  reviewUuid: ApiReviewDetail['uuid'];
  itemUuid: ApiReviewItem['uuid'];
}) => {
  useListenToReviewItem({ reviewUuid, itemUuid });

  return null;
};

const ReviewDetailItems = ({ review, ...rest }: Props) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState<Record<string, boolean>>({});

  const items = review.items;
  const completedItemsCount = getCompletedItemsCount(items);

  const groupedItems = groupBy(items, (item) => {
    if (item.groupName === 'lf_anomaly') {
      return t('review.new.anomalies');
    }
    return item.groupName ?? t('review.ungrouped');
  });

  const toggleShowMore = (groupName: string) => {
    setShowMore((prev) => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  return (
    <div className='flex flex-col gap-4'>
      {review.status !== ReviewStatus.COMPLETED && (
        <ProgressCard
          title={t(
            review.status === ReviewStatus.ANOMALIES
              ? 'review.new.progress.anomalies.title'
              : 'review.new.progress.general.title',
          )}
          subTitle={t(
            review.status === ReviewStatus.ANOMALIES
              ? 'review.new.progress.anomalies.subTitle'
              : 'review.new.progress.general.subTitle',
          )}
          action={`${completedItemsCount}/${items.length}`}
        >
          <ProgressCard.Bar value={Math.ceil((completedItemsCount / items.length) * 100)} />
        </ProgressCard>
      )}
      <div className='flex flex-col gap-4'>
        {Object.entries(groupedItems).map(([groupName, items]) => {
          const completedItemsCount = getCompletedItemsCount(items);

          const primaryItems = items.filter(
            (item) =>
              item.answer.level === 'high' || item.type !== PlaybookItemTypeEnum.RISK_ASSESSMENT,
          );
          const secondaryItems = items.filter(
            (item) =>
              item.answer.level !== 'high' && item.type === PlaybookItemTypeEnum.RISK_ASSESSMENT,
          );

          const secondaryHasAnswer = secondaryItems.some((item) => Object.keys(item.answer).length);

          return (
            <Fragment key={groupName}>
              {items.map((item) => (
                <ReviewItemListener key={item.uuid} reviewUuid={review.uuid} itemUuid={item.uuid} />
              ))}
              <Collapsible className='flex flex-col gap-4 bg-surface-main'>
                <CollapsibleTrigger className='p-6'>
                  <Text className='truncate'>{groupName}</Text>
                  <Text className='opacity-50'>
                    {completedItemsCount === items.length
                      ? `${items.length} ${t('label.item', { count: items.length })}`
                      : `${completedItemsCount}/${items.length} ${t('label.item', { count: items.length })}`}
                  </Text>
                </CollapsibleTrigger>
                <CollapsibleContent className='bg-surface-main px-6 pb-6'>
                  {sortReviewItemsByRisk(primaryItems).map((item) => (
                    <ReviewDetailItem key={item.uuid} item={item} {...rest} />
                  ))}
                  {showMore[groupName] &&
                    sortReviewItemsByRisk(secondaryItems).map((item) => (
                      <ReviewDetailItem key={item.uuid} item={item} {...rest} />
                    ))}
                  {secondaryHasAnswer && !showMore[groupName] && secondaryItems.length > 0 && (
                    <Button
                      onClick={() => toggleShowMore(groupName)}
                      className='w-full justify-center'
                    >
                      {t('review.actions.showLowerRiskItems')}
                    </Button>
                  )}
                </CollapsibleContent>
              </Collapsible>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default ReviewDetailItems;
