import type { App } from '../../../../apps/legalfly-backend/src';
import type { Fetcher } from '../fetcher';
import { buildSearchParams } from '../fetcher/helpers/query';
import type { EdenFetch, ExtractRoutePaths, MergeObjects } from './types';

export const createClient =
  (fetcher: Fetcher): EdenFetch.Create<App> =>
  // @ts-expect-error we want to use the fixed return type (no inference)
  (url: string, options = {}) => {
    let endpoint = url;

    if (options.query) {
      const queryParams = buildSearchParams(options.query);
      endpoint += `?${queryParams}`;
    }

    if (options.params) {
      Object.entries(options.params).forEach(([key, value]) => {
        if (value !== undefined) {
          endpoint = endpoint.replace(`:${key}`, value as string);
        } else {
          endpoint = endpoint.replace(`/:${key}`, '');
        }
      });
    }

    return fetcher.request(endpoint, options as RequestInit);
  };

type FlattenedSchema = MergeObjects<ExtractRoutePaths<App['_routes']>>;
type Paths = keyof FlattenedSchema;

export type ResponseOf<Path extends Paths> = FlattenedSchema[Path]['response'][200];
export type BodyOf<Path extends Paths> = FlattenedSchema[Path]['body'];
export type ParamsOf<Path extends Paths> = FlattenedSchema[Path]['params'];
export type QueryOf<Path extends Paths> = FlattenedSchema[Path]['query'];
