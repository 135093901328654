import { useTranslation } from 'react-i18next';
import { createFileRoute } from '@tanstack/react-router';

import { trackEvent } from '@legalfly/reporting/tracking';
import headerBackgroundVideo from '@legalfly/ui/assets/headerBackgroundVideo.mp4';
import { Button } from '@legalfly/ui/button';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';
import { Content } from 'components/common/content/Content';
import { DashboardCards } from 'components/dashboard/DashboardCards';
import { DashboardStats } from 'components/dashboard/DashboardStats';
import { OfficeWordLogo } from 'components/dashboard/OfficeWordLogo';
import { statisticsQueryOptions, useStatistics } from 'core/modules/statistics';
import { useCurrentUser } from 'core/modules/users';

export const Route = createFileRoute('/_auth/_layout/')({
  component: IndexRoute,
  loader: ({ context }) => context.queryClient.ensureQueryData(statisticsQueryOptions.statistics()),
});

const officeAppSourceUrl = 'https://appsource.microsoft.com/nl-nl/product/office/wa200007298';

function IndexRoute() {
  const { currentUser } = useCurrentUser();
  const { t } = useTranslation();
  const { statistics } = useStatistics();

  const onClickOfficeWord = () => {
    trackEvent({
      action: 'click',
      category: 'dashboard',
      label: 'installOfficeWord',
    });

    window.open(officeAppSourceUrl, '_blank');
  };

  return (
    <Content className='bg-transparent' innerStyle={{ padding: 0 }}>
      <div className='relative mb-6 flex h-[450px] w-full flex-col justify-center border border-stroke-frail bg-surface-nav px-20 py-6'>
        <div
          className='absolute left-0 top-0 z-10 h-full w-full'
          style={{
            background: 'linear-gradient(270deg, rgba(17, 19, 22, 0.00) 20.24%, #111316 82.9%)',
          }}
        />
        <div className='flex h-full w-full flex-col justify-center'>
          <video
            id='background-video'
            loop
            autoPlay
            muted
            className='absolute left-0 top-0 h-full w-full object-cover'
          >
            <track kind='captions' />
            <source src={headerBackgroundVideo} type='video/mp4' />
          </video>
          <div className='z-10 flex min-h-[300px] w-2/5 flex-col justify-center'>
            <Text variant='display' as='h1' className='text-surface-white'>
              {t('dashboard.hero.title', { name: currentUser.firstName })}
            </Text>
            <Text
              variant='bodyLarge'
              className='text-content-body-strong light:text-content-body-weak'
            >
              {t('dashboard.hero.subtitle')}
            </Text>
          </div>
        </div>
        <div className='absolute bottom-6 right-6 z-10 max-w-64 border border-stroke-weaker bg-fill-strongest bg-opacity-5 p-6 backdrop-blur-md'>
          <OfficeWordLogo className='h-10 w-10' />
          <Text className='mt-5 text-surface-white' variant='bodySemiBold'>
            {t('dashboard.officeWord.title', { name: currentUser.firstName })}
          </Text>
          <Text className='mt-2 text-surface-white' variant='bodyLight'>
            {t('dashboard.officeWord.subtitle')}
          </Text>
          <Button
            className='mt-3 w-full justify-center text-surface-white hover:text-surface-invert'
            variant='hard'
            onClick={onClickOfficeWord}
          >
            {t('dashboard.officeWord.action')}
          </Button>
        </div>
      </div>
      <div
        className={cn(
          'grid grid-flow-col gap-6 sm:grid-cols-2 md:grid-rows-4 xl:grid-cols-4 xl:grid-rows-2',
          statistics['total'] === 0 && 'xl:grid-cols-3',
        )}
      >
        {statistics['total'] > 0 && <DashboardStats statistics={statistics} />}
        <DashboardCards />
      </div>
    </Content>
  );
}
