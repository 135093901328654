import { forwardRef, type HTMLAttributes } from 'react';

import { cn } from '../../utils';

const List = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ children, className }, ref) => {
    return (
      <div className={cn('flex flex-col', className)} ref={ref} role='list'>
        {children}
      </div>
    );
  },
);
List.displayName = 'List';

export { List };
