import type { PropsWithChildren, ReactNode } from 'react';
import React, { useCallback, useMemo } from 'react';

import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';

import { BaseCopilotSkeleton } from './BaseCopilotSkeleton';

type Props = React.HTMLAttributes<HTMLDivElement> &
  PropsWithChildren<{
    minHeight: string;
  }>;

export const BaseCopilotMessage = ({ minHeight, children, ...props }: Props) => (
  <div
    className='copilot-message mx-auto mb-5 flex w-full flex-col last:mb-0'
    style={{ minHeight }}
    {...props}
  >
    {children}
  </div>
);

interface CopilotMessageQuestionProps extends React.HTMLAttributes<HTMLDivElement> {
  question: string;
  avatar: ReactNode;
}

const CopilotMessageQuestion = ({ question, avatar, ...props }: CopilotMessageQuestionProps) => (
  <div
    className='mb-3 ms-auto flex w-fit items-center gap-4 border border-stroke-weaker bg-fill-strongest px-5 py-3'
    {...props}
  >
    <div dangerouslySetInnerHTML={{ __html: question }} />
    {avatar}
  </div>
);

type CopilotMessageActionsProps = PropsWithChildren & React.HTMLAttributes<HTMLDivElement>;

const CopilotMessageActions = ({ children, ...props }: CopilotMessageActionsProps) => (
  <div className='flex items-center justify-between px-4 py-3' {...props}>
    {children}
  </div>
);

type CopilotMessageResponseProps = PropsWithChildren<{
  assistantMessage: string;
  renderInlineElement?: (element: Element, index: number) => ReactNode;
  renderHtmlElement?: (element: HTMLElement, index: number) => ReactNode;
}>;

const CopilotMessageResponse = ({
  assistantMessage,
  renderInlineElement,
  renderHtmlElement,
  children,
}: CopilotMessageResponseProps) => {
  const renderElement = useCallback(
    (element: Element, index: number): React.ReactNode => {
      const inlineElement = renderInlineElement?.(element, index);
      if (inlineElement) return inlineElement;

      const children = Array.from(element.childNodes).map((child, childIndex) => {
        if (child.nodeType === Node.ELEMENT_NODE)
          return renderElement(child as Element, childIndex);
        if (child.nodeType === Node.TEXT_NODE) return child.textContent;
        return null;
      });

      if (children.length === 0) {
        return React.createElement(element.tagName.toLowerCase(), {
          key: `${element.tagName.toLowerCase()}-${index}`,
        });
      }

      return React.createElement(
        element.tagName.toLowerCase(),
        {
          key: `${element.tagName.toLowerCase()}-${index}`,
          className: element.className || undefined,
        },
        children,
      );
    },
    [renderInlineElement],
  );

  const tags = useMemo(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(assistantMessage, 'text/html');

    if (doc.body.children.length === 0 && doc.body.textContent) {
      return [doc.body.textContent];
    }

    return Array.from(doc.body.childNodes).map((node, index) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return (
          <div
            key={index}
            className='prose dark:prose-invert w-full text-body duration-700 animate-in fade-in'
          >
            {node.textContent}
          </div>
        );
      }

      const el = node as HTMLElement;
      const htmlElement = renderHtmlElement?.(el, index);
      if (htmlElement) return htmlElement;

      if (el.tagName.toLowerCase() === 'p' && el.querySelector('var')) {
        const varElement = el.querySelector('var');
        if (varElement) {
          return (
            <Text key={index} className='flex items-center'>
              <Icon name='list' className='mr-2' />
              <var className='not-italic'>{varElement.textContent}</var>
            </Text>
          );
        }
      }

      return (
        <div
          key={index}
          className='prose dark:prose-invert w-full text-body duration-700 animate-in fade-in'
        >
          {renderElement(el, index)}
        </div>
      );
    });
  }, [assistantMessage, renderElement, renderHtmlElement]);

  if (tags.length === 0) {
    return (
      <div className='border border-stroke-weaker bg-fill-weak p-7'>
        <BaseCopilotSkeleton />
      </div>
    );
  }

  return (
    <div className='border border-stroke-weaker'>
      <div className='flex flex-col gap-4 border-b border-stroke-weaker bg-fill-strong p-7'>
        {tags.map((tag, index) =>
          typeof tag === 'string' ? (
            <div
              key={index}
              className='prose dark:prose-invert  w-full text-body duration-700 animate-in fade-in'
              dangerouslySetInnerHTML={{ __html: tag }}
            />
          ) : (
            tag
          ),
        )}
      </div>
      {children}
    </div>
  );
};

BaseCopilotMessage.Question = CopilotMessageQuestion;
BaseCopilotMessage.Response = CopilotMessageResponse;
BaseCopilotMessage.Actions = CopilotMessageActions;
