import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { FormApi } from '@tanstack/react-form';
import { useForm } from '@tanstack/react-form';
import DOMPurify from 'dompurify';

import { type ApiClause, ClauseTypeEnum } from '@legalfly/api/drafting';
import { Divider } from '@legalfly/ui/divider';
import { Editor } from '@legalfly/ui/editor';
import { TextareaAutoSize } from '@legalfly/ui/textarea';
import { requiredValidator } from 'components/common/form/validations';

import { ClauseInstructions } from './ClauseInstructions';

interface Props {
  clause: ApiClause;
  onUpdateClause: (clause: ApiClause) => Promise<ApiClause>;
}

const ClauseItem = ({ clause, onUpdateClause }: Props) => {
  const { t } = useTranslation();

  const defaultValues = {
    title: clause.title,
    content: clause.content,
  };

  type FormValues = typeof defaultValues;

  const handleSubmit = async ({
    value,
    formApi,
  }: {
    value: FormValues;
    formApi: FormApi<FormValues>;
  }) => {
    if (formApi.state.isDirty) {
      onUpdateClause({
        ...clause,
        title: value.title,
        content: value.content,
      });
    }
  };

  const handleUpdateInstructions = (instructions: string[]) => {
    onUpdateClause({
      ...clause,
      instructions,
    });
  };

  function getSanitizedValue(value: string) {
    return DOMPurify.sanitize(value, {
      ALLOWED_TAGS: ['h1', 'h2', 'h3', 'p', 'b', 'i', 'u', 'ol', 'ul', 'li', 'strong', 'em', 's'],
      FORBID_ATTR: ['style', 'class'],
    });
  }

  const form = useForm<FormValues>({
    defaultValues,
    onSubmit: handleSubmit,
  });

  const [editorRefreshKey, setEditorRefreshKey] = useState(Date.now().toString());

  useEffect(() => {
    form.setFieldValue('title', clause.title);
    form.setFieldValue('content', clause.content);
    setEditorRefreshKey(Date.now().toString());
  }, [clause, form]);

  return (
    <form
      className='group flex flex-1 flex-col items-start gap-6 border border-transparent bg-fill-maximal p-6 group-focus-within:border-stroke-weak group-focus-within:hover:border-solid group-hover:border-dashed group-hover:border-stroke-weak'
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <form.Field name='title'>
        {(field) => (
          <TextareaAutoSize
            id={field.name}
            name={field.name}
            value={field.state.value}
            onChange={(e) => field.handleChange(e.target.value)}
            rows={1}
            onBlur={() => {
              field.handleBlur();
              form.handleSubmit();
            }}
            placeholder={t('drafting.clauses.form.title.placeholder')}
            className='border-none bg-transparent p-0 text-subheading-semibold focus-visible:outline-0'
          />
        )}
      </form.Field>
      <form.Field
        name='content'
        validators={{
          onBlur: ({ value }) =>
            requiredValidator(t('drafting.clauses.form.content.validation.required'))(value),
        }}
      >
        {(field) => (
          <Editor
            key={editorRefreshKey}
            value={field.state.value}
            onChange={(e) => {
              field.handleChange(getSanitizedValue(e.editor.getHTML()));
            }}
            onBlur={() => {
              form.handleSubmit();
              field.handleBlur();
            }}
            errorMessage={field.state.meta.errors.join()}
            className='prose min-w-[150px] text-body-light text-content-body-strong dark:prose-invert focus:outline-none'
          >
            <Editor.Toolbar className='absolute bottom-[-4.1rem] left-0 opacity-0 group-focus-within:opacity-100'>
              {clause.type === ClauseTypeEnum.TEMPLATE && (
                <>
                  <Divider variant='strong' orientation='vertical' className='mx-5' />
                  <ClauseInstructions clause={clause} onSave={handleUpdateInstructions} />
                </>
              )}
            </Editor.Toolbar>
          </Editor>
        )}
      </form.Field>
    </form>
  );
};

export default memo(ClauseItem);
