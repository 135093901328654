import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiPlaybook, ApiPlaybookItem } from '@legalfly/api/playbooks';
import { trackEvent } from '@legalfly/reporting/tracking';
import { IconButton } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { Spinner } from '@legalfly/ui/spinner';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { useDeletePlaybookItem } from 'core/modules/playbooks';
import { playbookToasts } from 'core/modules/playbooks/toasts';

interface Props {
  playbookUuid: ApiPlaybook['uuid'];
  itemUuid: ApiPlaybookItem['uuid'];
  afterDelete: () => void;
}

export const PlaybookItemsListMenu = ({ itemUuid, playbookUuid, afterDelete }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { deletePlaybookItem, isLoading: isLoadingDeletePlaybook } = useDeletePlaybookItem();

  const handleDeletePlaybookItem = async () => {
    trackEvent({
      action: 'click',
      category: 'playbooksItem',
      label: 'delete',
    });
    await withToasts(deletePlaybookItem({ playbookUuid, itemUuid }))(
      playbookToasts.deletePlaybook(),
    );
    afterDelete();
  };

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <IconButton
          name='dots-horizontal'
          className={cn('rotate-90', isOpen && 'bg-fill-hover-strong')}
          variant='tertiary'
          size='sm'
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className='flex flex-col gap-2' align='end' data-dropdown-menu>
        <DropdownMenuItem
          onClick={() => {
            handleDeletePlaybookItem();
          }}
        >
          {isLoadingDeletePlaybook ? (
            <Spinner />
          ) : (
            <Icon name='trash-2' className='text-icon-weak' />
          )}
          {t('action.deletePlaybookItem')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
