import i18n from 'i18next';

import { createToasts } from '@legalfly/ui/toast';

export const userToasts = createToasts({
  updateAvatar: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.updateAvatar.description'),
    }),
  }),
  updateUser: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.updateUser.description'),
    }),
  }),
  createUser: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.createUser.description'),
    }),
  }),
  deleteUser: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deleteUser.description'),
    }),
  }),
  activateUser: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.activateUser.description'),
    }),
  }),
  deactivateUser: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deactivateUser.description'),
    }),
  }),
});
