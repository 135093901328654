import i18n from 'i18next';

import { createToasts } from '@legalfly/ui/toast';

export const reviewToasts = createToasts({
  createReview: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.createReview.description'),
    }),
  }),
  deleteReview: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deleteReview.description'),
    }),
  }),
});
