import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiAnonymization } from '@legalfly/api/anonymization';
import { trackEvent } from '@legalfly/reporting/tracking';
import { IconButton } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { Spinner } from '@legalfly/ui/spinner';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { useDeleteAnonymization } from 'core/modules/anonymization';
import { anonymizationToasts } from 'core/modules/anonymization/toasts';

interface Props {
  anonymization: ApiAnonymization;
}

export const AnonymizationsMenu = ({ anonymization }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { deleteAnonymization, isLoading: isDeletingAnonymization } = useDeleteAnonymization();

  const handleDeleteAnonymization = () => {
    trackEvent({
      action: 'click',
      category: 'anonymisation',
      label: 'delete',
    });
    withToasts(deleteAnonymization({ uuid: anonymization.uuid }))(anonymizationToasts.delete());
  };

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <IconButton
          name='dots-horizontal'
          className={cn('rotate-90', isOpen && 'bg-fill-hover-strong')}
          variant='tertiary'
          size='sm'
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className='flex flex-col gap-2' align='end'>
        <DropdownMenuItem onClick={handleDeleteAnonymization}>
          {isDeletingAnonymization ? (
            <Spinner />
          ) : (
            <Icon name='trash-2' className='text-icon-weak' />
          )}
          {t('action.deleteAnonymization')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
