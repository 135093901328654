import { useCallback, useLayoutEffect, useState } from 'react';

import { useDebounceCallback } from './useDebounceCallback';

interface WindowSize {
  width: number;
  height: number;
}

export function useWindowSize({ delay = 50 }: { delay?: number } = {}) {
  const [windowSize, setWindowSize] = useState<WindowSize>(() => ({
    width: window.innerWidth,
    height: window.innerHeight,
  }));

  const debouncedSetWindowSize = useDebounceCallback(setWindowSize, delay);

  const handleSize = useCallback(() => {
    const setSize = delay ? debouncedSetWindowSize : setWindowSize;

    setSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, [delay, debouncedSetWindowSize, setWindowSize]);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleSize);

    return () => window.removeEventListener('resize', handleSize);
  }, [handleSize]);

  return windowSize;
}
