import { useTranslation } from 'react-i18next';

import type { ApiConversationMessageSourceWeb } from '@legalfly/api/conversations';
import { Button } from '@legalfly/ui/button';

import { ConversationMessageSource } from './ConversationMessageSource';

export const WebSource = ({ results }: { results: ApiConversationMessageSourceWeb['results'] }) => {
  const { t } = useTranslation();

  return (
    <>
      {results.map((result, index) => (
        <ConversationMessageSource key={index} result={result}>
          <ConversationMessageSource.Title icon='globe-1' />
          <div className='mt-6 flex flex-col gap-5'>
            <Button variant='soft' className='self-start text-content-body-weak'>
              {t('action.visitWebPage', {
                url: new URL(result.url ?? '').origin.replace(/^https?:\/\//, ''),
              })}
            </Button>
          </div>
        </ConversationMessageSource>
      ))}
    </>
  );
};
