import { useTranslation } from 'react-i18next';

import { Button, IconButton } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { cn } from '@legalfly/ui/utils';
import { useSnippetHighlights } from 'core/modules/documents/helpers/useSnippetHighlights';

interface Props {
  snippetIds: string[];
  onHighlight?: (snippetId: string, riskLevel: string) => void;
  riskLevel: string;
  startIndex: number;
  activeSnippetTextId: string;
}

export const SnippetHighlightButtons = ({
  snippetIds,
  onHighlight,
  riskLevel,
  startIndex,
  activeSnippetTextId,
}: Props) => {
  const { t } = useTranslation();

  const { currentIndex, handlePrevious, handleNext, handleHighlightClick } = useSnippetHighlights({
    index: startIndex,
    snippetIds,
    onHighlight,
    riskLevel,
    activeSnippetTextId,
  });

  if (!snippetIds.length) {
    return null;
  }

  if (snippetIds.length === 1) {
    return (
      <Button
        size='sm'
        variant='soft'
        renderLeft={<Icon size='sm' name='file-search-3' className='text-icon-strongest' />}
        onClick={handleHighlightClick}
        className={cn('border', {
          'border-stroke-strongest': activeSnippetTextId === snippetIds[currentIndex],
        })}
      >
        {t('review.detail.items.action.highlight')}
      </Button>
    );
  }

  return (
    <div
      className={cn('flex border border-transparent', {
        'border-stroke-strongest': activeSnippetTextId === snippetIds[currentIndex],
      })}
    >
      <IconButton
        className='border-e-0'
        size='sm'
        variant='soft'
        onClick={handlePrevious}
        name='chevron-left'
      />
      <Button
        size='sm'
        variant='soft'
        renderLeft={<Icon size='sm' name='search-md' className='text-icon-strongest' />}
        onClick={handleHighlightClick}
      >
        {`${currentIndex === -1 ? 1 : currentIndex + 1}/${snippetIds.length}`}
      </Button>
      <IconButton
        className='border-s-0'
        size='sm'
        variant='soft'
        onClick={handleNext}
        name='chevron-right'
      />
    </div>
  );
};
