import { useTranslation } from 'react-i18next';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

import { ConversationTypeEnum } from '@legalfly/api/conversations';
import { FavoriteTypeEnum } from '@legalfly/api/favorites';
import { Button, IconButton } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { SidePaneProvider } from '@legalfly/ui/sidePane';
import { Text } from '@legalfly/ui/text';
import { withToasts } from '@legalfly/ui/toast';
import { AgentSandboxConversation } from 'components/agents/conversation/AgentSandboxConversation';
import { EditAgentDialog } from 'components/agents/edit/EditAgentDialog';
import { UpdateAgentForm } from 'components/agents/edit/UpdateAgentForm';
import { FavoriteButton } from 'components/common/button/FavoriteButton';
import { ContentRow } from 'components/common/content/ContentRow';
import { routeRedirectWithFeatureFlag } from 'core/analytics';
import { agentsQueryOptions, agentToasts, useAgent } from 'core/modules/agents';
import { useDeleteAgent } from 'core/modules/agents/index';
import { conversationsQueryOptions } from 'core/modules/conversations';

export const Route = createFileRoute('/_auth/_layout/agents/manage/$agentUuid')({
  beforeLoad: async ({ context }) =>
    routeRedirectWithFeatureFlag(context.queryClient, 'custom-agents'),
  component: RouteComponent,
  loader: async ({ context, params }) => {
    const agent = await context.queryClient.ensureQueryData(
      agentsQueryOptions.agent({ uuid: params.agentUuid }),
    );

    if (agent.sandboxConversationUuid) {
      await context.queryClient.ensureQueryData(
        conversationsQueryOptions.conversation({
          type: ConversationTypeEnum.AGENT,
          uuid: agent.sandboxConversationUuid,
        }),
      );
    }
  },
});

function RouteComponent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { agentUuid } = Route.useParams();

  const { agent } = useAgent({ uuid: agentUuid });
  const { deleteAgent } = useDeleteAgent();

  const handleDelete = async () => {
    await withToasts(deleteAgent({ uuid: agentUuid }))(agentToasts.deleteAgent());
    navigate({ to: '/agents/manage' });
  };

  return (
    <SidePaneProvider>
      <div className='flex flex-1 flex-col gap-3'>
        <ContentRow className='justify-between'>
          <div className='flex items-center gap-2'>
            <IconButton
              name='chevron-left'
              onClick={() => navigate({ to: '/agents/manage' })}
              variant='tertiary'
              size='sm'
            />
            <Text>{agent.name}</Text>
            <EditAgentDialog agent={agent} />
          </div>
          <div className='flex items-center gap-2'>
            <FavoriteButton entityType={FavoriteTypeEnum.AGENT} entity={agent} />
            <Button variant='soft' renderLeft={<Icon name='trash-2' />} onClick={handleDelete}>
              {t('action.delete')}
            </Button>
          </div>
        </ContentRow>
        <div className='relative grid grid-cols-1 gap-3 lg:grid-cols-2'>
          <UpdateAgentForm agent={agent} />
          <AgentSandboxConversation agent={agent} />
        </div>
      </div>
    </SidePaneProvider>
  );
}
