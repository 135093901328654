import { useSuspenseQuery } from '@tanstack/react-query';

import type { StatisticsApi } from '@legalfly/api/statistics';

import { statisticsQueryOptions } from './statisticsQueryOptions';

export const createStatisticsModule = ({ statisticsApi }: { statisticsApi: StatisticsApi }) => {
  const queryOptions = statisticsQueryOptions({ statisticsApi });

  const useStatistics = () => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.statistics());

    return {
      statistics: data,
      isLoading,
      error,
    };
  };

  return {
    useStatistics,
    statisticsQueryOptions: queryOptions,
  };
};
