import * as React from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';

import { cn } from 'utils';

const ProgressBar = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(({ className, value, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn(
      'relative h-[6px] w-full overflow-hidden rounded-full bg-surface-main',
      className,
    )}
    {...props}
  >
    <ProgressPrimitive.Indicator
      className='absolute left-0 top-[2px] h-[2px] rounded-full bg-fill-lemon transition-all duration-300 ease-linear'
      style={{
        width: `${value || 0.5}%`,
        boxShadow:
          '0px 0px 5px 2px rgba(239, 246, 38, 0.10), 0px 0px 24px 4px rgba(239, 246, 38, 0.30), 0px 0px 250px 231px rgba(239, 246, 38, 0.20)',
      }}
    />
  </ProgressPrimitive.Root>
));
ProgressBar.displayName = 'ProgressBar';

export { ProgressBar };
