import i18n from 'i18next';

import { createToasts } from '@legalfly/ui/toast';

export const agentToasts = createToasts({
  createAgent: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.createAgent.description'),
    }),
  }),
  updateAgent: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.updateAgent.description'),
    }),
  }),
  deleteAgent: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deleteAgent.description'),
    }),
  }),
});
