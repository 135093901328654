import type { HTMLAttributes } from 'react';

import { cn } from '@legalfly/ui/utils';

type SkeletonLineProps = {
  width: string;
  delay: number;
} & HTMLAttributes<HTMLDivElement>;

const SkeletonLine = ({ width, delay, className }: SkeletonLineProps) => (
  <div
    // eslint-disable-next-line tailwindcss/no-contradicting-classname
    className={cn(
      `h-2 rounded ${width} mb-2.5 animate-skeleton bg-gradient-to-r from-stroke-weaker from-0% via-stroke-frail via-stroke-strong via-stroke-weak via-25% via-50% via-75% to-stroke-strongest to-100% bg-[length:200%_100%]`,
      className,
    )}
    style={{ animationDelay: `${delay}s` }}
  />
);

export const BaseCopilotSkeleton = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn('p-0', className)} {...props}>
      <SkeletonLine width='w-full' delay={0} />
      <SkeletonLine width='w-4/5' delay={0.5} />
      <SkeletonLine width='w-3/5' delay={1} />
    </div>
  );
};

BaseCopilotSkeleton.displayName = 'BaseCopilotSkeleton';
