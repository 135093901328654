import { useTranslation } from 'react-i18next';

import { Card, CardFooter, CardIcon, CardSubtitle, CardTitle } from '@legalfly/ui/card';
import { cn } from '@legalfly/ui/utils';
import { CreateDraftingTemplateDialog } from 'components/drafting/templates/CreateDraftingTemplateDialog';

export const TemplatesEmptyState = () => {
  const { t } = useTranslation();

  return (
    <div className={cn('flex flex-1 flex-grow flex-col items-center justify-center')}>
      <Card className='max-w-96'>
        <CardIcon name='upload-2' />
        <CardTitle>{t('drafting.templates.empty.title')}</CardTitle>
        <CardSubtitle>{t('drafting.templates.empty.description')}</CardSubtitle>
        <CardFooter>
          <CreateDraftingTemplateDialog />
        </CardFooter>
      </Card>
    </div>
  );
};
