import { createFileRoute } from '@tanstack/react-router';

import { Layout } from 'components/common/layout/Layout';

export const Route = createFileRoute('/_auth/_layout')({
  component: CommonLayout,
});

function CommonLayout() {
  return <Layout />;
}
