import { type ComponentProps, type FC, useCallback } from 'react';
import { useNavigate } from '@tanstack/react-router';

import type { ApiDocument } from '@legalfly/api/documents';

import DocumentPicker from './documentPicker/DocumentPicker';

interface DocumentsListProps
  extends Pick<
    ComponentProps<typeof DocumentPicker>,
    'allowSelection' | 'allowDelete' | 'onDropDocument'
  > {
  documents: ApiDocument[];
}

export const DocumentsList: FC<DocumentsListProps> = ({ documents, ...props }) => {
  const navigate = useNavigate();

  const handleSelectFolder = useCallback(
    (document: ApiDocument) => {
      navigate({
        to: '/documents/folder/$uuid',
        params: { uuid: document.uuid },
      });
    },
    [navigate],
  );

  return (
    <DocumentPicker
      documents={documents}
      onSelectFolder={handleSelectFolder}
      allowSelection={false}
      allowMultiple={false}
      {...props}
    />
  );
};
