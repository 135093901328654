import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import type { AnonymizationApi } from '@legalfly/api/anonymization';

import { anonymizationQueryOptions } from './anonymizationQueryOptions';

export const createAnonymizationModule = ({
  anonymizationApi,
}: {
  anonymizationApi: AnonymizationApi;
}) => {
  const queryOptions = anonymizationQueryOptions({ anonymizationApi });

  const useAnonymizations = () => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.anonymizations());

    return {
      anonymizations: data,
      isLoading,
      error,
    };
  };

  const useAnonymization = ({ uuid }: { uuid: string }) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.anonymization({ uuid }));

    return {
      anonymization: data,
      isLoading,
      error,
    };
  };

  const useCreateAnonymization = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['anonymizations', 'create'],
      mutationFn: anonymizationApi.createAnonymization,
      onSuccess: (anonymization) => {
        queryClient.setQueryData(queryOptions.anonymizations().queryKey, (anonymizations) => {
          return [...(anonymizations ?? []), anonymization];
        });

        queryClient.setQueryData(
          queryOptions.anonymization({ uuid: anonymization.uuid }).queryKey,
          anonymization,
        );
      },
    });

    return {
      createAnonymization: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDeleteAnonymization = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['anonymizations', 'delete'],
      mutationFn: anonymizationApi.deleteAnonymization,
      onSuccess: (_, { uuid }) => {
        queryClient.setQueryData(queryOptions.anonymizations().queryKey, (anonymizations) => {
          return (anonymizations ?? []).filter((p) => p.uuid !== uuid);
        });
        queryClient.removeQueries(queryOptions.anonymization({ uuid }));
      },
    });

    return {
      deleteAnonymization: mutateAsync,
      isLoading: isPending,
    };
  };

  const useUpdateAnonymization = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['anonymizations', 'update'],
      mutationFn: anonymizationApi.updateAnonymization,
      onSuccess: (updatedAnonymization) => {
        queryClient.setQueryData(
          queryOptions.anonymization({ uuid: updatedAnonymization.uuid }).queryKey,
          updatedAnonymization,
        );

        queryClient.setQueryData(queryOptions.anonymizations().queryKey, (anonymizations) => {
          return anonymizations?.map((anonymization) =>
            anonymization.uuid === updatedAnonymization.uuid ? updatedAnonymization : anonymization,
          );
        });
      },
    });

    return {
      updateAnonymization: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    useAnonymization,
    useUpdateAnonymization,
    useAnonymizations,
    useCreateAnonymization,
    useDeleteAnonymization,
    anonymizationQueryOptions: queryOptions,
  };
};
