import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiReview } from '@legalfly/api/reviews';
import { trackEvent } from '@legalfly/reporting/tracking';
import { IconButton } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { Spinner } from '@legalfly/ui/spinner';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { reviewToasts, useDeleteReview } from 'core/modules/reviews';

interface Props {
  review: ApiReview;
}

export const ReviewsTableActionPopover = ({ review }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { deleteReview, isLoading: isLoadingDeleteReview } = useDeleteReview();

  const handleDeleteReview = () => {
    trackEvent({
      action: 'click',
      category: 'review',
      label: 'delete',
    });
    withToasts(deleteReview({ uuid: review.uuid }))(reviewToasts.deleteReview());
  };

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <IconButton
          name='dots-horizontal'
          className={cn('rotate-90', isOpen && 'bg-fill-hover-strong')}
          variant='tertiary'
          size='sm'
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className='flex flex-col gap-2' align='end'>
        <DropdownMenuItem onClick={handleDeleteReview}>
          {isLoadingDeleteReview ? <Spinner /> : <Icon name='trash-2' className='text-icon-weak' />}
          {t('action.delete')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
