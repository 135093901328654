import i18n from 'i18next';

import { createToasts } from '@legalfly/ui/toast';

export const multiReviewToasts = createToasts({
  createMultiReview: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.createMultiReview.description'),
    }),
  }),
  deleteMultiReview: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deleteMultiReview.description'),
    }),
  }),
  updateMultiReview: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.updateMultiReview.description'),
    }),
  }),
});
