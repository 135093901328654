import { useCallback } from 'react';
import i18n from 'i18next';

import { createToasts, withToasts } from '@legalfly/ui/toast';

const toasts = createToasts({
  copyToClipboard() {
    return {
      successToast: () => {
        return {
          title: i18n.t('toast.success.copyToClipboard.title'),
          description: i18n.t('toast.success.copyToClipboard.description'),
        };
      },
      errorToast: () => {
        return {
          title: i18n.t('toast.error.title'),
          description: i18n.t('toast.error.copyToClipboard.description'),
        };
      },
    };
  },
});

const stripHtmlTags = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

export function useCopyToClipboard() {
  const copyToClipboard = useCallback(async (text: string) => {
    if (!navigator.clipboard) {
      console.warn('Clipboard API not available');
      return;
    }

    await withToasts(navigator.clipboard.writeText(stripHtmlTags(text)))(toasts.copyToClipboard());
  }, []);

  return copyToClipboard;
}
