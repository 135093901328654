import { cn } from 'utils';

import { Icon } from '../icon';

interface Props {
  errorMessage: string | undefined;
  errorClassName?: string;
}

const InputErrorMessage = ({ errorMessage, errorClassName }: Props) => {
  if (!errorMessage) return null;

  return (
    <span
      className={cn(
        'mb-2 flex items-center gap-2 text-content-body-error duration-200 animate-in fade-in',
        errorClassName,
      )}
    >
      <Icon name='x-circle' size='md' className='text-icon-error' />
      {errorMessage}
    </span>
  );
};

export { InputErrorMessage };
