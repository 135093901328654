import { queryOptions } from '@tanstack/react-query';

import type { WebSocketApi } from '@legalfly/api/websocket';

export const websocketQueryOptions = ({ websocketApi }: { websocketApi: WebSocketApi }) => ({
  polling: (isEnabled: boolean) =>
    queryOptions({
      queryKey: ['websocket', 'polling'],
      queryFn: () => websocketApi.polling(),
      enabled: isEnabled,
      refetchInterval: 2000,
    }),
});
