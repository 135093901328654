import type { ComponentProps, HTMLAttributes, ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
  dropTargetForExternal,
  monitorForExternal,
} from '@atlaskit/pragmatic-drag-and-drop/external/adapter';
import { containsFiles, getFiles } from '@atlaskit/pragmatic-drag-and-drop/external/file';
import { preventUnhandled } from '@atlaskit/pragmatic-drag-and-drop/prevent-unhandled';

import { Button } from '@legalfly/ui/button';
import { Card, CardFooter, CardIcon, CardSubtitle, CardTitle } from '@legalfly/ui/card';
import { useToast } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';

export interface ProcessedFile {
  dataUrl: string;
  size: number;
  name: string;
  type: string;
}

export const UploadButton = ({
  onUpload,
  isSingle = false,
  className,
  variant = 'soft',
  onClick,
  ...buttonProps
}: { onUpload: (files: File[]) => void; isSingle?: boolean } & ComponentProps<typeof Button>) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onFileInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event.currentTarget.files ?? []);
      return onUpload(files);
    },
    [onUpload],
  );

  const onInputTriggerClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      inputRef.current?.click();
      onClick?.(e);
    },
    [onClick],
  );

  return (
    <>
      <Button
        onClick={onInputTriggerClick}
        className={cn('border-dashed', className)}
        variant={variant}
        {...buttonProps}
      />
      <input
        ref={inputRef}
        className='hidden'
        onChange={onFileInputChange}
        type='file'
        multiple={!isSingle}
      />
    </>
  );
};

interface DropZoneProps extends HTMLAttributes<HTMLDivElement> {
  isSingle?: boolean;
  disabled?: boolean;
  withBackground?: boolean;
  onDropFiles: (files: File[]) => void;
  renderActions?: () => ReactNode;
}

export const DropZone = ({
  onDropFiles,
  isSingle = false,
  className,
  children,
  disabled = false,
  withBackground = false,
  renderActions,
  ...props
}: DropZoneProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { toast } = useToast();
  const { t } = useTranslation();

  const [state, setState] = useState<'idle' | 'potential' | 'over'>('idle');

  useEffect(() => {
    if (disabled) return;
    const el = ref.current;
    return combine(
      dropTargetForExternal({
        element: el!,
        canDrop: containsFiles,
        onDragEnter: () => setState('over'),
        onDragLeave: () => setState('potential'),
        onDrop: async ({ source }) => {
          const files = getFiles({ source });
          if (isSingle && files.length > 1) {
            toast({
              title: t('toast.error.uploadFile.single.title'),
              description: t('toast.error.uploadFile.single.description'),
              variant: 'danger',
            });
            return;
          }
          return onDropFiles(files);
        },
      }),
      monitorForExternal({
        canMonitor: containsFiles,
        onDragStart: () => {
          setState('potential');
          preventUnhandled.start();
        },
        onDrop: () => {
          setState('idle');
          preventUnhandled.stop();
        },
      }),
    );
  }, [isSingle, onDropFiles, toast, disabled, t]);

  const card = () => {
    return (
      <Card>
        <CardIcon name='upload-2' />
        <CardTitle className='mb-2'>{t('uploader.title')}</CardTitle>
        <CardSubtitle>{t('uploader.subtitle')}</CardSubtitle>
        <CardFooter>{renderActions?.()}</CardFooter>
      </Card>
    );
  };

  if (disabled) {
    return (
      <div className={className} {...props}>
        {children}
      </div>
    );
  }

  return (
    <div
      ref={ref}
      className={cn(
        'relative flex w-full flex-1 flex-grow flex-col overflow-hidden',
        'border border-dashed border-transparent',
        withBackground &&
          'z-0 from-dropzone-start to-dropzone-stop/20 mask-blocked-background after:animate-dropzone',
        className,
        state === 'over' && `border-stroke-strong`,
      )}
      {...props}
    >
      {children ? (
        children
      ) : (
        <div className={cn('flex flex-1 flex-col items-center justify-center')}>{card()}</div>
      )}
      {state === 'over' && (
        <div className='absolute left-0 top-0 z-10 flex h-full w-full flex-1 items-center justify-center bg-risk-glow-neutral/30'>
          {card()}
        </div>
      )}
    </div>
  );
};
