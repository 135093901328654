import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@legalfly/ui/button';
import type { IconName } from '@legalfly/ui/icon';
import { Icon, iconNames } from '@legalfly/ui/icon';
import { Input } from '@legalfly/ui/input';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '@legalfly/ui/popover';
import { Text } from '@legalfly/ui/text';

interface Props {
  value: IconName;
  onChange: (value: IconName) => void;
}

export const AgentIconPickerPopover = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const filteredIconNames = iconNames.filter((icon) =>
    icon.toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <Popover>
      <PopoverTrigger>
        <div className='flex h-12 w-12 cursor-pointer items-center justify-center border border-stroke-weaker'>
          <Icon name={value} />
        </div>
      </PopoverTrigger>
      <PopoverContent align='end' className='w-[450px] p-6' asDialog>
        <div className='mb-2 flex items-center justify-between'>
          <Text variant='subheadingSemiBold'>{t('agents.iconPicker.selectIcon')}</Text>
          <PopoverClose asChild>
            <IconButton name='x-close' variant='tertiary' size='sm' />
          </PopoverClose>
        </div>
        <Input
          placeholder={t('agents.iconPicker.search')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className='mt-4 grid max-h-56 grid-cols-8 gap-3 overflow-y-auto'>
          {filteredIconNames.map((icon) => (
            <PopoverClose asChild key={icon}>
              <IconButton name={icon} variant='tertiary' size='sm' onClick={() => onChange(icon)} />
            </PopoverClose>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
