import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import type { ApiReview, ReviewsApi } from '@legalfly/api/reviews';

import { reviewsQueryOptions } from './reviewsQueryOptions';

export const createReviewsModule = ({ reviewsApi }: { reviewsApi: ReviewsApi }) => {
  const queryOptions = reviewsQueryOptions({ reviewsApi });

  const useReviews = () => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.reviews());

    return {
      reviews: data?.reviews ?? [],
      stats: data?.stats,
      isLoading,
      error,
    };
  };

  const useReview = ({ uuid }: { uuid: ApiReview['uuid'] }) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.review({ uuid }));

    return {
      review: data,
      isLoading,
      error,
    };
  };

  const useCreateReview = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['review', 'create'],
      mutationFn: reviewsApi.createReview,
      onSuccess: (review) => {
        queryClient.setQueryData(queryOptions.reviews().queryKey, (data) => {
          if (!data) {
            return;
          }

          return {
            ...data,
            reviews: [...(data?.reviews ?? []), review],
          };
        });

        queryClient.setQueryData(queryOptions.review({ uuid: review.uuid }).queryKey, review);
      },
    });

    return {
      createReview: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDeleteReview = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['review', 'delete'],
      mutationFn: reviewsApi.deleteReview,
      onSuccess: (_, { uuid }) => {
        queryClient.setQueryData(queryOptions.reviews().queryKey, (data) => {
          if (!data) {
            return;
          }

          return {
            ...data,
            reviews: data.reviews?.filter((review) => review.uuid !== uuid),
          };
        });
      },
    });

    return {
      deleteReview: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    useReviews,
    useReview,
    useCreateReview,
    useDeleteReview,
    reviewsQueryOptions: queryOptions,
  };
};
