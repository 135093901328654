import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '@tanstack/react-form';
import { useDefaultJurisdiction } from 'common/hooks/useDefaultJurisdiction';

import type { Jurisdiction } from '@legalfly/api/core';
import type { LegalFlyLanguage } from '@legalfly/config';
import { trackEvent } from '@legalfly/reporting/tracking';
import { FormItem } from '@legalfly/ui/form';
import { Input } from '@legalfly/ui/input';
import { Text } from '@legalfly/ui/text';
import { withToasts } from '@legalfly/ui/toast';
import { SelectJurisdiction } from 'components/common/select/SelectJurisdiction';
import SelectLanguage from 'components/common/select/SelectLanguage';
import { UploadButton } from 'components/common/upload/Uploader';
import { settingsToasts, useUpdateUserSettings, useUserSettings } from 'core/modules/settings';
import { useCurrentUser, useUpdateAvatar, useUpdateUser } from 'core/modules/users';
import { userToasts } from 'core/modules/users/toasts';

import { firstNameValidator, lastNameValidator } from '../common/form/validations';
import { CurrentUserAvatar } from './CurrentUserAvatar';
import { UserFormItem } from './UserFormItem';

interface FormValues {
  firstName: string;
  lastName: string;
  jobTitle: string;
  language: LegalFlyLanguage;
  jurisdiction: Jurisdiction | undefined;
  avatar: string;
}

export const UserProfileForm = () => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const { updateUser } = useUpdateUser();
  const { userSettings } = useUserSettings();
  const { updateUserSettings } = useUpdateUserSettings();
  const { updateAvatar } = useUpdateAvatar();

  const handleAvatarUpload = useCallback(
    async (files: File[]) => {
      if (files.length > 0) {
        trackEvent({
          action: 'click',
          category: 'profile',
          label: 'updateAvatar',
        });
        const file = files[0];
        const formData = new FormData();
        formData.append('file', file, file.name);
        await withToasts(updateAvatar({ file: formData }))(userToasts.updateAvatar());
      }
    },
    [updateAvatar],
  );

  const { i18n } = useTranslation();
  const { changeLanguage } = i18n;

  const handleFieldBlur = useCallback(
    ({
      fieldName,
      value,
      hasError,
    }: {
      fieldName: keyof FormValues;
      value: string | Jurisdiction | null;
      hasError: boolean;
    }) => {
      if (hasError) return;

      switch (fieldName) {
        case 'firstName':
        case 'lastName':
          withToasts(updateUser({ uuid: currentUser.uuid, body: { [fieldName]: value } }))(
            userToasts.updateUser(),
          );
          break;
        case 'jobTitle':
          trackEvent({
            action: 'click',
            category: 'profile',
            label: 'updateJobTitle',
          });
          withToasts(updateUserSettings({ jobDescription: value as string }))(
            settingsToasts.updateUserSettings(),
          );
          break;
        case 'jurisdiction':
          trackEvent(
            {
              action: 'click',
              category: 'profile',
              label: 'updateJurisdiction',
            },
            { jurisdiction: value },
          );
          withToasts(updateUserSettings({ jurisdiction: value as Jurisdiction }))(
            settingsToasts.updateUserSettings(),
          );
          break;
        case 'language':
          trackEvent(
            {
              action: 'click',
              category: 'profile',
              label: 'updateLanguage',
            },
            { language: value },
          );
          withToasts(updateUserSettings({ language: value as LegalFlyLanguage }))(
            settingsToasts.updateUserSettings(),
          );
          changeLanguage(value as string);
          break;
      }
    },
    [updateUser, currentUser.uuid, updateUserSettings, changeLanguage],
  );

  const form = useForm<FormValues>({
    defaultValues: {
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      jobTitle: userSettings.jobDescription ?? '',
      language: userSettings.language,
      jurisdiction: useDefaultJurisdiction(),
      avatar: currentUser.avatarUrl ?? '',
    },
  });

  return (
    <form className='flex  flex-col gap-4' aria-label={t('form.userProfile')}>
      <section className='mb-4 border border-stroke-weaker'>
        <div className='mb-3 bg-fill-strong p-6'>
          <FormItem>
            <Text as='label' variant='bodyLarge'>
              {t('form.profilePicture.label')}
            </Text>
            <div className='flex flex-col items-center'>
              <CurrentUserAvatar className='h-32 w-32' />
              <UploadButton
                onUpload={(files) => handleAvatarUpload(files)}
                className='mt-4 border-solid text-content-body-weak'
                size='sm'
              >
                {t('action.edit')}
              </UploadButton>
            </div>
          </FormItem>
        </div>
        <UserFormItem label={t('form.email.label')}>
          <Input className='w-full' value={currentUser.email} disabled />
        </UserFormItem>

        <UserFormItem label={t('form.firstName.label')}>
          <form.Field
            name='firstName'
            validators={{
              onChange: ({ value }) => firstNameValidator(t)(value),
            }}
          >
            {(field) => (
              <Input
                id={field.name}
                name={field.name}
                value={field.state.value}
                onBlur={() =>
                  handleFieldBlur({
                    fieldName: 'firstName',
                    value: field.state.value,
                    hasError: field.state.meta.errors.length > 0,
                  })
                }
                onChange={(e) => field.handleChange(e.target.value)}
                autoComplete='given-name'
                errorMessage={field.state.meta.errors.join()}
                placeholder={t('form.firstName.placeholder')}
              />
            )}
          </form.Field>
        </UserFormItem>

        <UserFormItem label={t('form.lastName.label')}>
          <form.Field
            name='lastName'
            validators={{
              onChange: ({ value }) => lastNameValidator(t)(value),
            }}
          >
            {(field) => (
              <Input
                id={field.name}
                name={field.name}
                value={field.state.value}
                onBlur={() =>
                  handleFieldBlur({
                    fieldName: 'lastName',
                    value: field.state.value,
                    hasError: field.state.meta.errors.length > 0,
                  })
                }
                onChange={(e) => field.handleChange(e.target.value)}
                autoComplete='family-name'
                errorMessage={field.state.meta.errors.join()}
                placeholder={t('form.lastName.placeholder')}
              />
            )}
          </form.Field>
        </UserFormItem>

        <UserFormItem label={t('form.jobTitle.label')}>
          <form.Field name='jobTitle'>
            {(field) => (
              <Input
                id={field.name}
                name={field.name}
                value={field.state.value}
                onBlur={() =>
                  handleFieldBlur({
                    fieldName: 'jobTitle',
                    value: field.state.value,
                    hasError: false,
                  })
                }
                onChange={(e) => field.handleChange(e.target.value)}
                autoComplete='organization-title'
                placeholder={t('form.jobTitle.placeholder')}
              />
            )}
          </form.Field>
        </UserFormItem>
      </section>

      <section className='border border-stroke-weaker'>
        <div className='mb-3 bg-fill-strong p-5'>
          <Text className='mb-2 from-content-body'>{t('form.legalflySettings')}</Text>
        </div>
        <div className='mb-3'>
          <UserFormItem label={t('form.language.label')} subtitle={t('form.language.subtitle')}>
            <form.Field name='language'>
              {(field) => (
                <SelectLanguage
                  value={field.state.value}
                  onChange={(value) => {
                    field.handleChange(value);
                    handleFieldBlur({ fieldName: 'language', value, hasError: false });
                  }}
                  triggerProps={{ className: 'px-3 py-4' }}
                />
              )}
            </form.Field>
          </UserFormItem>

          <UserFormItem
            label={t('form.jurisdiction.label')}
            subtitle={t('form.jurisdiction.subtitle')}
          >
            <form.Field name='jurisdiction'>
              {(field) => (
                <SelectJurisdiction
                  value={field.state.value}
                  onChange={(value) => {
                    field.handleChange(value);
                    handleFieldBlur({ fieldName: 'jurisdiction', value, hasError: false });
                  }}
                  triggerProps={{ className: 'px-3 py-4 w-full' }}
                  contentProps={{ align: 'end' }}
                />
              )}
            </form.Field>
          </UserFormItem>
        </div>
      </section>
    </form>
  );
};
