import { type ElementType, Fragment } from 'react';
import { escapeString } from 'common/utils/string/escape';

import type { ApiDocumentSnippetEntity, ApiDocumentSnippetText } from '@legalfly/api/documents';
import { Button } from '@legalfly/ui/button';
import { SidePaneTrigger } from '@legalfly/ui/sidePane';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';

const renderEntity = (part: string, index: number, props: Props) => {
  const { entities, isAnonymous, selectedText, onEntityClick } = props;
  const name = part.slice(1, -1);
  const entity = entities.find((e) => e.name === name);

  if (!entity) return part;

  if (!onEntityClick)
    return (
      <span
        key={index}
        className={cn(
          'bg-fill-weak px-2 py-0.5',
          isAnonymous && 'blur-sm transition-all duration-200 hover:blur-none',
        )}
      >
        {isAnonymous ? entity.name.split('_')[0] : entity.value.trim()}
      </span>
    );

  return (
    <SidePaneTrigger asChild paneId={entity.uuid} key={index} onClick={() => onEntityClick(entity)}>
      <Button
        key={index}
        variant='unstyled'
        className={cn(
          'min-h-4 cursor-pointer select-none bg-fill-weak px-4',
          selectedText === entity.value &&
            'animate-pulse bg-fill-focus-weak text-body-semibold delay-150 duration-1000 repeat-1',
          isAnonymous && 'blur-sm transition-all duration-200 hover:blur-none',
        )}
        onClick={() => onEntityClick(entity)}
      >
        {isAnonymous ? entity.name.split('_')[0] : entity.value.trim()}
      </Button>
    </SidePaneTrigger>
  );
};

const renderSnippet = (snippetText: string, props: Props) => {
  const { selectedText } = props;

  const parts = snippetText.split(/(\[.*?\])/);
  return parts.map((part, index) => {
    if (part.startsWith('[') && part.endsWith(']')) {
      return renderEntity(part, index, props);
    }

    if (!selectedText.trim()) return part;

    const searchRegex = new RegExp(`(?<!\\d)(${escapeString(selectedText)})(?!\\d)`, 'gi');
    const textParts = part.split(searchRegex);

    return (
      <Fragment key={`${index}`}>
        {textParts.map((textPart, i) => (
          <Text
            key={i}
            as='span'
            className={cn(
              searchRegex.test(textPart) &&
                'animate-pulse bg-fill-focus-weak px-2 py-0.5 text-body-semibold delay-150 duration-1000 repeat-1',
            )}
          >
            {textPart}
          </Text>
        ))}
      </Fragment>
    );
  });
};

const renderElementNode = (element: HTMLElement, index: number, props: Props) => {
  const tagName = element.tagName.toLowerCase();
  const isHeaderOrParagraph = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'].includes(tagName);

  const Component = isHeaderOrParagraph ? Text : (tagName as ElementType);

  if (!element.innerHTML.trim()) return <Component key={index} />;

  return (
    <Component
      key={index}
      {...element.attributes}
      as={isHeaderOrParagraph ? (tagName as ElementType) : undefined}
    >
      {element.childElementCount > 0
        ? Array.from(element.childNodes).map((childNode, childIndex) =>
            renderNode(childNode, childIndex, props),
          )
        : renderSnippet(element.innerHTML, props)}
    </Component>
  );
};

const renderNode = (element: Node, index: number, props: Props) => {
  if (element.nodeType === Node.TEXT_NODE) {
    return renderSnippet(element.textContent || '', props);
  }

  if (element.nodeType === Node.ELEMENT_NODE) {
    return renderElementNode(element as HTMLElement, index, props);
  }

  return null;
};

interface Props {
  snippet: ApiDocumentSnippetText;
  entities: ApiDocumentSnippetEntity[];
  isAnonymous: boolean;
  selectedText: string;
  onEntityClick: ((entity: ApiDocumentSnippetEntity) => void) | undefined;
  isActive: boolean;
  riskLevel?: string;
}

const DocumentSnippet = (props: Props) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(props.snippet.text, 'text/html');

  const elements = Array.from(doc.body.childNodes);

  return (
    <div
      id={props.snippet.uuid}
      className={cn(
        '-mx-4 border border-transparent px-4 py-4 [&_p]:m-0',
        props.isActive && 'overflow-hidden border border-stroke-strong ',
        props.isActive && props.riskLevel === 'low' && 'border-risk-fill-low',
        props.isActive && props.riskLevel === 'medium' && 'border-risk-fill-mid',
        props.isActive && props.riskLevel === 'high' && 'border-risk-fill-high',
      )}
    >
      {props.isActive && (
        <div
          className={cn(
            'h-0 w-0 shadow-risk-glow-neutral',
            props.riskLevel === 'low' && 'shadow-risk-glow-low',
            props.riskLevel === 'medium' && 'shadow-risk-glow-mid',
            props.riskLevel === 'high' && 'shadow-risk-glow-high',
          )}
        />
      )}
      {elements.map((element, index) => renderNode(element, index, props))}
    </div>
  );
};

export default DocumentSnippet;
