import { useTranslation } from 'react-i18next';

import { type ApiReviewItem } from '@legalfly/api/reviews';
import { ColorPickerPopover } from '@legalfly/ui/colorPickerPopover';

import { PlaybookItemMultipleChoiceOption } from './PlaybookItemMultipleChoiceOption';

interface Props {
  item: ApiReviewItem;
}

export const PlaybookItemMultipleChoice = ({ item }: Props) => {
  const { t } = useTranslation();

  const NONE_SELECTED_OPTION = {
    color: ColorPickerPopover.defaultColor,
    option: t('playbooks.multipleChoice.noOptionsApply'),
  };

  if (!item.answer?.selectedChoices || item.answer?.selectedChoices?.length === 0) {
    return (
      <div className='flex flex-wrap gap-2'>
        <PlaybookItemMultipleChoiceOption option={NONE_SELECTED_OPTION} />
      </div>
    );
  }

  if (item.answer?.selectedChoices?.length > 0) {
    return (
      <div className='flex flex-wrap gap-2'>
        {item.answer.selectedChoices.map((choice) => (
          <PlaybookItemMultipleChoiceOption key={choice.option} option={choice} />
        ))}
      </div>
    );
  }

  return null;
};
