import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiUser } from '@legalfly/api/users';
import { trackEvent } from '@legalfly/reporting/tracking';
import { IconButton } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { Spinner } from '@legalfly/ui/spinner';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import {
  isActiveUser,
  useActivateUser,
  useDeactivateUser,
  useDeleteUser,
} from 'core/modules/users';
import { userToasts } from 'core/modules/users/toasts';

interface Props {
  user: ApiUser;
}

export const UsersTableActionPopover = ({ user }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { activateUser, isLoading: isLoadingActivateUser } = useActivateUser();
  const { deactivateUser, isLoading: isLoadingDeactivateUser } = useDeactivateUser();
  const { deleteUser, isLoading: isLoadingDeleteUser } = useDeleteUser();

  const onActiveUser = async (user: ApiUser) => {
    try {
      trackEvent({ action: 'click', category: 'users', label: 'activate' });
      await withToasts(activateUser(user.uuid))(userToasts.activateUser());
    } finally {
      setIsOpen(false);
    }
  };

  const onDeactiveUser = async (user: ApiUser) => {
    try {
      trackEvent({ action: 'click', category: 'users', label: 'deactivate' });
      await withToasts(deactivateUser(user.uuid))(userToasts.deactivateUser());
    } finally {
      setIsOpen(false);
    }
  };

  const onDeleteUser = async (user: ApiUser) => {
    try {
      trackEvent({ action: 'click', category: 'users', label: 'delete' });
      await withToasts(deleteUser(user.uuid))(userToasts.deleteUser());
    } finally {
      setIsOpen(false);
    }
  };

  const isActive = isActiveUser(user);

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <IconButton
          name='dots-horizontal'
          className={cn('rotate-90', isOpen && 'bg-fill-hover-strong')}
          variant='tertiary'
          size='sm'
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className='flex flex-col gap-2' align='end'>
        <DropdownMenuItem onClick={() => (isActive ? onDeactiveUser(user) : onActiveUser(user))}>
          {isLoadingActivateUser || isLoadingDeactivateUser ? (
            <Spinner />
          ) : (
            <Icon name='trash-2' className='text-icon-weak' />
          )}
          {isActive ? t('action.deactivateUser') : t('action.activateUser')}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => onDeleteUser(user)}>
          {isLoadingDeleteUser ? <Spinner /> : <Icon name='trash-2' className='text-icon-weak' />}
          {t('action.deleteUser')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
