import { useTranslation } from 'react-i18next';

import type { ApiDraft } from '@legalfly/api/drafting';
import { Button } from '@legalfly/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@legalfly/ui/dialog';
import { Icon } from '@legalfly/ui/icon';
import { useDownloadDraftAsDocx, useDownloadDraftAsPdf } from 'core/modules/drafting';

interface Props {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  draftUuid: ApiDraft['uuid'];
}

export const DraftCompletedDialog = ({ isOpen, onOpenChange, draftUuid }: Props) => {
  const { t } = useTranslation();
  const { downloadPdf, isLoading: isDownloadingPdf } = useDownloadDraftAsPdf();
  const { downloadDocx, isLoading: isDownloadingDocx } = useDownloadDraftAsDocx();

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('dialog.draftCompleted.title')}</DialogTitle>
        </DialogHeader>
        <DialogDescription>{t('dialog.draftCompleted.description')}</DialogDescription>
        <DialogFooter className='mt-6 flex gap-5'>
          <Button
            size='lg'
            renderLeft={<Icon name='download-2' />}
            onClick={() => downloadPdf({ uuid: draftUuid })}
            isLoading={isDownloadingPdf}
          >
            PDF
          </Button>
          <Button
            size='lg'
            renderLeft={<Icon name='download-2' />}
            onClick={() => downloadDocx({ uuid: draftUuid })}
            isLoading={isDownloadingDocx}
          >
            Word
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
