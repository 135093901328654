import { useEffect } from 'react';
import type { BrowserOptions } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import type { AnyRouter } from '@tanstack/react-router';

import { FetcherError } from '@legalfly/api/fetcher';
import { WebSocketError } from '@legalfly/api/websocket';
import type { LegalFlyEnvironment } from '@legalfly/config';

import { handleFetcherError, handleWebSocketError } from './errorHandlers';

export const useRouterTracing = (router: AnyRouter) => {
  useEffect(() => {
    Sentry.addIntegration(Sentry.tanstackRouterBrowserTracingIntegration(router));
  }, [router]);
};

interface Props extends BrowserOptions {
  environment: LegalFlyEnvironment;
}

export const initSentry = ({ environment, ...props }: Props) => {
  if (environment === 'local') {
    return;
  }

  Sentry.init({
    environment,
    autoSessionTracking: true,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: environment === 'production' ? 0.5 : 1.0,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api.legalfly.dev/,
      /^https:\/\/admin.legalfly.dev/,
      /^https:\/\/api.legalfly.com/,
    ],
    beforeSend(event, hint) {
      const error = hint.originalException;

      if (error instanceof FetcherError) {
        return handleFetcherError(event, error);
      }

      if (error instanceof WebSocketError) {
        return handleWebSocketError(event, error);
      }

      return event;
    },
    ...props,
  });
};

export const reportError = (error: Error) => {
  Sentry.captureException(error);
};

export const reportMessage = Sentry.captureMessage;
