import { useNavigate } from '@tanstack/react-router';

import type { ApiNotification } from '@legalfly/api/notifications';
import { createNotificationsModule } from '@legalfly/modules/notifications';

import { notificationsApi } from '../../di';

export const useNavigateToNotification = () => {
  const navigate = useNavigate();

  return (notification: ApiNotification) => {
    switch (notification.type) {
      case 'FILE_UPLOADED':
        navigate({
          to: '/anonymisation/file/$uuid',
          params: { uuid: notification.metadata.entityId },
        });
        break;
      case 'MULTI_REVIEW_COMPLETED':
        navigate({
          to: '/multi-review/$uuid',
          params: { uuid: notification.metadata.entityId },
        });
        break;
      case 'FILE_UPLOAD_FAILED':
        if (notification.metadata.entityId) {
          navigate({
            to: '/documents/folder/$uuid',
            params: { uuid: notification.metadata.entityId },
          });
        } else {
          navigate({
            to: '/documents/folder',
          });
        }
        break;
      case 'QUEUE_ERROR':
        if (notification.metadata.entityType === 'file') {
          navigate({ to: '/documents/folder' });
        }
        break;
      default:
        navigate({ to: '/' });
    }
  };
};

export const {
  useNotifications,
  useMarkNotificationAsSeen,
  useMarkAllNotificationsAsSeen,
  useDeleteNotification,
  useDeleteAllNotifications,
  notificationsQueryOptions,
} = createNotificationsModule({
  notificationsApi,
});

export * from './toasts';
