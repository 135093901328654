import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';

import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@legalfly/ui/dialog';
import { Icon } from '@legalfly/ui/icon';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';

import { CreatePlaybookForm } from './CreatePlaybookForm';

export const CreatePlaybookDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleAfterSubmit = (newPlaybookUuid: string) => {
    setIsOpen(false);
    navigate({ to: `/playbooks/${newPlaybookUuid}` });
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          renderLeft={<Icon name='book-open' />}
          variant='soft'
          size='sm'
          onClick={() =>
            trackEvent({
              action: 'click',
              category: 'playbooks',
              label: 'create',
            })
          }
        >
          {t('action.createPlaybook')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <VisuallyHidden>
          <DialogDescription>{t('dialog.createPlaybook.description')}</DialogDescription>
        </VisuallyHidden>
        <DialogHeader>
          <Icon name='book-open' />
          <DialogTitle>{t('dialog.createPlaybook.title')}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <CreatePlaybookForm afterSubmit={handleAfterSubmit} />
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};
