import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

import type { ApiConversationDetail, ConversationTypeEnum } from '@legalfly/api/conversations';
import { useConversation } from 'core/modules/conversations';

interface OptimisticConversationContextType {
  conversationUuid: string | undefined;
  conversationType: ConversationTypeEnum;
  conversation: ApiConversationDetail | undefined;
  setOptimisticConversationUuid: (uuid: string | undefined) => void;
}

const OptimisticConversationContext = createContext<OptimisticConversationContextType | null>(null);

const OptimisticConversationProvider = ({
  children,
  conversationUuid,
  conversationType,
}: PropsWithChildren<
  Pick<OptimisticConversationContextType, 'conversationUuid' | 'conversationType'>
>) => {
  const [optimisticConversationUuid, setOptimisticConversationUuid] = useState<
    ApiConversationDetail['uuid'] | undefined
  >();

  const { conversation } = useConversation({
    uuid: conversationUuid ?? optimisticConversationUuid,
    type: conversationType,
  });

  const value = useMemo(
    () => ({
      conversationUuid,
      conversationType,
      setOptimisticConversationUuid,
      conversation,
    }),
    [conversationUuid, conversationType, setOptimisticConversationUuid, conversation],
  );

  return (
    <OptimisticConversationContext.Provider value={value}>
      {children}
    </OptimisticConversationContext.Provider>
  );
};

const useOptimisticConversationContext = () => {
  const context = useContext(OptimisticConversationContext);
  if (context === null) {
    throw new Error(
      'useOptimisticConversationContext must be used within a OptimisticConversationProvider',
    );
  }
  return context;
};

export { OptimisticConversationProvider, useOptimisticConversationContext };
