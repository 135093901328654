import { useTranslation } from 'react-i18next';
import { Link } from '@tanstack/react-router';

import { ErrorCard } from '@legalfly/ui/error';

import { Layout } from '../layout/Layout';

export const DefaultNotFound = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <ErrorCard className='h-content'>
        <ErrorCard.Content>
          <ErrorCard.Title>{t('errors.notFound.title')}</ErrorCard.Title>
          <ErrorCard.Message>{t('errors.notFound.message')}</ErrorCard.Message>
          <ErrorCard.Action>
            <Link to='/'>{t('errors.card.cta')}</Link>
          </ErrorCard.Action>
        </ErrorCard.Content>
      </ErrorCard>
    </Layout>
  );
};
