import { useTranslation } from 'react-i18next';

import type { ApiConfigUserSettings } from '@legalfly/api/config';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from '@legalfly/ui/dialog';
import { Text } from '@legalfly/ui/text';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';

import background from './header.png';
import { OnboardingForm } from './OnboardingForm';

interface Props {
  userSettings: ApiConfigUserSettings;
}

export const OnboardingDialog = ({ userSettings }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={!userSettings.isOnboardingCompleted}>
      <DialogContent className='max-w-[80%] p-0 lg:max-w-[40%]'>
        <VisuallyHidden>
          <DialogDescription>{t('dialog.createOnboarding.title')}</DialogDescription>
        </VisuallyHidden>
        <DialogHeader closeButtonEnabled={false} className='relative h-32 items-end px-8 pb-4'>
          <img
            src={background}
            alt='background'
            className='absolute inset-0 h-full w-full object-cover'
          />
          <div className='z-10'>
            <Text className='mb-1 text-content-body-constant-dark'>
              {t('dialog.createOnboarding.subTitle')}
            </Text>
            <Text variant='heading2' className='text-content-body-constant-dark'>
              legalfly
            </Text>
          </div>
        </DialogHeader>
        <DialogBody className='px-8 pb-8'>
          <OnboardingForm userSettings={userSettings} />
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};
