import { useTranslation } from 'react-i18next';
import { useForm } from '@tanstack/react-form';

import type { ApiDocument } from '@legalfly/api/documents';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { FormItem, FormItemLabel } from '@legalfly/ui/form';
import { Input } from '@legalfly/ui/input';
import { withToasts } from '@legalfly/ui/toast';
import { nameValidator } from 'components/common/form/validations';
import { documentsToasts, useCreateFolder } from 'core/modules/documents';

interface Props {
  afterSubmit: () => void;
  uuid: ApiDocument['uuid'] | undefined;
}

const defaultValues = {
  name: '',
};

type FormValues = typeof defaultValues;

const CreateFolderForm = ({ afterSubmit, uuid }: Props) => {
  const { t } = useTranslation();
  const { createFolder } = useCreateFolder();

  const onCreateUser = async ({ value }: { value: FormValues }) => {
    trackEvent({
      action: 'submit',
      category: 'documents',
      label: 'createFolder',
    });
    await withToasts(
      createFolder({
        uuid,
        body: {
          name: value.name,
        },
      }),
    )(documentsToasts.createFolder(value.name));
    afterSubmit();
  };

  const form = useForm<FormValues>({
    defaultValues,
    onSubmit: onCreateUser,
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      className='flex flex-col gap-4'
    >
      <FormItem>
        <FormItemLabel label={t('form.name.label')} htmlFor='name' />
        <form.Field
          name='name'
          validators={{
            onBlur: ({ value }) => nameValidator(t)(value),
          }}
        >
          {(field) => (
            <Input
              id={field.name}
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              errorMessage={field.state.meta.errors.join()}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          )}
        </form.Field>
      </FormItem>

      <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
        {([canSubmit, isSubmitting]) => (
          <Button type='submit' disabled={!canSubmit} className='self-end' isLoading={isSubmitting}>
            {t('action.createFolder')}
          </Button>
        )}
      </form.Subscribe>
    </form>
  );
};

export default CreateFolderForm;
