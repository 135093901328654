import { createUsersModule } from '@legalfly/modules/users';

import { usersApi } from '../../di';

export const {
  useUsers,
  useUser,
  useCurrentUser,
  useUpdateUser,
  useActivateUser,
  useDeactivateUser,
  useDeleteUser,
  useCreateUser,
  usersQueryOptions,
  useUpdateAvatar,
} = createUsersModule({
  usersApi,
});

export * from './helpers';
