import { useState } from 'react';
import { Link } from '@tanstack/react-router';

import type { ApiAgent } from '@legalfly/api/agents';
import { FavoriteTypeEnum } from '@legalfly/api/favorites';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';
import { FavoriteButton } from 'components/common/button/FavoriteButton';

import { AgentsListMenu } from './AgentsListMenu';

interface Props {
  agents: ApiAgent[];
}

export function AgentsList({ agents }: Props) {
  return (
    <div className='grid grid-flow-row grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-4'>
      {agents.map((agent) => (
        <AgentsListItem key={agent.uuid} agent={agent} />
      ))}
    </div>
  );
}

const AgentsListItem = ({ agent }: { agent: ApiAgent }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Link
      to='/agents/manage/$agentUuid'
      params={{ agentUuid: agent.uuid }}
      className={cn(
        'group flex flex-col gap-3 bg-fill-strongest p-6 transition-colors duration-700',
        'hover:bg-fill-strong hover:outline hover:outline-1 hover:outline-offset-4 hover:outline-stroke-weak',
        'focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-4 focus-visible:outline-stroke-weak',
        isMenuOpen && 'bg-fill-strong outline outline-1 outline-offset-4 outline-stroke-weak',
      )}
    >
      <div className='flex items-center justify-between'>
        <Icon name={agent.icon} className='self-center' size='sm' />
        <div className='flex items-center'>
          <FavoriteButton
            onClick={(e) => {
              e.preventDefault();
            }}
            entityType={FavoriteTypeEnum.AGENT}
            entity={agent}
            className={cn(
              'invisible hover:bg-fill-weak group-hover:visible',
              isMenuOpen && 'visible',
            )}
          />
          <AgentsListMenu
            isOpen={isMenuOpen}
            setIsOpen={setIsMenuOpen}
            agent={agent}
            className={cn(
              'invisible hover:bg-fill-weak group-hover:visible',
              isMenuOpen && 'visible',
            )}
          />
        </div>
      </div>
      <Text variant='bodyLarge' className='line-clamp-2'>
        {agent.name}
      </Text>
      <Text variant='body' className='line-clamp-3 text-content-body-strong'>
        {agent.description}
      </Text>
    </Link>
  );
};
