import { useCallback, useLayoutEffect, useState } from 'react';
import { setCssVariable } from 'common/utils/setCssVariable';
import useResizeObserver from 'use-resize-observer';

import { useWindowSize } from '@legalfly/utils/hooks';

import { getCssVariable } from '../utils/getCssVariable';

export const useContentWidth = () => {
  const { width } = useWindowSize();

  const [contentWidth, setContentWidth] = useState(width);

  const updateContentWidth = useCallback(() => {
    const bodyPadding = getCssVariable('body-padding');
    const w = width - getCssVariable('sidebar-width') - bodyPadding * 3;
    setCssVariable('content-width', `${w}px`);
    setContentWidth(w);
  }, [width]);

  useResizeObserver<HTMLDivElement>({
    ref: document.getElementById('sidebar') as HTMLDivElement,
    onResize: updateContentWidth,
  });

  useLayoutEffect(() => {
    updateContentWidth();
  }, [updateContentWidth]);

  return contentWidth;
};
