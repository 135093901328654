import { BaseCopilotScrollContainer } from '@legalfly/components/copilot';

import { useCopilotContext } from './CopilotProvider';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type Props = {
  children: React.ReactNode;
  className?: string;
  stickyClassName?: string;
};

export const CopilotScrollContainer = ({ children, className, stickyClassName }: Props) => {
  const { conversation, containerRef } = useCopilotContext();

  const hasMessages = Boolean(conversation?.messages.length);

  return (
    <BaseCopilotScrollContainer
      hasMessages={hasMessages}
      containerRef={containerRef}
      className={className}
      stickyClassName={stickyClassName}
    >
      {children}
    </BaseCopilotScrollContainer>
  );
};
