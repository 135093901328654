import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';

import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { Card, CardFooter, CardIcon, CardSubtitle, CardTitle } from '@legalfly/ui/card';
import { Icon } from '@legalfly/ui/icon';
import { cn } from '@legalfly/ui/utils';
import { useTemplates } from 'core/modules/drafting';

import { CreateDraftingTemplateDialog } from '../templates/CreateDraftingTemplateDialog';

export const DraftsEmptyState = () => {
  const { t } = useTranslation();
  const { templates } = useTemplates();
  const navigate = useNavigate();

  return (
    <div className={cn('flex flex-1 flex-grow flex-col items-center justify-center')}>
      <Card>
        <CardIcon name='paragraph-wrap' />
        {templates.length > 0 ? (
          <>
            <CardTitle>{t('drafting.drafts.empty.title')}</CardTitle>
            <CardSubtitle>{t('drafting.drafts.empty.description')}</CardSubtitle>
            <CardFooter>
              <Button
                variant='soft'
                renderLeft={<Icon name='file-2' className='text-icon-strong' />}
                onClick={() => {
                  trackEvent({
                    action: 'click',
                    category: 'drafts',
                    label: 'createDraft',
                  });
                  navigate({ to: '/drafting/drafts/choose-template' });
                }}
              >
                {t('action.createDraft')}
              </Button>
            </CardFooter>
          </>
        ) : (
          <>
            <CardTitle>{t('drafting.drafts.emptyTemplates.title')}</CardTitle>
            <CardSubtitle>{t('drafting.drafts.emptyTemplates.description')}</CardSubtitle>
            <CardFooter>
              <CreateDraftingTemplateDialog />
            </CardFooter>
          </>
        )}
      </Card>
    </div>
  );
};
