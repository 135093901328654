import { memo, useCallback, useEffect, useState } from 'react';
import type { DragEndEvent } from '@dnd-kit/core';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import type { ApiPlaybook, ApiPlaybookItem } from '@legalfly/api/playbooks';
import { List } from '@legalfly/ui/list';
import { useMovePlaybookItem } from 'core/modules/playbooks';

import { PlaybookItemsListItem } from './PlaybookItemsListItem';

interface SortableClausesListProps {
  items: ApiPlaybookItem[];
  playbookUuid: ApiPlaybook['uuid'];
}

export const PlaybookItemsSortableList = memo(
  ({ items, playbookUuid }: SortableClausesListProps) => {
    const [order, setOrder] = useState<ApiPlaybookItem['uuid'][]>([]);
    const { movePlaybookItem } = useMovePlaybookItem();

    useEffect(() => {
      setOrder(items.sort((a, b) => a.order - b.order).map((item) => item.uuid));
    }, [items]);

    const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: {
          distance: 8,
        },
      }),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      }),
    );

    const handleDragEnd = useCallback(
      (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
          const oldIndex = order.findIndex((item) => item === active.id);
          const newIndex = order.findIndex((item) => item === over?.id);

          setOrder((prevOrder) => arrayMove(prevOrder, oldIndex, newIndex));
          movePlaybookItem({
            playbookUuid,
            itemUuid: active.id.toString(),
            body: { order: newIndex },
          });
        }
      },
      [order, movePlaybookItem, playbookUuid],
    );

    const sortedItems = items.sort((a, b) => order.indexOf(a.uuid) - order.indexOf(b.uuid));

    return (
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={order} strategy={verticalListSortingStrategy}>
          <List className='mt-6'>
            {sortedItems.map((item) => (
              <PlaybookItemsListItem item={item} key={item.uuid} />
            ))}
          </List>
        </SortableContext>
      </DndContext>
    );
  },
);

PlaybookItemsSortableList.displayName = 'PlaybookItemsSortableList';
