import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiDocumentTypeFile } from '@legalfly/api/documents';
import { Button } from '@legalfly/ui/button';
import { Divider } from '@legalfly/ui/divider';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';
import { useFileSummary } from 'core/modules/documents';

interface Props {
  fileUuid: ApiDocumentTypeFile['uuid'];
}

const MAX_SUMMARY_LENGTH = 300;

export const DocumentSummary = ({ fileUuid }: Props) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const { summary: documentSummary } = useFileSummary(fileUuid);

  if (!documentSummary?.summary) {
    return null;
  }

  const truncatedSummary = documentSummary.summary.slice(0, MAX_SUMMARY_LENGTH);
  const shouldTruncate = documentSummary.summary.length > MAX_SUMMARY_LENGTH;

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <>
      <Divider variant='weak' className='my-8' />

      <Text className='mb-2 font-semibold'>{t('documents.summary.title')}</Text>
      <Button
        className={cn(
          'relative h-auto w-full p-0 text-left',
          shouldTruncate && !isExpanded && 'max-h-[240px] overflow-hidden',
          'border-none',
        )}
        variant='unstyled'
        onClick={shouldTruncate ? toggleExpand : undefined}
      >
        <Text className='whitespace-pre-line text-content-body-weak'>
          <>{isExpanded ? documentSummary.summary.toString() : truncatedSummary}</>
        </Text>
        {shouldTruncate && !isExpanded && (
          <>
            <div className='absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-fill-maximal from-30% to-transparent' />
            <Text className='absolute bottom-0 left-0 right-0 text-center text-content-body-weak'>
              {t('documents.summary.viewMore')}
            </Text>
          </>
        )}
      </Button>
    </>
  );
};
