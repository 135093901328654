import type { ComponentProps } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiAgentDetail } from '@legalfly/api/agents';
import { Button, IconButton } from '@legalfly/ui/button';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@legalfly/ui/dialog';
import { Icon } from '@legalfly/ui/icon';
import { ListItem } from '@legalfly/ui/list';
import { Text } from '@legalfly/ui/text';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';

import { AgentConversationStarterForm } from './AgentConversationStarterForm';

interface Props {
  conversationStarter?: ApiAgentDetail['conversationStarters'][number];
  onChange: ComponentProps<typeof AgentConversationStarterForm>['onSubmit'];
  onDelete?: VoidFunction;
}

export const AgentConversationStarterDialog = ({
  onChange,
  conversationStarter,
  onDelete,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <>
        {conversationStarter ? (
          <ListItem
            key={conversationStarter.name}
            className='group flex items-center justify-between border-b py-0 hover:bg-fill-pressed-weak'
          >
            <Button
              variant='unstyled'
              className='flex flex-1 items-center gap-2'
              onClick={() => setIsOpen(true)}
            >
              <Text>{conversationStarter.name}</Text>
            </Button>
            <IconButton
              className='opacity-0 group-hover:opacity-100'
              name='trash-4'
              variant='unstyled'
              size='md'
              onClick={() => {
                onDelete?.();
              }}
            />
          </ListItem>
        ) : (
          <DialogTrigger asChild>
            <Button
              renderLeft={<Icon name='plus' />}
              variant='hard'
              className='w-fit text-content-body-strong'
            >
              {t('action.addConversationStarter')}
            </Button>
          </DialogTrigger>
        )}
      </>
      <DialogContent>
        <VisuallyHidden>
          <DialogDescription>
            {t('dialog.createAgentConversationStarter.description')}
          </DialogDescription>
        </VisuallyHidden>
        <DialogHeader>
          <DialogTitle>{t('dialog.createAgentConversationStarter.title')}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <AgentConversationStarterForm
            conversationStarter={conversationStarter}
            onSubmit={(values) => {
              onChange(values);
              setIsOpen(false);
            }}
            onClose={() => setIsOpen(false)}
          />
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};
