import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

import type { ApiDocumentTypeFile } from '@legalfly/api/documents';

interface DocumentContextType {
  document: ApiDocumentTypeFile;
  isAnonymous: boolean;
  setIsAnonymous: React.Dispatch<React.SetStateAction<boolean>>;
  setDocument: React.Dispatch<React.SetStateAction<ApiDocumentTypeFile>>;
}

const DocumentContext = createContext<DocumentContextType | null>(null);

export const useDocumentContext = () => {
  const context = useContext(DocumentContext);
  if (context === null) {
    throw new Error('useDocument must be used within a DocumentProvider');
  }
  return context;
};

export const DocumentProvider = ({
  children,
  document: documentProp,
}: PropsWithChildren<{ document: ApiDocumentTypeFile }>) => {
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [document, setDocument] = useState(documentProp);

  const value = useMemo(
    () => ({ document, setDocument, isAnonymous, setIsAnonymous }),
    [isAnonymous, document],
  );

  return <DocumentContext.Provider value={value}>{children}</DocumentContext.Provider>;
};
