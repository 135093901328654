import { useState } from 'react';
import { base } from 'theme/index';

import { cn } from 'utils';

import { Button } from '../button';
import { Icon } from '../icon';
import { Popover, PopoverContent, PopoverTrigger } from '../popover';

const DEFAULT_COLOR_PICKER_COLORS = [
  base.navy[60],
  base.red[80],
  base.red[60],
  base.orange[80],
  base.green[80],
  base.orange[40],
  base.green[40],
  base.lemon[100],
];

interface ColorResult {
  hex: string;
}

interface ColorPickerPopoverProps {
  value: string;
  onChange: (color: ColorResult) => void;
}

interface ColorCircleProps {
  color: string;
  size?: 'sm' | 'lg';
  hasBorder?: boolean;
}

const ColorCircle = ({ color, size = 'sm', hasBorder = false }: ColorCircleProps) => {
  const sizeClasses = {
    sm: 'min-h-4 min-w-4',
    lg: 'min-h-6 min-w-6',
  };

  return (
    <div
      className={cn(
        sizeClasses[size],
        'cursor-pointer rounded-full ring-1 ring-stroke-weaker/20 transition-transform duration-300 hover:scale-125',
        hasBorder && 'light:border-2 light:border-stroke-strong',
      )}
      style={{ backgroundColor: color }}
    />
  );
};

export const ColorPickerPopover = ({ value, onChange }: ColorPickerPopoverProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant='soft' renderRight={<Icon name='chevron-down' />} className='m-2 h-12'>
          <ColorCircle color={value} size='sm' hasBorder />
        </Button>
      </PopoverTrigger>
      <PopoverContent align='end' className='p-4' asDialog>
        <div className='grid min-w-6 grid-cols-4 gap-2'>
          {DEFAULT_COLOR_PICKER_COLORS.map((color) => (
            <Button
              key={color}
              onClick={() => {
                onChange({ hex: color });
                setOpen(false);
              }}
              className={cn(
                'min-h-0 w-full rounded-full border-none p-1 outline-none',
                value === color &&
                  'rounded-full p-1 outline-none ring-2 ring-stroke-weak ring-offset-2',
              )}
              type='button'
            >
              <ColorCircle color={color} size='lg' />
            </Button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};

ColorPickerPopover.defaultColor = DEFAULT_COLOR_PICKER_COLORS[0];
