import { useTranslation } from 'react-i18next';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

import { trackEvent } from '@legalfly/reporting/tracking';
import { Icon } from '@legalfly/ui/icon';
import { withToasts } from '@legalfly/ui/toast';
import { AnonymizationList } from 'components/anonymization/AnonymizationList';
import { ContentDropZone } from 'components/common/content/ContentDropZone';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { DropZone, UploadButton } from 'components/common/upload/Uploader';
import {
  anonymizationQueryOptions,
  useAnonymizations,
  useCreateAnonymization,
} from 'core/modules/anonymization';
import { documentsToasts, useCreateFiles } from 'core/modules/documents';

export const Route = createFileRoute('/_auth/_layout/anonymisation/')({
  component: AnonymisationsRoute,
  loader({ context }) {
    return context.queryClient.ensureQueryData(anonymizationQueryOptions.anonymizations());
  },
});

function AnonymisationsRoute() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { anonymizations } = useAnonymizations();

  const { createFiles, isCreatingFiles } = useCreateFiles('anonymization');

  const { createAnonymization, isLoading: isCreatingAnonymization } = useCreateAnonymization();

  const uploadFiles = async (files: File[]) => {
    const documents = await createFiles({ uuid: undefined, files });

    if (!documents.length) return;

    const anonymization = await withToasts(
      createAnonymization({
        body: {
          documentUuids: documents.map((document) => document.uuid),
        },
      }),
    )(documentsToasts.createAnonymization());

    navigate({
      to: '/anonymisation/$uuid',
      params: { uuid: anonymization.uuid },
    });
  };

  const hasAnonymizations = anonymizations.length > 0;

  return (
    <ContentDropZone
      onDropFiles={(files: File[]) => {
        trackEvent({
          action: 'drop',
          category: 'anonymisation',
        });
        uploadFiles(files);
      }}
      parentClassName='flex-1'
      disabled={!hasAnonymizations}
    >
      <ContentHeader>
        <ContentHeader.Title>{t('anonymisation.title')}</ContentHeader.Title>
        <ContentHeader.SubTitle>{t('anonymisation.subtitle')}</ContentHeader.SubTitle>
        <ContentHeader.Actions>
          <UploadButton
            onUpload={uploadFiles}
            renderLeft={<Icon name='upload-1' />}
            isLoading={isCreatingFiles || isCreatingAnonymization}
            onClick={() => {
              trackEvent({
                action: 'click',
                category: 'anonymisation',
                label: 'upload',
              });
            }}
          >
            {t('action.uploadOrDrop')}
          </UploadButton>
        </ContentHeader.Actions>
      </ContentHeader>
      {hasAnonymizations ? (
        <AnonymizationList anonymizations={anonymizations} />
      ) : (
        <DropZone
          withBackground
          onDropFiles={(files: File[]) => {
            trackEvent({
              action: 'drop',
              category: 'anonymisation',
            });
            uploadFiles(files);
          }}
          renderActions={() => (
            <div className='flex flex-col gap-3'>
              <UploadButton
                onUpload={uploadFiles}
                isLoading={isCreatingFiles || isCreatingAnonymization}
                renderLeft={<Icon name='upload-1' />}
                onClick={() => {
                  trackEvent({
                    action: 'click',
                    category: 'anonymisation',
                    label: 'upload',
                  });
                }}
              >
                {t('action.uploadOrDrop')}
              </UploadButton>
            </div>
          )}
        />
      )}
    </ContentDropZone>
  );
}
