import { useState } from 'react';

import { WebSocketProvider } from '@legalfly/websockets';
import { useAuth } from 'core/auth';
import { websocketApi } from 'core/di';
import { usePublishWebSocketMessages } from 'core/modules/websocket';

export const FrontendWebSocketProvider = () => {
  const [pollingEnabled, setPollingEnabled] = useState(false);
  usePublishWebSocketMessages(pollingEnabled);

  const auth = useAuth();

  return (
    <WebSocketProvider
      isAuthenticated={auth.isAuthenticated}
      authenticateCallback={websocketApi.authenticate}
      pollingEnabled={pollingEnabled}
      togglePollingEnabled={setPollingEnabled}
    />
  );
};
