import type { WebSocketMessageType } from '@legalfly/api/websocket';

// Define the event type and data type mapping
export type WebSocketEventType = keyof WebSocketMessageType;
export type WebSocketDataType<T extends WebSocketEventType> = WebSocketMessageType[T];

// Enforce type safety in your callback
export type WebSocketCallback<T extends WebSocketEventType> = (data: WebSocketDataType<T>) => void;

class WebSocketManager {
  private subscribers: {
    [K in WebSocketEventType]?: WebSocketCallback<K>[];
  };

  constructor() {
    this.subscribers = {};
  }

  subscribe<T extends WebSocketEventType>(eventType: T, callback: WebSocketCallback<T>): void {
    if (!this.subscribers[eventType]) {
      this.subscribers[eventType] = [];
    }

    (this.subscribers[eventType] as WebSocketCallback<T>[]).push(callback);
  }

  unsubscribe<T extends WebSocketEventType>(eventType: T, callback: WebSocketCallback<T>): void {
    const callbacks = this.subscribers[eventType] as WebSocketCallback<T>[] | undefined;

    if (callbacks) {
      this.subscribers[eventType] = callbacks.filter((cb) => cb !== callback) as any;
    }
  }

  publish<T extends WebSocketEventType>(eventType: T, data: WebSocketDataType<T>): void {
    if (this.subscribers[eventType]) {
      this.subscribers[eventType]!.forEach((callback) => callback(data));
    }
  }
}

export const WebSocketManagerInstance = new WebSocketManager();
