import type { BodyOf, ResponseOf } from '../client';
import type { createConversationsAgentApi } from './ConversationsAgentApi';
import type { createConversationsDiscoveryApi } from './ConversationsDiscoveryApi';
import type { createConversationsDraftApi } from './ConversationsDraftApi';
import type { createConversationsReviewApi } from './ConversationsReviewApi';

export enum ConversationTypeEnum {
  DISCOVERY = 'discovery',
  REVIEW = 'review',
  DRAFTS = 'drafts',
  AGENT = 'agent',
}

export const ConversationMessageStatusValues = ['in_progress', 'anomalies', 'completed'] as const;
export type ConversationMessageStatus = (typeof ConversationMessageStatusValues)[number];

type ExtractTypeFromObject<T> = T extends Record<string, (infer U)[]> ? U : never;

export type ApiConversationGrouped = ResponseOf<'discovery/conversations/get'>;
export type ApiConversation = ExtractTypeFromObject<ApiConversationGrouped>;
export type ApiConversationDetail = ResponseOf<'discovery/conversations/:uuid/get'>;
export type ApiConversationJurisdiction = ResponseOf<'discovery/conversations/:uuid/get'>;
export type ApiConversationGroupKey = keyof ResponseOf<'discovery/conversations/get'>;
export type ApiConversationMessageBody = BodyOf<'discovery/conversations/:uuid/patch'>;
export type ApiConversationMessage =
  ResponseOf<'discovery/conversations/:uuid/get'>['messages'][number];
export type ApiConversationMessageSource = ApiConversationMessage['sources'][number];
export type ApiConversationMessageSourceWeb = Extract<
  ApiConversationMessageSource,
  { type: 'web' }
>;
export type ApiConversationMessageSourceDocument = Extract<
  ApiConversationMessageSource,
  { type: 'document' }
>;
export type ApiConversationMessageSourceFullDocument = Extract<
  ApiConversationMessageSource,
  { type: 'full-document' }
>;
export type ApiConversationMessageSourceSwissCaseLaw = Extract<
  ApiConversationMessageSource,
  { type: 'swiss case law' }
>;

export type ConversationsReviewApi = ReturnType<typeof createConversationsReviewApi>;
export type ConversationsDiscoveryApi = ReturnType<typeof createConversationsDiscoveryApi>;
export type ConversationsDraftApi = ReturnType<typeof createConversationsDraftApi>;
export type ConversationsAgentApi = ReturnType<typeof createConversationsAgentApi>;

export interface ConversationsApi {
  reviewApi: ConversationsReviewApi;
  discoveryApi: ConversationsDiscoveryApi;
  draftApi: ConversationsDraftApi;
  agentApi: ConversationsAgentApi;
}
