import { legalFlyConfig } from '@legalfly/config';

import type { ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher';

export const createStatisticsApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const getStatistics = () => {
    return client(api.statistics.list, { method: 'GET' });
  };

  return {
    getStatistics,
  };
};

export type StatisticsApi = ReturnType<typeof createStatisticsApi>;
export type ApiStatistics = ResponseOf<'statistics/get'>;
export type ApiOrganisationStatistic = ResponseOf<'reviews/get'>['stats']['organization'];

export * from './types';
