import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiClause, ApiDraft, ApiDraftClause } from '@legalfly/api/drafting';
import { trackEvent } from '@legalfly/reporting/tracking';
import { withToasts } from '@legalfly/ui/toast';
import {
  draftingToasts,
  useCreateDraftClause,
  useDeleteDraftClause,
  useMoveDraftClause,
  useUpdateDraftClause,
} from 'core/modules/drafting';

import { ReadOnlyClauseItem } from '../clauses/ReadOnlyClauseItem';
import { SortableClausesList } from '../clauses/SortableClausesList';
import { DraftEmptyClausesState } from './DraftEmptyClausesState';

interface Props {
  draft: ApiDraft;
  nextClauseToDraft: ApiDraftClause | undefined;
}

export const DraftClausesList = ({ draft, nextClauseToDraft }: Props) => {
  const { t } = useTranslation();

  const { createClause } = useCreateDraftClause();
  const { moveClause } = useMoveDraftClause();
  const { updateClause } = useUpdateDraftClause();
  const { deleteClause } = useDeleteDraftClause();

  const handleAddClause = useCallback(
    (sequence: number) => {
      trackEvent({
        action: 'click',
        category: 'drafts',
        label: 'addClause',
      });
      withToasts(
        createClause({
          uuid: draft.uuid,
          body: {
            title: t('drafting.clauses.new.title'),
            content: t('drafting.clauses.new.subtitle'),
            status: 'done',
            sequence,
          },
        }),
      )(draftingToasts.createDraftClause());
    },
    [createClause, draft.uuid, t],
  );

  const handleUpdateClause = useCallback(
    async (clause: ApiClause) => {
      const updatedClause = await withToasts(
        updateClause({
          uuid: draft.uuid,
          clauseUuid: clause.uuid,
          body: {
            title: clause.title,
            content: clause.content,
            instructions: clause.instructions,
          },
        }),
      )(draftingToasts.updateDraftClause());

      return updatedClause;
    },
    [updateClause, draft.uuid],
  );

  const handleDeleteClause = useCallback(
    (clauseUuid: ApiClause['uuid']) => {
      trackEvent({
        action: 'click',
        category: 'drafts',
        label: 'deleteClause',
      });
      withToasts(
        deleteClause({
          uuid: draft.uuid,
          clauseUuid,
        }),
      )(draftingToasts.deleteDraftClause());
    },
    [deleteClause, draft.uuid],
  );

  const handleMoveClause = useCallback(
    (clauseUuid: string, newSequence: number) => {
      trackEvent({
        action: 'click',
        category: 'drafts',
        label: 'moveClause',
      });
      withToasts(
        moveClause({
          uuid: draft.uuid,
          clauseUuid,
          body: { sequence: newSequence },
        }),
      )(draftingToasts.moveDraftClause());
    },
    [moveClause, draft.uuid],
  );

  if (!nextClauseToDraft) {
    if (draft.clauses.length === 0) {
      return <DraftEmptyClausesState onAddClause={() => handleAddClause(0)} />;
    }

    return (
      <SortableClausesList
        clauses={draft.clauses}
        onAddClause={handleAddClause}
        onUpdateClause={handleUpdateClause}
        onDeleteClause={handleDeleteClause}
        onMoveClause={handleMoveClause}
      />
    );
  }

  return (
    <div className='flex flex-col gap-2'>
      {draft.clauses.map((clause) => (
        <ReadOnlyClauseItem
          key={clause.uuid}
          clause={clause}
          isDrafting={nextClauseToDraft?.uuid === clause.uuid}
        />
      ))}
    </div>
  );
};
