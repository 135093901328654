import { forwardRef } from 'react';
import type { FieldValidators } from '@tanstack/react-form';
import { useForm } from '@tanstack/react-form';

import { IconButton } from '@legalfly/ui/button';
import { Textarea } from '@legalfly/ui/textarea';
import { cn } from '@legalfly/ui/utils';
import { useDebounceValue } from '@legalfly/utils/hooks';

const defaultValues = {
  message: '',
};

type FormValues = typeof defaultValues;

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type Props = {
  onSendMessage: (message: string) => void;
  fieldPlaceholder: string;
  fieldValidators: FieldValidators<FormValues, 'message'>;
  size?: 'small' | 'large';
  className?: string;
  isLoading?: boolean;
};

export const BaseCopilotInput = forwardRef<HTMLTextAreaElement, Props>(
  (
    { className, fieldValidators, fieldPlaceholder, isLoading, onSendMessage, size = 'small' },
    ref,
  ) => {
    const [debouncedIsLoading] = useDebounceValue(isLoading, 300);

    const form = useForm<FormValues>({
      defaultValues,
      onSubmit: ({ value: { message } }) => {
        onSendMessage(message);
        form.reset();
        form.setFieldValue('message', '');
      },
    });

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        form.handleSubmit();
      }
    };

    return (
      <form
        className={cn('relative flex flex-col', className)}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <form.Field name='message' validators={fieldValidators}>
          {(field) => (
            <Textarea
              ref={ref}
              id={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              placeholder={fieldPlaceholder}
              onChange={(e) => field.handleChange(e.target.value)}
              onKeyDown={handleKeyDown}
              className={cn('resize-none', size === 'small' && 'px-4 py-6')}
              rows={size === 'small' ? 1 : 5}
              disabled={debouncedIsLoading}
            />
          )}
        </form.Field>
        <form.Subscribe selector={(state) => state.canSubmit}>
          {(canSubmit) => (
            <IconButton
              name='trend-up-2'
              variant='tertiary'
              type='submit'
              className={cn(
                'absolute text-icon-strong disabled:text-icon-strong',
                size === 'small' ? 'right-4 top-3' : 'bottom-2 right-2',
              )}
              disabled={!canSubmit}
            />
          )}
        </form.Subscribe>
      </form>
    );
  },
);

BaseCopilotInput.displayName = 'BaseCopilotInput';
