import { useTranslation } from 'react-i18next';

import { Text } from '@legalfly/ui/text';
import { useCurrentUser } from 'core/modules/users';

const CopilotEmptyState = () => {
  const { currentUser } = useCurrentUser();
  const { t } = useTranslation();

  return (
    <div className='self-center'>
      <Text as='h1' variant='title' className='mb-6'>
        {t('discovery.emptyState.title', { name: currentUser.firstName })}
      </Text>
      <Text variant='bodyLarge' className='mb-2 text-content-body-strong'>
        {t('discovery.emptyState.subtitle')}
      </Text>
    </div>
  );
};

export default CopilotEmptyState;
