import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Popover, PopoverContent } from '@legalfly/ui/popover';
import { cn } from '@legalfly/ui/utils';
import { useDebounceCallback } from '@legalfly/utils/hooks';

type Props = PropsWithChildren<{
  popoverContent: React.ReactNode;
  onHighlight: (selectedText: string) => void;
}>;

const useTextSelection = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showPopover, setShowPopover] = useState(false);
  const [selectedText, setSelectedText] = useState('');
  const [clientRect, setClientRect] = useState<Pick<DOMRect, 'x' | 'y' | 'width'>>({
    width: 0,
    x: 0,
    y: 0,
  });

  const hidePopover = () => {
    setShowPopover(false);
  };

  const handleScroll = () => {
    if (showPopover) {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const { x, y, width } = range.getBoundingClientRect();
        setClientRect({ x, y, width });
      }
    }
  };

  const debouncedHandleScroll = useDebounceCallback(handleScroll, 100);

  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll, true);

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll, true);
    };
  }, [showPopover, debouncedHandleScroll]);

  const onMouseUp = useCallback(() => {
    const selection = window.getSelection();
    const selectedText = selection?.toString().trim();

    if (!selection || !selectedText) {
      return hidePopover();
    }

    const selectionRange = selection.getRangeAt(0);

    const startNode = selectionRange.startContainer.parentNode;
    const endNode = selectionRange.endContainer.parentNode;

    if (!containerRef.current?.contains(startNode) || !containerRef.current?.contains(endNode)) {
      return hidePopover();
    }

    if (!startNode?.isSameNode(endNode)) {
      return hidePopover();
    }

    const { x, y, width } = selectionRange.getBoundingClientRect();
    if (!width) {
      return hidePopover();
    }

    setShowPopover(true);
    setSelectedText(selectedText);
    setClientRect({ x, y, width });
  }, [containerRef]);

  return {
    onMouseUp,
    selectedText,
    clientRect,
    showPopover,
    containerRef,
  };
};

const HighlightPopover = ({ children, popoverContent, onHighlight }: Props) => {
  const { containerRef, onMouseUp, showPopover, selectedText, clientRect } = useTextSelection();

  /**
   * Call the onHighlight function when the selected text changes
   */
  useEffect(() => {
    onHighlight(selectedText);
  }, [onHighlight, selectedText]);

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [onMouseUp]);

  return (
    <div ref={containerRef}>
      <Popover open={showPopover}>
        <PopoverContent
          className={cn('absolute p-0')}
          style={{
            left: clientRect?.x + clientRect?.width / 2,
            top: clientRect?.y - 8,
            transform: 'translate(-50%, -100%)',
          }}
          onMouseDown={(e) => e.preventDefault()}
        >
          {popoverContent}
        </PopoverContent>
      </Popover>
      {children}
    </div>
  );
};

export default HighlightPopover;
