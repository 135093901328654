import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@legalfly/ui/button';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@legalfly/ui/dialog';
import { Icon } from '@legalfly/ui/icon';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';

import { CreateAgentForm } from './CreateAgentForm';

export const CreateAgentDialog = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button renderLeft={<Icon name='plus' />} variant='soft' className='w-fit'>
          {t('action.createAgent')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <VisuallyHidden>
          <DialogDescription>{t('dialog.createAgent.description')}</DialogDescription>
        </VisuallyHidden>
        <DialogHeader>
          <DialogTitle>{t('dialog.createAgent.title')}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <CreateAgentForm afterSubmit={() => setIsOpen(false)} />
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};
