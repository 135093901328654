import type React from 'react';

import { SelectedDocumentsProvider } from './SelectedDocumentsProvider';

export function withSelectedDocuments<P extends object>(WrappedComponent: React.ComponentType<P>) {
  const WithSelectedDocuments: React.FC<P> = (props) => {
    return (
      <SelectedDocumentsProvider>
        <WrappedComponent {...props} />
      </SelectedDocumentsProvider>
    );
  };

  WithSelectedDocuments.displayName = `withSelectedDocuments(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return WithSelectedDocuments;
}
