import { useTranslation } from 'react-i18next';
import { Link } from '@tanstack/react-router';

import type { ApiPlaybook } from '@legalfly/api/playbooks';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button, IconButton } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { withToasts } from '@legalfly/ui/toast';
import { Content } from 'components/common/content/Content';
import { ContentRow } from 'components/common/content/ContentRow';
import { useCreatePlaybookItems } from 'core/modules/playbooks';
import { playbookToasts } from 'core/modules/playbooks/toasts';

import { usePlaybookItemActions } from './context/PlaybookItemProvider';
import { EditablePlaybookName } from './EditablePlaybookName';
import { PlaybookItemsListEmpty } from './PlaybookItemsListEmpty';
import { PlaybookItemsSortableList } from './PlaybookItemsSortableList';

interface Props {
  playbook: ApiPlaybook;
}

export const PlaybookDetailItems = ({ playbook }: Props) => {
  const { t } = useTranslation();
  const { setSelectedPlaybookItem } = usePlaybookItemActions();

  const { createPlaybookItems, isLoading: isLoadingCreatePlaybookItem } = useCreatePlaybookItems();

  const handleCreatePlaybookItem = async () => {
    trackEvent({
      action: 'click',
      category: 'playbooksItem',
      label: 'create',
    });
    const [item] = await withToasts(
      createPlaybookItems({ playbookUuid: playbook.uuid, body: [{}] }),
    )(playbookToasts.createPlaybookItems());
    setSelectedPlaybookItem(item);
  };

  return (
    <div className='flex flex-1 flex-col gap-3'>
      <ContentRow className='justify-between'>
        <div className='flex items-center gap-2'>
          <Link to='/playbooks'>
            <IconButton name='chevron-left' variant='tertiary' size='sm' />
          </Link>
          <Icon name='book-open' />
          <EditablePlaybookName playbook={playbook} />
        </div>
        <Button asChild size='sm' variant='soft' renderLeft={<Icon name='list' />}>
          <Link to='/playbooks/$playbookUuid/bulk-items' params={{ playbookUuid: playbook.uuid }}>
            {t('playbooks.overview.empty.bulk')}
          </Link>
        </Button>
      </ContentRow>

      {playbook.items.length ? (
        <Content className='h-content-with-row flex-none'>
          <div>
            <Button
              variant='soft'
              renderLeft={<Icon name='plus' />}
              onClick={handleCreatePlaybookItem}
              isLoading={isLoadingCreatePlaybookItem}
            >
              {t('action.addPlaybookItem')}
            </Button>

            <PlaybookItemsSortableList items={playbook.items} playbookUuid={playbook.uuid} />
          </div>
        </Content>
      ) : (
        <PlaybookItemsListEmpty>
          <Button
            variant='soft'
            renderLeft={<Icon name='plus' />}
            onClick={handleCreatePlaybookItem}
            isLoading={isLoadingCreatePlaybookItem}
          >
            {t('action.addPlaybookItem')}
          </Button>
          <Button asChild variant='soft'>
            <Link to='/playbooks/$playbookUuid/bulk-items' params={{ playbookUuid: playbook.uuid }}>
              {t('playbooks.overview.empty.bulk')}
            </Link>
          </Button>
        </PlaybookItemsListEmpty>
      )}
    </div>
  );
};
