import { useTranslation } from 'react-i18next';

import { Button } from '@legalfly/ui/button';
import { Divider } from '@legalfly/ui/divider';
import { Icon } from '@legalfly/ui/icon';
import { Spinner } from '@legalfly/ui/spinner';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';
import { SelectDocumentsSheet } from 'components/documents/documentPicker/SelectDocumentsSheet';

import { usePlaybookItemActions, usePlaybookItemState } from '../context/PlaybookItemProvider';
import { PlaybookItemSandboxMultiReview } from './PlaybookItemSandboxMultiReview';
import { PlaybookItemSandboxReview } from './PlaybookItemSandboxReview';

export const PlaybookItemSandbox = () => {
  const { t } = useTranslation();
  const { layout, reviewItem, isLoading, selectedDocument, isFormValid } = usePlaybookItemState();
  const { setLayout, handleStart } = usePlaybookItemActions();

  return (
    <div className='my-10 flex flex-grow flex-col gap-4'>
      <div className='flex items-center justify-between gap-3'>
        {reviewItem && (
          <SelectDocumentsSheet>
            <SelectDocumentsSheet.Content allowMultiple={false} allowFolderSelection={false} />
          </SelectDocumentsSheet>
        )}
        <Text className='text-content-body-strong'>{t('playbooks.sandbox.title')}</Text>
      </div>
      {reviewItem && (
        <div>
          <Text className='mb-2'>{t('playbooks.sandbox.layout')}</Text>
          <div className='flex flex-wrap items-center gap-3'>
            <Button
              variant='soft'
              renderLeft={<Icon name='check-done' />}
              onClick={() => setLayout('review')}
              className={cn(layout === 'review' && 'bg-fill-weak')}
            >
              {t('nav.review')}
            </Button>
            <Button
              variant='soft'
              renderLeft={<Icon name='table' />}
              onClick={() => setLayout('multi-review')}
              className={cn(layout === 'multi-review' && 'bg-fill-weak')}
            >
              {t('nav.multi-review')}
            </Button>
          </div>
        </div>
      )}
      <div className='relative flex flex-1 items-center justify-center border border-dashed border-stroke-weak'>
        <div
          className={cn(
            'my-14 flex min-w-[50%] max-w-[80%] flex-col items-start justify-center border border-stroke-weaker bg-fill-maximal',
            !reviewItem && 'p-8',
          )}
        >
          {reviewItem && !isLoading && (
            <Button
              variant='soft'
              size='sm'
              className='absolute left-2 top-2'
              renderLeft={<Icon name='refresh-ccw-5' />}
              onClick={() => handleStart('retry')}
            >
              {t('action.retry')}
            </Button>
          )}
          {(() => {
            if (isLoading) {
              return <Spinner className='my-10 self-center' />;
            }

            if (reviewItem && selectedDocument) {
              if (layout === 'review') {
                return <PlaybookItemSandboxReview item={reviewItem} document={selectedDocument} />;
              } else if (layout === 'multi-review') {
                return (
                  <PlaybookItemSandboxMultiReview item={reviewItem} document={selectedDocument} />
                );
              }
            }

            return (
              <>
                <Text variant='subheadingSemiBold' className='mb-4'>
                  {t('playbooks.sandbox.test')}
                </Text>
                <SelectDocumentsSheet>
                  <SelectDocumentsSheet.Content
                    allowMultiple={false}
                    allowFolderSelection={false}
                  />
                </SelectDocumentsSheet>
                <Divider className='my-4' variant='weak' />
                <Button
                  onClick={() => handleStart('start')}
                  disabled={!selectedDocument || !isFormValid}
                >
                  {t('action.start')}
                </Button>
              </>
            );
          })()}
        </div>
      </div>
    </div>
  );
};
