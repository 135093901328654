import { queryOptions } from '@tanstack/react-query';

import type { ApiConversation, ConversationsApi } from '@legalfly/api/conversations';
import { ConversationTypeEnum } from '@legalfly/api/conversations';

export const conversationsQueryOptions = ({
  agentApi,
  discoveryApi,
  draftApi,
  reviewApi,
}: ConversationsApi) => ({
  conversations: ({ type }: { type: ConversationTypeEnum }) =>
    queryOptions({
      queryKey: [type, 'conversations'],
      queryFn: () => {
        switch (type) {
          case ConversationTypeEnum.REVIEW:
            return reviewApi.getConversations();
          case ConversationTypeEnum.DRAFTS:
            return draftApi.getConversations();
          case ConversationTypeEnum.AGENT:
            return agentApi.getConversations();
          default:
            return discoveryApi.getConversations();
        }
      },
    }),
  conversation: ({
    type,
    uuid,
  }: {
    type: ConversationTypeEnum;
    uuid: ApiConversation['uuid'] | undefined;
  }) =>
    queryOptions({
      queryKey: [type, 'conversation', uuid],
      queryFn: () => {
        switch (type) {
          case ConversationTypeEnum.REVIEW:
            return reviewApi.getConversation({ uuid: uuid! });
          case ConversationTypeEnum.DRAFTS:
            return draftApi.getConversation({ uuid: uuid! });
          case ConversationTypeEnum.AGENT:
            return agentApi.getConversation({ uuid: uuid! });
          default:
            return discoveryApi.getConversation({ uuid: uuid! });
        }
      },
      enabled: Boolean(uuid),
    }),
});
