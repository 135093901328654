import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import type { LegalFlyLanguage } from '@legalfly/config';
import { legalFlyConfig } from '@legalfly/config';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@legalfly/ui/select';
import { env } from 'core/env';

interface Props {
  value: LegalFlyLanguage;
  onChange: (v: LegalFlyLanguage) => void;
  triggerProps?: ComponentProps<typeof SelectTrigger>;
  contentProps?: ComponentProps<typeof SelectContent>;
}

const dummyLanguage = 'cimode';
const languages = env.isProduction
  ? legalFlyConfig.supportedLanguages
  : ([...legalFlyConfig.supportedLanguages, dummyLanguage] as const);

const SelectLanguage = ({ value, onChange, triggerProps, contentProps }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <Select
      value={value}
      onValueChange={(t) => {
        if (t === dummyLanguage) {
          i18n.changeLanguage(t);
          return;
        }
        onChange(t as LegalFlyLanguage);
      }}
    >
      <SelectTrigger {...triggerProps}>
        <SelectValue placeholder='Select language'>{t(`language.${value}`)}</SelectValue>
      </SelectTrigger>
      <SelectContent {...contentProps}>
        {languages.map((language) => (
          <SelectItem key={language} value={language}>
            {language === dummyLanguage ? 'Show keys' : t(`language.${language}`)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default SelectLanguage;
