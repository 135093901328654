import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import type { ApiMultiReview, MultiReviewsApi } from '@legalfly/api/multi-reviews';

import { multiReviewsQueryOptions } from './multiReviewsQueryOptions';

export const createMultiReviewsModule = ({
  multiReviewsApi,
}: {
  multiReviewsApi: MultiReviewsApi;
}) => {
  const queryOptions = multiReviewsQueryOptions({ multiReviewsApi });

  const useMultiReviews = () => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.multiReviews());

    return {
      multiReviews: data?.multiReviews ?? [],
      stats: data?.stats,
      isLoading,
      error,
    };
  };

  const useMultiReview = ({ uuid }: { uuid: ApiMultiReview['uuid'] }) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.multiReview({ uuid }));

    return {
      multiReview: data,
      isLoading,
      error,
    };
  };

  const useCreateMultiReview = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['multi-review', 'create'],
      mutationFn: multiReviewsApi.createMultiReview,
      onSuccess: (multiReview) => {
        queryClient.setQueryData(queryOptions.multiReviews().queryKey, (data) => {
          if (!data) return;

          return {
            ...data,
            multiReviews: [...(data?.multiReviews ?? []), multiReview],
          };
        });

        queryClient.setQueryData(
          queryOptions.multiReview({ uuid: multiReview.uuid }).queryKey,
          multiReview,
        );
      },
    });

    return {
      createMultiReview: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDeleteMultiReview = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['multi-review', 'delete'],
      mutationFn: multiReviewsApi.deleteMultiReview,
      onSuccess: (_, { uuid }) => {
        queryClient.setQueryData(queryOptions.multiReviews().queryKey, (data) => {
          if (!data) return;

          return {
            ...data,
            multiReviews: data.multiReviews?.filter((multiReview) => multiReview.uuid !== uuid),
          };
        });

        queryClient.removeQueries(queryOptions.multiReview({ uuid }));
      },
    });

    return {
      deleteMultiReview: mutateAsync,
      isLoading: isPending,
    };
  };

  const useUpdateMultiReview = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['multi-review', 'update'],
      mutationFn: multiReviewsApi.updateMultiReview,
      onSuccess: (multiReview) => {
        queryClient.setQueryData(
          queryOptions.multiReview({ uuid: multiReview.uuid }).queryKey,
          (old: ApiMultiReview | undefined) => {
            if (!old) return old;
            return {
              ...old,
              name: multiReview.name,
            };
          },
        );
      },
    });

    return {
      updateMultiReview: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    useMultiReviews,
    useMultiReview,
    useCreateMultiReview,
    useDeleteMultiReview,
    useUpdateMultiReview,
    multiReviewsQueryOptions: queryOptions,
  };
};
