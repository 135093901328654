import type { HTMLAttributes } from 'react';

import { cn } from '@legalfly/ui/utils';

export const ContentRow = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn('flex h-content-row items-center bg-fill-maximal px-4 py-3', className)}
      {...props}
    />
  );
};
