import { useCallback } from 'react';

import type { ApiDocument } from '@legalfly/api/documents';
import { FavoriteTypeEnum } from '@legalfly/api/favorites';
import { createFavoritesModule } from '@legalfly/modules/favorites';
import { trackEvent } from '@legalfly/reporting/tracking';
import { withToasts } from '@legalfly/ui/toast';

import { agentsApi, documentsApi, favoritesApi } from '../../di';
import { favoritesToasts } from './toasts';

const favoritesModule = createFavoritesModule({
  favoritesApi,
  documentsApi,
  agentsApi,
});

export const useFavorite = () => {
  const { toggleFavorite } = favoritesModule.useToggleFavorite();

  const favoriteEntity = useCallback(
    async (params: Parameters<typeof toggleFavorite>[0]) => {
      const category = (
        {
          [FavoriteTypeEnum.DOCUMENT]: 'documents',
          [FavoriteTypeEnum.AGENT]: 'agents',
        } as const
      )[params.body.entityType];

      trackEvent({
        action: 'click',
        category,
        label: 'favorite',
      });

      await withToasts(toggleFavorite(params))(favoritesToasts.favorite());
    },
    [toggleFavorite],
  );

  const favoriteDocument = useCallback(
    (document: ApiDocument) =>
      favoriteEntity({
        body: {
          entityUuid: document.uuid,
          entityType: FavoriteTypeEnum.DOCUMENT,
          favorited: !document.favorited,
        },
        metadata: { parentUuid: document.parent?.uuid },
      }),
    [favoriteEntity],
  );

  return { favoriteEntity, favoriteDocument };
};
