export enum PlaybookStatusEnum {
  PRIVATE = 'private',
  PUBLIC = 'public',
  SYSTEM = 'system',
}

export enum PlaybookItemTypeEnum {
  QUESTION = 'question',
  RISK_ASSESSMENT = 'risk_assessment',
  ANOMALY = 'anomaly',
  YES_NO = 'yes_no',
  MULTIPLE_CHOICE = 'multiple_choice',
}

export enum PlaybookItemRiskLevelEnum {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  NEUTRAL = 'neutral',
}
