import { type ApiDocument, DocumentType } from '@legalfly/api/documents';
import type { IconName } from '@legalfly/ui/icon';
import type { WebSocketDataType } from '@legalfly/websockets';
import { WebSocketManagerInstance } from '@legalfly/websockets';

import { useDownloadAnonymizedDocumentUrlViaWS, useDownloadDocumentUrl } from '..';
import { useDownload } from './useDownload';

export const isDocumentFolder = (document: ApiDocument) => document.type === DocumentType.FOLDER;
export const isDocumentFile = (document: ApiDocument) => document.type === DocumentType.FILE;

export const getExtension = (filename: string) =>
  filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;

export const getFileIcon = (fileType: string): IconName => {
  switch (fileType) {
    case 'pdf':
      return 'file-pdf';
    case 'doc':
      return 'file-doc';
    case 'png':
      return 'file-png';
    case 'jpg':
      return 'file-jpg';
    default:
      return 'file-2';
  }
};

export const getDocumentIcon = (document: ApiDocument): IconName => {
  if (isDocumentFolder(document)) return 'folder';
  return getFileIcon(document.fileType);
};

export const getDocumentName = (document: ApiDocument) => {
  const { name } = document;
  if (isDocumentFolder(document)) return name;
  return `${name}.${document.extension}`;
};

export const useDownloadAnonymizedDocument = () => {
  const {
    progress,
    isDownloading,
    setProgress,
    setIsDownloading,
    cancelDownload,
    showErrorToast,
    performDownload,
  } = useDownload();
  const { getDownloadUrlViaWS } = useDownloadAnonymizedDocumentUrlViaWS();

  const handleDownload = async (
    {
      uuid,
      fileName,
    }: {
      uuid: ApiDocument['uuid'];
      fileName: string;
    },
    callback?: () => void,
  ) => {
    if (isDownloading) {
      cancelDownload();
      return;
    }

    const createDownloadListener = async (
      data: WebSocketDataType<'anonymized_document_url_uuid'>,
    ) => {
      WebSocketManagerInstance.unsubscribe(
        `anonymized_document_url_${uuid}`,
        createDownloadListener,
      );
      await performDownload(data.url, fileName);
      callback?.();
    };

    WebSocketManagerInstance.subscribe(`anonymized_document_url_${uuid}`, createDownloadListener);

    try {
      setIsDownloading(true);
      setProgress(0);
      await getDownloadUrlViaWS({ uuid });
    } catch {
      showErrorToast();
    }
  };

  return { progress, isDownloading, handleDownload };
};

export const useDownloadDocument = () => {
  const {
    progress,
    isDownloading,
    setProgress,
    setIsDownloading,
    cancelDownload,
    performDownload,
    showErrorToast,
  } = useDownload();
  const { getDownloadUrl } = useDownloadDocumentUrl();

  const handleDownload = async ({
    uuid,
    fileName,
  }: {
    uuid: ApiDocument['uuid'];
    fileName: string;
  }) => {
    if (isDownloading) {
      cancelDownload();
      return;
    }

    setIsDownloading(true);
    setProgress(0);
    try {
      const data = await getDownloadUrl({ uuid });
      if (!data?.url) return;
      await performDownload(data.url, fileName);
    } catch {
      showErrorToast();
    }
  };

  return { progress, isDownloading, handleDownload };
};

export const useDownloadBlobWithProgress = ({
  blob,
  fileName,
}: {
  blob: Blob;
  fileName: string;
}) => {
  const {
    progress,
    isDownloading,
    setProgress,
    setIsDownloading,
    cancelDownload,
    performDownload,
  } = useDownload();

  const handleDownload = async () => {
    if (isDownloading) {
      cancelDownload();
      return;
    }

    setIsDownloading(true);
    setProgress(0);
    const blobUrl = URL.createObjectURL(blob);
    await performDownload(blobUrl, fileName);
    URL.revokeObjectURL(blobUrl);
  };

  return { progress, isDownloading, handleDownload };
};
