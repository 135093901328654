import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiPlaybook } from '@legalfly/api/playbooks';
import { trackEvent } from '@legalfly/reporting/tracking';
import { IconButton } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { Spinner } from '@legalfly/ui/spinner';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { useDeletePlaybook } from 'core/modules/playbooks';
import { playbookToasts } from 'core/modules/playbooks/toasts';

interface Props {
  playbook: ApiPlaybook;
}

export const PlaybookListMenu = ({ playbook }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { deletePlaybook, isLoading: isLoadingDeletePlaybook } = useDeletePlaybook();

  const handleDeletePlaybook = () => {
    trackEvent({
      action: 'click',
      category: 'playbooks',
      label: 'deletePlaybook',
    });
    withToasts(deletePlaybook({ playbookUuid: playbook.uuid }))(playbookToasts.deletePlaybook());
  };

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <IconButton
          name='dots-horizontal'
          className={cn('rotate-90', isOpen && 'bg-fill-hover-strong')}
          variant='tertiary'
          size='sm'
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className='flex flex-col gap-2' align='end'>
        <DropdownMenuItem
          onClick={() => {
            handleDeletePlaybook();
          }}
        >
          {isLoadingDeletePlaybook ? (
            <Spinner />
          ) : (
            <Icon name='trash-2' className='text-icon-weak' />
          )}
          {t('action.deletePlaybook')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
