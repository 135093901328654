import type { HTMLAttributes } from 'react';

import { cn } from '@legalfly/ui/utils';

type Props = HTMLAttributes<HTMLDivElement>;

export const BaseCopilotActions = ({ children, className, ...props }: Props) => {
  return (
    <div className={cn('flex flex-wrap gap-3', className)} {...props}>
      {children}
    </div>
  );
};

// CopilotActions.SelectJurisdiction = SelectJurisdiction;
