import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createFileRoute, Link, useNavigate } from '@tanstack/react-router';

import { PlaybookItemTypeEnum } from '@legalfly/api/playbooks';
import { Button, IconButton } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { TextareaLineNumbers } from '@legalfly/ui/textarea';
import { withToasts } from '@legalfly/ui/toast';
import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { ContentRow } from 'components/common/content/ContentRow';
import { playbookQueryOptions, useCreatePlaybookItems, usePlaybook } from 'core/modules/playbooks';
import { playbookToasts } from 'core/modules/playbooks/toasts';

export const Route = createFileRoute('/_auth/_layout/playbooks/$playbookUuid/bulk-items')({
  component: PlaybookBulkItemsRoute,
  loader({ context, params }) {
    return context.queryClient.ensureQueryData(
      playbookQueryOptions.playbook({ playbookUuid: params.playbookUuid }),
    );
  },
});

function PlaybookBulkItemsRoute() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { playbookUuid } = Route.useParams();
  const [value, setValue] = useState('');

  const { playbook } = usePlaybook({ playbookUuid });
  const { createPlaybookItems, isLoading: isLoadingCreatePlaybookItem } = useCreatePlaybookItems();

  const handleCreatePlaybookItems = async () => {
    const items = value
      .split('\n')
      .map((item) => ({ name: item, question: item, type: PlaybookItemTypeEnum.QUESTION }));
    await withToasts(createPlaybookItems({ playbookUuid, body: items }))(
      playbookToasts.createPlaybookItems(),
    );
    navigate({ to: '/playbooks/$playbookUuid', params: { playbookUuid } });
  };

  return (
    <div className='flex flex-1 flex-col gap-3'>
      <ContentRow>
        <div className='flex items-center gap-2'>
          <Link to='/playbooks'>
            <IconButton name='chevron-left' variant='tertiary' size='sm' />
          </Link>
          <Icon name='book-open' />
          <Text>{playbook.name}</Text>
        </div>
      </ContentRow>

      <Content className='h-content-with-row flex-none'>
        <ContentHeader>
          <ContentHeader.Title variant='subheadingSemiBold'>
            {t('playbooks.bulk.title')}
          </ContentHeader.Title>
          <ContentHeader.SubTitle>{t('playbooks.bulk.subtitle')}</ContentHeader.SubTitle>
        </ContentHeader>

        <div className='flex flex-col gap-6'>
          <TextareaLineNumbers
            placeholder={t('playbooks.bulk.placeholder')}
            value={value}
            rows={20}
            onChange={(e) => setValue(e.target.value)}
          />
          <Button
            variant='soft'
            renderLeft={<Icon name='list' />}
            className='self-end'
            disabled={value.length === 0}
            isLoading={isLoadingCreatePlaybookItem}
            onClick={handleCreatePlaybookItems}
          >
            {t('playbooks.bulk.cta')}
          </Button>
        </div>
      </Content>
    </div>
  );
}
