import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiConversationMessageSourceSwissCaseLaw } from '@legalfly/api/conversations';
import { Button } from '@legalfly/ui/button';
import { DialogTitle } from '@legalfly/ui/dialog';
import { Icon } from '@legalfly/ui/icon';
import { Sheet, SheetContent, SheetTrigger } from '@legalfly/ui/sheet';
import { Text } from '@legalfly/ui/text';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';

interface Snippet {
  id: number;
  court: string;
  text: string;
  source?: string;
}

interface GroupedResult {
  jurisdiction: string;
  snippets: Snippet[];
}

const groupResultsByJurisdiction = (
  source: ApiConversationMessageSourceSwissCaseLaw,
): GroupedResult[] => {
  const groupedMap: Record<string, GroupedResult> = {};

  const addToGroup = (jurisdiction: string, snippet: Snippet) => {
    if (!groupedMap[jurisdiction]) {
      groupedMap[jurisdiction] = { jurisdiction, snippets: [] };
    }
    groupedMap[jurisdiction].snippets.push(snippet);
  };

  source.results.forEach(({ jurisdiction, snippet, id, court, source }) => {
    addToGroup(jurisdiction, { text: snippet, id, court, source });
  });

  return Object.values(groupedMap);
};

const GroupedJurisdiction = ({ result }: { result: GroupedResult }) => {
  const { t } = useTranslation();
  let currentCourt = '';

  return (
    <Sheet>
      <SheetTrigger asChild>
        <div className='flex cursor-pointer flex-col bg-fill-strongest p-6 transition-all duration-300 ease-in-out'>
          <div className='flex justify-between'>
            <div className='flex gap-2'>
              <Icon name='flag-switzerland' className='flex-shrink-0' />
              <Text variant='body' className='line-clamp-1'>
                {result.jurisdiction}
              </Text>
            </div>
          </div>
          <div className='mt-6 flex flex-col gap-5'>
            <Button variant='soft' className='self-start text-content-body-weak'>
              {t('action.viewJurisdictionHighlights', { count: result.snippets.length })}
            </Button>
          </div>
        </div>
      </SheetTrigger>
      <SheetContent className='flex h-full w-auto flex-col gap-8 p-8 sm:max-w-[50%]'>
        <VisuallyHidden>
          <DialogTitle>{result.jurisdiction}</DialogTitle>
        </VisuallyHidden>
        {result.snippets
          .sort((a, b) => a.court.localeCompare(b.court))
          .map((snippet) => {
            const showCourtHeading = snippet.court !== currentCourt;
            const url = snippet.source && new URL(snippet.source);
            if (showCourtHeading) {
              currentCourt = snippet.court;
            }

            return (
              <div key={snippet.id} className='flex flex-col gap-4'>
                {showCourtHeading && (
                  <Text as='h2' className='pb-4' variant='subheadingSemiBold'>
                    {snippet.court}
                  </Text>
                )}
                <Text>{snippet.text}</Text>
                {url && (
                  <a href={url.href} target='_blank' rel='noreferrer'>
                    <Button
                      asChild
                      variant='hard'
                      className='mt-4 flex justify-center gap-2'
                      renderLeft={<Icon name='flag-switzerland' />}
                    >
                      {t('action.visitWebPage', {
                        url: url.origin.replace(/^https?:\/\//, ''),
                      })}
                    </Button>
                  </a>
                )}
              </div>
            );
          })}
      </SheetContent>
    </Sheet>
  );
};

interface Props {
  source: ApiConversationMessageSourceSwissCaseLaw;
}

export const SwissCaseLawSource = ({ source }: Props) => {
  const groupedResults = useMemo(() => groupResultsByJurisdiction(source), [source]);

  return groupedResults.map((result) => (
    <GroupedJurisdiction key={result.jurisdiction} result={result} />
  ));
};
