import type { LogoutOptions } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';

import { resetAnalytics, trackEvent } from '@legalfly/reporting/tracking';

export const useAuth = () => {
  const queryClient = useQueryClient();
  const { logout: auth0Logout, ...rest } = useAuth0();

  const logout = async (options?: LogoutOptions) => {
    trackEvent({
      action: 'click',
      category: 'auth',
      label: 'logout',
    });
    queryClient.clear();
    resetAnalytics();
    await auth0Logout(options);
  };

  return {
    ...rest,
    logout,
  };
};
