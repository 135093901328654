import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiAgentDetail } from '@legalfly/api/agents';
import { IconButton } from '@legalfly/ui/button';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@legalfly/ui/dialog';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';

import { EditAgentDialogForm } from './EditAgentDialogForm';

interface Props {
  agent: ApiAgentDetail;
}

export const EditAgentDialog = ({ agent }: Props) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <IconButton name='edit' className='bg-transparent text-icon-weak' size='sm' />
      </DialogTrigger>
      <DialogContent>
        <VisuallyHidden>
          <DialogDescription>{t('dialog.editAgent.description')}</DialogDescription>
        </VisuallyHidden>
        <DialogHeader>
          <DialogTitle>{t('dialog.editAgent.title')}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <EditAgentDialogForm agent={agent} afterSubmit={() => setIsOpen(false)} />
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};
