import { useTranslation } from 'react-i18next';
import { useForm } from '@tanstack/react-form';

import { FeedbackFeature } from '@legalfly/api/feedback';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { FormItem } from '@legalfly/ui/form';
import { Textarea } from '@legalfly/ui/textarea';
import { withToasts } from '@legalfly/ui/toast';
import { useSendFeedback } from 'core/modules/feedback';
import { feedbackToasts } from 'core/modules/feedback/toasts';

import { requiredValidator } from '../form/validations';
import SelectFeedbackFeature from './SelectFeedbackFeature';

interface Props {
  afterSubmit: () => void;
}

const defaultValues = {
  feedback: '',
  feature: FeedbackFeature.Review,
};

interface FormValues {
  feedback: string;
  feature: FeedbackFeature;
}

export const FeedbackForm = ({ afterSubmit }: Props) => {
  const { t } = useTranslation();
  const { sendFeedback } = useSendFeedback();

  const form = useForm<FormValues>({
    defaultValues,
    onSubmit: async ({ value }: { value: FormValues }) => {
      trackEvent({
        action: 'submit',
        category: 'feedback',
        label: 'give',
      });
      await withToasts(sendFeedback({ description: value.feedback, feature: value.feature }))(
        feedbackToasts.sendFeedback(),
      );
      afterSubmit();
    },
  });

  return (
    <form
      className='flex flex-col gap-4'
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <FormItem>
        <form.Field
          name='feature'
          validators={{
            onBlur: ({ value }) => requiredValidator(t('form.feedback.validation.required'))(value),
          }}
        >
          {(field) => (
            <SelectFeedbackFeature
              value={field.state.value}
              onChange={(value) => {
                field.handleChange(value);
              }}
              triggerProps={{ className: 'px-3 py-4' }}
            />
          )}
        </form.Field>
      </FormItem>

      <FormItem>
        <form.Field
          name='feedback'
          validators={{
            onBlur: ({ value }) => requiredValidator(t('form.feedback.validation.required'))(value),
          }}
        >
          {(field) => (
            <Textarea
              id={field.name}
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              placeholder={t('form.feedback.placeholder')}
              onChange={(e) => field.handleChange(e.target.value)}
              errorMessage={field.state.meta.errors.join()}
              rows={5}
            />
          )}
        </form.Field>
      </FormItem>

      <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
        {([canSubmit, isSubmitting]) => (
          <Button type='submit' disabled={!canSubmit} className='self-end' isLoading={isSubmitting}>
            {t('action.send')}
          </Button>
        )}
      </form.Subscribe>
    </form>
  );
};
