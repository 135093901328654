import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@legalfly/ui/button';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@legalfly/ui/dialog';
import { Icon } from '@legalfly/ui/icon';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';

import { CreateUserForm } from './CreateUserForm';

export const CreateUserDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button className='ps-2 ' variant='soft' size='sm' renderLeft={<Icon name='plus' />}>
          {t('action.inviteUser')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <VisuallyHidden>
          <DialogDescription>{t('dialog.createUser.description')}</DialogDescription>
        </VisuallyHidden>
        <DialogHeader>
          <DialogTitle>{t('dialog.createUser.title')}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <CreateUserForm afterSubmit={() => setIsOpen(false)} />
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};
