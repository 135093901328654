import { t } from 'i18next';

import type { ApiMultiReview } from '@legalfly/api/multi-reviews';
import { getDocumentName } from 'core/modules/documents/helpers';

export const getMultiReviewName = (multiReview: ApiMultiReview) => {
  if (multiReview.name) {
    return multiReview.name;
  }

  const firstPart = multiReview.reviews
    .slice(0, 2)
    .map((review) => getDocumentName(review.document))
    .join(', ');

  if (multiReview.reviews.length <= 2) {
    return firstPart;
  }

  const additionalCount = multiReview.reviews.length - 2;
  return `${firstPart} ${t('multiReview.additionalReviews', { count: additionalCount })}`;
};
