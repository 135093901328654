import { createFileRoute } from '@tanstack/react-router';

import { ConversationTypeEnum } from '@legalfly/api/conversations';
import { Conversation } from 'components/conversations/Conversation';
import { conversationsQueryOptions } from 'core/modules/conversations';

export const Route = createFileRoute('/_auth/_layout/discovery/$uuid')({
  component: ConversationsWrapper,
  loader({ context, params }) {
    return context.queryClient.ensureQueryData(
      conversationsQueryOptions.conversation({
        type: ConversationTypeEnum.DISCOVERY,
        uuid: params.uuid,
      }),
    );
  },
});

function ConversationsWrapper() {
  const { uuid } = Route.useParams();

  return (
    <Conversation
      uuid={uuid}
      conversationType={ConversationTypeEnum.DISCOVERY}
      analyticsMeta={{ category: 'discovery' }}
    />
  );
}
