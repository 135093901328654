import { queryOptions } from '@tanstack/react-query';

import type { ApiReview, ReviewsApi } from '@legalfly/api/reviews';

export const reviewsQueryOptions = ({ reviewsApi }: { reviewsApi: ReviewsApi }) => ({
  reviews: () =>
    queryOptions({
      queryKey: ['reviews'],
      queryFn: () => reviewsApi.getReviews(),
    }),
  review: ({ uuid }: { uuid: ApiReview['uuid'] }) =>
    queryOptions({
      queryKey: ['reviews', uuid],
      queryFn: () => reviewsApi.getReview({ uuid }),
    }),
});
