import type { InputHTMLAttributes, ReactElement } from 'react';
import { cloneElement, forwardRef } from 'react';

import { cn } from 'utils';

import { InputErrorMessage } from './InputErrorMessage';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
  renderLeft?: ReactElement;
  leftClassName?: string;
  renderRight?: ReactElement;
  rightClassName?: string;
  errorMessage?: string;
  errorClassName?: string;
  underlineOnError?: boolean;
}

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      containerClassName,
      type = 'text',
      renderLeft,
      leftClassName,
      renderRight,
      rightClassName,
      errorMessage,
      errorClassName,
      underlineOnError,
      ...props
    },
    ref,
  ) => {
    const inputComponent = (
      <input
        type={type}
        className={cn(
          'flex h-12 w-full border border-stroke-weaker bg-transparent px-3 py-4',
          'placeholder:text-content-body-placeholder',
          'focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-4 focus-visible:outline-stroke-weaker',
          'disabled:pointer-events-none disabled:cursor-not-allowed disabled:border-stroke-weaker disabled:text-content-body-disabled disabled:placeholder:text-content-body-disabled',
          Boolean(renderLeft) && 'ps-10',
          Boolean(renderRight) && 'pe-10',
          Boolean(errorMessage) && 'border-stroke-error focus-visible:outline-stroke-error-weak',
          underlineOnError && 'underline decoration-stroke-error underline-offset-4',
          className,
        )}
        ref={ref}
        {...props}
      />
    );

    return (
      <span className={cn('relative', containerClassName)}>
        <InputErrorMessage errorMessage={errorMessage} errorClassName={errorClassName} />
        {renderLeft &&
          cloneElement(renderLeft, { className: cn('absolute left-3 top-4', leftClassName) })}
        {inputComponent}
        {renderRight &&
          cloneElement(renderRight, {
            className: cn('absolute right-3 top-4', rightClassName),
          })}
      </span>
    );
  },
);
Input.displayName = 'Input';

export { Input };
