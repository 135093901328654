import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useKeywordFilter } from 'common/hooks/useKeywordFilter';

import type { ApiDocument } from '@legalfly/api/documents';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { Divider } from '@legalfly/ui/divider';
import { SearchInputForm } from '@legalfly/ui/form';
import { Icon } from '@legalfly/ui/icon';
import { SidePaneContent, SidePaneHeader, SidePaneProvider } from '@legalfly/ui/sidePane';
import { Text } from '@legalfly/ui/text';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { ContentDropZone } from 'components/common/content/ContentDropZone';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { DropZone, UploadButton } from 'components/common/upload/Uploader';
import CreateFolderDialog from 'components/documents/createFolder/CreateFolderDialog';
import { DocumentsDetailPane } from 'components/documents/detailPane/DocumentsDetailPane';
import DocumentPicker from 'components/documents/documentPicker/DocumentPicker';
import { useSelectedDocuments } from 'components/documents/documentPicker/SelectedDocumentsProvider';
import { withSelectedDocuments } from 'components/documents/documentPicker/withSelectedDocuments';
import {
  DocumentsUploadProgress,
  useDocumentsUploadProgress,
} from 'components/documents/DocumentsUploadProgress';
import { EditableFolderName } from 'components/documents/EditableFolderName';
import {
  documentsQueryOptions,
  documentsToasts,
  ListenToDocumentsUploadStatus,
  useCreateFiles,
  useDropDocument,
  useFolder,
} from 'core/modules/documents';
import { getDocumentIcon } from 'core/modules/documents/helpers';

export const Route = createFileRoute('/_auth/_layout/documents/folder/')({
  component: withSelectedDocuments(DocumentsFolderRoute),
  loader: ({ context }) => context.queryClient.ensureQueryData(documentsQueryOptions.folder()),
});

export function DocumentsFolderRoute({ uuid }: { uuid?: string }) {
  const { documents, folder } = useFolder(uuid);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { createFiles, numberOfFilesToUpload, isCreatingFiles } = useCreateFiles('documents');
  const { dropDocument } = useDropDocument();
  const { filteredItems: filteredDocuments, setKeyword } = useKeywordFilter(documents, 'name');

  const { selectedDocuments, clearSelectedDocuments } = useSelectedDocuments();

  const hasDocuments = documents.length > 0;
  const canGoBack = Boolean(documents[0]?.parent) || Boolean(!hasDocuments && uuid);

  const documentUploadProgress = useDocumentsUploadProgress(documents, numberOfFilesToUpload);
  const hasSelectedDocuments = selectedDocuments.length > 0;

  useEffect(() => {
    if (hasSelectedDocuments) {
      trackEvent({
        action: 'click',
        category: 'documentsDetailPane',
        label: 'open',
      });
    }
  }, [hasSelectedDocuments]);

  const onNavigateBack = () => {
    if (folder?.parent) {
      navigate({
        to: '/documents/folder/$uuid',
        params: { uuid: folder.parent.uuid },
      });
    } else {
      navigate({
        to: '/documents/folder',
      });
    }
  };

  const uploadFiles = (files: File[]) => {
    createFiles({ uuid, files });
  };

  const handleDropDocument = (params: { sourceUuid: string; targetUuid: string }) => {
    withToasts(dropDocument({ body: { ...params, uuid } }))(documentsToasts.dropDocument());
  };

  const handleDoubleClickFolder = useCallback(
    (document: ApiDocument) => {
      navigate({
        to: '/documents/folder/$uuid',
        params: { uuid: document.uuid },
      });
    },
    [navigate],
  );

  return (
    <SidePaneProvider
      open={hasSelectedDocuments}
      onOpenChange={(open) => {
        if (!open) {
          clearSelectedDocuments();
        }
      }}
    >
      <div
        className={cn(
          'flex flex-1 flex-col gap-3',
          !documentUploadProgress.isUploading ? 'h-content' : 'h-content-with-progress',
        )}
      >
        <ContentDropZone
          onDropFiles={(files) => {
            trackEvent({
              action: 'drop',
              category: 'documents',
            });
            uploadFiles(files);
          }}
          parentClassName={cn(
            'h-full',
            documentUploadProgress.isUploading ? 'flex-none' : 'flex-1',
          )}
          disabled={!hasDocuments}
        >
          <ContentHeader className={cn(!hasDocuments && 'mb-0')}>
            <ContentHeader.Title>
              {folder ? (
                <EditableFolderName uuid={folder.uuid} name={folder.name} />
              ) : (
                t('documents.title')
              )}
            </ContentHeader.Title>
            <ContentHeader.SubTitle>{t('documents.subtitle')}</ContentHeader.SubTitle>
            <ContentHeader.Actions className='flex-wrap'>
              {canGoBack && (
                <Button
                  onClick={onNavigateBack}
                  renderLeft={<Icon name='chevron-left' />}
                  size='sm'
                  variant='soft'
                >
                  {t('action.back')}
                </Button>
              )}
              <SearchInputForm
                placeholder={t('label.search')}
                onKeywordChange={setKeyword}
                onBlur={() =>
                  trackEvent({
                    action: 'blur',
                    category: 'documents',
                    label: 'search',
                  })
                }
              />
              <CreateFolderDialog uuid={uuid} />
              <UploadButton
                onUpload={uploadFiles}
                renderLeft={<Icon name='upload-1' />}
                isLoading={isCreatingFiles}
                size='sm'
                onClick={() =>
                  trackEvent({
                    action: 'click',
                    category: 'documents',
                    label: 'upload',
                  })
                }
              >
                {t('action.uploadOrDrop')}
              </UploadButton>
            </ContentHeader.Actions>
          </ContentHeader>

          {hasDocuments ? (
            <>
              <ListenToDocumentsUploadStatus documents={documents} />
              <DocumentPicker
                documents={filteredDocuments}
                onDropDocument={handleDropDocument}
                onDoubleClickFolder={handleDoubleClickFolder}
                allowContextMenu
                allowFolderSelection
              />
              <SidePaneContent className='h-content'>
                <SidePaneHeader>
                  <div className='flex gap-2 truncate'>
                    {selectedDocuments.length === 1 ? (
                      <Icon name={getDocumentIcon(selectedDocuments[0])} />
                    ) : (
                      <Icon name='list' />
                    )}
                    <Text as='h3' className='truncate'>
                      {selectedDocuments.length === 1
                        ? selectedDocuments[0].name
                        : t('documents.documentPicker.amount_selected', {
                            count: selectedDocuments.length,
                          })}
                    </Text>
                  </div>
                </SidePaneHeader>
                <Divider variant='weak' className='mb-8 mt-3' />
                {hasSelectedDocuments && <DocumentsDetailPane documents={selectedDocuments} />}
              </SidePaneContent>
            </>
          ) : (
            <DropZone
              onDropFiles={(files) => {
                trackEvent({
                  action: 'drop',
                  category: 'documents',
                });
                uploadFiles(files);
              }}
              withBackground
              renderActions={() => (
                <UploadButton
                  onUpload={uploadFiles}
                  renderLeft={<Icon name='upload-1' />}
                  onClick={() =>
                    trackEvent({
                      action: 'click',
                      category: 'documents',
                      label: 'upload',
                    })
                  }
                >
                  {t('action.uploadDocuments')}
                </UploadButton>
              )}
            />
          )}
        </ContentDropZone>
        <DocumentsUploadProgress {...documentUploadProgress} />
      </div>
    </SidePaneProvider>
  );
}
