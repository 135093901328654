import type { ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'common/hooks/useCopyToClipboard';

import { trackEvent } from '@legalfly/reporting/tracking';
import { IconButton } from '@legalfly/ui/button';
import { cn } from '@legalfly/ui/utils';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & { content: string };

export const CopyToClipboardButton = ({ content, className, ...props }: Props) => {
  const { t } = useTranslation();

  const copyToClipboard = useCopyToClipboard();

  const handleClick = () => {
    trackEvent({
      action: 'click',
      category: 'discovery',
      label: 'copyToClipboard',
    });
    copyToClipboard(content);
  };

  return (
    <IconButton
      {...props}
      name='clipboard'
      className={cn('ms-auto', className)}
      aria-label={t('action.copyToClipboard')}
      variant='tertiary'
      onClick={handleClick}
      size='sm'
    />
  );
};
