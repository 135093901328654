import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf, ParamsOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';

export const createReviewsApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const getReviews = () => {
    return client(api.reviews.list, { method: 'GET' });
  };

  const getReview = ({ uuid }: ParamsOf<'reviews/:uuid/get'>) => {
    return client(api.reviews.detail, { method: 'GET', params: { uuid } });
  };

  const createReview = ({ body }: { body: BodyOf<'reviews/post'> }) => {
    return client(api.reviews.create, { method: 'POST', body });
  };

  const deleteReview = ({ uuid }: ParamsOf<'reviews/:uuid/delete'>) => {
    return client(api.reviews.delete, { method: 'DELETE', params: { uuid } });
  };

  return {
    getReviews,
    getReview,
    createReview,
    deleteReview,
  };
};

export type ReviewsApi = ReturnType<typeof createReviewsApi>;
export type ApiReview = ResponseOf<'reviews/get'>['reviews'][number];
export type ApiReviewStats = ResponseOf<'reviews/get'>['stats'];
export type ApiReviewItem = ResponseOf<'reviews/:uuid/get'>['items'][number];
export type ApiReviewDetail = ResponseOf<'reviews/:uuid/get'>;
