export const getCssVariable = (
  variable:
    | 'tabs-list-height'
    | 'sidebar-width'
    | 'body-padding'
    | 'content-width'
    | 'content-padding-y'
    | 'content-header-height',
) => {
  return parseInt(
    getComputedStyle(document.documentElement).getPropertyValue(`--lf-${variable}`).trim(),
    10,
  );
};
