import { queryOptions } from '@tanstack/react-query';

import type { NotificationsApi } from '@legalfly/api/notifications';

export const notificationsQueryOptions = ({
  notificationsApi,
}: {
  notificationsApi: NotificationsApi;
}) => ({
  getNotifications: () =>
    queryOptions({
      queryKey: ['notifications'],
      queryFn: () => notificationsApi.getNotifications(),
    }),
});
