import type { TextareaHTMLAttributes } from 'react';
import { forwardRef, useMemo } from 'react';

import { cn } from 'utils';

import { InputErrorMessage } from '../input/InputErrorMessage';
import './styles.css';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  errorMessage?: string;
  errorClassName?: string;
  autoSize?: boolean;
}

const getStyles = (className?: string, errorMessage?: string) =>
  cn(
    'flex w-full border border-stroke-weaker bg-fill-strongest p-4',
    'focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-4 focus-visible:outline-stroke-weak',
    'disabled:pointer-events-none disabled:cursor-not-allowed disabled:text-content-body-disabled disabled:placeholder:text-content-body-disabled',
    Boolean(errorMessage) && 'border-stroke-error focus-visible:outline-stroke-error-weak',
    className,
  );

const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  ({ className, errorMessage, errorClassName, ...props }, ref) => {
    const styles = getStyles(className, errorMessage);

    return (
      <>
        <InputErrorMessage errorMessage={errorMessage} errorClassName={errorClassName} />
        <textarea className={styles} ref={ref} {...props} />
      </>
    );
  },
);
Textarea.displayName = 'Textarea';

// https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas
const TextareaAutoSize = forwardRef<HTMLTextAreaElement, Props>(
  ({ className, ...props }: Props, ref) => {
    className = className ? `${className} resize-none` : 'resize-none';

    const afterStyles = useMemo(() => {
      return getStyles(className)
        .split(' ')
        .map((style) => `after:${style}`)
        .join(' ');
    }, [className]);

    if (window.CSS?.supports('field-sizing', 'content')) {
      return (
        // @ts-expect-error fieldSizing is only supported in chromium browsers
        <Textarea style={{ fieldSizing: 'content' }} className={className} {...props} ref={ref} />
      );
    }

    return (
      <div className={`tx-auto-size ${afterStyles}`}>
        <Textarea
          ref={ref}
          onInput={(e) => {
            const target = e.target as HTMLTextAreaElement;
            const parent = target.parentElement;
            if (parent) {
              parent.dataset.value = target.value;
            }
          }}
          className={className}
          {...props}
        />
      </div>
    );
  },
);
TextareaAutoSize.displayName = 'TextareaAutoSize';

export { Textarea, TextareaAutoSize };
