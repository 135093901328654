import { useTranslation } from 'react-i18next';
import { useForm } from '@tanstack/react-form';

import { PlaybookStatusEnum } from '@legalfly/api/playbooks';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { FormItem, FormItemLabel } from '@legalfly/ui/form';
import { Input } from '@legalfly/ui/input';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { nameValidator } from 'components/common/form/validations';
import { useCreatePlaybook } from 'core/modules/playbooks';
import { playbookToasts } from 'core/modules/playbooks/toasts';

interface Props {
  afterSubmit: (newPlaybookUuid: string) => void;
}

const defaultValues = {
  name: '',
  status: PlaybookStatusEnum.PRIVATE,
};

type FormValues = typeof defaultValues;

export const CreatePlaybookForm = ({ afterSubmit }: Props) => {
  const { t } = useTranslation();
  const { createPlaybook } = useCreatePlaybook();

  const form = useForm<FormValues>({
    defaultValues,
    onSubmit: async ({ value }) => {
      trackEvent({
        action: 'submit',
        category: 'playbooks',
        label: 'createPlaybook',
      });
      const newPlaybook = await withToasts(
        createPlaybook({ name: value.name.trim(), items: [], status: value.status }),
      )(playbookToasts.createPlaybook());
      afterSubmit(newPlaybook.uuid);
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      className='flex flex-col gap-4'
    >
      <FormItem>
        <FormItemLabel label={t('form.playbookStatus.label')} htmlFor='status' />
        <form.Field name='status'>
          {(field) => (
            <div className='flex gap-4'>
              <Button
                className={cn(field.state.value === PlaybookStatusEnum.PRIVATE && 'bg-fill-weak')}
                onClick={() => field.handleChange(PlaybookStatusEnum.PRIVATE)}
              >
                {t('form.status.private')}
              </Button>
              <Button
                className={cn(field.state.value === PlaybookStatusEnum.PUBLIC && 'bg-fill-weak')}
                onClick={() => field.handleChange(PlaybookStatusEnum.PUBLIC)}
              >
                {t('form.status.company')}
              </Button>
            </div>
          )}
        </form.Field>
      </FormItem>

      <FormItem>
        <FormItemLabel label={t('form.playbookName.label')} htmlFor='name' />
        <form.Field
          name='name'
          validators={{
            onChange: ({ value }) => nameValidator(t)(value),
          }}
        >
          {(field) => (
            <Input
              id={field.name}
              name={field.name}
              placeholder={t('form.playbookName.placeholder')}
              value={field.state.value}
              onChange={(e) => field.handleChange(e.target.value)}
              onBlur={field.handleBlur}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          )}
        </form.Field>
      </FormItem>

      <form.Subscribe selector={(state) => state.canSubmit}>
        {(canSubmit) => (
          <Button type='submit' disabled={!canSubmit} className='self-end'>
            {t('action.save')}
          </Button>
        )}
      </form.Subscribe>
    </form>
  );
};
