import type { ComponentProps } from 'react';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import useResizeObserver from 'use-resize-observer';

import { cn } from 'utils';

import { InputErrorMessage } from '../input/InputErrorMessage';
import { Textarea } from './Textarea';

interface Props extends Omit<ComponentProps<typeof Textarea>, 'value'> {
  value: string;
}

const TextareaLineNumbers = forwardRef<HTMLTextAreaElement, Props>(
  ({ className, value, errorMessage, errorClassName, ...props }, ref) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [textAreaWidth, setTextAreaWidth] = useState(0);
    const lineNumbersRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => textareaRef.current!, []);

    const [canvasContext] = useState<CanvasRenderingContext2D>(
      () => document.createElement('canvas').getContext('2d')!,
    );
    const [lineNumbers, setLineNumbers] = useState<number[]>([]);

    const calculateNumLines = useCallback(
      (str: string, width: number) => {
        const words = str.split(' ');
        let lineCount = 0;
        let currentLine = '';
        for (let i = 0; i < words.length; i++) {
          const wordWidth = canvasContext.measureText(i === 0 ? words[i] : ` ${words[i]}`).width;
          const lineWidth = canvasContext.measureText(currentLine).width;

          if (lineWidth + wordWidth > width) {
            lineCount++;
            currentLine = words[i];
          } else {
            currentLine += i === 0 ? words[i] : ` ${words[i]}`;
          }
        }

        if (currentLine.trim() !== '') {
          lineCount++;
        }

        return lineCount;
      },
      [canvasContext],
    );

    const calculateLineNumbers = useCallback(
      (value: string, width: number) => {
        const lines = value.split('\n');
        const numLines = lines.map((line) => calculateNumLines(line, width));
        const lineNumbers = [];

        let i = 1;
        while (numLines.length > 0) {
          const numLinesOfSentence = numLines.shift();
          lineNumbers.push(i);
          if (Number(numLinesOfSentence) > 1) {
            Array(Number(numLinesOfSentence) - 1)
              .fill('')
              .forEach((_) => lineNumbers.push(''));
          }
          i++;
        }

        return lineNumbers;
      },
      [calculateNumLines],
    );

    useResizeObserver<HTMLTextAreaElement>({
      ref: textareaRef,
      onResize: ({ width }) => {
        if (!width || !textareaRef.current) {
          return;
        }

        const textareaRect = textareaRef.current.getBoundingClientRect();

        setTextAreaWidth(textareaRect.width);
      },
    });

    const updateLineNumbers = useCallback(() => {
      if (!lineNumbersRef.current || !textareaRef.current) return;

      const lineNumbers = lineNumbersRef.current;
      const textarea = textareaRef.current;

      // horizontally align line numbers with the textarea
      const textareaStyles = window.getComputedStyle(textarea);
      (['fontFamily', 'fontSize', 'lineHeight', 'padding'] as const).forEach((property) => {
        lineNumbers.style[property] = textareaStyles[property];
      });

      canvasContext.font = `${textareaStyles.fontSize} ${textareaStyles.fontFamily}`;

      const width =
        textAreaWidth -
        parseInt(textareaStyles.paddingLeft, 10) -
        parseInt(textareaStyles.paddingRight, 10);

      const lines = calculateLineNumbers(value, width);
      setLineNumbers(lines);
    }, [calculateLineNumbers, canvasContext, value, textAreaWidth]);

    useLayoutEffect(() => {
      updateLineNumbers();
    }, [updateLineNumbers, textAreaWidth]);

    const onScroll = useCallback(() => {
      if (lineNumbersRef.current && textareaRef.current) {
        lineNumbersRef.current.scrollTop = textareaRef.current.scrollTop;
      }
    }, []);

    return (
      <>
        <InputErrorMessage errorMessage={errorMessage} errorClassName={errorClassName} />
        <div
          className={cn(
            'flex max-h-[30vh] overflow-hidden border border-stroke-weaker',
            Boolean(errorMessage) && 'border-stroke-error focus-visible:outline-stroke-error-weak',
          )}
        >
          <div
            ref={lineNumbersRef}
            className='relative min-w-10 overflow-hidden border-r border-stroke-weaker text-center '
          >
            {lineNumbers.map((num, index) => (
              <div key={index}>{num || '\u00A0'}</div>
            ))}
          </div>
          <Textarea
            ref={textareaRef}
            className={cn(
              'w-full resize-none border-none px-2 py-4 outline-none focus:ring-0 focus-visible:outline-transparent',
              className,
            )}
            value={value}
            onScroll={onScroll}
            {...props}
          />
        </div>
      </>
    );
  },
);
TextareaLineNumbers.displayName = 'TextareaLineNumbers';

export { TextareaLineNumbers };
