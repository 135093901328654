import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import type { ApiDocument, DocumentOrigin } from '@legalfly/api/documents';
import type { FetcherError } from '@legalfly/api/fetcher';
import { createDocumentsModule } from '@legalfly/modules/documents';
import { useDialog } from '@legalfly/ui/dialog';
import { withToasts } from '@legalfly/ui/toast';
import type { WebSocketDataType } from '@legalfly/websockets';
import { useWebSocketSubscriber } from '@legalfly/websockets';
import { FileTypeErrorDialog } from 'components/common/errors/FileTypeErrorDialog';

import { documentsApi } from '../../di';
import { documentsToasts } from './toasts';

const dialogErrorPriority: Record<string, number> = {
  INVALID_FILE: 1,
  // add more errors here as needed
};

const getDialogErrorPriority = (code: string) => dialogErrorPriority[code] || 999;

const documentsModule = createDocumentsModule({
  documentsApi,
});

export const {
  useFolder,
  useFile,
  useFiles,
  useFileSnippets,
  useFileSummary,
  useCreateFile,
  useCreateFolder,
  useDeleteFile,
  useDeleteFolder,
  useCreateDocumentEntity,
  useUpdateDocumentEntity,
  useDeleteDocumentEntity,
  useDownloadDocumentUrl,
  useDownloadAnonymizedDocumentUrlViaWS,
  useDropDocument,
  useUpdateFolder,
  useRetryFile,
  useCountFolderDocuments,
  useDeleteDocuments,
  documentsQueryOptions,
} = documentsModule;

export * from './toasts';

export type FileUploadState = Record<
  File['name'],
  { isLoading: boolean; error: FetcherError | null }
>;

type DocumentOriginValue = `${DocumentOrigin}`;

export const useCreateFiles = (origin: DocumentOriginValue) => {
  const { createFile } = useCreateFile();
  const dialog = useDialog();
  const [isCreatingFiles, setIsCreatingFiles] = useState(false);
  const [numberOfFilesToUpload, setNumberOfFilesToUpload] = useState(0);
  const { t } = useTranslation();

  const createFiles = async ({
    uuid,
    files,
  }: {
    uuid: ApiDocument['uuid'] | undefined;
    files: File[];
  }) => {
    setNumberOfFilesToUpload(files.length);
    setIsCreatingFiles(true);

    type UploadResult =
      | { success: true; document: ApiDocument }
      | { success: false; error: FetcherError };

    const uploadResults = await Promise.all<UploadResult>(
      files.map(async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('origin', origin);

        try {
          const { document } = await withToasts(createFile({ uuid, file: formData }))(
            documentsToasts.uploadFile(file.name),
          );
          return { success: true, document };
        } catch (error) {
          return { success: false, error: error as FetcherError };
        }
      }),
    );

    setIsCreatingFiles(false);

    const errors = uploadResults
      .filter((result) => !result.success)
      .filter((result) => result.error);

    if (errors.length > 0) {
      const highestPriorityError = errors.reduce((prev, curr) =>
        getDialogErrorPriority(curr.error.response.code) <
        getDialogErrorPriority(prev.error.response.code)
          ? curr
          : prev,
      );
      if (highestPriorityError.error.response.code === 'INVALID_FILE') {
        dialog.open(<FileTypeErrorDialog onClose={dialog.close} actionText={t('action.close')} />);
      }
    }

    return uploadResults.filter((result) => result.success).map(({ document }) => document);
  };

  return {
    createFiles,
    numberOfFilesToUpload,
    isCreatingFiles,
  };
};

export const useListenToDocumentUploadStatus = (
  {
    document,
  }: {
    document: ApiDocument;
  },
  callback?: (data: WebSocketDataType<`document_upload_status_string`>) => void,
) => {
  const queryClient = useQueryClient();

  useWebSocketSubscriber(`document_upload_status_${document.uuid}`, (data) => {
    queryClient.setQueryData(documentsQueryOptions.file(document.uuid).queryKey, (oldData) => {
      if (!oldData) return oldData;
      return { ...oldData, status: data.status };
    });

    queryClient.setQueryData(
      documentsQueryOptions.folder(document.parent?.uuid ?? undefined).queryKey,
      (oldData) => {
        if (!oldData) return oldData;
        const updatedDocuments = oldData.documents.map((doc) =>
          doc.uuid === document.uuid ? { ...doc, status: data.status } : doc,
        );
        return { ...oldData, documents: updatedDocuments };
      },
    );

    callback?.(data);
  });
};

const ListenToDocumentUploadStatus = ({ document }: { document: ApiDocument }) => {
  useListenToDocumentUploadStatus({ document });
  return null;
};

export const ListenToDocumentsUploadStatus = ({ documents }: { documents: ApiDocument[] }) =>
  documents.map((document) => (
    <ListenToDocumentUploadStatus key={document.uuid} document={document} />
  ));
