import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import type { ApiDraftClause } from '@legalfly/api/drafting';
import { createDraftingModule } from '@legalfly/modules/drafting';
import { withToasts } from '@legalfly/ui/toast';

import { draftingApi } from '../../di';
import { downloadBlobToDesktop } from '../documents/helpers/useDownload';
import { draftingToasts } from './toasts';

const draftingModule = createDraftingModule({
  draftingApi,
});

export const {
  useTemplates,
  useTemplate,
  useCreateTemplate,
  useDeleteTemplate,
  useUpdateTemplate,
  useDrafts,
  useDraft,
  useCreateDraft,
  useUpdateDraft,
  useDeleteDraft,
  useCreateDraftClause,
  useUpdateDraftClause,
  useMoveDraftClause,
  useDeleteDraftClause,
  useCreateTemplateClause,
  useUpdateTemplateClause,
  useMoveTemplateClause,
  useDeleteTemplateClause,
  draftingQueryOptions,
} = draftingModule;

interface UpdateDraftClauseCacheParams {
  draftUuid: string;
  updatedClause: Partial<ApiDraftClause>;
}

export const useUpdateDraftClauseCache = () => {
  const queryClient = useQueryClient();

  return useCallback(
    ({ draftUuid, updatedClause }: UpdateDraftClauseCacheParams) => {
      queryClient.setQueryData(
        draftingQueryOptions.draft({ uuid: draftUuid }).queryKey,
        (draft) => {
          if (!draft) return undefined;

          return {
            ...draft,
            clauses: draft.clauses.map((clause) =>
              clause.uuid === updatedClause.uuid
                ? {
                    ...clause,
                    ...updatedClause,
                  }
                : clause,
            ),
          };
        },
      );
    },
    [queryClient],
  );
};

export const useDownloadDraftAsPdf = () => {
  const { downloadDraftAsPdf, isLoading } = draftingModule.useDownloadDraftAsPdf();

  const downloadPdf = useCallback(
    async ({ uuid }: Parameters<typeof downloadDraftAsPdf>[0]) => {
      const { document, filename, type } = await withToasts(downloadDraftAsPdf({ uuid }))(
        draftingToasts.downloadDraftAsPdf(),
      );
      const blob = new Blob([new Uint8Array(Object.values(document))], { type });
      downloadBlobToDesktop(blob, filename);
    },
    [downloadDraftAsPdf],
  );

  return { downloadPdf, isLoading };
};

export const useDownloadDraftAsDocx = () => {
  const { downloadDraftAsDocx, isLoading } = draftingModule.useDownloadDraftAsDocx();

  const downloadDocx = useCallback(
    async ({ uuid }: Parameters<typeof downloadDraftAsDocx>[0]) => {
      const { document, filename, type } = await withToasts(downloadDraftAsDocx({ uuid }))(
        draftingToasts.downloadDraftAsDocx(),
      );

      const blob = new Blob([new Uint8Array(Object.values(document))], { type });
      downloadBlobToDesktop(blob, filename);
    },
    [downloadDraftAsDocx],
  );

  return { downloadDocx, isLoading };
};

export * from './toasts';
