import { useTranslation } from 'react-i18next';
import { createFileRoute, Navigate, useNavigate } from '@tanstack/react-router';
import { useKeywordFilter } from 'common/hooks/useKeywordFilter';

import type { ApiTemplate } from '@legalfly/api/drafting';
import { trackEvent } from '@legalfly/reporting/tracking';
import { SearchInputForm } from '@legalfly/ui/form';
import { withToasts } from '@legalfly/ui/toast';
import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { TemplatesList } from 'components/drafting/templates/TemplatesList';
import {
  draftingQueryOptions,
  draftingToasts,
  useCreateDraft,
  useTemplates,
} from 'core/modules/drafting';

export const Route = createFileRoute('/_auth/_layout/drafting/drafts/choose-template')({
  component: DraftingChooseTemplateRoute,
  loader({ context }) {
    return context.queryClient.ensureQueryData(draftingQueryOptions.templates());
  },
});

function DraftingChooseTemplateRoute() {
  const { t } = useTranslation();
  const { templates } = useTemplates();
  const navigate = useNavigate();
  const { filteredItems: filteredTemplates, setKeyword } = useKeywordFilter(templates, 'title');

  const { createDraft } = useCreateDraft();

  const handleSelectTemplate = async (template: ApiTemplate) => {
    const draft = await withToasts(
      createDraft({
        body: {
          templateUuid: template.uuid,
        },
      }),
    )(draftingToasts.createDraftingTemplate());

    navigate({
      to: '/drafting/drafts/$uuid',
      params: { uuid: draft.uuid },
    });
  };

  if (templates.length === 0) {
    return <Navigate to='/drafting/drafts' />;
  }

  return (
    <Content>
      <ContentHeader>
        <ContentHeader.Title>{t('drafting.templates.cta.title')}</ContentHeader.Title>
        <ContentHeader.SubTitle>{t('drafting.templates.cta.subtitle')}</ContentHeader.SubTitle>
        <ContentHeader.Actions>
          <SearchInputForm
            placeholder={t('label.search')}
            onKeywordChange={setKeyword}
            onBlur={() =>
              trackEvent({
                action: 'blur',
                category: 'templates',
                label: 'search',
              })
            }
          />
        </ContentHeader.Actions>
      </ContentHeader>
      <TemplatesList
        hasAvailableTemplates={templates.length > 0}
        filteredTemplates={filteredTemplates}
        onSelectTemplate={handleSelectTemplate}
      />
    </Content>
  );
}
