import { queryOptions } from '@tanstack/react-query';

import type { ApiMultiReview, MultiReviewsApi } from '@legalfly/api/multi-reviews';

export const multiReviewsQueryOptions = ({
  multiReviewsApi,
}: {
  multiReviewsApi: MultiReviewsApi;
}) => ({
  multiReviews: () =>
    queryOptions({
      queryKey: ['multi-reviews'],
      queryFn: () => multiReviewsApi.getMultiReviews(),
    }),
  multiReview: ({ uuid }: { uuid: ApiMultiReview['uuid'] }) =>
    queryOptions({
      queryKey: ['multi-reviews', uuid],
      queryFn: () => multiReviewsApi.getMultiReview({ uuid }),
    }),
});
