import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import type { DocumentCategoryKey } from '@legalfly/api/documents';
import type { ApiPlaybook, PlaybooksApi } from '@legalfly/api/playbooks';

import { playbookQueryOptions } from './playbookQueryOptions';

export const createPlaybooksModule = ({ playbooksApi }: { playbooksApi: PlaybooksApi }) => {
  const queryOptions = playbookQueryOptions({ playbooksApi });

  const usePlaybooks = (query?: Parameters<PlaybooksApi['getPlaybooks']>[0]) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.playbooks(query));

    return {
      playbooks: data ?? [],
      isLoading,
      error,
    };
  };

  const usePlaybook = ({ playbookUuid }: { playbookUuid: ApiPlaybook['uuid'] }) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.playbook({ playbookUuid }));

    return {
      playbook: data,
      isLoading,
      error,
    };
  };

  const useSystemPlaybooks = (category: DocumentCategoryKey) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.systemPlaybooks(category));

    return {
      systemPlaybooks: data,
      isLoading,
      error,
    };
  };

  const useCreatePlaybook = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['playbook', 'create'],
      mutationFn: playbooksApi.createPlaybook,
      onSuccess: (playbook) => {
        queryClient.setQueryData(queryOptions.playbooks().queryKey, (playbooks) => {
          return [...(playbooks ?? []), playbook];
        });
      },
    });

    return {
      createPlaybook: mutateAsync,
      isLoading: isPending,
    };
  };

  const useUpdatePlaybook = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['playbook', 'update'],
      mutationFn: playbooksApi.updatePlaybook,
      onSuccess: (playbook) => {
        queryClient.setQueryData(queryOptions.playbooks().queryKey, (playbooks) => {
          return playbooks?.map((p) => (p.uuid === playbook.uuid ? playbook : p));
        });
      },
    });

    return {
      updatePlaybook: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDeletePlaybook = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['playbook', 'delete'],
      mutationFn: playbooksApi.deletePlaybook,
      onSuccess: (_, { playbookUuid }) => {
        queryClient.setQueriesData(
          {
            queryKey: queryOptions.playbooks().queryKey,
          },
          (playbooks: ApiPlaybook[] | undefined) => {
            if (!playbooks) return [];

            return playbooks.filter((p) => p.uuid !== playbookUuid);
          },
        );

        queryClient.removeQueries(queryOptions.playbook({ playbookUuid }));
      },
    });

    return {
      deletePlaybook: mutateAsync,
      isLoading: isPending,
    };
  };

  const useCreatePlaybookItems = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['playbook', 'item', 'create'],
      mutationFn: playbooksApi.createPlaybookItem,
      onSuccess: (items, { playbookUuid }) => {
        queryClient.setQueryData(queryOptions.playbook({ playbookUuid }).queryKey, (playbook) => {
          if (!playbook) return playbook;

          return {
            ...playbook,
            items: [...items, ...playbook.items],
          };
        });
      },
    });

    return {
      createPlaybookItems: mutateAsync,
      isLoading: isPending,
    };
  };

  const useUpdatePlaybookItem = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['playbook', 'item', 'update'],
      mutationFn: playbooksApi.updatePlaybookItem,
      onSuccess: (playbookItem, { playbookUuid, itemUuid }) => {
        queryClient.setQueryData(queryOptions.playbook({ playbookUuid }).queryKey, (playbook) => {
          if (!playbook) return playbook;

          return {
            ...playbook,
            items: playbook.items.map((item) => (item.uuid === itemUuid ? playbookItem : item)),
          };
        });
      },
    });

    return {
      updatePlaybookItem: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDeletePlaybookItem = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['playbook', 'item', 'delete'],
      mutationFn: playbooksApi.deletePlaybookItem,
      onSuccess: (_, { playbookUuid, itemUuid }) => {
        queryClient.setQueryData(queryOptions.playbook({ playbookUuid }).queryKey, (playbook) => {
          if (!playbook) return playbook;

          return {
            ...playbook,
            items: playbook.items.filter((item) => item.uuid !== itemUuid),
          };
        });
      },
    });

    return {
      deletePlaybookItem: mutateAsync,
      isLoading: isPending,
    };
  };

  const useMovePlaybookItem = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['playbook', 'item', 'move'],
      mutationFn: playbooksApi.movePlaybookItem,
      onSuccess: (playbook) => {
        queryClient.setQueryData(
          queryOptions.playbook({ playbookUuid: playbook.uuid }).queryKey,
          (playbook) => {
            return playbook;
          },
        );
      },
    });

    return {
      movePlaybookItem: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    usePlaybooks,
    usePlaybook,
    useSystemPlaybooks,
    useCreatePlaybook,
    useDeletePlaybook,
    useUpdatePlaybook,
    useCreatePlaybookItems,
    useUpdatePlaybookItem,
    useDeletePlaybookItem,
    useMovePlaybookItem,
    playbookQueryOptions: queryOptions,
  };
};
