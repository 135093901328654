import type { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import { useTableHeight } from 'common/hooks/useTableHeight';

import type { ApiAnonymization } from '@legalfly/api/anonymization';
import { Icon } from '@legalfly/ui/icon';
import { List, ListItem } from '@legalfly/ui/list';
import { Text } from '@legalfly/ui/text';
import { formatLongDate, formatRelativeTime } from '@legalfly/utils/date';

import { getAnonymizationName } from './helpers/getAnonymizationName';
import { AnonymizationsMenu } from './AnonymizationsMenu';

interface Props {
  anonymizations: ApiAnonymization[];
}

export const AnonymizationList = ({ anonymizations }: Props) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { tableHeight, tableRef } = useTableHeight();

  return (
    <List style={{ maxHeight: tableHeight }} ref={tableRef} className='relative overflow-y-auto'>
      {anonymizations.map((anonymization) => {
        const handleOnClick: MouseEventHandler<HTMLDivElement> = (e) => {
          const isDropdownMenu = (e.target as HTMLElement).closest('[data-dropdown-menu]');

          if (!isDropdownMenu) {
            navigate({
              to: '/anonymisation/$uuid',
              params: { uuid: anonymization.uuid },
            });
          }
        };

        return (
          <ListItem key={anonymization.uuid} onClick={handleOnClick}>
            <div className='flex items-center gap-2'>
              <Icon name='book-open' />
              <span className='text-body-semibold'>{getAnonymizationName(anonymization)}</span>
            </div>
            <div className='flex items-center gap-2'>
              <Text
                className='text-content-body-weak'
                title={formatLongDate(anonymization.createdAt, i18n.language)}
              >
                {formatRelativeTime(anonymization.createdAt, i18n.language)}
              </Text>
              <AnonymizationsMenu anonymization={anonymization} />
            </div>
          </ListItem>
        );
      })}
    </List>
  );
};
