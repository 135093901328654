import { PlaybookItemTypeEnum } from '@legalfly/api/playbooks';
import type { ApiReviewItem } from '@legalfly/api/reviews';
import { cn } from '@legalfly/ui/utils';

import { RISK_RELATED_PLAYBOOK_ITEM_TYPES } from './helpers';

interface Props {
  highlight?: boolean;
  className?: string;
  item: ApiReviewItem;
}

export const ReviewRiskGlow = ({ highlight, className, item }: Props) => {
  if (
    !(
      RISK_RELATED_PLAYBOOK_ITEM_TYPES.includes(item.type) ||
      item.type === PlaybookItemTypeEnum.YES_NO
    )
  )
    return null;

  const level =
    item.type === PlaybookItemTypeEnum.YES_NO
      ? item.answer.isYes
        ? 'low'
        : 'high'
      : (item.answer.level ?? 'neutral');

  return (
    <div
      className={cn(
        'h-2 min-h-2 w-2 min-w-2 rounded-full bg-risk-fill-neutral transition-shadow duration-700 group-hover:shadow-risk-glow-neutral ',
        {
          'bg-risk-fill-low group-hover:shadow-risk-glow-low': level === 'low',
          'bg-risk-fill-mid group-hover:shadow-risk-glow-mid': level === 'medium',
          'bg-risk-fill-high group-hover:shadow-risk-glow-high': level === 'high',
          'shadow-risk-glow-low': highlight && level === 'low',
          'shadow-risk-glow-mid': highlight && level === 'medium',
          'shadow-risk-glow-high': highlight && level === 'high',
        },
        className,
      )}
    />
  );
};
