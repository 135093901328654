import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiReviewStats } from '@legalfly/api/reviews';

import {
  InsightsCard,
  InsightsCardFooter,
  InsightsCardHeader,
  InsightsCardIcon,
  InsightsCardList,
  InsightsCardTitle,
} from './InsightsCard';

interface Props {
  stats: ApiReviewStats['organization'];
}

export const OrganizationInsightsCard = ({ stats }: Props) => {
  const { t } = useTranslation();

  const organizationItems = useMemo(
    () =>
      stats.map(({ count, user }) => ({
        title: `${user.firstName} ${user.lastName}`.trim(),
        count,
      })),
    [stats],
  );

  if (organizationItems.length === 0) {
    return null;
  }

  return (
    <InsightsCard>
      <InsightsCardHeader>
        <InsightsCardTitle>{t('insights.organization.title')}</InsightsCardTitle>
        <InsightsCardIcon name='users-2' />
      </InsightsCardHeader>
      <InsightsCardFooter className='flex flex-col items-start gap-2'>
        <InsightsCardList items={organizationItems} />
      </InsightsCardFooter>
    </InsightsCard>
  );
};
