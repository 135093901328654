import { toast } from './useToast';

type ToastOptions = Parameters<typeof toast>[0];

const showDefaultToast = (options?: ToastOptions) => {
  toast({
    variant: 'default',
    ...options,
  });
};

const showSuccessToast = (options?: ToastOptions) => {
  toast({
    variant: 'success',
    ...options,
  });
};

const showErrToast = (options?: ToastOptions) =>
  toast({
    variant: 'danger',
    ...options,
  });

export interface Toasts {
  initialToast?: ToastOptions;
  successToast?: ToastOptions | ((...args: any[]) => ToastOptions | undefined);
  errorToast?: ToastOptions | ((...args: any[]) => ToastOptions);
}

const handleInitialToast = (initialToast: Toasts['initialToast']) => {
  if (initialToast) {
    showDefaultToast(initialToast);
  }
};
const handleSuccessToast = (data: unknown) => (successToast: Toasts['successToast']) => {
  if (successToast) {
    showSuccessToast(typeof successToast === 'function' ? successToast(data) : successToast);
  }
};
const handleErrorToast = (err: unknown) => (errorToast: Toasts['errorToast']) => {
  if (errorToast) {
    showErrToast(typeof errorToast === 'function' ? errorToast(err) : errorToast);
  }
};

export const withToasts =
  <T extends Promise<Awaited<T>>>(fn: T) =>
  async ({ initialToast, successToast, errorToast }: Toasts = {}) => {
    try {
      handleInitialToast(initialToast);
      const res = await fn;
      handleSuccessToast(res)(successToast);
      return res;
    } catch (err) {
      handleErrorToast(err)(errorToast);
      throw err;
    }
  };

export const createToasts = <T extends Record<string, (p: any) => Toasts>>(obj: T): T => obj;
