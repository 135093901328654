import { useTranslation } from 'react-i18next';
import { useForm } from '@tanstack/react-form';

import type { ApiAgentDetail } from '@legalfly/api/agents';
import { Button } from '@legalfly/ui/button';
import { FormItem, FormItemLabel } from '@legalfly/ui/form';
import { Input } from '@legalfly/ui/input';
import { Textarea } from '@legalfly/ui/textarea';
import { nameValidator, requiredValidator } from 'components/common/form/validations';

interface Props {
  onSubmit: (values: FormValues) => void;
  onClose: VoidFunction;
  conversationStarter?: ApiAgentDetail['conversationStarters'][number];
}

interface FormValues {
  name: string;
  prompt: string;
}

export const AgentConversationStarterForm = ({ onClose, onSubmit, conversationStarter }: Props) => {
  const { t } = useTranslation();

  const defaultValues: FormValues = {
    name: conversationStarter?.name ?? '',
    prompt: conversationStarter?.prompt ?? '',
  };

  const form = useForm<FormValues>({
    defaultValues,
    onSubmit: ({ value }) => onSubmit(value),
  });

  return (
    <form
      className='flex flex-col gap-4'
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <FormItem className='flex-1'>
        <FormItemLabel
          className='text-content-body-strong'
          label={t('form.name.label')}
          htmlFor='name'
        />
        <form.Field
          name='name'
          validators={{
            onBlur: ({ value }) => nameValidator(t)(value),
          }}
        >
          {(field) => (
            <Input
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              id={field.name}
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              placeholder={t('form.name.placeholder')}
              onChange={(e) => field.handleChange(e.target.value)}
              errorMessage={field.state.meta.errors.join()}
            />
          )}
        </form.Field>
      </FormItem>

      <FormItem>
        <FormItemLabel
          className='text-content-body-strong'
          label={t('form.agentConversationStarterPrompt.label')}
          htmlFor='prompt'
        />
        <form.Field
          name='prompt'
          validators={{
            onBlur: ({ value }) =>
              requiredValidator(t('form.agentConversationStarterPrompt.validation.required'))(
                value,
              ),
          }}
        >
          {(field) => (
            <Textarea
              id={field.name}
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              placeholder={t('form.agentConversationStarterPrompt.placeholder')}
              onChange={(e) => field.handleChange(e.target.value)}
              errorMessage={field.state.meta.errors.join()}
            />
          )}
        </form.Field>
      </FormItem>

      <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
        {([canSubmit, isSubmitting]) => (
          <div className='flex items-center justify-end gap-4'>
            <Button onClick={onClose}>{t('action.cancel')}</Button>
            <Button
              type='submit'
              disabled={!canSubmit}
              className='self-end'
              isLoading={isSubmitting}
            >
              {t('action.save')}
            </Button>
          </div>
        )}
      </form.Subscribe>
    </form>
  );
};
