import { useTranslation } from 'react-i18next';
import type { SearchSchemaInput } from '@tanstack/react-router';
import { createFileRoute, Link } from '@tanstack/react-router';
import { useKeywordFilter } from 'common/hooks/useKeywordFilter';

import { PlaybookStatusEnum } from '@legalfly/api/playbooks';
import { trackEvent } from '@legalfly/reporting/tracking';
import { SearchInputForm } from '@legalfly/ui/form';
import { Tabs, TabsList, TabsTrigger } from '@legalfly/ui/tabs';
import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { CreatePlaybookDialog } from 'components/playbooks/create/CreatePlaybookDialog';
import { PlaybookList } from 'components/playbooks/list/PlaybookList';
import { PlaybookListEmpty } from 'components/playbooks/list/PlaybookListEmpty';
import { playbookQueryOptions, usePlaybooks } from 'core/modules/playbooks';

export const Route = createFileRoute('/_auth/_layout/playbooks/')({
  validateSearch: (search: { status?: PlaybookStatusEnum } & SearchSchemaInput) => {
    return {
      status: search?.status,
    };
  },
  component: PlaybooksOverviewRoute,
  loaderDeps: ({ search: { status } }) => ({ status }),
  loader({ context, deps: { status } }) {
    return context.queryClient.ensureQueryData(
      playbookQueryOptions.playbooks(status ? { status } : undefined),
    );
  },
});

function PlaybooksOverviewRoute() {
  const { t } = useTranslation();
  const { status } = Route.useSearch();

  const { playbooks } = usePlaybooks(status ? { status } : undefined);
  const { filteredItems: filteredPlaybooks, setKeyword } = useKeywordFilter(playbooks, 'name');

  return (
    <Content className='overflow-visible'>
      <ContentHeader>
        <ContentHeader.Title>{t('nav.playbooks')}</ContentHeader.Title>
        <ContentHeader.SubTitle>{t('playbooks.overview.description')}</ContentHeader.SubTitle>
        <Tabs defaultValue={status ?? 'all'}>
          <TabsList className='mt-6 w-auto bg-transparent p-0'>
            <TabsTrigger
              value='all'
              asChild
              className='flex-grow-0 border-b-2 border-transparent px-6 data-[state=active]:border-stroke-weak'
            >
              <Link to='/playbooks'>{t('playbooks.tabs.all')}</Link>
            </TabsTrigger>
            <TabsTrigger
              value='private'
              asChild
              className='flex-grow-0 border-b-2 border-transparent px-6 data-[state=active]:border-stroke-weak'
            >
              <Link to='/playbooks' search={{ status: PlaybookStatusEnum.PRIVATE }}>
                {t('playbooks.tabs.private')}
              </Link>
            </TabsTrigger>
            <TabsTrigger
              value='public'
              asChild
              className='flex-grow-0 border-b-2 border-transparent px-6 data-[state=active]:border-stroke-weak'
            >
              <Link to='/playbooks' search={{ status: PlaybookStatusEnum.PUBLIC }}>
                {t('playbooks.tabs.company')}
              </Link>
            </TabsTrigger>
          </TabsList>
        </Tabs>

        <ContentHeader.Actions>
          <SearchInputForm
            placeholder={t('label.search')}
            onKeywordChange={setKeyword}
            onBlur={() =>
              trackEvent({
                action: 'blur',
                category: 'playbooks',
                label: 'search',
              })
            }
          />
          <CreatePlaybookDialog />
        </ContentHeader.Actions>
      </ContentHeader>

      {playbooks.length ? <PlaybookList playbooks={filteredPlaybooks} /> : <PlaybookListEmpty />}
    </Content>
  );
}
