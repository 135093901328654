import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  dropdownMenuItemStyles,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { cn } from '@legalfly/ui/utils';
import { UploadButton } from 'components/common/upload/Uploader';
import { useUpdateConversation } from 'core/modules/conversations';

import { useCopilotContext } from '../CopilotProvider';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type Props = {
  onUpload: (files: File[]) => void;
};

const CopilotAttachDocuments = ({ onUpload }: Props) => {
  const { t } = useTranslation();
  const { conversation, conversationType } = useCopilotContext();

  const countAttachedDocuments = conversation?.documents.length ?? 0;

  const { updateConversation } = useUpdateConversation({
    type: conversationType,
  });

  if (countAttachedDocuments === 0) {
    return (
      <UploadButton
        onUpload={onUpload}
        renderLeft={<Icon name='link-1' />}
        className='gap-2 border-solid'
        size='sm'
        onClick={() => {
          trackEvent({
            action: 'click',
            category: 'discovery',
            label: 'attachDocuments',
          });
        }}
      >
        {t('discovery.actions.attachDocuments')}
      </UploadButton>
    );
  }

  const handleDeleteDocument = async (e: MouseEvent<HTMLDivElement>, documentUuid: string) => {
    trackEvent({
      action: 'click',
      category: 'discovery',
      label: 'deleteDocument',
    });
    e.preventDefault();
    e.stopPropagation();

    if (!conversation?.uuid) return;

    await updateConversation({
      uuid: conversation.uuid,
      body: {
        documentUuids: conversation.documents
          .filter((doc) => doc.uuid !== documentUuid)
          .map((doc) => doc.uuid),
      },
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button renderLeft={<Icon name='link-1' />} variant='soft' size='md'>
          {t('discovery.attachDocuments.files', { count: countAttachedDocuments })}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='flex w-72 flex-col gap-2' align='start'>
        <UploadButton
          onUpload={onUpload}
          renderRight={<Icon name='plus' />}
          className={cn(dropdownMenuItemStyles, 'justify-between')}
          variant='unstyled'
          onClick={() => {
            trackEvent({
              action: 'click',
              category: 'discovery',
              label: 'attachExtraDocuments',
            });
          }}
        >
          <span className='text-content-body-placeholder'>
            {t('discovery.attachDocuments.filesAttached', { count: countAttachedDocuments })}
          </span>
        </UploadButton>
        {conversation?.documents.map((document) => (
          <DropdownMenuItem
            key={document.uuid}
            className='justify-between'
            onClick={(e) => handleDeleteDocument(e, document.uuid)}
          >
            <span className='truncate'>{document.name}</span>
            <Icon name='trash-4' className='flex-shrink-0' size='sm' />
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default CopilotAttachDocuments;
