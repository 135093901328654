import { useTranslation } from 'react-i18next';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_auth/auth/error')({
  beforeLoad: async ({ context }) => {
    if (!context.auth?.error) {
      throw redirect({
        to: '/',
        replace: true,
      });
    }
  },
  component: ErrorRoute,
});

// TODO:
function ErrorRoute() {
  const { t } = useTranslation();

  const error = Route.useRouteContext({
    select: (context) => context.auth?.error,
  });

  return (
    <div>
      <h1>{t('errors.auth.title')}</h1>
      <p>{error?.message}</p>
    </div>
  );
}
