import { createFileRoute } from '@tanstack/react-router';

import { withSelectedDocuments } from 'components/documents/documentPicker/withSelectedDocuments';
import { PlaybookItemProvider } from 'components/playbooks/item/context/PlaybookItemProvider';
import { PlaybookDetail } from 'components/playbooks/item/PlaybookDetail';
import { playbookQueryOptions } from 'core/modules/playbooks';

export const Route = createFileRoute('/_auth/_layout/playbooks/$playbookUuid/')({
  component: withSelectedDocuments(PlaybookDetailRoute),
  loader({ context, params }) {
    return context.queryClient.ensureQueryData(
      playbookQueryOptions.playbook({ playbookUuid: params.playbookUuid }),
    );
  },
});

function PlaybookDetailRoute() {
  const { playbookUuid } = Route.useParams();

  return (
    <PlaybookItemProvider playbookUuid={playbookUuid}>
      <PlaybookDetail />
    </PlaybookItemProvider>
  );
}
