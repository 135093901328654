import type { HTMLAttributes } from 'react';

import { cn } from '@legalfly/ui/utils';

export const HeaderCell = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn(
        'sticky top-0 flex items-center gap-2 border-r border-stroke-weaker bg-fill-strong px-4 py-3 text-content-body-strong shadow-table-border',
        className,
      )}
      {...props}
    />
  );
};
