import { useState } from 'react';
import { t } from 'i18next';

import type { ApiConversation, ConversationTypeEnum } from '@legalfly/api/conversations';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button, IconButton } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { Spinner } from '@legalfly/ui/spinner';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { useWebSocketSubscriber } from '@legalfly/websockets';
import { useConversations, useDeleteConversation } from 'core/modules/conversations';
import { conversationToasts } from 'core/modules/conversations/toasts';

interface Props {
  conversation: ApiConversation;
  isActive: boolean;
  conversationType: ConversationTypeEnum;
  onDeleteConversation: () => void;
  onClickConversation: (uuid: ApiConversation['uuid']) => void;
}

const ConversationListItem = ({
  conversation,
  isActive,
  conversationType,
  onDeleteConversation,
  onClickConversation,
}: Props) => {
  const [name, setName] = useState(conversation.name);
  const [isOpen, setIsOpen] = useState(false);

  const { refetch } = useConversations({ type: conversationType });
  const { deleteConversation, isLoading: isLoadingDeleteConversation } = useDeleteConversation({
    type: conversationType,
  });

  const handleDeleteConversation = async () => {
    trackEvent({
      action: 'click',
      category: 'discovery',
      label: 'deleteConversation',
    });
    await withToasts(deleteConversation({ uuid: conversation.uuid }))(
      conversationToasts.deleteConversation(),
    );

    if (isActive) {
      onDeleteConversation();
    }
  };

  useWebSocketSubscriber(`conversation_name_${conversation.uuid}`, (data) => {
    setName(data.name);
    refetch();
  });

  return (
    <div className='group relative flex items-center justify-between'>
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <Button
          onClick={() => onClickConversation(conversation.uuid)}
          className={cn(
            'flex-1 border-none pe-2 ps-4 group-hover:bg-fill-strong',
            (isOpen || isActive) && 'bg-fill-strong',
          )}
          asChild
        >
          <div className='justify-between'>
            <span
              className={cn(
                'overflow-hidden text-ellipsis whitespace-nowrap group-hover:text-clip',
                'before:absolute before:bottom-0 before:left-0 before:right-0 before:top-0',
                'before:bg-gradient-to-r before:from-transparent before:from-50% before:to-fill-maximal',
                (isOpen || isActive) && 'text-clip before:to-fill-strong',
                'before:group-hover:to-fill-strong',
              )}
            >
              {name || t('label.new')}
            </span>
            <DropdownMenuTrigger
              asChild
              className={cn(
                'opacity-0 group-hover:opacity-100 group-focus:opacity-100',
                (isOpen || isActive) && 'opacity-100',
              )}
            >
              <IconButton
                name='dots-horizontal'
                className={cn('h-auto w-auto rotate-90 bg-transparent transition-colors')}
                variant='unstyled'
              />
            </DropdownMenuTrigger>
          </div>
        </Button>
        <DropdownMenuContent align='end' className='-me-2 mt-2'>
          <DropdownMenuItem onClick={handleDeleteConversation}>
            {isLoadingDeleteConversation ? (
              <Spinner />
            ) : (
              <Icon name='trash-2' className='text-icon-weak' />
            )}
            {t('action.delete')}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default ConversationListItem;
