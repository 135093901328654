import { useTranslation } from 'react-i18next';

import { SidePaneContent, SidePaneHeader, SidePaneProvider } from '@legalfly/ui/sidePane';
import { Text } from '@legalfly/ui/text';
import { usePlaybook } from 'core/modules/playbooks';

import { usePlaybookItemActions, usePlaybookItemState } from './context/PlaybookItemProvider';
import { PlaybookItemSandbox } from './sandbox/PlaybookItemSandbox';
import { PlaybookDetailItems } from './PlaybookDetailItems';
import { PlaybookItemForm } from './PlaybookItemForm';

export function PlaybookDetail() {
  const { t } = useTranslation();

  const { selectedPlaybookItem, playbookUuid } = usePlaybookItemState();
  const { reset } = usePlaybookItemActions();
  const { playbook } = usePlaybook({ playbookUuid });

  const isOpen = Boolean(selectedPlaybookItem);

  return (
    <SidePaneProvider
      contentClassName='flex-1'
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          reset();
        }
      }}
    >
      <PlaybookDetailItems playbook={playbook} />
      <SidePaneContent className='h-content' containerClassName='flex flex-col' size='flex'>
        <SidePaneHeader className='mb-8'>
          <Text as='h3'>{t('playbooks.details.objective')}</Text>
        </SidePaneHeader>

        {selectedPlaybookItem && (
          <PlaybookItemForm
            key={selectedPlaybookItem?.uuid}
            playbookUuid={playbook.uuid}
            playbookItem={selectedPlaybookItem}
          />
        )}

        <div className='-mx-6 h-3 min-h-3 bg-surface-main' />

        <PlaybookItemSandbox />
      </SidePaneContent>
    </SidePaneProvider>
  );
}
