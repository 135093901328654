import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf, ParamsOf, QueryOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';

export const createPlaybooksApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const getPlaybooks = (query: QueryOf<'playbooks/get'> = {}) => {
    return client(api.playbooks.list, { method: 'GET', query });
  };

  const getPlaybook = ({ playbookUuid }: { playbookUuid: ApiPlaybook['uuid'] }) => {
    return client(api.playbooks.detail, { method: 'GET', params: { playbookUuid } });
  };

  const getSystemPlaybooks = ({ category }: ParamsOf<'playbooks/system/:category/get'>) => {
    return client(api.playbooks.system.list, { method: 'GET', params: { category } });
  };

  const createPlaybook = (body: BodyOf<'playbooks/post'>) => {
    return client(api.playbooks.create, { method: 'POST', body });
  };

  const deletePlaybook = ({ playbookUuid }: ParamsOf<'playbooks/:playbookUuid/delete'>) => {
    return client(api.playbooks.delete, { method: 'DELETE', params: { playbookUuid } });
  };

  const updatePlaybook = ({
    playbookUuid,
    body,
  }: ParamsOf<'playbooks/:playbookUuid/put'> & { body: BodyOf<'playbooks/:playbookUuid/put'> }) => {
    return client(api.playbooks.update, { method: 'PUT', params: { playbookUuid }, body });
  };

  const createPlaybookItem = ({
    playbookUuid,
    body,
  }: ParamsOf<'playbooks/:playbookUuid/items/post'> & {
    body: BodyOf<'playbooks/:playbookUuid/items/post'>;
  }) => {
    return client(api.playbooks.items.create, { method: 'POST', params: { playbookUuid }, body });
  };

  const updatePlaybookItem = ({
    playbookUuid,
    itemUuid,
    body,
  }: ParamsOf<'playbooks/:playbookUuid/items/:itemUuid/patch'> & {
    body: BodyOf<'playbooks/:playbookUuid/items/:itemUuid/patch'>;
  }) => {
    return client(api.playbooks.items.update, {
      method: 'PATCH',
      params: { playbookUuid, itemUuid },
      body,
    });
  };

  const deletePlaybookItem = ({
    playbookUuid,
    itemUuid,
  }: ParamsOf<'playbooks/:playbookUuid/items/:itemUuid/delete'>) => {
    return client(api.playbooks.items.delete, {
      method: 'DELETE',
      params: { playbookUuid, itemUuid },
    });
  };

  const movePlaybookItem = ({
    playbookUuid,
    itemUuid,
    body,
  }: ParamsOf<'playbooks/:playbookUuid/items/:itemUuid/move/put'> & {
    body: BodyOf<'playbooks/:playbookUuid/items/:itemUuid/move/put'>;
  }) => {
    return client(api.playbooks.items.move, {
      method: 'PUT',
      params: { playbookUuid, itemUuid },
      body,
    });
  };

  return {
    getPlaybooks,
    getPlaybook,
    getSystemPlaybooks,
    createPlaybook,
    deletePlaybook,
    updatePlaybook,
    createPlaybookItem,
    updatePlaybookItem,
    deletePlaybookItem,
    movePlaybookItem,
  };
};

export type PlaybooksApi = ReturnType<typeof createPlaybooksApi>;
export type ApiPlaybook = ResponseOf<'playbooks/get'>[number];
export type ApiPlaybookItem = ResponseOf<'playbooks/:playbookUuid/get'>['items'][number];
export type ApiPlaybookItemContextMultipleChoiceOption = NonNullable<
  ApiPlaybookItem['context']
>['multipleChoiceOptions'];
