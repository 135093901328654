import { useTranslation } from 'react-i18next';
import type { ParseKeys } from 'i18next';

import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { Icon, type IconName } from '@legalfly/ui/icon';
import { Spinner } from '@legalfly/ui/spinner';
import { Text } from '@legalfly/ui/text';
import { useCopilotContext } from 'components/conversations/copilot/CopilotProvider';
import { useCurrentUser } from 'core/modules/users';

const predefinedPrompts: { title: ParseKeys; prompt: ParseKeys; icon: IconName }[] = [
  {
    title: 'copilot.prompt.summarizeTerms.title',
    prompt: 'copilot.prompt.summarizeTerms.prompt',
    icon: 'file-2',
  },
  {
    title: 'copilot.prompt.identifyKeyParties.title',
    prompt: 'copilot.prompt.identifyKeyParties.prompt',
    icon: 'users-2',
  },
  {
    title: 'copilot.prompt.analyzeConflictingClauses.title',
    prompt: 'copilot.prompt.analyzeConflictingClauses.prompt',
    icon: 'arrows-triangle',
  },
  {
    title: 'copilot.prompt.analyzeAmbiguousLanguage.title',
    prompt: 'copilot.prompt.analyzeAmbiguousLanguage.prompt',
    icon: 'search',
  },
  {
    title: 'copilot.prompt.draftEmailSummary.title',
    prompt: 'copilot.prompt.draftEmailSummary.prompt',
    icon: 'mail-1',
  },
];

interface Props {
  onSendMessage: (message: string) => void;
}

export const ReviewDetailPrompts = ({ onSendMessage }: Props) => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const { conversation, isLoadingConversation, isConversationLoaded } = useCopilotContext();

  if (isLoadingConversation) {
    return <Spinner />;
  }

  if (isConversationLoaded && conversation?.messages.length) {
    return null;
  }

  return (
    <div className='mx-auto mt-10 w-[90%]'>
      <Text as='h3' variant='heading2' className='mb-5'>
        {t('discovery.emptyState.title', { name: currentUser.firstName })}
      </Text>
      <Text variant='bodyLarge' className='mb-4 text-content-body-strong'>
        {t('discovery.emptyState.promptsDescription')}
      </Text>
      <div className='flex flex-col gap-3'>
        {predefinedPrompts.map(({ title, prompt, icon }) => (
          <Button
            key={title}
            variant='soft'
            className='bg-transparent'
            renderLeft={<Icon name={icon} className='text-icon-strong' />}
            onClick={() => {
              trackEvent(
                {
                  action: 'click',
                  category: 'reviewDetail',
                  label: 'prompt',
                },
                { icon },
              );
              onSendMessage(t(prompt));
            }}
          >
            {t(title)}
          </Button>
        ))}
      </div>
    </div>
  );
};
