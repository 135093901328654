import type { ComponentProps } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ConversationTypeEnum } from '@legalfly/api/conversations';
import type { ApiClause, ApiDraft, ApiDraftClause } from '@legalfly/api/drafting';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { withToasts } from '@legalfly/ui/toast';
import { useWebSocketSubscriber } from '@legalfly/websockets';
import { Copilot } from 'components/conversations/copilot/Copilot';
import type CopilotMessage from 'components/conversations/copilot/CopilotMessage';
import {
  useAddConversationMessage,
  useCreateConversationMessage,
} from 'core/modules/conversations';
import { conversationToasts } from 'core/modules/conversations/toasts';
import {
  draftingToasts,
  useUpdateDraftClause,
  useUpdateDraftClauseCache,
} from 'core/modules/drafting';

interface DraftClauseMessage {
  draftClauseUuid: ApiClause['uuid'];
  message: string;
}

interface Props {
  draft: ApiDraft;
  nextClauseToDraft: ApiDraftClause | undefined;
}

export const DraftConversation = ({ draft, nextClauseToDraft }: Props) => {
  const { t } = useTranslation();

  const conversationUuid = draft.conversations[0]?.uuid;
  const { createConversationMessage } = useCreateConversationMessage({
    type: ConversationTypeEnum.DRAFTS,
  });
  const updateDraftClauseCache = useUpdateDraftClauseCache();
  const { updateClause } = useUpdateDraftClause();

  useWebSocketSubscriber(`draft_clause_${draft.uuid}`, (data) => {
    updateDraftClauseCache({
      draftUuid: draft.uuid,
      updatedClause: data.clause,
    });
  });

  const addConversationMessage = useAddConversationMessage({
    conversationType: ConversationTypeEnum.DRAFTS,
  });

  const renderMessageActions: ComponentProps<typeof CopilotMessage>['renderActions'] = (
    message,
  ) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(message.assistantMessage, 'text/html');

    const draftMessages: DraftClauseMessage[] = [];

    Array.from(doc.getElementsByTagName('article'))
      .filter((el) => el.classList.contains('draft'))
      .forEach((el) =>
        draftMessages.push({
          draftClauseUuid: el.id,
          message: el.innerHTML,
        }),
      );

    // When the draft is not ready, we don't want to show the apply changes button
    if (draftMessages.length === 0 || nextClauseToDraft !== undefined) {
      return null;
    }

    const handleUpdateClause = () => {
      trackEvent({
        action: 'click',
        category: 'drafts',
        label: 'applyChanges',
      });

      draftMessages.forEach((draftMessage) => {
        withToasts(
          updateClause({
            uuid: draft.uuid,
            clauseUuid: draftMessage.draftClauseUuid,
            body: {
              content: draftMessage.message,
            },
          }),
        )(draftingToasts.updateDraftClause());
      });
    };

    return (
      <Button size='sm' onClick={handleUpdateClause}>
        {t('drafting.drafts.copilot.apply')}
      </Button>
    );
  };

  useWebSocketSubscriber(`conversations_${conversationUuid}`, (data) => {
    addConversationMessage(conversationUuid, data.messageUuid, data.message);
  });

  const handleSendMessage = useCallback(
    async (msg: string) => {
      await withToasts(
        createConversationMessage({
          uuid: conversationUuid,
          question: msg,
          context: {
            clauseUuid: nextClauseToDraft?.uuid,
          },
        }),
      )(conversationToasts.createConversation());
    },
    [conversationUuid, createConversationMessage, nextClauseToDraft],
  );

  return (
    <Copilot
      conversationHasMessages
      onSendMessage={handleSendMessage}
      conversationType={ConversationTypeEnum.DRAFTS}
      conversationUuid={conversationUuid}
    >
      <Copilot.ScrollContainer stickyClassName='hidden'>
        <Copilot.Messages
          renderActions={renderMessageActions}
          analyticsMeta={{ category: 'drafts' }}
        />
      </Copilot.ScrollContainer>
      <Copilot.Footer className='mt-auto w-full pt-4'>
        <Copilot.StopResponding />
        <Copilot.Input className='mb-0' />
      </Copilot.Footer>
    </Copilot>
  );
};
