import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_auth')({
  beforeLoad: async ({ context, location }) => {
    const authErrorPath = '/auth/error';
    if (context.auth?.error && location.pathname !== authErrorPath) {
      throw redirect({
        to: authErrorPath,
      });
    }

    if (!context.auth?.isAuthenticated) {
      await context.auth?.loginWithRedirect({
        // the default OnRedirectCallback will redirect to the returnTo location
        appState: { returnTo: location.pathname + location.searchStr },
      });
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject();
    }
  },
});
