import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  ApiConversation,
  ApiConversationGrouped,
  ConversationTypeEnum,
} from '@legalfly/api/conversations';
import type { ApiDocument } from '@legalfly/api/documents';
import { Button } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';
import { getKeys } from '@legalfly/utils/object';
import {
  DocumentsUploadProgress,
  useDocumentsUploadProgress,
} from 'components/documents/DocumentsUploadProgress';
import { ListenToConversationDocumentsStatus } from 'core/modules/conversations';

import ConversationListItem from './ConversationListItem';

interface Props
  extends Pick<
    ComponentProps<typeof ConversationListItem>,
    'onDeleteConversation' | 'onClickConversation'
  > {
  uuid: ApiConversation['uuid'] | undefined;
  conversationType: ConversationTypeEnum;
  documents: ApiDocument[];
  numberOfFilesToUpload: number;
  conversations: ApiConversationGrouped;
  onCreateConversation: () => void;
}

export function ConversationList({
  uuid,
  conversationType,
  documents,
  numberOfFilesToUpload,
  conversations,
  onCreateConversation,
  onDeleteConversation,
  onClickConversation,
}: Props) {
  const { t } = useTranslation();

  const documentUploadProgress = useDocumentsUploadProgress(documents, numberOfFilesToUpload);

  return (
    <div className='flex flex-col gap-3'>
      <div
        className={cn(
          'flex w-[260px] min-w-[260px] flex-col overflow-auto border border-stroke-weaker bg-fill-maximal px-5 pb-6',
          !documentUploadProgress.isUploading ? 'h-content' : 'h-content-with-progress',
        )}
      >
        <div className='sticky top-0 z-10 bg-fill-maximal pb-4 pt-6'>
          <Button
            renderLeft={<Icon name='plus' />}
            asChild
            className='w-full'
            variant='soft'
            onClick={onCreateConversation}
          >
            <Text>{t('discovery.actions.newConversation')}</Text>
          </Button>
        </div>
        <div className='flex flex-grow flex-col gap-4'>
          {getKeys(conversations).map((group) => (
            <div key={group} className='flex flex-col'>
              <Text className='my-2 px-4 text-content-body-weak'>{t(group)}</Text>
              {conversations[group]?.map((conversation) => (
                <ConversationListItem
                  key={conversation.uuid}
                  isActive={uuid === conversation.uuid}
                  conversation={conversation}
                  conversationType={conversationType}
                  onDeleteConversation={onDeleteConversation}
                  onClickConversation={onClickConversation}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
      {documents.length > 0 && uuid && (
        <ListenToConversationDocumentsStatus
          conversationUuid={uuid}
          documents={documents}
          conversationType={conversationType}
        />
      )}
      <DocumentsUploadProgress {...documentUploadProgress} />
    </div>
  );
}
