import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTextToParagraphedText } from 'common/hooks/useTextToParagraphedText';

import type { ApiDocumentTypeFile } from '@legalfly/api/documents';
import { PlaybookItemTypeEnum } from '@legalfly/api/playbooks';
import { type ApiReviewItem, ReviewPlaybookItemStatus } from '@legalfly/api/reviews';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Icon } from '@legalfly/ui/icon';
import { Sheet, SheetTrigger } from '@legalfly/ui/sheet';
import { Spinner } from '@legalfly/ui/spinner';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';
import { DocumentSnippetsViewerSheetContent } from 'components/documents/DocumentSnippetsViewerSheetContent';
import { PlaybookItemMultipleChoice } from 'components/playbooks/item/PlaybookItemMultipleChoice';
import { PlaybookItemYesNo } from 'components/playbooks/item/PlaybookItemYesNo';
import { ReviewRiskGlow } from 'components/review/detail/ReviewRiskGlow';

export const MultiReviewItem = ({
  item,
  document,
  shortFormat,
  className,
}: {
  item: ApiReviewItem;
  document: ApiDocumentTypeFile;
  shortFormat?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation();
  const [activeSnippetTextId, setActiveSnippetTextId] = useState<string>();

  const formattedParagraphs = useTextToParagraphedText({
    text: item?.answer.text,
  });

  const htmlText = formattedParagraphs.map((paragraph) => `<p>${paragraph}</p>`).join('');

  const snippetIds = item?.answer.snippetIds ?? [];

  if (
    item?.status === ReviewPlaybookItemStatus.IN_PROGRESS ||
    item?.status === ReviewPlaybookItemStatus.CREATED
  ) {
    return (
      <div className='flex items-center justify-center border-b border-r border-stroke-weaker px-4 py-3'>
        <Spinner />
      </div>
    );
  }

  if (item?.status === ReviewPlaybookItemStatus.FAILED) {
    return (
      <div className='flex items-center justify-center border-b border-r border-stroke-weaker px-4 py-3'>
        <Text>{t('multiReview.failedToProcess')}</Text>
      </div>
    );
  }

  return (
    <Sheet>
      <SheetTrigger asChild>
        <div
          className={cn(
            'group relative cursor-pointer overflow-hidden border-b border-r border-stroke-weaker hover:bg-fill-strongest',
            className,
          )}
        >
          <ReviewRiskGlow
            item={item}
            highlight
            className='absolute right-6 top-6 h-0 min-h-0 w-0 min-w-0'
          />

          <div
            className={cn(
              'prose content-start overflow-hidden break-words px-4 pb-8 pt-3 dark:prose-invert focus-visible:outline focus-visible:outline-1 focus-visible:-outline-offset-8 focus-visible:outline-stroke-weak',
              shortFormat && '[&>:not(:first-child)]:hidden [&>p]:line-clamp-5',
            )}
            tabIndex={0}
            role='button'
            onClick={() => {
              trackEvent({
                action: 'click',
                category: 'multiReview',
                label: 'item',
              });
              setActiveSnippetTextId(snippetIds[0]);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.currentTarget.click();
              }
            }}
          >
            {item.type === PlaybookItemTypeEnum.YES_NO ? (
              <PlaybookItemYesNo item={item} />
            ) : item.type === PlaybookItemTypeEnum.MULTIPLE_CHOICE ? (
              <PlaybookItemMultipleChoice item={item} />
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: shortFormat
                    ? formattedParagraphs[0]
                    : (htmlText ?? t('multiReview.failedToProcess')),
                }}
              />
            )}
          </div>

          <Icon
            name='arrow-narrow-right'
            className='invisible absolute bottom-2 right-2 text-icon-strong group-hover:visible'
          />
        </div>
      </SheetTrigger>
      <DocumentSnippetsViewerSheetContent
        document={document}
        snippetIds={snippetIds}
        activeSnippetTextId={activeSnippetTextId}
        question={item?.text}
        answer={item?.answer.text}
        setActiveSnippetTextId={(id) => {
          trackEvent({
            action: 'click',
            category: 'multiReview',
            label: 'highlight',
          });
          setActiveSnippetTextId(id);
        }}
      />
    </Sheet>
  );
};
