import { cn } from 'utils';

import { Spinner } from '../spinner';
import { Text } from '../text';

interface Props {
  showSpinner?: boolean;
}

export const LegalFlyLoader = ({ showSpinner = true }: Props) => {
  return (
    <div className='flex h-full w-full flex-col items-center justify-center'>
      <Text variant='title' as='h1' className='mb-8'>
        LEGALFLY
      </Text>
      <Spinner className={cn('opacity-0', showSpinner && 'opacity-100')} />
    </div>
  );
};
