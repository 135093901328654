import type { ComponentProps } from 'react';

import { Button } from '@legalfly/ui/button';
import { cn } from '@legalfly/ui/utils';

interface Props extends ComponentProps<typeof Button> {
  label: string;
  onStopResponding: VoidFunction;
  parentClassName?: string;
}

export const BaseCopilotStopRespondingButton = ({
  parentClassName,
  label,
  onStopResponding,
  ...props
}: Props) => {
  return (
    <div
      className={cn(
        'absolute -top-40 left-0 flex h-40 w-full flex-col items-center justify-end bg-gradient-to-t from-fill-maximal from-10% to-transparent to-100% pb-2',
        parentClassName,
      )}
    >
      <Button onClick={onStopResponding} {...props}>
        {label}
      </Button>
    </div>
  );
};

BaseCopilotStopRespondingButton.displayName = 'BaseCopilotStopRespondingButton';
