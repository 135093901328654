/* eslint-disable jsx-a11y/label-has-associated-control */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ColumnDef } from '@tanstack/react-table';

import { type ApiDocument, DocumentStatus, DocumentType } from '@legalfly/api/documents';
import { trackEvent } from '@legalfly/reporting/tracking';
import { UserAvatar } from '@legalfly/ui/avatar';
import { Button, IconButton } from '@legalfly/ui/button';
import { Checkbox } from '@legalfly/ui/checkbox';
import { ContextMenu, ContextMenuTrigger } from '@legalfly/ui/contextMenu';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';
import { formatLongDate, formatRelativeTime } from '@legalfly/utils/date';
import { useRetryFile } from 'core/modules/documents';
import { getDocumentIcon, getDocumentName } from 'core/modules/documents/helpers';
import { useFavorite } from 'core/modules/favorites';

import DocumentsContextMenu from '../contextMenu/DocumentsContextMenu';
import DocumentListActionPopover from '../DocumentListActionPopover';

interface Props {
  onDocumentClick: (document: ApiDocument) => void;
  onDocumentDoubleClick?: (document: ApiDocument) => void;
  allowMultiple: boolean;
  allowSelection: boolean;
  allowDelete: boolean;
  allowContextMenu: boolean;
}

export const useDocumentPickerColumns = ({
  onDocumentClick,
  onDocumentDoubleClick,
  allowMultiple,
  allowSelection,
  allowContextMenu,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { retryFile } = useRetryFile();
  const { favoriteDocument } = useFavorite();

  const columns = useMemo(
    () => {
      const columnDefs: ColumnDef<ApiDocument>[] = [
        {
          id: 'select',
          size: 50,
          header: ({ table }) => {
            if (!allowMultiple) {
              return null;
            }

            return (
              <label className='cursor-pointer p-4'>
                <Checkbox
                  checked={
                    table.getIsAllPageRowsSelected() ||
                    (table.getIsSomePageRowsSelected() && 'indeterminate')
                  }
                  onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
                  aria-label={t('documents.documentPicker.selectAll')}
                />
              </label>
            );
          },
          cell: ({ row }) => {
            const disabled = !row.getCanSelect();
            return (
              <label className={cn('p-4', disabled ? 'pointer-events-none' : 'cursor-pointer')}>
                <Checkbox
                  checked={row.getIsSelected()}
                  onCheckedChange={(value) => row.toggleSelected(!!value)}
                  aria-label={t('documents.documentPicker.selectRow')}
                  disabled={disabled}
                />
              </label>
            );
          },
        },
        {
          id: 'name',
          header: ({ column }) => {
            return (
              <Button
                variant='unstyled'
                className='flex items-center gap-2'
                onClick={() => {
                  column.toggleSorting(column.getIsSorted() === 'asc');
                  trackEvent(
                    {
                      action: 'click',
                      category: 'documents',
                      label: 'sort',
                    },
                    { direction: column.getIsSorted(), column: column.id },
                  );
                }}
              >
                {t('documents.documentPicker.name')}
                <Icon
                  name='arrow-up'
                  className={cn(column.getIsSorted() === 'asc' && 'rotate-180')}
                />
              </Button>
            );
          },
          accessorKey: 'name',
          cell: ({ row, table }) => {
            const document = row.original;
            const selectedDocuments = table.getSelectedRowModel().rows.map((r) => r.original);
            const canSelect = row.getCanSelect();
            const disabled = !allowContextMenu || !canSelect;

            return (
              <ContextMenu>
                <ContextMenuTrigger asChild disabled={disabled}>
                  <div
                    className='flex items-center gap-2 px-5 py-5'
                    onContextMenu={() => {
                      if (disabled) {
                        return;
                      }
                      // when right clicking on a document that is not selected
                      // deselect all other documents and then select the document
                      if (!selectedDocuments.some((d) => d.uuid === document.uuid)) {
                        table.toggleAllRowsSelected(false);
                        setTimeout(() => {
                          row.toggleSelected(true);
                        }, 0);
                      }
                    }}
                    onClick={(e) => {
                      switch (e.detail) {
                        case 0:
                        case 1:
                          onDocumentClick(document);
                          break;
                        case 2:
                          onDocumentDoubleClick?.(document);
                          break;
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.currentTarget.click();
                      }
                    }}
                    role='row'
                    tabIndex={0}
                  >
                    <Icon name={getDocumentIcon(document)} className='min-h-5 min-w-5' />
                    <Text className='line-clamp-2' as='span'>
                      {getDocumentName(document)}
                      {document.favorited && (
                        <Icon
                          name='star'
                          size='xs'
                          className='ms-2 fill-icon-strongest stroke-icon-strongest'
                        />
                      )}
                    </Text>
                    {document.type === DocumentType.FILE &&
                      document.status === DocumentStatus.ERROR && (
                        <>
                          <Button
                            variant='soft'
                            className='ml-2 px-2'
                            onClick={() => retryFile({ uuid: document.uuid })}
                            renderLeft={<Icon name='refresh-ccw-5' />}
                          >
                            <Text variant='caption'>{t('documents.upload.retry')}</Text>
                          </Button>
                          <Icon name='x-close' className='ml-2 text-risk-fill-high' />
                          <Text className='text-risk-fill-high'>
                            {t('documents.upload.failed')}
                          </Text>
                        </>
                      )}
                  </div>
                </ContextMenuTrigger>
                <DocumentsContextMenu document={document} selectedDocuments={selectedDocuments} />
              </ContextMenu>
            );
          },
        },
        {
          id: 'quick-actions',
          size: 50,
          cell: ({ row }) => {
            const document = row.original;

            return (
              <div className='flex items-center justify-end gap-2 px-5 opacity-0 group-hover:opacity-100'>
                <IconButton
                  variant='unstyled'
                  onClick={() => favoriteDocument(document)}
                  name='star'
                  size='md'
                  className={cn(
                    document.favorited
                      ? 'fill-icon-strongest'
                      : 'fill-transparent stroke-icon-strongest',
                  )}
                />
              </div>
            );
          },
        },
        {
          id: 'actions',
          size: 250,
          cell: ({ row }) => {
            const document = row.original;

            return (
              <div className='flex items-center justify-end gap-2 px-5'>
                <Text
                  className='text-content-body-weak'
                  title={formatLongDate(document.updatedAt, i18n.language)}
                >
                  {formatRelativeTime(document.updatedAt, i18n.language)}
                </Text>
                {document.owner && <UserAvatar size='sm' {...document.owner} />}
                <DocumentListActionPopover document={document} />
              </div>
            );
          },
        },
      ];

      if (!allowSelection) {
        return columnDefs.filter((column) => column.id !== 'select');
      }

      return columnDefs;
    },
    // all the dependencies need to be stable
    // if you change or add any of these, test that the context menu still works
    [
      allowSelection,
      allowMultiple,
      allowContextMenu,
      t,
      onDocumentClick,
      onDocumentDoubleClick,
      favoriteDocument,
      i18n.language,
      retryFile,
    ],
  );

  return columns;
};
