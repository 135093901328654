import type { HTMLAttributes } from 'react';

import { cn } from '@legalfly/ui/utils';

import { HeaderCell } from './HeaderCell';

export const DocumentNameCell = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <HeaderCell
      className={cn(
        'left-0 top-auto z-10 content-center break-all border-b text-body-semibold text-content-body-strong',
        className,
      )}
      {...props}
    />
  );
};
