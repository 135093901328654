import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import type { ApiMultiReview } from '@legalfly/api/multi-reviews';
import { type ApiReviewItem, ReviewStatus } from '@legalfly/api/reviews';
import { createMultiReviewsModule } from '@legalfly/modules/multi-reviews';
import type { WebSocketCallback } from '@legalfly/websockets';
import { useWebSocketSubscriber } from '@legalfly/websockets';

import { multiReviewsApi } from '../../di';

export const useListenToMultiReviewStatus = ({ uuid }: { uuid: ApiMultiReview['uuid'] }) => {
  const queryClient = useQueryClient();

  const listener: WebSocketCallback<`multi_review_status_${string}`> = useCallback(
    (data) => {
      if (data.status === ReviewStatus.COMPLETED) {
        queryClient.invalidateQueries({
          queryKey: multiReviewsQueryOptions.multiReview({ uuid }).queryKey,
        });
      }
    },
    [uuid, queryClient],
  );

  useWebSocketSubscriber(`multi_review_status_${uuid}`, listener);
};

export const useListenToMultiReviewItem = ({
  multiReviewUuid,
  itemUuid,
}: {
  multiReviewUuid: ApiMultiReview['uuid'];
  itemUuid: ApiReviewItem['uuid'];
}) => {
  const queryClient = useQueryClient();

  const listener: WebSocketCallback<`review_item_${string}`> = useCallback(
    (data) => {
      queryClient.setQueryData(
        multiReviewsQueryOptions.multiReview({ uuid: multiReviewUuid }).queryKey,
        (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            reviews: oldData.reviews.map((review) => ({
              ...review,
              items: review.items.map((item) => (item.uuid === data.uuid ? data : item)),
            })),
          };
        },
      );
    },
    [multiReviewUuid, queryClient],
  );

  useWebSocketSubscriber(`review_item_${itemUuid}`, listener);
};

export const {
  useMultiReviews,
  useMultiReview,
  useCreateMultiReview,
  useDeleteMultiReview,
  useUpdateMultiReview,
  multiReviewsQueryOptions,
} = createMultiReviewsModule({
  multiReviewsApi,
});

export * from './toasts';
