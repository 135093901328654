import type { ComponentProps, HTMLAttributes } from 'react';

import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';

const InsightsCard = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex w-full flex-col border border-stroke-weaker bg-fill-strongest px-4 py-3',
      className,
    )}
    {...props}
  />
);

InsightsCard.displayName = 'InsightsCard';

const InsightsCardHeader = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex justify-between', className)} {...props} />
);

InsightsCardHeader.displayName = 'InsightsCardHeader';

const InsightsCardTitle = ({ className, ...props }: ComponentProps<typeof Text>) => (
  <Text variant='body' className={cn('flex', className)} {...props} />
);
InsightsCardTitle.displayName = 'InsightsCardTitle';

const InsightsCardIcon = ({ className, ...props }: ComponentProps<typeof Icon>) => (
  <Icon size='sm' className={cn('flex', className)} {...props} />
);
InsightsCardIcon.displayName = 'InsightsCardIcon';

const InsightsCardFooter = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex items-end justify-between pt-4', className)} {...props} />
);
InsightsCardFooter.displayName = 'InsightsCardFooter';

interface InsightsCardListProps {
  items: {
    title: string;
    count: number;
  }[];
}

const InsightsCardList = ({ items }: InsightsCardListProps) => (
  <>
    {items.map(({ count, title }) => (
      <div
        key={title}
        className='flex w-full flex-row justify-between border-t border-stroke-weaker p-2'
      >
        <Text variant='bodyLight' className='text-content-body-strong'>
          {title}
        </Text>
        <Text variant='bodyLight' className='text-content-body-strong'>
          {count}
        </Text>
      </div>
    ))}
  </>
);
InsightsCardList.displayName = 'InsightsCardList';

export {
  InsightsCard,
  InsightsCardList,
  InsightsCardHeader,
  InsightsCardFooter,
  InsightsCardIcon,
  InsightsCardTitle,
};
