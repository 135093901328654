export const base = {
  white: {
    100: '#FFFFFF',
    90: 'rgba(255, 255, 255, 0.9)',
    80: 'rgba(255, 255, 255, 0.8)',
    60: 'rgba(255, 255, 255, 0.6)',
    40: 'rgba(255, 255, 255, 0.4)',
    20: 'rgba(255, 255, 255, 0.2)',
    10: 'rgba(255, 255, 255, 0.1)',
    5: 'rgba(255, 255, 255, 0.05)',
  },
  slate: {
    900: '#0A0A0C',
    800: '#111316',
    700: '#1B1C21',
    650: '#23252C',
    600: '#2B3036',
    550: '#56585F',
    450: '#818287',
    400: '#ABABB0',
    350: '#ABABB0',
    300: '#D5D6D7',
    250: '#DADBDC',
    200: '#DFE0E1',
    150: '#E4E5E6',
    100: '#EAEAEB',
    50: '#EFEFF0',
    30: '#F4F5F5',
    20: '#FAFAFA',
    0: '#FFFFFF',
  },
  navy: {
    60: '#233743',
    40: '#506B6A',
    20: '#D5D6D7',
  },
  lemon: {
    100: '#EFF626',
  },
  blue: {
    100: '#95A4FC',
  },
  peach: {
    100: '#FCB87E',
    80: '#FDE7B0',
    60: '#FEF1D2',
  },
  green: {
    100: '#16A34A',
    80: '#22C55E',
    60: '#4ADE80',
    40: '#86EFAC',
    20: '#BBF7D0',
    10: '#DCFCE7',
  },
  orange: {
    100: '#D97706',
    80: '#F59E0B',
    60: '#FBBF24',
    40: '#FCD34D',
    20: '#FDE68A',
    10: '#FEF3C7',
  },
  red: {
    100: '#DC2626',
    80: '#EF4444',
    60: '#F87171',
    40: '#FCA5A5',
    20: '#FECACA',
    10: '#FEE2E2',
  },
} as const;
