import { useTranslation } from 'react-i18next';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useKeywordFilter } from 'common/hooks/useKeywordFilter';

import type { ApiTemplate } from '@legalfly/api/drafting';
import { trackEvent } from '@legalfly/reporting/tracking';
import { SearchInputForm } from '@legalfly/ui/form';
import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { CreateDraftingTemplateDialog } from 'components/drafting/templates/CreateDraftingTemplateDialog';
import { TemplatesList } from 'components/drafting/templates/TemplatesList';
import { draftingQueryOptions, useTemplates } from 'core/modules/drafting';

import { DraftingTabs } from '../-tabs/DraftingTabs';

export const Route = createFileRoute('/_auth/_layout/drafting/templates/')({
  component: DraftingTemplatesRoute,
  loader({ context }) {
    return context.queryClient.ensureQueryData(draftingQueryOptions.templates());
  },
});

function DraftingTemplatesRoute() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { templates } = useTemplates();
  const { filteredItems: filteredTemplates, setKeyword } = useKeywordFilter(templates, 'title');

  const handleSelectTemplate = (template: ApiTemplate) => {
    navigate({
      to: '/drafting/templates/$uuid',
      params: { uuid: template.uuid },
    });
  };

  return (
    <div className='flex h-full flex-col gap-3'>
      <DraftingTabs />
      <Content>
        <ContentHeader>
          <ContentHeader.Title>{t('drafting.templates.title')}</ContentHeader.Title>
          <ContentHeader.SubTitle>{t('drafting.templates.subtitle')}</ContentHeader.SubTitle>
          <ContentHeader.Actions>
            <SearchInputForm
              placeholder={t('label.search')}
              onKeywordChange={setKeyword}
              onBlur={() =>
                trackEvent({
                  action: 'blur',
                  category: 'templates',
                  label: 'search',
                })
              }
            />
            <CreateDraftingTemplateDialog />
          </ContentHeader.Actions>
        </ContentHeader>
        <TemplatesList
          hasAvailableTemplates={templates.length > 0}
          filteredTemplates={filteredTemplates}
          onSelectTemplate={handleSelectTemplate}
        />
      </Content>
    </div>
  );
}
