import type { ReactNode } from 'react';
import { Outlet } from '@tanstack/react-router';

import { LayoutHeader } from './LayoutHeader';
import { LayoutNavigation } from './LayoutNavigation';

interface Props {
  isPending?: boolean;
  children?: ReactNode;
}

export const Layout = ({ isPending = false, children }: Props) => {
  return (
    <div className='flex h-dvh flex-col p-body'>
      <LayoutHeader isPending={isPending} />
      <main className='flex flex-1 pt-body'>
        {!isPending && <LayoutNavigation />}
        <section className='flex-grow ps-body'>{children || <Outlet />}</section>
      </main>
    </div>
  );
};
