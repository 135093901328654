import type { BodyOf, ParamsOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';
import type { ConversationTypeEnum } from './types';

export const createBaseConversationsApi = (fetcher: Fetcher, type: ConversationTypeEnum) => {
  const client = createClient(fetcher);

  return {
    getConversations: (): Promise<ResponseOf<`${ConversationTypeEnum.REVIEW}/conversations/get`>> =>
      // @ts-expect-error Don't infer the type, it will generate compiler issues
      client(`/${type}/conversations` as string, { method: 'GET' }),

    getConversation: ({
      uuid,
    }: ParamsOf<`${ConversationTypeEnum.REVIEW}/conversations/:uuid/get`>): Promise<
      ResponseOf<`${ConversationTypeEnum.REVIEW}/conversations/:uuid/get`>
    > =>
      // @ts-expect-error Don't infer the type, it will generate compiler issues
      client(`/${type}/conversations/:uuid` as string, { method: 'GET', params: { uuid } }),

    createConversation: (
      body: BodyOf<`${ConversationTypeEnum.REVIEW}/conversations/post`>,
    ): Promise<ResponseOf<`${ConversationTypeEnum.REVIEW}/conversations/post`>> =>
      // @ts-expect-error Don't infer the type, it will generate compiler issues
      client(`/${type}/conversations` as string, { method: 'POST', body }),

    createConversationMessage: (
      body: BodyOf<`${ConversationTypeEnum.REVIEW}/conversations/message/post`>,
    ): Promise<ResponseOf<`${ConversationTypeEnum.REVIEW}/conversations/message/post`>> =>
      // @ts-expect-error Don't infer the type, it will generate compiler issues
      client(`/${type}/conversations/message` as string, { method: 'POST', body }),

    updateConversation: ({
      uuid,
      body,
    }: ParamsOf<`${ConversationTypeEnum.REVIEW}/conversations/:uuid/patch`> & {
      body: BodyOf<`${ConversationTypeEnum.REVIEW}/conversations/:uuid/patch`>;
    }): Promise<ResponseOf<`${ConversationTypeEnum.REVIEW}/conversations/:uuid/patch`>> =>
      // @ts-expect-error Don't infer the type, it will generate compiler issues
      client(`/${type}/conversations/:uuid` as string, { method: 'PATCH', params: { uuid }, body }),

    deleteConversation: ({
      uuid,
    }: ParamsOf<`${ConversationTypeEnum.REVIEW}/conversations/:uuid/delete`>): Promise<
      ResponseOf<`${ConversationTypeEnum.REVIEW}/conversations/:uuid/delete`>
    > =>
      // @ts-expect-error Don't infer the type, it will generate compiler issues
      client(`/${type}/conversations/:uuid` as string, { method: 'DELETE', params: { uuid } }),

    clearConversation: ({
      uuid,
    }: ParamsOf<`${ConversationTypeEnum.REVIEW}/conversations/:uuid/clear/post`>): Promise<
      ResponseOf<`${ConversationTypeEnum.REVIEW}/conversations/:uuid/clear/post`>
    > =>
      // @ts-expect-error Don't infer the type, it will generate compiler issues
      client(`/${type}/conversations/:uuid/clear` as string, { method: 'POST', params: { uuid } }),

    stopConversationMessage: ({
      uuid,
      messageUuid,
    }: ParamsOf<`${ConversationTypeEnum.REVIEW}/conversations/:uuid/message/:messageUuid/stop/post`>): Promise<
      ResponseOf<`${ConversationTypeEnum.REVIEW}/conversations/:uuid/message/:messageUuid/stop/post`>
    > =>
      // @ts-expect-error Don't infer the type, it will generate compiler issues
      client(`/${type}/conversations/:uuid/message/:messageUuid/stop` as string, {
        method: 'POST',
        params: { uuid, messageUuid },
      }),
  };
};
