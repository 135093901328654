import { Button } from '@legalfly/ui/button';

import { useCopilotContext } from '../copilot/CopilotProvider';

interface Props {
  choices: HTMLCollection;
}

export const ConversationMessageMultipleChoice = ({ choices }: Props) => {
  const { onSendMessage } = useCopilotContext();

  return (
    <div className='flex flex-row flex-wrap gap-2'>
      {Array.from(choices).map((item) => {
        const choice = item.innerHTML;
        return (
          <Button size='sm' key={choice} onClick={() => onSendMessage(choice)}>
            {choice}
          </Button>
        );
      })}
    </div>
  );
};
