import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from '@tanstack/react-router';

import { Button } from '@legalfly/ui/button';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@legalfly/ui/hoverCard';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { NavigationMenu } from 'components/common/navigationMenu';
import { useFeatureFlag } from 'core/analytics';
import { useAgents } from 'core/modules/agents';

import { getIconClasses, getItemClasses, getTextClasses } from '../helpers';

interface Props {
  isExpanded: boolean;
}

export const CustomAgentsNavigationItem = ({ isExpanded }: Props) => {
  const { t } = useTranslation();
  const { pathname: currentPath } = useLocation();
  const [cardOpen, setCardOpen] = useState(false);

  const { agents } = useAgents();
  const isCustomAgentsEnabled = useFeatureFlag('custom-agents');

  const iconClasses = getIconClasses(cardOpen);
  const textClasses = getTextClasses(cardOpen, isExpanded);

  const items = (
    <NavigationMenu.Content className='mb-2'>
      {isCustomAgentsEnabled && (
        <NavigationMenu.Item
          asChild
          isActive={currentPath.startsWith('/agents/manage')}
          className={getItemClasses(currentPath.startsWith('/agents/manage'), cardOpen, isExpanded)}
        >
          <Link to='/agents/manage'>
            <Icon name='menu-5' className={iconClasses} />
            <Text className={textClasses}>{t('nav.overview')}</Text>
          </Link>
        </NavigationMenu.Item>
      )}
      {agents
        .filter((agent) => agent.favorited)
        .map((agent) => (
          <NavigationMenu.Item
            key={agent.uuid}
            asChild
            isActive={currentPath.startsWith(`/agents/${agent.uuid}`)}
            className={getItemClasses(
              currentPath.startsWith(`/agents/${agent.uuid}`),
              cardOpen,
              isExpanded,
            )}
          >
            <Link to={`/agents/${agent.uuid}`}>
              <Icon name={agent.icon} className={iconClasses} />
              <Text className={textClasses}>{agent.name}</Text>
            </Link>
          </NavigationMenu.Item>
        ))}
    </NavigationMenu.Content>
  );

  if (isExpanded) {
    return items;
  }

  return (
    <HoverCard openDelay={100} open={cardOpen} onOpenChange={setCardOpen}>
      <HoverCardTrigger asChild>
        <NavigationMenu.Item
          asChild
          isActive={agents.some((agent) => currentPath.startsWith(`/agents/${agent.uuid}`))}
        >
          <Button variant='unstyled'>
            <Icon name='placeholder' className='size-5' />
          </Button>
        </NavigationMenu.Item>
      </HoverCardTrigger>
      <HoverCardContent className='w-full' align='start'>
        {items}
      </HoverCardContent>
    </HoverCard>
  );
};
