import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const ContentSidebar = ({ children }: Props) => {
  return (
    <div className='relative hidden h-content max-w-side-pane-sm flex-1 overflow-auto bg-fill-maximal p-6 lg:block'>
      {children}
    </div>
  );
};
