import { type ComponentProps, type ReactNode } from 'react';

import anonymisationIcon from '@legalfly/ui/assets/anonymisation.webp';
import discoveryIcon from '@legalfly/ui/assets/discovery.webp';
import documentsIcon from '@legalfly/ui/assets/documents.webp';
import draftingIcon from '@legalfly/ui/assets/drafting.webp';
import multireviewIcon from '@legalfly/ui/assets/multireview.webp';
import reviewIcon from '@legalfly/ui/assets/review.webp';
import { Button } from '@legalfly/ui/button';
import type { IconName } from '@legalfly/ui/icon';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';

const Card = ({ children }: { children: ReactNode }) => {
  return (
    <div className='flex flex-col items-start border border-stroke-frail bg-surface-nav p-6'>
      {children}
    </div>
  );
};

const CardTitle = (props: ComponentProps<typeof Text>) => {
  return <Text variant='subheading' className='mb-2' {...props} />;
};

const CardSubTitle = (props: ComponentProps<typeof Text>) => {
  return <Text className='mb-4 text-content-body-strong' {...props} />;
};

const CardAction = (props: ComponentProps<typeof Button>) => {
  return <Button variant='soft' {...props} />;
};

const imageMapper = {
  review: reviewIcon,
  'multi-review': multireviewIcon,
  anonymisation: anonymisationIcon,
  discovery: discoveryIcon,
  drafting: draftingIcon,
  documents: documentsIcon,
};

Card.Title = CardTitle;
Card.SubTitle = CardSubTitle;
Card.Action = CardAction;

type DashboardCardImageType =
  | 'review'
  | 'multi-review'
  | 'anonymisation'
  | 'discovery'
  | 'drafting'
  | 'documents';

interface Props {
  title: string;
  subtitle: string;
  iconName: IconName;
  actionText: string;
  onActionClick: () => void;
  imageType: DashboardCardImageType;
}

export const DashboardCard = ({
  title,
  subtitle,
  iconName,
  actionText,
  onActionClick,
  imageType,
}: Props) => {
  const image = imageMapper[imageType];

  return (
    <Card>
      <img src={image} className='-m-1 mb-4 mt-2 max-w-[55px]' alt='agent' loading='lazy' />
      <Card.Title>{title}</Card.Title>
      <Card.SubTitle>{subtitle}</Card.SubTitle>
      <Card.Action onClick={onActionClick}>
        <Icon name={iconName} />
        {actionText}
      </Card.Action>
    </Card>
  );
};
