import { useSuspenseQuery } from '@tanstack/react-query';

import type { ApiConfig, ConfigApi } from '@legalfly/api/config';

import { configQueryOptions } from './configQueryOptions';

export const createConfigModule = ({ configApi }: { configApi: ConfigApi }) => {
  const queryOptions = configQueryOptions({ configApi });

  const useConfig = <TData = ApiConfig>({
    select,
  }: { select?: (data: ApiConfig) => TData } = {}) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.config({ select }));

    return {
      data,
      isLoading,
      error,
    };
  };

  return {
    useConfig,
    configQueryOptions: queryOptions,
  };
};
