import { legalFlyConfig } from '@legalfly/config';

import type { ParamsOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';

export const createNotificationsApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const getNotifications = () => {
    return client('/notifications', { method: 'GET' });
  };

  const markNotificationAsSeen = (
    uuid: ParamsOf<'notifications/:uuid/mark-as-seen/post'>['uuid'],
  ) => {
    return client(api.notifications.markAsSeen, { method: 'POST', params: { uuid } });
  };

  const markAllNotificationsAsSeen = () => {
    return client(api.notifications.markAllAsSeen, { method: 'POST' });
  };

  const deleteNotification = (uuid: ParamsOf<'notifications/:uuid/delete'>['uuid']) => {
    return client(api.notifications.delete, { method: 'DELETE', params: { uuid } });
  };

  const deleteAllNotifications = () => {
    return client(api.notifications.deleteAll, { method: 'DELETE' });
  };

  return {
    getNotifications,
    markNotificationAsSeen,
    markAllNotificationsAsSeen,
    deleteNotification,
    deleteAllNotifications,
  };
};

export type NotificationsApi = ReturnType<typeof createNotificationsApi>;
export type ApiNotification = ResponseOf<'notifications/get'>[number];
