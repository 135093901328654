import i18n from 'i18next';

import { createToasts } from '@legalfly/ui/toast';

export const playbookToasts = createToasts({
  createPlaybook: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.createPlaybook.description'),
    }),
  }),
  deletePlaybook: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deletePlaybook.description'),
    }),
  }),
  updatePlaybook: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.updatePlaybook.description'),
    }),
  }),
  createPlaybookItems: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.createPlaybookItem.description'),
    }),
  }),
  deletePlaybookItem: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deletePlaybookItem.description'),
    }),
  }),
  updatePlaybookItem: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.updatePlaybookItem.description'),
    }),
  }),
});
