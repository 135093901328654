import { useTranslation } from 'react-i18next';
import { useForm } from '@tanstack/react-form';
import { useNavigate } from '@tanstack/react-router';

import type { ApiTemplate } from '@legalfly/api/drafting';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { FormItem, FormItemLabel } from '@legalfly/ui/form';
import { Input } from '@legalfly/ui/input';
import { withToasts } from '@legalfly/ui/toast';
import { nameValidator } from 'components/common/form/validations';
import SelectDocumentCategory from 'components/common/select/SelectDocumentCategory';
import { draftingToasts, useCreateTemplate } from 'core/modules/drafting';

interface Props {
  afterSubmit: () => void;
}

interface FormValues {
  name: string;
  category: ApiTemplate['category'];
}

const defaultValues: FormValues = {
  name: '',
  category: 'UNDEFINED_DOCUMENT',
};

const CreateDraftingTemplateForm = ({ afterSubmit }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { createTemplate } = useCreateTemplate();

  const onCreateTemplate = async ({ value }: { value: FormValues }) => {
    trackEvent({
      action: 'submit',
      category: 'templates',
      label: 'createTemplate',
    });
    const template = await withToasts(
      createTemplate({
        body: {
          title: value.name,
          category: value.category,
        },
      }),
    )(draftingToasts.createDraftingTemplate());

    navigate({ to: `/drafting/templates/$uuid`, params: { uuid: template.uuid } });
    afterSubmit();
  };

  const form = useForm<FormValues>({
    defaultValues,
    onSubmit: onCreateTemplate,
  });

  return (
    <form
      className='flex flex-col gap-4'
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <FormItem>
        <FormItemLabel label={t('drafting.templates.form.name.label')} htmlFor='name' />
        <form.Field
          name='name'
          validators={{
            onBlur: ({ value }) => nameValidator(t)(value),
          }}
        >
          {(field) => (
            <Input
              id={field.name}
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              placeholder={t('drafting.templates.form.name.placeholder')}
              onChange={(e) => field.handleChange(e.target.value)}
              errorMessage={field.state.meta.errors.join()}
            />
          )}
        </form.Field>
      </FormItem>

      <FormItem>
        <FormItemLabel label={t('drafting.templates.form.category.label')} htmlFor='category' />
        <form.Field name='category'>
          {(field) => (
            <SelectDocumentCategory
              triggerProps={{ className: 'h-12' }}
              value={field.state.value}
              onChange={field.handleChange}
            />
          )}
        </form.Field>
      </FormItem>

      <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
        {([canSubmit, isSubmitting]) => (
          <Button type='submit' disabled={!canSubmit} className='self-end' isLoading={isSubmitting}>
            {t('action.createTemplate')}
          </Button>
        )}
      </form.Subscribe>
    </form>
  );
};

export default CreateDraftingTemplateForm;
