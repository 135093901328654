import type { ButtonHTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'utils';

import { Spinner } from '../spinner';

const buttonVariants = cva(
  cn(
    'line-clamp-2 inline-flex items-center gap-2 whitespace-nowrap text-wrap transition-colors',
    'focus-visible:bg-fill-focus-weak focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-4',
    'disabled:pointer-events-none disabled:bg-transparent disabled:text-content-body-disabled',
  ),
  {
    variants: {
      variant: {
        hard: 'border border-stroke-strong bg-transparent text-content-body hover:bg-fill-hover-weak focus-visible:outline-stroke-weak active:bg-fill-pressed-weak disabled:border-stroke-disabled',
        soft: 'border border-stroke-weaker bg-fill-strongest text-content-body-strong hover:bg-fill-strong focus-visible:outline-stroke-weak active:bg-fill-pressed-strong disabled:border-stroke-weaker',
        unstyled: '',
      },
      size: {
        sm: 'min-h-8 px-3',
        md: 'min-h-10 px-4',
        lg: 'min-h-14 px-6',
      },
    },
    compoundVariants: [
      {
        variant: 'unstyled',
        className: 'inline h-auto px-0',
      },
    ],
    defaultVariants: {
      variant: 'hard',
      size: 'md',
    },
  },
);

type Props = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
    isLoading?: boolean;
    renderLeft?: ReactNode;
    leftClassName?: string;
    renderRight?: ReactNode;
    rightClassName?: string;
  };

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      className,
      variant = 'hard',
      size = 'md',
      asChild = false,
      type = 'button',
      isLoading,
      renderLeft,
      renderRight,
      leftClassName,
      rightClassName,
      children,
      disabled,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    const body = asChild ? (
      children
    ) : (
      <>
        {isLoading && <Spinner />}
        {children}
      </>
    );

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        type={type}
        role='button'
        aria-disabled={disabled || isLoading}
        disabled={disabled || isLoading}
        {...props}
      >
        {Boolean(renderLeft) && !isLoading && (
          <span className={cn('flex', leftClassName)}>{renderLeft}</span>
        )}
        <Slottable>{body}</Slottable>
        {Boolean(renderRight) && !isLoading && (
          <span className={cn('flex', rightClassName)}>{renderRight}</span>
        )}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
