import { queryOptions } from '@tanstack/react-query';

import type { ApiConfig, ConfigApi } from '@legalfly/api/config';

export const configQueryOptions = ({ configApi }: { configApi: ConfigApi }) => ({
  config: <TData = ApiConfig>({ select }: { select?: (data: ApiConfig) => TData } = {}) =>
    queryOptions({
      queryKey: ['config'],
      queryFn: () => configApi.getConfig(),
      staleTime: 1000 * 60 * 60 * 24, // 1 day
      select,
    }),
});
