import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import type { AgentsApi, ApiAgent } from '../../../legalfly-api/src/agents';
import { agentsQueryOptions } from './agentsQueryOptions';

export const createAgentsModule = ({ agentsApi }: { agentsApi: AgentsApi }) => {
  const queryOptions = agentsQueryOptions({ agentsApi });

  const useAgents = () => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.agents());

    return {
      agents: data ?? [],
      isLoading,
      error,
    };
  };

  const useAgent = ({ uuid }: { uuid: ApiAgent['uuid'] }) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.agent({ uuid }));

    return {
      agent: data,
      isLoading,
      error,
    };
  };

  const useCreateAgent = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['agents', 'create'],
      mutationFn: agentsApi.createAgent,
      onSuccess(agent) {
        queryClient.setQueryData(queryOptions.agent({ uuid: agent.uuid }).queryKey, agent);
        queryClient.setQueryData(queryOptions.agents().queryKey, (agents) => {
          return agents ? [...agents, agent] : [agent];
        });
      },
    });

    return {
      createAgent: mutateAsync,
      isCreatingAgent: isPending,
    };
  };

  const useUpdateAgent = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['agents', 'update'],
      mutationFn: agentsApi.updateAgent,
      onSuccess(agent) {
        queryClient.setQueryData(queryOptions.agent({ uuid: agent.uuid }).queryKey, agent);
        queryClient.setQueryData(queryOptions.agents().queryKey, (agents) => {
          return agents?.map((a) => (a.uuid === agent.uuid ? agent : a));
        });
      },
    });

    return {
      updateAgent: mutateAsync,
      isUpdatingAgent: isPending,
    };
  };

  const useAgentConversations = ({ uuid }: { uuid: ApiAgent['uuid'] }) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.agentConversations({ uuid }));

    return {
      conversations: data ?? [],
      isLoading,
      error,
    };
  };

  const useDeleteAgent = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['agents', 'delete'],
      mutationFn: agentsApi.deleteAgent,
      onSuccess(_, { uuid }) {
        queryClient.setQueryData(queryOptions.agents().queryKey, (agents) => {
          return agents?.filter((agent) => agent.uuid !== uuid);
        });
        queryClient.removeQueries(queryOptions.agent({ uuid }));
        queryClient.removeQueries(queryOptions.agentConversations({ uuid }));
      },
    });

    return {
      deleteAgent: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    useAgents,
    useAgent,
    useCreateAgent,
    useUpdateAgent,
    useAgentConversations,
    useDeleteAgent,
    agentsQueryOptions: queryOptions,
  };
};
