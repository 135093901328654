import type { ComponentProps, ReactNode } from 'react';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiDocument } from '@legalfly/api/documents';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { Divider } from '@legalfly/ui/divider';
import { Icon } from '@legalfly/ui/icon';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@legalfly/ui/sheet';
import { Spinner } from '@legalfly/ui/spinner';
import DocumentPicker from 'components/documents/documentPicker/DocumentPicker';
import { useSelectedDocuments } from 'components/documents/documentPicker/SelectedDocumentsProvider';
import { useCountFolderDocuments, useFolder } from 'core/modules/documents';
import { isDocumentFile, isDocumentFolder } from 'core/modules/documents/helpers';

const SheetDocumentPicker = (props: Omit<ComponentProps<typeof DocumentPicker>, 'documents'>) => {
  const [activeDocument, setActiveDocument] = useState<ApiDocument | undefined>(undefined);
  const { documents } = useFolder(activeDocument?.uuid);

  const handleBackPress = () => {
    if (activeDocument?.parent) {
      setActiveDocument(activeDocument.parent);
    } else {
      setActiveDocument(undefined);
    }
  };

  return (
    <DocumentPicker
      documents={documents}
      onSelectFolder={setActiveDocument}
      onBackPress={activeDocument ? handleBackPress : undefined}
      {...props}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type Props = {
  onOpenChange?: (open: boolean) => void;
  children: ReactNode;
};

export const SelectDocumentsSheet = ({ onOpenChange, children }: Props) => {
  const { t } = useTranslation();
  const { selectedDocuments } = useSelectedDocuments();

  const selectedFolders = selectedDocuments.filter(isDocumentFolder);
  const selectedFiles = selectedDocuments.filter(isDocumentFile);

  const { numberOfDocuments } = useCountFolderDocuments({
    uuids: selectedFolders.map((d) => d.uuid),
    enabled: Boolean(selectedFolders.length),
  });

  const selectedDocumentsCount = selectedFolders.length
    ? numberOfDocuments + selectedFiles.length
    : selectedFiles.length;

  return (
    <Sheet onOpenChange={onOpenChange}>
      <SheetTrigger asChild>
        <Button
          renderLeft={<Icon name='folder' />}
          className='gap-2'
          size='md'
          variant='soft'
          onClick={() => {
            trackEvent({
              action: 'click',
              category: 'discovery',
              label: 'selectDocuments',
            });
          }}
        >
          {t('discovery.actions.selectDocuments', {
            count: selectedDocumentsCount,
          })}
        </Button>
      </SheetTrigger>
      <SheetContent
        style={{
          top: 'calc(var(--lf-content-header-height) + var(--lf-body-padding) * 2)',
        }}
        className='max-w-[90%] overflow-hidden bg-fill-maximal sm:max-w-[80%] md:max-w-[60%] lg:max-w-[40%]'
      >
        <SheetHeader>
          <SheetTitle>{t('nav.documents')}</SheetTitle>
        </SheetHeader>
        <Divider variant='weak' className='my-4 px-6' />
        <div className='px-6'>
          <Suspense fallback={<Spinner size='md' className='mt-10' />}>{children}</Suspense>
        </div>
      </SheetContent>
    </Sheet>
  );
};

SelectDocumentsSheet.Content = SheetDocumentPicker;
