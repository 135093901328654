import { queryOptions } from '@tanstack/react-query';

import type { ApiDraft, ApiTemplate, DraftingApi } from '@legalfly/api/drafting';

export const draftingQueryOptions = ({ draftingApi }: { draftingApi: DraftingApi }) => ({
  templates: () =>
    queryOptions({
      queryKey: ['drafting', 'templates'],
      queryFn: () => draftingApi.getDraftingTemplates(),
    }),
  template: ({ uuid }: { uuid: ApiTemplate['uuid'] }) =>
    queryOptions({
      queryKey: ['drafting', 'templates', uuid],
      queryFn: () => draftingApi.getDraftingTemplate({ uuid }),
    }),
  drafts: () =>
    queryOptions({
      queryKey: ['drafting', 'drafts'],
      queryFn: () => draftingApi.getDrafts(),
    }),
  draft: ({ uuid }: { uuid: ApiDraft['uuid'] }) =>
    queryOptions({
      queryKey: ['drafting', 'drafts', uuid],
      queryFn: () => draftingApi.getDraft({ uuid }),
    }),
});
