import { useCallback, useEffect } from 'react';

import { WebSocketError } from '@legalfly/api/websocket';
import { legalFlyConfig } from '@legalfly/config';
import { reportError, reportMessage } from '@legalfly/reporting/tracing';
import { tryCatch } from '@legalfly/utils';

import { WebSocketManagerInstance } from './WebSocketManager';

const BASE_URL = `${legalFlyConfig.websocket.protocol}://${legalFlyConfig.websocket.hostname}:${legalFlyConfig.websocket.port}`;
const MAX_RETRY_COUNT = 5;
const NORMAL_CLOSURE_CODE = 1000;
const RETRY_DELAY_MULTIPLIER = 1000;

interface WebSocketProviderProps {
  isAuthenticated: boolean;
  pollingEnabled: boolean;
  authenticateCallback: () => Promise<string>;
  togglePollingEnabled: (pollingEnabled: boolean) => void;
}

export const WebSocketProvider = ({
  isAuthenticated,
  pollingEnabled,
  authenticateCallback,
  togglePollingEnabled,
}: WebSocketProviderProps) => {
  const handleRetry = useCallback(
    (message: string, retryCount: number) => {
      if (retryCount < MAX_RETRY_COUNT) {
        reportMessage(`${message}. Retrying connection (${retryCount + 1}/${MAX_RETRY_COUNT})`);
        console.log(`${message}. Retrying connection (${retryCount + 1}/${MAX_RETRY_COUNT})`);
        return true;
      }

      reportMessage('Switching to polling system after 5 failed attempts');
      console.log('Switching to polling system after 5 failed attempts');
      togglePollingEnabled(true);
      return false;
    },
    [togglePollingEnabled],
  );

  const connectWebSocket = useCallback(
    async (retryCount: number) => {
      const [error, token] = await tryCatch(authenticateCallback());

      if (error) {
        handleRetry('Could not fetch ws token', retryCount);
        return null;
      }

      const socket = new WebSocket(`${BASE_URL}/${token}`);

      socket.onopen = () => {
        console.log('WebSocket connection established successfully');
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data.toString());
        WebSocketManagerInstance.publish(data.type, data.data);
      };

      socket.onclose = (event) => {
        console.log('WebSocket connection closed', event);

        if (event.code === NORMAL_CLOSURE_CODE) {
          return;
        }

        const wsError = new WebSocketError(
          'WebSocket connection closed unexpectedly',
          String(event.code),
          event.reason,
          BASE_URL,
        );

        const shouldRetry = handleRetry(wsError.message, retryCount);
        if (shouldRetry) {
          setTimeout(
            () => connectWebSocket(retryCount + 1),
            RETRY_DELAY_MULTIPLIER * (retryCount + 1),
          );
        }
        reportError(wsError);
      };

      return socket;
    },
    [authenticateCallback, handleRetry],
  );

  useEffect(() => {
    if (!isAuthenticated || pollingEnabled) return;

    let socket: WebSocket | null = null;

    const initializeWebSocket = async () => {
      socket = await connectWebSocket(0);
    };

    initializeWebSocket();

    return () => {
      if (socket) {
        socket.close(NORMAL_CLOSURE_CODE, 'Normal closure');
      }
    };
  }, [isAuthenticated, pollingEnabled, connectWebSocket]);

  return null;
};
