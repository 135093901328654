import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';

import { FetcherError } from '@legalfly/api/fetcher';
import { reportError } from '@legalfly/reporting/tracing';
import { ErrorCard } from '@legalfly/ui/error';
import { env } from 'core/env';

export const DefaultError = ({ error }: { error: FetcherError | Error }) => {
  const { t } = useTranslation();
  const queryErrorResetBoundary = useQueryErrorResetBoundary();

  useEffect(() => {
    // Reset the query error boundary
    queryErrorResetBoundary.reset();
  }, [queryErrorResetBoundary]);

  useEffect(() => {
    // fetch errors get automatically reported
    if (!(error instanceof FetcherError)) {
      reportError(error);
    }
  }, [error]);

  const getInfo = () => {
    if (env.isProduction) {
      return {
        title: t('errors.default.title'),
        message: t('errors.default.message'),
      };
    }

    if (error instanceof FetcherError) {
      return {
        title: error.response.name,
        message: JSON.stringify({ url: error.request.url, ...error.response }, null, 2),
      };
    }

    return {
      title: error.name,
      message: JSON.stringify(
        {
          cause: error.cause,
          message: error.message,
          stack: error.stack,
        },
        null,
        2,
      ),
    };
  };

  const info = getInfo();

  return (
    <ErrorCard className='h-dvh'>
      <ErrorCard.Content>
        <ErrorCard.Title>{info.title}</ErrorCard.Title>
        <ErrorCard.Message>{info.message}</ErrorCard.Message>
        <ErrorCard.Action>
          <Link to='/'>{t('errors.card.cta')}</Link>
        </ErrorCard.Action>
      </ErrorCard.Content>
    </ErrorCard>
  );
};
