import { useEffect, useRef, useState } from 'react';

export function useTabAnimation(disabled: boolean) {
  const listRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    const list = listRef.current;
    const background = backgroundRef.current;

    if (!list || !background || disabled) return;

    const updateBackground = () => {
      const activeTab = list.querySelector('[data-state="active"]') as HTMLElement;
      if (activeTab) {
        background.style.width = `${activeTab.offsetWidth}px`;

        // Skip animation on initial render
        if (isInitialRender) {
          background.style.transition = 'none';
          background.style.transform = `translateX(${activeTab.offsetLeft}px)`;

          // Reset transition after initial positioning
          requestAnimationFrame(() => {
            if (background) {
              background.style.transition = '';
              setIsInitialRender(false);
            }
          });
        } else {
          background.style.transform = `translateX(${activeTab.offsetLeft}px)`;
        }
      }
    };

    updateBackground();

    const mutationObserver = new MutationObserver(updateBackground);
    mutationObserver.observe(list, { attributes: true, subtree: true });

    const resizeObserver = new ResizeObserver(updateBackground);
    resizeObserver.observe(list);

    return () => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
    };
  }, [disabled, isInitialRender]);

  return { listRef, backgroundRef };
}
