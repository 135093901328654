import type { ReactNode } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@legalfly/ui/button';
import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  useDialog,
} from '@legalfly/ui/dialog';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';

interface Props {
  subTitle: string;
  type: 'warning' | 'error';
  children: ReactNode;
}

export const MaxFilesDialog = ({ subTitle, type, children }: Props) => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
        if (!open) {
          dialog.close();
        }
      }}
    >
      <DialogContent
        onPointerDownOutside={(e) => {
          e.preventDefault();
        }}
      >
        <VisuallyHidden>
          <DialogDescription>{subTitle}</DialogDescription>
        </VisuallyHidden>
        <DialogHeader>
          <Icon
            name={type === 'warning' ? 'info-circle' : 'x-circle'}
            className={type === 'warning' ? 'text-content-body-warning' : 'text-content-body-error'}
          />
          <DialogTitle
            className={cn(
              'text-subheading-semibold',
              type === 'warning' ? 'text-content-body-warning' : 'text-content-body-error',
            )}
          >
            {t('dialog.maxFiles.title')}
          </DialogTitle>
        </DialogHeader>
        <DialogBody className='mb-6'>
          <Text>{subTitle}</Text>
        </DialogBody>
        <DialogFooter className='flex justify-end gap-2'>
          <DialogClose asChild>
            <Button variant='soft'>{t('action.cancel')}</Button>
          </DialogClose>
          {children}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
