import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher';

export const createSettingsApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const getUserSettings = () => {
    return client(api.settings.user, { method: 'GET' });
  };

  const updateUserSettings = (body: BodyOf<'settings/user/put'>) => {
    return client(api.settings.user, { method: 'PUT', body });
  };

  return {
    getUserSettings,
    updateUserSettings,
  };
};

export type SettingsApi = ReturnType<typeof createSettingsApi>;
export type ApiUserSettings = ResponseOf<'settings/user/get'>;
