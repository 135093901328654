import { useEffect, useState } from 'react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { usePrevious } from 'common/hooks/usePrevious';

import { trackEvent } from '@legalfly/reporting/tracking';
import { Button, IconButton } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { withToasts } from '@legalfly/ui/toast';
import { useWebSocketSubscriber } from '@legalfly/websockets';
import { ContentRow } from 'components/common/content/ContentRow';
import { DraftingHeader } from 'components/drafting/DraftingHeader';
import { DraftClausesList } from 'components/drafting/drafts/DraftClausesList';
import { DraftCompletedDialog } from 'components/drafting/drafts/DraftCompletedDialog';
import { DraftConversation } from 'components/drafting/drafts/DraftConversation';
import {
  draftingQueryOptions,
  draftingToasts,
  useDownloadDraftAsDocx,
  useDownloadDraftAsPdf,
  useDraft,
  useUpdateDraft,
  useUpdateDraftClauseCache,
} from 'core/modules/drafting';

export const Route = createFileRoute('/_auth/_layout/drafting/drafts/$uuid')({
  component: DraftingDraftRoute,
  loader({ context, params }) {
    return context.queryClient.ensureQueryData(draftingQueryOptions.draft({ uuid: params.uuid }));
  },
});

function DraftingDraftRoute() {
  const { uuid } = Route.useParams();
  const navigate = useNavigate();

  const [isCompletedDialogOpen, setIsCompletedDialogOpen] = useState(false);
  const { downloadPdf, isLoading: isDownloadingPdf } = useDownloadDraftAsPdf();
  const { downloadDocx, isLoading: isDownloadingDocx } = useDownloadDraftAsDocx();

  const { draft } = useDraft({ uuid });
  const { updateDraft } = useUpdateDraft();
  const updateDraftClauseCache = useUpdateDraftClauseCache();

  const nextClauseToDraft = draft.clauses.find((clause) => clause.status === 'progress');

  useWebSocketSubscriber(`draft_clause_${draft.uuid}`, (data) => {
    updateDraftClauseCache({
      draftUuid: draft.uuid,
      updatedClause: data.clause,
    });
  });

  const prevNextClauseToDraft = usePrevious(nextClauseToDraft);
  useEffect(() => {
    if (prevNextClauseToDraft && !nextClauseToDraft) {
      setIsCompletedDialogOpen(true);
    }
  }, [prevNextClauseToDraft, nextClauseToDraft]);

  const handleChangeTitle = (title: string) => {
    trackEvent({
      action: 'click',
      category: 'drafts',
      label: 'changeTitle',
    });
    withToasts(updateDraft({ uuid, body: { ...draft, title } }))(draftingToasts.updateDraft());
  };

  const handleDownloadPdf = () => {
    trackEvent({
      action: 'click',
      category: 'drafts',
      label: 'downloadPdf',
    });
    downloadPdf({ uuid });
  };

  const handleDownloadDocx = () => {
    trackEvent({
      action: 'click',
      category: 'drafts',
      label: 'downloadDocx',
    });
    downloadDocx({ uuid });
  };

  return (
    <div className='flex gap-3'>
      <div className='flex h-content flex-2 flex-col gap-3'>
        <ContentRow className='justify-between'>
          <div className='flex items-center gap-2'>
            <IconButton
              name='chevron-left'
              onClick={() => navigate({ to: '/drafting/drafts' })}
              variant='tertiary'
              size='sm'
            />
            <Text>{draft.title}</Text>
          </div>
          {!nextClauseToDraft && (
            <div className='flex items-center gap-4'>
              <Button
                size='md'
                variant='soft'
                renderLeft={<Icon name='download-2' />}
                onClick={handleDownloadPdf}
                isLoading={isDownloadingPdf}
              >
                PDF
              </Button>
              <Button
                size='md'
                variant='soft'
                renderLeft={<Icon name='download-2' />}
                onClick={handleDownloadDocx}
                isLoading={isDownloadingDocx}
              >
                Word
              </Button>
            </div>
          )}
        </ContentRow>
        <div className='flex h-content flex-col overflow-auto bg-fill-maximal px-content-x py-content-y'>
          <DraftingHeader
            title={draft.title}
            createdAt={draft.createdAt}
            onChangeTitle={handleChangeTitle}
            className='xl:w-[var(--lf-template-width)]'
          />
          <DraftClausesList draft={draft} nextClauseToDraft={nextClauseToDraft} />
        </div>
      </div>
      <div className='relative h-content flex-1 overflow-auto bg-fill-maximal px-6 py-6'>
        <DraftConversation draft={draft} nextClauseToDraft={nextClauseToDraft} />
      </div>
      <DraftCompletedDialog
        isOpen={isCompletedDialogOpen}
        onOpenChange={setIsCompletedDialogOpen}
        draftUuid={draft.uuid}
      />
    </div>
  );
}
