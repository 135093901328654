import { useTranslation } from 'react-i18next';

import type { ApiConversationMessage, ConversationTypeEnum } from '@legalfly/api/conversations';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { SidePaneContent, SidePaneHeader, SidePaneTrigger } from '@legalfly/ui/sidePane';
import { Text } from '@legalfly/ui/text';
import { SwissCaseLawSource } from 'components/conversations/message/SwissCaseLawSource.tsx';

import { DocumentSource } from './DocumentSource';
import { WebSource } from './WebSource';

interface Props {
  message: ApiConversationMessage;
  sources: ApiConversationMessage['sources'];
  conversationType: ConversationTypeEnum;
}

export const ConversationMessageSources = ({ message, sources, conversationType }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <SidePaneTrigger asChild paneId={message.uuid}>
        <Button
          className='gap-2'
          renderLeft={<Icon name='sources' />}
          onClick={() => {
            trackEvent(
              {
                action: 'click',
                category: 'discovery',
                label: 'sources',
              },
              { type: conversationType },
            );
          }}
        >
          {t('discovery.sources.title')}
        </Button>
      </SidePaneTrigger>
      <SidePaneContent paneId={message.uuid} className='h-content' size='md'>
        <SidePaneHeader>
          <Text as='p' variant='bodyLarge'>
            {t('discovery.sources.title')}
          </Text>
        </SidePaneHeader>
        <div className='mt-6 flex flex-col gap-3'>
          {sources
            .sort((a) => (a.type === 'document' ? -1 : 1))
            .map((source, index) => {
              if (source.type === 'document' || source.type === 'full-document') {
                return (
                  <DocumentSource
                    key={index}
                    source={source}
                    question={message.question}
                    answer={message.assistantMessage}
                  />
                );
              } else if (source.type === 'swiss case law') {
                return <SwissCaseLawSource key={index} source={source} />;
              } else {
                return <WebSource key={index} results={source.results} />;
              }
            })}
        </div>
      </SidePaneContent>
    </>
  );
};
