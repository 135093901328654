import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiMultiReview } from '@legalfly/api/multi-reviews';
import type { ApiReview } from '@legalfly/api/reviews';
import { ReviewPlaybookItemStatus } from '@legalfly/api/reviews';
import { BaseCopilotSkeleton } from '@legalfly/components/copilot';
import { Icon } from '@legalfly/ui/icon';
import { ProgressCard } from '@legalfly/ui/progress';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';

import { DocumentNameCell } from './grid/DocumentNameCell';
import { HeaderCell } from './grid/HeaderCell';
import { MultiReviewItem } from './grid/MultiReviewItem';
import { MultiReviewItemsListener } from './grid/MultiReviewItemsListener';
import { useContentWidth } from '../../common/hooks/useContentWidth';

interface Props {
  reviews: ApiReview[];
  multiReviewUuid: ApiMultiReview['uuid'];
}

export const ReviewsGrid = ({ reviews, multiReviewUuid }: Props) => {
  const { t } = useTranslation();
  const contentWidth = useContentWidth();

  const playbookItems = reviews[0]?.items.map((item) => item.text) ?? [];

  const numberOfItems = playbookItems.length * reviews.length;
  const completedItemsCount = reviews
    .flatMap((review) => review.items)
    .filter(
      (item) =>
        item.status === ReviewPlaybookItemStatus.COMPLETED ||
        item.status === ReviewPlaybookItemStatus.FAILED,
    ).length;

  const isComplete = completedItemsCount === numberOfItems;

  return (
    <div className='flex flex-col gap-3 overflow-hidden'>
      <div
        className={cn(
          'flex flex-col bg-fill-maximal',
          isComplete ? 'h-content-with-row' : 'h-content-with-row-with-progress',
        )}
        style={{
          width: `${contentWidth}px`,
        }}
      >
        <MultiReviewItemsListener reviews={reviews} multiReviewUuid={multiReviewUuid} />

        <div
          className='relative grid overflow-auto'
          style={{
            gridTemplateColumns: `auto repeat(${playbookItems.length}, minmax(300px, 1fr))`,
          }}
        >
          <HeaderCell className='left-0 z-20'>
            <Icon name='file-2' />
            <Text className='text-content-body-strong'>{t('documents.title')}</Text>
          </HeaderCell>
          {playbookItems.map((item, index) => (
            <HeaderCell key={index} className='z-10'>
              <Text className='text-content-body-strong'>{item}</Text>
            </HeaderCell>
          ))}

          {isComplete ? (
            reviews.map((review) => (
              <Fragment key={review.uuid}>
                <DocumentNameCell className='max-w-80'>
                  <div className='flex flex-col gap-1'>
                    {review.document.filePath?.split('/').map((part, index) => (
                      <div key={index} style={{ marginLeft: `${index * 8}px` }}>
                        {part}
                      </div>
                    ))}
                  </div>
                </DocumentNameCell>
                {review.items.map((item) => (
                  <MultiReviewItem
                    key={item.uuid}
                    item={item}
                    document={review.document}
                    shortFormat
                  />
                ))}
              </Fragment>
            ))
          ) : (
            <>
              <DocumentNameCell />
              {playbookItems.map((_, index) => (
                <HeaderCell key={index} className='z-10 min-h-32 border-b bg-fill-maximal'>
                  <BaseCopilotSkeleton className='w-full' />
                </HeaderCell>
              ))}
              <DocumentNameCell />
              {playbookItems.map((_, index) => (
                <HeaderCell key={index} className='z-10 min-h-32 border-b bg-fill-maximal'>
                  <BaseCopilotSkeleton className='w-full' />
                </HeaderCell>
              ))}
            </>
          )}
        </div>
      </div>

      {!isComplete && (
        <ProgressCard
          className='animate-in slide-in-from-bottom'
          title={t('review.new.progress.title')}
          subTitle={t('review.new.progress.subTitle')}
          action={`${completedItemsCount}/${numberOfItems}`}
        >
          <ProgressCard.Bar value={Math.ceil((completedItemsCount / numberOfItems) * 100)} />
        </ProgressCard>
      )}
    </div>
  );
};
