import ReactDOM from 'react-dom/client';

import { initSentry } from '@legalfly/reporting/tracing';
import { App } from 'app/App';
import { env } from 'core/env';

import { version } from '../package.json';
import './index.css';

initSentry({
  environment: env.environment,
  release: `frontend@${version}`,
  dsn: 'https://82d14296c4013b301235f34662fb1c60@o4508040987475968.ingest.de.sentry.io/4508041106030672',
});

// https://github.com/vitejs/vite/issues/11804
window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
