import type { ApiMultiReview } from '@legalfly/api/multi-reviews';
import { trackEvent } from '@legalfly/reporting/tracking';
import { withToasts } from '@legalfly/ui/toast';
import { EditableValueForm } from 'components/common/form/EditableValueForm';
import { multiReviewToasts, useUpdateMultiReview } from 'core/modules/multi-reviews';

import { getMultiReviewName } from './helpers/getMultiReviewName';

interface Props {
  multiReview: ApiMultiReview;
}

export const EditableMultiReviewName = ({ multiReview }: Props) => {
  const { updateMultiReview } = useUpdateMultiReview();

  const handleSubmit = async (newName: string) => {
    trackEvent({
      action: 'submit',
      category: 'multiReview',
      label: 'editName',
    });

    await withToasts(
      updateMultiReview({
        uuid: multiReview.uuid,
        body: { name: newName },
      }),
    )(multiReviewToasts.updateMultiReview());
  };

  return <EditableValueForm value={getMultiReviewName(multiReview)} onsubmit={handleSubmit} />;
};
