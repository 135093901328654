import { useTranslation } from 'react-i18next';
import { useTableHeight } from 'common/hooks/useTableHeight';

import type { ApiTemplate } from '@legalfly/api/drafting';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@legalfly/ui/table';
import { formatLongDate, formatRelativeTime } from '@legalfly/utils/date';
import DraftingTemplateActionPopover from 'components/drafting/templates/DraftingTemplateActionPopover';

import { TemplatesEmptyState } from './TemplatesEmptyState';

interface Props {
  hasAvailableTemplates: boolean;
  filteredTemplates: ApiTemplate[];
  onSelectTemplate: (template: ApiTemplate) => void;
}

export const TemplatesList = ({
  hasAvailableTemplates,
  filteredTemplates,
  onSelectTemplate,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { tableHeight, tableRef } = useTableHeight();

  if (!hasAvailableTemplates && filteredTemplates.length === 0) {
    return <TemplatesEmptyState />;
  }

  return (
    <Table
      parentClassName='h-fit overflow-y-auto relative'
      ref={tableRef}
      style={{ maxHeight: tableHeight }}
    >
      <TableHeader className='sticky top-0 z-10 bg-fill-maximal'>
        <TableRow className='shadow-table-border'>
          <TableHead>{t('drafting.templates.list.title')}</TableHead>
          <TableHead>{t('drafting.templates.list.contractType')}</TableHead>
          <TableHead>{t('drafting.templates.list.createdAt')}</TableHead>
          <TableHead />
        </TableRow>
      </TableHeader>
      <TableBody>
        {hasAvailableTemplates && filteredTemplates.length === 0 ? (
          <TableRow>
            <TableCell colSpan={3}>{t('drafting.templates.list.empty')}</TableCell>
          </TableRow>
        ) : (
          filteredTemplates.map((template) => {
            const handleOnClick = () => {
              onSelectTemplate(template);
            };

            return (
              <TableRow key={template.uuid} className='cursor-pointer'>
                <TableCell onClick={handleOnClick} className='text-body-semibold'>
                  {template.title}
                </TableCell>
                <TableCell onClick={handleOnClick}>
                  {t(`config.documentCategory.${template.category}`)}
                </TableCell>
                <TableCell
                  onClick={handleOnClick}
                  title={formatLongDate(template.createdAt, i18n.language)}
                >
                  {formatRelativeTime(template.createdAt, i18n.language)}
                </TableCell>
                <TableCell>
                  <DraftingTemplateActionPopover template={template} />
                </TableCell>
              </TableRow>
            );
          })
        )}
      </TableBody>
    </Table>
  );
};
