import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf, ParamsOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';

export const createMultiReviewsApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const getMultiReviews = () => {
    return client(api.multiReviews.list, { method: 'GET' });
  };

  const getMultiReview = ({ uuid }: ParamsOf<'multi-reviews/:uuid/get'>) => {
    return client(api.multiReviews.detail, { method: 'GET', params: { uuid } });
  };

  const createMultiReview = ({ body }: { body: BodyOf<'multi-reviews/post'> }) => {
    return client(api.multiReviews.create, { method: 'POST', body });
  };

  const deleteMultiReview = ({ uuid }: ParamsOf<'multi-reviews/:uuid/delete'>) => {
    return client(api.multiReviews.delete, { method: 'DELETE', params: { uuid } });
  };

  const updateMultiReview = ({
    uuid,
    body,
  }: {
    uuid: string;
    body: BodyOf<'multi-reviews/:uuid/put'>;
  }) => {
    return client(api.multiReviews.update, { method: 'PUT', params: { uuid }, body });
  };

  return {
    getMultiReviews,
    getMultiReview,
    createMultiReview,
    deleteMultiReview,
    updateMultiReview,
  };
};

export type MultiReviewsApi = ReturnType<typeof createMultiReviewsApi>;
export type ApiMultiReview = ResponseOf<'multi-reviews/get'>['multiReviews'][number];
export type ApiMultiReviewStats = ResponseOf<'multi-reviews/get'>['stats'];
