import { memo } from 'react';

import type { ApiDraftClause } from '@legalfly/api/drafting';
import { BaseCopilotSkeleton } from '@legalfly/components/copilot';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';

interface Props {
  clause: ApiDraftClause;
  isDrafting: boolean;
}

export const ReadOnlyClauseItem = memo(({ clause, isDrafting }: Props) => {
  return (
    <div
      className={cn(
        'mx-auto my-6 flex w-5/6 flex-col items-start gap-6 ',
        isDrafting && 'outline-dashed outline-1 outline-offset-[1.5rem] outline-stroke-weak',
      )}
    >
      <Text className='text-subheading-semibold'>{clause.title}</Text>
      {isDrafting && <BaseCopilotSkeleton className='w-full' />}

      {clause.status === 'done' && (
        <Text
          className='text-content-body-strong'
          dangerouslySetInnerHTML={{ __html: clause.content }}
        />
      )}
    </div>
  );
});

ReadOnlyClauseItem.displayName = 'ReadOnlyClauseItem';
