import { createFileRoute } from '@tanstack/react-router';

import { ConversationTypeEnum } from '@legalfly/api/conversations';
import { Conversation } from 'components/conversations/Conversation';
import { conversationsQueryOptions } from 'core/modules/conversations';

export const Route = createFileRoute('/_auth/_layout/discovery/')({
  loader({ context }) {
    return context.queryClient.ensureQueryData(
      conversationsQueryOptions.conversations({ type: ConversationTypeEnum.DISCOVERY }),
    );
  },
  component: DiscoveryRoute,
});

function DiscoveryRoute() {
  return (
    <Conversation
      conversationType={ConversationTypeEnum.DISCOVERY}
      analyticsMeta={{ category: 'discovery' }}
    />
  );
}
