import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@legalfly/ui/button';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@legalfly/ui/dialog';
import { Icon } from '@legalfly/ui/icon';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';

import CreateDraftingTemplateForm from './CreateDraftingTemplateForm';

export const CreateDraftingTemplateDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant='soft' renderLeft={<Icon name='file-2' className='text-icon-strong' />}>
          {t('action.createTemplate')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <VisuallyHidden>
          <DialogDescription>{t('dialog.createDraftingTemplate.description')}</DialogDescription>
        </VisuallyHidden>
        <DialogHeader>
          <Icon name='file-2' size='md' />
          <DialogTitle>{t('dialog.createDraftingTemplate.title')}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <CreateDraftingTemplateForm afterSubmit={() => setIsOpen(false)} />
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};
