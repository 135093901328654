import type { ApiDocumentTypeFolder } from '@legalfly/api/documents';
import { trackEvent } from '@legalfly/reporting/tracking';
import { withToasts } from '@legalfly/ui/toast';
import { EditableValueForm } from 'components/common/form/EditableValueForm';
import { documentsToasts, useUpdateFolder } from 'core/modules/documents';

interface Props {
  uuid: ApiDocumentTypeFolder['uuid'];
  name: ApiDocumentTypeFolder['name'];
}

export const EditableFolderName = ({ uuid, name }: Props) => {
  const { updateFolder } = useUpdateFolder();

  const handleSubmit = async (newName: string) => {
    trackEvent({
      action: 'submit',
      category: 'documents',
      label: 'editFolderName',
    });

    await withToasts(
      updateFolder({
        uuid,
        body: { name: newName },
      }),
    )(documentsToasts.updateFolderEntity());
  };

  return <EditableValueForm value={name} onsubmit={handleSubmit} />;
};
