import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { OnboardingDialog } from 'components/dashboard/OnboardingDialog';
import { useConfig } from 'core/modules/config';

const useUserConfig = () => {
  const { data: userSettings } = useConfig({ select: (data) => data.userSettings });
  const { i18n } = useTranslation();

  useEffect(() => {
    if (userSettings?.language) {
      i18n.changeLanguage(userSettings.language);
    }
  }, [userSettings, i18n]);

  return {
    userSettings,
  };
};

export const UserConfig = () => {
  const { userSettings } = useUserConfig();
  return <OnboardingDialog userSettings={userSettings} />;
};
