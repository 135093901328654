import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '@tanstack/react-form';

import type { ApiPlaybook, ApiPlaybookItem } from '@legalfly/api/playbooks';
import { PlaybookItemTypeEnum } from '@legalfly/api/playbooks';
import { trackEvent } from '@legalfly/reporting/tracking';
import { FormItem, FormItemLabel } from '@legalfly/ui/form';
import { Input } from '@legalfly/ui/input';
import { TextareaAutoSize } from '@legalfly/ui/textarea';
import { requiredValidator } from 'components/common/form/validations';
import { useUpdatePlaybookItem } from 'core/modules/playbooks';

import { usePlaybookItemActions } from './context/PlaybookItemProvider';
import SelectPlaybookItemType from './select/SelectPlaybookItemType';
import { PlaybookItemMultipleChoiceOption } from './PlaybookItemMultipleChoiceOption';
import type { OptionValues } from './PlaybookMultipleChoiceOptionsDialog';
import { PlaybookMultipleChoiceOptionsDialog } from './PlaybookMultipleChoiceOptionsDialog';

interface Props {
  playbookItem: ApiPlaybookItem;
  playbookUuid: ApiPlaybook['uuid'];
}

export const PlaybookItemForm = ({ playbookItem, playbookUuid }: Props) => {
  const { t } = useTranslation();
  const { reset, setIsFormValid } = usePlaybookItemActions();

  const { updatePlaybookItem } = useUpdatePlaybookItem();

  const form = useForm({
    defaultValues: {
      type: playbookItem.type,
      name: playbookItem.name,
      question: playbookItem.question,
      contextText: playbookItem.context?.text ?? '',
      contextDocumentIds: playbookItem.context?.documentIds ?? [],
      contextMultipleChoiceOptions: playbookItem.context?.multipleChoiceOptions ?? [],
    },
  });

  // the onMount validator is not cleared when input changes
  // need to recheck the actual values for validation :/
  // https://github.com/TanStack/form/issues/689
  const [name, question] = form.useStore((state) => [state.values.name, state.values.question]);
  useEffect(() => {
    setIsFormValid(Boolean(name) && Boolean(question));
  }, [name, question, setIsFormValid]);

  const onChangeType = (type: PlaybookItemTypeEnum) => {
    trackEvent({
      action: 'click',
      category: 'playbooksItem',
      label: 'changeType',
    });

    // don't reset the selected playbook item and isFormValid
    reset(['selectedPlaybookItem', 'isFormValid']);
    form.setFieldValue('type', type);

    if (form.getFieldValue('contextMultipleChoiceOptions')?.length > 0) {
      form.setFieldValue('contextMultipleChoiceOptions', []);
    }

    updatePlaybookItem({
      playbookUuid,
      itemUuid: playbookItem.uuid,
      body: { type, context: { multipleChoiceOptions: [] } },
    });
  };

  const onBlurName = (value: string) => {
    updatePlaybookItem({
      playbookUuid,
      itemUuid: playbookItem.uuid,
      body: { name: value },
    });
  };

  const onBlurQuestion = (value: string) => {
    updatePlaybookItem({
      playbookUuid,
      itemUuid: playbookItem.uuid,
      body: { question: value },
    });
  };

  const onBlurContext = (value: string) => {
    updatePlaybookItem({
      playbookUuid,
      itemUuid: playbookItem.uuid,
      body: { context: { text: value } },
    });
  };

  const onChangeMultipleChoiceOptions = (updatedOptions: OptionValues[]) => {
    updatePlaybookItem({
      playbookUuid,
      itemUuid: playbookItem.uuid,
      body: {
        context: {
          multipleChoiceOptions: updatedOptions,
        },
      },
    });
  };

  return (
    <form
      className='mb-10 flex flex-col gap-4'
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <FormItem className='w-1/2'>
        <FormItemLabel label={`${t('form.type.label')}*`} htmlFor='type' />
        <form.Field name='type'>
          {(field) => (
            <SelectPlaybookItemType
              triggerProps={{ id: field.name }}
              value={field.state.value}
              onChange={onChangeType}
            />
          )}
        </form.Field>
      </FormItem>

      <FormItem>
        <FormItemLabel label={`${t('form.name.label')}*`} htmlFor='name' />
        <form.Field
          name='name'
          validators={{
            onBlur: ({ value }) => requiredValidator(t('form.name.validation.required'))(value),
          }}
        >
          {(field) => (
            <Input
              id={field.name}
              name={field.name}
              value={field.state.value}
              placeholder={t('form.name.placeholder')}
              onChange={(e) => field.handleChange(e.target.value)}
              onBlur={() => {
                field.handleBlur();
                if (!field.state.meta.errors.length && field.state.meta.isDirty) {
                  onBlurName(field.state.value);
                }
              }}
              errorMessage={field.state.meta.errors.join()}
            />
          )}
        </form.Field>
      </FormItem>

      <FormItem>
        <form.Subscribe selector={(state) => state.values.type}>
          {(type) => (
            <FormItemLabel
              label={`${
                {
                  [PlaybookItemTypeEnum.QUESTION]: t('playbooks.question.question'),
                  [PlaybookItemTypeEnum.YES_NO]: t('playbooks.question.condition'),
                  [PlaybookItemTypeEnum.RISK_ASSESSMENT]: t('playbooks.question.topic'),
                  [PlaybookItemTypeEnum.MULTIPLE_CHOICE]: t('playbooks.question.condition'),
                  [PlaybookItemTypeEnum.ANOMALY]: t('playbooks.question.question'),
                }[type]
              }*`}
              htmlFor='question'
            />
          )}
        </form.Subscribe>

        <form.Field
          name='question'
          validators={{
            onBlur: ({ value }) => requiredValidator(t('form.question.validation.required'))(value),
          }}
        >
          {(field) => (
            <TextareaAutoSize
              id={field.name}
              name={field.name}
              value={field.state.value}
              placeholder={t('form.question.placeholder')}
              onChange={(e) => field.handleChange(e.target.value)}
              onBlur={() => {
                field.handleBlur();
                if (!field.state.meta.errors.length && field.state.meta.isDirty) {
                  onBlurQuestion(field.state.value);
                }
              }}
              errorMessage={field.state.meta.errors.join()}
            />
          )}
        </form.Field>
      </FormItem>

      {form.getFieldValue('type') === PlaybookItemTypeEnum.MULTIPLE_CHOICE && (
        <FormItem>
          <form.Field name='contextMultipleChoiceOptions' mode='array'>
            {(field) => (
              <div className='flex flex-col'>
                <div className='w-fit'>
                  <PlaybookMultipleChoiceOptionsDialog
                    options={field.state.value}
                    onChange={(options) => {
                      field.handleChange(options);
                      onChangeMultipleChoiceOptions(options);
                    }}
                  />
                </div>

                {field.state.value.length > 0 && (
                  <div className='mt-2 flex w-full flex-wrap gap-2'>
                    {field.state.value.map((option, index) => (
                      <PlaybookItemMultipleChoiceOption key={index} option={option} />
                    ))}
                  </div>
                )}
              </div>
            )}
          </form.Field>
        </FormItem>
      )}

      <FormItem>
        <FormItemLabel label={t('form.info.label')} htmlFor='contextText' />
        <form.Field name='contextText'>
          {(field) => (
            <TextareaAutoSize
              id={field.name}
              name={field.name}
              value={field.state.value}
              placeholder={t('form.info.placeholder')}
              onChange={(e) => field.handleChange(e.target.value)}
              onBlur={() => {
                field.handleBlur();
                if (!field.state.meta.errors.length && field.state.meta.isDirty) {
                  onBlurContext(field.state.value);
                }
              }}
              errorMessage={field.state.meta.errors.join()}
              className='min-h-32'
            />
          )}
        </form.Field>
      </FormItem>
    </form>
  );
};
