import { legalFlyConfig } from '@legalfly/config';

import type { ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';

export const createConfigApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const getConfig = () => {
    return client(api.config.list, { method: 'GET' });
  };

  return {
    getConfig,
  };
};

export type ConfigApi = ReturnType<typeof createConfigApi>;
export type ApiConfig = ResponseOf<'config/get'>;
export type ApiConfigUserSettings = ApiConfig['userSettings'];
