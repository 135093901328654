import { useMutation } from '@tanstack/react-query';

import type { FeedbackApi } from '@legalfly/api/feedback';

export const createFeedbackModule = ({ feedbackApi }: { feedbackApi: FeedbackApi }) => {
  const useSendFeedback = () => {
    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['feedback', 'send'],
      mutationFn: feedbackApi.sendFeedback,
    });

    return {
      sendFeedback: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    useSendFeedback,
  };
};
