import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaybookItemTypeEnum } from '@legalfly/api/playbooks';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@legalfly/ui/select';

interface Props {
  value: PlaybookItemTypeEnum;
  onChange: (v: PlaybookItemTypeEnum) => void;
  triggerProps?: ComponentProps<typeof SelectTrigger>;
  contentProps?: ComponentProps<typeof SelectContent>;
}

const types = Object.values(PlaybookItemTypeEnum).filter(
  (type) => type !== PlaybookItemTypeEnum.ANOMALY,
);

const SelectPlaybookItemType = ({ value, onChange, triggerProps, contentProps }: Props) => {
  const { t } = useTranslation();

  return (
    <Select
      value={value}
      onValueChange={(v) => {
        onChange(v as PlaybookItemTypeEnum);
      }}
    >
      <SelectTrigger {...triggerProps}>
        <SelectValue placeholder={t('playbooks.type.selectType')}>
          {t(`playbooks.type.${value}`)}
        </SelectValue>
      </SelectTrigger>
      <SelectContent {...contentProps}>
        {types.map((key) => (
          <SelectItem key={key} value={key}>
            {t(`playbooks.type.${key}`)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default SelectPlaybookItemType;
