import { useMemo } from 'react';

interface UseTextToParagraphedTextProps {
  text: string | undefined;
  numberOfSentencesInParagraph?: number;
}

export const useTextToParagraphedText = ({
  text,
  numberOfSentencesInParagraph = 2,
}: UseTextToParagraphedTextProps): string[] => {
  const formattedText = useMemo(() => {
    if (!text) return [];

    // Split the text into sentences, considering punctuation and special cases
    const sentences = text.split(/([.!?])\s+(?=[A-Z])/).filter(Boolean);
    const paragraphs: string[] = [];

    // Handle cases where the text doesn't contain complete sentences
    if (sentences.length === 0) {
      return [text];
    }

    for (let i = 0; i < sentences.length; i += numberOfSentencesInParagraph) {
      const paragraph = sentences
        .slice(i, i + numberOfSentencesInParagraph)
        .join(' ')
        .trim();
      if (paragraph) {
        paragraphs.push(paragraph);
      }
    }

    return paragraphs;
  }, [text, numberOfSentencesInParagraph]);

  return formattedText;
};
