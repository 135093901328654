import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';

const api = legalFlyConfig.api.paths;

export const createFavoritesApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const toggleFavorite = (body: BodyOf<'favorites/toggle/post'>) => {
    return client(api.favorites.toggle, {
      method: 'POST',
      body,
    });
  };

  return {
    toggleFavorite,
  };
};

export type FavoritesApi = ReturnType<typeof createFavoritesApi>;
