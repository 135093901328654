import { useTranslation } from 'react-i18next';

import type { ApiDraftStats } from '@legalfly/api/drafting';
import { Text } from '@legalfly/ui/text';
import {
  InsightsCard,
  InsightsCardFooter,
  InsightsCardHeader,
  InsightsCardIcon,
  InsightsCardTitle,
} from 'components/common/insights/InsightsCard';
import { OrganizationInsightsCard } from 'components/common/insights/OrganisationInsightsCard';

interface Props {
  stats: ApiDraftStats;
}

export const DraftsInsights = ({ stats }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Text className='mb-4' variant='bodyLargeLight'>
        {t('drafting.drafts.insights.title')}
      </Text>
      <div className='flex flex-col gap-3'>
        <InsightsCard>
          <InsightsCardHeader>
            <InsightsCardTitle>{t('drafting.drafts.insights.completedDrafts')}</InsightsCardTitle>
            <InsightsCardIcon name='check-done' />
          </InsightsCardHeader>
          <InsightsCardFooter>
            <Text variant='heading2'>{stats.completed.total}</Text>
            <Text variant='bodyLight'>{`${stats.completed.increase > 0 ? '+' : ''}${stats.completed.increase}%`}</Text>
          </InsightsCardFooter>
        </InsightsCard>

        <OrganizationInsightsCard stats={stats.organization} />
      </div>
    </>
  );
};
