import { queryOptions } from '@tanstack/react-query';

import type { SettingsApi } from '@legalfly/api/settings';

export const settingsQueryOptions = ({ settingsApi }: { settingsApi: SettingsApi }) => ({
  userSettings: () =>
    queryOptions({
      queryKey: ['userSettings'],
      queryFn: () => settingsApi.getUserSettings(),
    }),
});
