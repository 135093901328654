import { type ComponentProps, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { DocumentCategoryKey } from '@legalfly/api/documents';
import { DocumentCategory } from '@legalfly/api/documents';
import {
  Combobox,
  ComboboxContent,
  ComboboxInput,
  ComboboxList,
  ComboboxListItem,
  ComboboxTrigger,
} from '@legalfly/ui/combobox';
import { getKeys } from '@legalfly/utils/object';

interface Props {
  value: DocumentCategoryKey;
  onChange: (v: DocumentCategoryKey) => void;
  triggerProps?: ComponentProps<typeof ComboboxTrigger>;
  contentProps?: ComponentProps<typeof ComboboxContent>;
}

const SelectDocumentCategory = ({ value, onChange, triggerProps, contentProps }: Props) => {
  const { t } = useTranslation();

  const values = useMemo(() => {
    return getKeys(DocumentCategory)
      .map((key) => ({ key, label: t(`config.documentCategory.${key}`) }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [t]);

  return (
    <Combobox value={value} onChange={onChange}>
      <ComboboxTrigger {...triggerProps}>
        {value
          ? t(`config.documentCategory.${value}`)
          : t('config.documentCategory.UNDEFINED_DOCUMENT')}
      </ComboboxTrigger>
      <ComboboxContent {...contentProps}>
        <ComboboxInput />
        <ComboboxList>
          {values.map((value) => (
            <ComboboxListItem key={value.key} value={value.key}>
              {value.label}
            </ComboboxListItem>
          ))}
        </ComboboxList>
      </ComboboxContent>
    </Combobox>
  );
};

export default SelectDocumentCategory;
