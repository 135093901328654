import type { HTMLAttributes } from 'react';

import { cn } from '@legalfly/ui/utils';

export const BaseCopilotFooter = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn('relative', className)} {...props}>
      {children}
    </div>
  );
};

BaseCopilotFooter.displayName = 'BaseCopilotFooter';
