import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiTemplate } from '@legalfly/api/drafting';
import { trackEvent } from '@legalfly/reporting/tracking';
import { IconButton } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { Spinner } from '@legalfly/ui/spinner';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { draftingToasts, useDeleteTemplate } from 'core/modules/drafting';

interface Props {
  template: ApiTemplate;
}

const DraftingTemplateActionPopover = ({ template }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { deleteTemplate, isLoading: isLoadingDeleteDraftingTemplate } = useDeleteTemplate();

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <IconButton
          name='dots-horizontal'
          className={cn('rotate-90', isOpen && 'bg-fill-hover-strong')}
          variant='tertiary'
          size='sm'
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className='flex flex-col gap-2' align='end'>
        <DropdownMenuItem
          onClick={() => {
            trackEvent({
              action: 'click',
              category: 'templates',
              label: 'deleteTemplate',
            });
            withToasts(deleteTemplate({ uuid: template.uuid }))(
              draftingToasts.deleteDraftingTemplate(),
            );
          }}
        >
          {isLoadingDeleteDraftingTemplate ? (
            <Spinner />
          ) : (
            <Icon name='trash-2' className='text-icon-weak' />
          )}
          {t('action.deleteTemplate')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default DraftingTemplateActionPopover;
