import * as React from 'react';
import * as Portal from '@radix-ui/react-portal';
import * as ToastPrimitives from '@radix-ui/react-toast';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'utils';

import { Icon } from '../icon';
import { Text } from '../text';

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <Portal.Root className='fixed z-[60]'>
    <ToastPrimitives.Viewport
      ref={ref}
      className={cn(
        'fixed top-0 z-[60] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-auto sm:right-2 sm:top-20 sm:flex-col md:max-w-[420px]',
        className,
      )}
      {...props}
    />
  </Portal.Root>
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

// TODO: colors
// eslint-disable-next-line tailwindcss/no-custom-classname
const toastVariants = cva(
  'group !pointer-events-auto relative flex w-full flex-col justify-between space-x-4 overflow-hidden border border-stroke-frail bg-stroke-weaker p-6 pr-8 transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-bottom-full data-[state=open]:sm:slide-in-from-top-full',
  {
    variants: {
      variant: {
        default: 'default',
        success: 'success',
        warning: 'warning',
        danger: 'danger',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> &
    VariantProps<typeof toastVariants> & { onClose?: () => void }
>(({ className, variant, onClose, ...props }, ref) => {
  return (
    <ToastPrimitives.Root
      ref={ref}
      className={cn(
        toastVariants({ variant }),
        className,
        props.onClick ? 'cursor-pointer' : '',
        'light:bg-fill-maximal',
      )}
      {...props}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        const target = e.target as HTMLElement;
        if (props.onClick && !target.closest('[toast-close]')) {
          props.onClick(e);
          if (onClose) {
            onClose();
          }
          props.onOpenChange?.(false);
        }
      }}
    />
  );
});
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Action ref={ref} className={className} {...props} />
));
ToastAction.displayName = ToastPrimitives.Action.displayName;

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close> & { onClose?: () => void }
>(({ className, onClose, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cn('absolute right-2 top-2 rounded-md p-1', className)}
    toast-close=''
    onClick={() => {
      if (onClose) {
        onClose();
      }
    }}
    {...props}
  >
    <Icon name='x-close' />
  </ToastPrimitives.Close>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <div className={cn(className, 'mb-2 flex gap-2')} ref={ref}>
    <div
      className={cn(
        'mr-2 mt-2 h-3 min-h-3 w-3 min-w-3 rounded-full transition-shadow duration-700 group-[.default]:hidden',
        'group-[.danger]:bg-risk-fill-high dark:group-[.danger]:shadow-risk-glow-high',
        'group-[.warning]:bg-risk-fill-mid dark:group-[.warning]:shadow-risk-glow-mid',
        'group-[.success]:bg-risk-fill-low dark:group-[.success]:shadow-risk-glow-low',
      )}
    />
    <Text variant='subheading'>{props.children}</Text>
  </div>
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const ToastDescription = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description ref={ref} className={cn('opacity-70', className)} {...props} />
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>;

type ToastActionElement = React.ReactElement<typeof ToastAction>;

export {
  type ToastProps,
  type ToastActionElement,
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction,
};
