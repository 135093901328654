import { useQueryClient } from '@tanstack/react-query';

import type { ApiAnonymization } from '@legalfly/api/anonymization';
import type { ApiDocument } from '@legalfly/api/documents';
import { createAnonymizationModule } from '@legalfly/modules/anonymization';
import type { WebSocketCallback } from '@legalfly/websockets';
import { useWebSocketSubscriber } from '@legalfly/websockets';

import { anonymizationApi } from '../../di';

const anonymizationModule = createAnonymizationModule({
  anonymizationApi,
});

export const {
  anonymizationQueryOptions,
  useAnonymizations,
  useAnonymization,
  useCreateAnonymization,
  useDeleteAnonymization,
  useUpdateAnonymization,
} = anonymizationModule;

const useListenToAnonymizationDocumentStatus = (
  {
    document,
    anonymization,
  }: {
    document: ApiDocument;
    anonymization: ApiAnonymization;
  },
  callback?: WebSocketCallback<`document_upload_status_${string}`>,
) => {
  const queryClient = useQueryClient();

  useWebSocketSubscriber(`document_upload_status_${document.uuid}`, (data) => {
    queryClient.setQueryData(
      anonymizationQueryOptions.anonymization({ uuid: anonymization.uuid }).queryKey,
      (oldData) => {
        if (!oldData) return oldData;

        const updatedDocuments = oldData.documents.map((doc) =>
          doc.uuid === document.uuid ? { ...doc, status: data.status } : doc,
        );
        return { ...oldData, documents: updatedDocuments };
      },
    );

    callback?.(data);
  });
};

const ListenToAnonymizationDocumentStatus = ({
  document,
  anonymization,
}: {
  document: ApiDocument;
  anonymization: ApiAnonymization;
}) => {
  useListenToAnonymizationDocumentStatus({ document, anonymization });
  return null;
};

export const ListenToAnonymizationDocumentsStatus = ({
  anonymization,
}: {
  anonymization: ApiAnonymization;
}) =>
  anonymization.documents.map((document) => (
    <ListenToAnonymizationDocumentStatus
      key={document.uuid}
      document={document}
      anonymization={anonymization}
    />
  ));
