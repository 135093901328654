import { createFileRoute } from '@tanstack/react-router';

import { withSelectedDocuments } from 'components/documents/documentPicker/withSelectedDocuments';
import { documentsQueryOptions } from 'core/modules/documents';

import { ReviewStartRoute } from './index';

export const Route = createFileRoute('/_auth/_layout/review/start/$uuid')({
  component: withSelectedDocuments(ReviewStartNestedFolderRoute),
  loader: ({ context, params }) =>
    context.queryClient.ensureQueryData(documentsQueryOptions.folder(params.uuid)),
});

function ReviewStartNestedFolderRoute() {
  const { uuid } = Route.useParams();
  return <ReviewStartRoute uuid={uuid} />;
}
