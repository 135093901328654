import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';

export const createFeedbackApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const sendFeedback = (body: BodyOf<'feedback/post'>) => {
    return client(api.feedback.send, { method: 'POST', body });
  };

  return {
    sendFeedback,
  };
};

export type FeedbackApi = ReturnType<typeof createFeedbackApi>;
