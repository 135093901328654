import { queryOptions } from '@tanstack/react-query';

import type { StatisticsApi } from '@legalfly/api/statistics';

export const statisticsQueryOptions = ({ statisticsApi }: { statisticsApi: StatisticsApi }) => ({
  statistics: () =>
    queryOptions({
      queryKey: ['statistics'],
      queryFn: () => statisticsApi.getStatistics(),
    }),
});
