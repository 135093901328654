import { memo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import type { ApiClause } from '@legalfly/api/drafting';
import { IconButton } from '@legalfly/ui/button';
import { cn } from '@legalfly/ui/utils';

import ClauseItem from './ClauseItem';

interface Props {
  clause: ApiClause;
  onAddClause: (sequence: number) => void;
  onUpdateClause: (clause: ApiClause) => Promise<ApiClause>;
  onDeleteClause: (clauseUuid: ApiClause['uuid']) => void;
}

interface SortableClauseItemAddButtonProps {
  isSorting: boolean;
  onAddClause: VoidFunction;
}

const SortableClauseItemAddButton = ({
  isSorting,
  onAddClause,
}: SortableClauseItemAddButtonProps) => {
  return (
    <IconButton
      name='plus'
      className={cn(
        'w-full bg-transparent text-icon-strongest hover:bg-fill-strongest',
        isSorting && 'opacity-0',
      )}
      size='sm'
      onClick={onAddClause}
    />
  );
};

const SortableClauseItem = ({ clause, onAddClause, onUpdateClause, onDeleteClause }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isSorting, index, items } =
    useSortable({
      id: clause.uuid,
    });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className='relative mx-auto flex w-[calc(3rem+var(--lf-template-width))] flex-wrap gap-2 focus-within:z-10'
    >
      <SortableClauseItemAddButton
        isSorting={isSorting}
        onAddClause={() => onAddClause(clause.sequence)}
      />
      <div className='group w-full'>
        <div className='absolute -left-12 top-8 flex flex-col opacity-0 group-focus-within:opacity-100 group-hover:opacity-100 '>
          <IconButton
            size='sm'
            name='paragraph-spacing'
            className='bg-transparent text-icon-strongest'
            {...listeners}
            {...attributes}
          />
          <IconButton
            name='trash-2'
            size='sm'
            className={cn(
              'bg-transparent text-icon-strongest transition-opacity duration-300',
              isSorting && 'opacity-0',
            )}
            onClick={() => {
              onDeleteClause(clause.uuid);
            }}
          />
        </div>
        <ClauseItem clause={clause} onUpdateClause={onUpdateClause} />
      </div>
      {index === items.length - 1 && (
        <SortableClauseItemAddButton
          isSorting={isSorting}
          onAddClause={() => onAddClause(clause.sequence + 1)}
        />
      )}
    </div>
  );
};

export default memo(SortableClauseItem);
