import { useTranslation } from 'react-i18next';
import { useForm } from '@tanstack/react-form';

import type { ApiClause } from '@legalfly/api/drafting';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import {
  SidePaneClose,
  SidePaneContent,
  SidePaneHeader,
  SidePaneTrigger,
  useSidePaneContext,
} from '@legalfly/ui/sidePane';
import { Text } from '@legalfly/ui/text';
import { TextareaLineNumbers } from '@legalfly/ui/textarea';

interface FormData {
  instructions: string;
}

interface Props {
  clause: ApiClause;
  onSave: (instructions: string[]) => void;
}

export const ClauseInstructions = ({ clause, onSave }: Props) => {
  const { t } = useTranslation();
  const { onOpenToggle } = useSidePaneContext('ClauseInstructions');

  const form = useForm<FormData>({
    defaultValues: {
      instructions: clause.instructions.join('\n'),
    },
    onSubmit: ({ value }) => {
      onOpenToggle();
      onSave(value.instructions.split('\n'));
    },
  });

  return (
    <>
      <SidePaneTrigger asChild paneId={clause.uuid}>
        <Button
          variant='soft'
          size='sm'
          renderLeft={<Icon name='list' />}
          onClick={() => {
            trackEvent({
              action: 'click',
              category: 'templates',
              label: 'instructions',
            });
          }}
        >
          {t('drafting.templates.instructions.title')}
        </Button>
      </SidePaneTrigger>
      <SidePaneContent paneId={clause.uuid} className='h-content' size='md'>
        <SidePaneHeader>
          <Text as='p' variant='bodyLarge'>
            {t('drafting.templates.instructions.title')}
          </Text>
        </SidePaneHeader>
        <Text variant='bodyLight' className='mt-4 text-content-body-strong'>
          {t('drafting.templates.instructions.subtitle')}
        </Text>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
        >
          <form.Field name='instructions'>
            {(field) => (
              <div className='flex flex-col gap-4'>
                <Text variant='bodyLight' className='mt-4 text-content-body'>
                  {clause.title}
                </Text>
                <TextareaLineNumbers
                  rows={20}
                  value={field.state.value}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              </div>
            )}
          </form.Field>
          <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
            {([canSubmit, isSubmitting]) => (
              <div className='mt-4 flex gap-2'>
                <SidePaneClose asChild>
                  <Button variant='soft' renderLeft={<Icon name='x-close' />}>
                    {t('action.close')}
                  </Button>
                </SidePaneClose>
                <Button
                  type='submit'
                  variant='soft'
                  disabled={!canSubmit}
                  isLoading={isSubmitting}
                  renderLeft={<Icon name='check' />}
                >
                  {t('action.save')}
                </Button>
              </div>
            )}
          </form.Subscribe>
        </form>
      </SidePaneContent>
    </>
  );
};
