import { useEffect } from 'react';
import { Userpilot } from 'userpilot';

import { useAuth } from 'core/auth';
import { env } from 'core/env';

export const UserPilotInitializer = () => {
  const { user } = useAuth();

  useEffect(() => {
    if (!user?.sub) return;

    Userpilot.initialize(env.userPilot);
    Userpilot.identify(user.sub);
  }, [user?.sub]);

  return null;
};
