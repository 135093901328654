import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiDocumentSnippetEntity } from '@legalfly/api/documents';
import { Card } from '@legalfly/ui/card';
import { Icon } from '@legalfly/ui/icon';
import { SidePaneContent, SidePaneHeader } from '@legalfly/ui/sidePane';
import { Text } from '@legalfly/ui/text';
import { DocumentEntityForm } from 'components/documents/detail/DocumentEntityForm';
import { useDocumentContext } from 'components/documents/detail/DocumentProvider';

interface Props {
  selectedEntity: Partial<ApiDocumentSnippetEntity> | null;
  setSelectedEntity: Dispatch<SetStateAction<ApiDocumentSnippetEntity | null>>;
}

export const AnonymisationSidePane = ({ selectedEntity, setSelectedEntity }: Props) => {
  const { t } = useTranslation();
  const { document } = useDocumentContext();

  return (
    <SidePaneContent paneId={selectedEntity?.uuid} className='h-content' size='md'>
      <SidePaneHeader className='flex flex-col items-start gap-4'>
        <Text variant='bodyLarge'>{t('anonymisation.sidePane.title')}</Text>
        <Text variant='bodyLight' className='text-content-body-strong'>
          {t('anonymisation.sidePane.subtitle')}
        </Text>
      </SidePaneHeader>
      {selectedEntity && (
        <Card className='mt-4 bg-fill-strongest'>
          <div className='flex w-full items-center justify-between'>
            <Text>{t('anonymisation.sidePane.selected')}</Text>
            <Icon name='check-done' />
          </div>
          <DocumentEntityForm
            selectedEntity={selectedEntity}
            documentUuid={document.uuid}
            onSubmitComplete={() => setSelectedEntity(null)}
          />
        </Card>
      )}
    </SidePaneContent>
  );
};
