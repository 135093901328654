import type { QueryClient } from '@tanstack/react-query';
import { redirect } from '@tanstack/react-router';

import type { FeatureFlagType } from '@legalfly/api/core';
import { configQueryOptions, useConfig } from 'core/modules/config';

export function useFeatureFlag<T extends FeatureFlagType>(flag: T) {
  const { data } = useConfig({ select: (data) => data.flags.includes(flag) });

  return data;
}

export function withFeatureFlag<T extends FeatureFlagType, P extends { [K in T]: boolean }>(
  WrappedComponent: React.ComponentType<P>,
  flag: T,
) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithFeatureFlag = (props: Omit<P, T>) => {
    const featureFlagValue = useFeatureFlag(flag);

    if (!featureFlagValue) {
      return null;
    }

    return <WrappedComponent {...(props as P)} {...({ [flag]: featureFlagValue } as P)} />;
  };

  ComponentWithFeatureFlag.displayName = `withFeatureFlag(${displayName})`;

  return ComponentWithFeatureFlag;
}

export const routeRedirectWithFeatureFlag = async (
  queryClient: QueryClient,
  flag: FeatureFlagType,
) => {
  // select does not work with ensureQueryData
  const { flags } = await queryClient.ensureQueryData(configQueryOptions.config());

  if (!flags.includes(flag)) {
    throw redirect({ to: '/' });
  }
};
