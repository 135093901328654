import i18n from 'i18next';

import { createToasts } from '@legalfly/ui/toast';

export const feedbackToasts = createToasts({
  sendFeedback: () => ({
    successToast: () => ({
      title: i18n.t('toast.success.sendFeedback.title'),
      description: i18n.t('toast.success.sendFeedback.description'),
    }),
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.sendFeedback.description'),
    }),
  }),
});
