import { useTranslation } from 'react-i18next';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

import { Button } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { SidePaneContent, SidePaneHeader, SidePaneProvider } from '@legalfly/ui/sidePane';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';
import { EditableAnonymizationName } from 'components/anonymization/detail/EditableAnonymizationName';
import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { DocumentsDetailPane } from 'components/documents/detailPane/DocumentsDetailPane';
import { useSelectedDocuments } from 'components/documents/documentPicker/SelectedDocumentsProvider';
import { withSelectedDocuments } from 'components/documents/documentPicker/withSelectedDocuments';
import { DocumentsList } from 'components/documents/DocumentsList';
import {
  DocumentsUploadProgress,
  useDocumentsUploadProgress,
} from 'components/documents/DocumentsUploadProgress';
import {
  anonymizationQueryOptions,
  ListenToAnonymizationDocumentsStatus,
  useAnonymization,
} from 'core/modules/anonymization';
import { getDocumentIcon } from 'core/modules/documents/helpers';

export const Route = createFileRoute('/_auth/_layout/anonymisation/$uuid')({
  component: withSelectedDocuments(AnonymisationRoute),
  loader({ context, params }) {
    return context.queryClient.ensureQueryData(
      anonymizationQueryOptions.anonymization({ uuid: params.uuid }),
    );
  },
});

function AnonymisationRoute() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uuid } = Route.useParams();

  const { anonymization } = useAnonymization({ uuid });

  const handleBackPress = () => {
    navigate({
      to: '/anonymisation',
    });
  };

  const { selectedDocuments, clearSelectedDocuments } = useSelectedDocuments();
  const selectedDocument = selectedDocuments[0];

  const documentUploadProgress = useDocumentsUploadProgress(
    anonymization.documents,
    anonymization.documents.length,
  );

  return (
    <div className='flex flex-col gap-3'>
      <SidePaneProvider
        open={Boolean(selectedDocument)}
        onOpenChange={(open) => {
          if (!open) {
            clearSelectedDocuments();
          }
        }}
      >
        <Content
          innerClassName='p-0'
          className={cn(
            !documentUploadProgress.isUploading ? 'h-content' : 'h-content-with-progress',
          )}
        >
          <ContentHeader>
            <ContentHeader.Title>{t('anonymisation.title')}</ContentHeader.Title>
            <ContentHeader.SubTitle asChild>
              <EditableAnonymizationName anonymization={anonymization} />
            </ContentHeader.SubTitle>
            <ContentHeader.Actions>
              <Button onClick={handleBackPress} variant='soft'>
                {t('action.back')}
              </Button>
            </ContentHeader.Actions>
          </ContentHeader>
          <ListenToAnonymizationDocumentsStatus anonymization={anonymization} />
          <DocumentsList documents={anonymization.documents} allowDelete={false} />
          <SidePaneContent className='h-content'>
            <SidePaneHeader>
              <div className='flex gap-2'>
                {selectedDocument && <Icon name={getDocumentIcon(selectedDocument)} />}
                <Text as='h3'>{selectedDocument?.name}</Text>
              </div>
            </SidePaneHeader>
            {selectedDocument && <DocumentsDetailPane documents={[selectedDocument]} />}
          </SidePaneContent>
        </Content>
      </SidePaneProvider>
      <DocumentsUploadProgress {...documentUploadProgress} />
    </div>
  );
}
