import * as React from 'react';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from 'utils';

const spinnerVariants = cva('animate-spinner', {
  variants: {
    size: {
      sm: 'h-4 w-1.5',
      md: 'h-6 w-1.5',
      lg: 'h-24 w-4',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

interface Props extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof spinnerVariants> {
  innerClassName?: string;
}

const Spinner = React.forwardRef<HTMLDivElement, Props>(
  ({ innerClassName, className, size = 'sm', ...rest }, ref) => {
    return (
      <div ref={ref} className={cn('flex items-center justify-center', className)} {...rest}>
        <div className='flex space-x-1'>
          <div className={cn(spinnerVariants({ size, className: innerClassName }))} />
          <div
            className={cn(spinnerVariants({ size, className: innerClassName }))}
            style={{ animationDelay: '0.08s' }}
          />
          <div
            className={cn(spinnerVariants({ size, className: innerClassName }))}
            style={{ animationDelay: '0.16s' }}
          />
          <div
            className={cn(spinnerVariants({ size, className: innerClassName }))}
            style={{ animationDelay: '0.24s' }}
          />
          <div
            className={cn(spinnerVariants({ size, className: innerClassName }))}
            style={{ animationDelay: '0.32s' }}
          />
        </div>
      </div>
    );
  },
);

Spinner.displayName = 'Spinner';

export { Spinner, spinnerVariants };
