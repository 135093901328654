import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  ApiConversationMessage,
  ApiConversationMessageSourceDocument,
  ApiConversationMessageSourceFullDocument,
} from '@legalfly/api/conversations';
import { Button } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { Sheet, SheetTrigger } from '@legalfly/ui/sheet';
import { Text } from '@legalfly/ui/text';
import { DocumentSnippetsViewerSheetContent } from 'components/documents/DocumentSnippetsViewerSheetContent';
import { useFile } from 'core/modules/documents';

interface Snippet {
  text: string;
  uuid: string;
}

interface GroupedResult {
  uuid: string;
  title: string;
  snippets: Snippet[];
}

const groupResultsByUuid = (
  source: ApiConversationMessageSourceDocument | ApiConversationMessageSourceFullDocument,
): GroupedResult[] => {
  const groupedMap: Record<string, GroupedResult> = {};

  const addToGroup = (uuid: string, title: string, snippet: Snippet) => {
    if (!groupedMap[uuid]) {
      groupedMap[uuid] = { uuid, title, snippets: [] };
    }
    groupedMap[uuid].snippets.push(snippet);
  };

  if (source.type === 'document') {
    source.results.forEach(({ documentUuid, title, snippet, uuid }) => {
      addToGroup(documentUuid, title, { text: snippet, uuid });
    });
  } else if (source.type === 'full-document') {
    source.results.forEach(({ snippet, uuid }) => {
      addToGroup(source.documentUuid, source.title, { text: snippet, uuid });
    });
  }

  return Object.values(groupedMap);
};

const GroupedDocument = ({
  result,
  question,
  answer,
}: { result: GroupedResult } & Pick<Props, 'question' | 'answer'>) => {
  const { t } = useTranslation();
  const { file } = useFile(result.uuid);
  const [activeSnippetTextId, setActiveSnippetTextId] = useState(result.snippets[0]?.uuid);

  useEffect(() => {
    setActiveSnippetTextId(result.snippets[0]?.uuid);
  }, [result]);

  const snippetIds = result.snippets.map(({ uuid }) => uuid);

  return (
    <Sheet>
      <SheetTrigger asChild>
        <div className='flex cursor-pointer flex-col bg-fill-strongest p-6 transition-all duration-300 ease-in-out'>
          <div className='flex justify-between'>
            <div className='flex gap-2'>
              <Icon name='file-pdf' className='flex-shrink-0' />
              <Text variant='body' className='line-clamp-1'>
                {result.title}
              </Text>
            </div>
          </div>
          <div className='mt-6 flex flex-col gap-5'>
            <Button variant='soft' className='self-start text-content-body-weak'>
              {t('action.viewDocumentHighlights', { count: result.snippets.length })}
            </Button>
          </div>
        </div>
      </SheetTrigger>
      <DocumentSnippetsViewerSheetContent
        document={file}
        snippetIds={snippetIds}
        activeSnippetTextId={activeSnippetTextId}
        question={question}
        answer={answer}
        setActiveSnippetTextId={setActiveSnippetTextId}
      />
    </Sheet>
  );
};

interface Props {
  source: ApiConversationMessageSourceDocument | ApiConversationMessageSourceFullDocument;
  question: ApiConversationMessage['question'];
  answer: ApiConversationMessage['assistantMessage'];
}

export const DocumentSource = ({ source, question, answer }: Props) => {
  const groupedResults = useMemo(() => groupResultsByUuid(source), [source]);

  return groupedResults.map((result) => (
    <GroupedDocument key={result.uuid} result={result} question={question} answer={answer} />
  ));
};
