import { useEffect } from 'react';

import { createWebsocketModule } from '@legalfly/modules/websocket';
import { WebSocketManagerInstance } from '@legalfly/websockets';

import { websocketApi } from '../../di';

export const { useWebsocketPolling, websocketQueryOptions } = createWebsocketModule({
  websocketApi,
});

export const usePublishWebSocketMessages = (pollingEnabled: boolean) => {
  const { messages } = useWebsocketPolling(pollingEnabled);

  useEffect(() => {
    if (messages.length > 0) {
      messages.forEach((message: any) => {
        WebSocketManagerInstance.publish(message.type, message.data);
      });
    }
  }, [messages]);
};
