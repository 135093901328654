import type { ApiAgentDetail } from '@legalfly/api/agents';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { Text } from '@legalfly/ui/text';

interface Props {
  agent: ApiAgentDetail;
  onPromptClick: (prompt: string) => void;
}

export const AgentConversationEmptyState = ({ agent, onPromptClick }: Props) => {
  return (
    <div className='self-center'>
      <Text as='h1' className='mb-5 text-heading2'>
        {agent.name}
      </Text>
      <Text variant='bodyLarge' className='mb-2 text-content-body-strong'>
        {agent.description}
      </Text>
      {agent.conversationStarters.length > 0 && (
        <div className='flex flex-row gap-3 py-5'>
          {agent.conversationStarters.map((starter) => (
            <Button
              key={starter.name}
              variant='soft'
              className='flex-1 bg-transparent'
              onClick={() => {
                trackEvent({
                  action: 'click',
                  category: 'agents',
                  label: 'prompt',
                });
                onPromptClick(starter.prompt);
              }}
            >
              {starter.name}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};
