import { forwardRef, type PropsWithChildren, type ReactNode } from 'react';
import { Slot } from '@radix-ui/react-slot';
import type { LinkProps } from '@tanstack/react-router';
import { Link } from '@tanstack/react-router';

import { cn } from '@legalfly/ui/utils';

const NavigationMenu = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
  <nav className={cn(className)}>{children}</nav>
);

const NavigationMenuContent = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <div className={cn('flex flex-col gap-2 group-[.open]:gap-2', className)}>{children}</div>
);

interface Props extends Omit<LinkProps, 'children'> {
  asChild?: boolean;
  children?: ReactNode;
  isActive?: boolean;
  className?: string;
}

const NavigationMenuItem = forwardRef<HTMLAnchorElement, Props>(
  ({ asChild, isActive, className, ...props }, ref) => {
    const Comp = asChild ? Slot : Link;

    return (
      <Comp
        ref={ref}
        className={cn(
          'flex items-center gap-2 py-2 text-body-large transition-colors duration-100 hover:bg-fill-hover-strong active:bg-fill-pressed-strong group-[.open]:px-6',
          'w-full justify-center group-[.open]:justify-start',
          'focus-visible:bg-fill-hover-strong focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-4 focus-visible:outline-fill-pressed-strong',
          isActive && 'bg-fill-focus-strong',
          className,
        )}
        {...props}
      />
    );
  },
);

NavigationMenuItem.displayName = 'NavigationMenuItem';

NavigationMenu.Content = NavigationMenuContent;
NavigationMenu.Item = NavigationMenuItem;

export { NavigationMenu };
