import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardFooter, CardIcon, CardSubtitle, CardTitle } from '@legalfly/ui/card';
import { ErrorCard } from '@legalfly/ui/error';

interface Props {
  children: ReactNode;
}

export const PlaybookItemsListEmpty = ({ children }: Props) => {
  const { t } = useTranslation();

  return (
    <ErrorCard className='h-content-with-row'>
      <Card>
        <CardIcon name='book-open' />
        <CardTitle className='mb-2'>{t('playbooks.overview.empty.title')}</CardTitle>
        <CardSubtitle className='max-w-[40ch] text-center'>
          {t('playbooks.overview.empty.description')}
        </CardSubtitle>
        <CardFooter className='gap-3'>{children}</CardFooter>
      </Card>
    </ErrorCard>
  );
};
