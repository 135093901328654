import { useTranslation } from 'react-i18next';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import type { ApiPlaybookItem } from '@legalfly/api/playbooks';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@legalfly/ui/hoverCard';
import { Icon } from '@legalfly/ui/icon';
import { ListItem } from '@legalfly/ui/list';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';
import { formatLongDate, formatRelativeTime } from '@legalfly/utils/date';

import { usePlaybookItemActions, usePlaybookItemState } from './context/PlaybookItemProvider';
import { PlaybookItemsListMenu } from './PlaybookItemsListMenu';

interface Props {
  item: ApiPlaybookItem;
}

export const PlaybookItemsListItem = ({ item }: Props) => {
  const { setSelectedPlaybookItem } = usePlaybookItemActions();
  const { selectedPlaybookItem, playbookUuid } = usePlaybookItemState();

  const { t, i18n } = useTranslation();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.uuid,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  let errorMessage = '';
  if (!item.name && !item.question) {
    errorMessage = t('playbooks.form.nameAndQuestionRequired');
  } else if (!item.name) {
    errorMessage = t('form.name.validation.required');
  } else if (!item.question) {
    errorMessage = t('form.question.validation.required');
  }
  const isValid = !errorMessage;

  return (
    <ListItem
      key={item.uuid}
      onClick={(e) => {
        if (!(e.target as HTMLElement).closest('[data-dropdown-menu]')) {
          setSelectedPlaybookItem(item);
        }
      }}
      className={cn(
        'select-none',
        selectedPlaybookItem?.uuid === item.uuid && 'bg-fill-hover-weak',
      )}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {isValid ? (
        <div className='me-2 flex flex-1 items-center gap-2'>
          <Icon
            name={isValid ? 'list' : 'alert-circle'}
            className={cn('min-h-5 min-w-5', !isValid && 'text-icon-error')}
          />
          <span className='text-body-semibold'>{item.name}</span>
        </div>
      ) : (
        <HoverCard openDelay={100}>
          <HoverCardTrigger className='flex items-center gap-2'>
            <div>
              <Icon
                name={isValid ? 'list' : 'alert-circle'}
                className={cn('min-h-5 min-w-5', !isValid && 'text-icon-error')}
              />
              <span className='text-body-semibold'>{item.name}</span>
            </div>
          </HoverCardTrigger>
          <HoverCardContent className='px-3'>
            <span>{errorMessage}</span>
          </HoverCardContent>
        </HoverCard>
      )}

      <div className='flex items-center gap-2'>
        <Text
          className='text-content-body-weak'
          title={formatLongDate(item.createdAt, i18n.language)}
        >
          {formatRelativeTime(item.createdAt, i18n.language)}
        </Text>
        <PlaybookItemsListMenu
          playbookUuid={playbookUuid}
          itemUuid={item.uuid}
          afterDelete={() => {
            if (item.uuid === selectedPlaybookItem?.uuid) {
              setSelectedPlaybookItem(undefined);
            }
          }}
        />
      </div>
    </ListItem>
  );
};
