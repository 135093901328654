import { useEffect } from 'react';

import type { WebSocketMessageType } from '@legalfly/api/websocket';

import type { WebSocketCallback } from './WebSocketManager';
import { WebSocketManagerInstance } from './WebSocketManager';

export const useWebSocketSubscriber = <T extends keyof WebSocketMessageType>(
  eventType: T,
  callback: WebSocketCallback<T>,
) => {
  useEffect(() => {
    WebSocketManagerInstance.subscribe(eventType, callback);

    return () => {
      WebSocketManagerInstance.unsubscribe(eventType, callback);
    };
  }, [eventType, callback]);
};
