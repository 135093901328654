import { useTranslation } from 'react-i18next';
import type { FormApi } from '@tanstack/react-form';
import { useForm } from '@tanstack/react-form';

import { Icon } from '@legalfly/ui/icon';
import { Input } from '@legalfly/ui/input';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';
import { formatLongDate, formatRelativeTime } from '@legalfly/utils/date';
import { requiredValidator } from 'components/common/form/validations';

interface Props {
  title: string;
  createdAt: Date;
  onChangeTitle: (title: string) => void;
  className?: string;
}

export const DraftingHeader = ({ title, createdAt, onChangeTitle, className }: Props) => {
  const { t, i18n } = useTranslation();

  const defaultValues = {
    title,
  };

  type FormValues = typeof defaultValues;

  const handleSubmit = async ({
    value,
    formApi,
  }: {
    value: FormValues;
    formApi: FormApi<FormValues>;
  }) => {
    if (formApi.state.isDirty && value.title !== title) {
      onChangeTitle(value.title);
    }
  };

  const form = useForm<FormValues>({
    defaultValues,
    onSubmit: handleSubmit,
  });

  return (
    <div
      className={cn(
        'mx-auto mb-5 flex min-h-24 justify-between bg-gradient-to-r from-fill-strongest from-60% to-fill-lemon/15 px-6 py-4',
        className,
      )}
    >
      <form
        className='w-full self-end pe-2'
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <form.Field
          name='title'
          validators={{
            onBlur: ({ value }) => requiredValidator('drafting')(value),
          }}
        >
          {(field) => (
            <div className='flex w-full gap-2'>
              <Icon name='edit' className='text-icon-weak' />
              <Input
                containerClassName='w-full'
                id={field.name}
                name={field.name}
                value={field.state.value}
                onChange={(e) => field.handleChange(e.target.value)}
                onBlur={() => {
                  field.handleBlur();
                  form.handleSubmit();
                }}
                placeholder={t('drafting.templates.form.name.placeholder')}
                underlineOnError={field.state.meta.errors.length > 0}
                className={cn(
                  'm-0 h-auto w-full truncate border-none bg-transparent p-0 font-writer text-heading uppercase focus-visible:outline-offset-8',
                )}
              />
            </div>
          )}
        </form.Field>
      </form>
      <div className='flex flex-col items-end justify-between'>
        <Text
          variant='captionLight'
          className='text-content-body-weak'
          title={formatLongDate(createdAt, i18n.language)}
        >
          {formatRelativeTime(createdAt, i18n.language)}
        </Text>
        <Text className='font-writer'>Legalfly</Text>
      </div>
    </div>
  );
};
