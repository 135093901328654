import type { ComponentProps, CSSProperties } from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

import { TableRow } from '@legalfly/ui/table';
import { cn } from '@legalfly/ui/utils';

export const DraggableTableRow = ({
  id,
  isDroppable,
  className,
  ...props
}: ComponentProps<typeof TableRow> & {
  id: string;
  isDroppable?: boolean;
}) => {
  const {
    transform,
    setNodeRef: setDraggableRef,
    isDragging,
    attributes,
    listeners,
  } = useDraggable({ id });

  const { isOver, setNodeRef: setDroppableRef } = useDroppable({
    id,
    data: {
      isDroppable,
    },
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
  };

  return (
    <TableRow
      ref={(el) => {
        setDraggableRef(el);
        setDroppableRef(el);
      }}
      style={style}
      className={cn(
        'relative',
        isOver && isDroppable && !isDragging && 'bg-fill-hover-strongest',
        isDragging && 'z-10',
        className,
      )}
      {...attributes}
      {...listeners}
      {...props}
    />
  );
};
