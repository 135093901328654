import { createFileRoute } from '@tanstack/react-router';

import { AgentConversation } from 'components/agents/conversation/AgentConversation';
import { agentsQueryOptions, useAgent, useAgentConversations } from 'core/modules/agents';

export const Route = createFileRoute('/_auth/_layout/agents/$agentUuid/')({
  async loader({ context, params }) {
    const agent = await context.queryClient.ensureQueryData(
      agentsQueryOptions.agent({ uuid: params.agentUuid }),
    );

    const conversations = await context.queryClient.ensureQueryData(
      agentsQueryOptions.agentConversations({ uuid: params.agentUuid }),
    );

    return { agent, conversations };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { agentUuid } = Route.useParams();
  const { agent } = useAgent({ uuid: agentUuid });
  const { conversations } = useAgentConversations({ uuid: agentUuid });

  return <AgentConversation agent={agent} conversations={conversations} />;
}
