import type { ReactNode } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiPlaybook } from '@legalfly/api/playbooks';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Divider } from '@legalfly/ui/divider';
import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { PlaybookListItem } from 'components/playbooks/common/PlaybookListItem';
import { SelectPlaybook } from 'components/playbooks/common/SelectPlaybook';

interface Props {
  children: (props: { playbooks: ApiPlaybook[] }) => ReactNode;
}

export const MultiReviewSelectPlaybooks = ({ children }: Props) => {
  const { t } = useTranslation();

  const [playbooks, setPlaybooks] = useState<ApiPlaybook[]>([]);
  const [activePlaybooks, setActivePlaybooks] = useState<ApiPlaybook[]>([]);

  return (
    <div className='flex-1'>
      <Content>
        <ContentHeader>
          <ContentHeader.Title variant='heading'>
            {t('multiReview.playbook.title')}
          </ContentHeader.Title>
          <ContentHeader.SubTitle variant='body'>
            {t('multiReview.playbook.subtitle')}
          </ContentHeader.SubTitle>
        </ContentHeader>
        <SelectPlaybook
          triggerProps={{ size: 'md', className: 'mb-5' }}
          selectedPlaybooks={activePlaybooks}
          onChange={(playbook) => {
            trackEvent({
              action: 'click',
              category: 'multiReviewStart',
              label: 'addPlaybook',
            });
            setPlaybooks([...playbooks, playbook]);
            setActivePlaybooks([...activePlaybooks, playbook]);
          }}
        />
        <div className='flex flex-col gap-3'>
          {playbooks.map((playbook) => {
            const isChecked = activePlaybooks.some((p) => p.uuid === playbook.uuid);

            return (
              <PlaybookListItem
                key={playbook.uuid}
                playbook={playbook}
                checked={isChecked}
                onCheckedChange={(checked) => {
                  trackEvent(
                    {
                      action: 'click',
                      category: 'multiReviewStart',
                      label: checked ? 'selectPlaybook' : 'deselectPlaybook',
                    },
                    { status: playbook.status },
                  );

                  const updatedActiveIds = checked
                    ? [...activePlaybooks, playbook]
                    : activePlaybooks.filter((p) => p.uuid !== playbook.uuid);

                  setActivePlaybooks(updatedActiveIds);
                }}
                onRemove={() => {
                  trackEvent({
                    action: 'click',
                    category: 'multiReviewStart',
                    label: 'removePlaybook',
                  });

                  setActivePlaybooks(activePlaybooks.filter((p) => p.uuid !== playbook.uuid));
                  setPlaybooks(playbooks.filter((p) => p.uuid !== playbook.uuid));
                }}
              />
            );
          })}
        </div>
        {playbooks.length > 0 && <Divider variant='weak' className='my-5' />}
        {children({ playbooks: activePlaybooks })}
      </Content>
    </div>
  );
};
