import type { SearchSchemaInput } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';

import { withSelectedDocuments } from 'components/documents/documentPicker/withSelectedDocuments';
import { documentsQueryOptions } from 'core/modules/documents';

import { MultiReviewStart } from './index';

export const Route = createFileRoute('/_auth/_layout/multi-review/start/$uuid')({
  validateSearch: (search: { documentUuids?: string[] } & SearchSchemaInput) => {
    return {
      documentUuids: search?.documentUuids ?? [],
    };
  },
  component: withSelectedDocuments(MultiReviewStartNestedFolderRoute),
  loader: ({ context, params }) =>
    context.queryClient.ensureQueryData(documentsQueryOptions.folder(params.uuid)),
});

function MultiReviewStartNestedFolderRoute() {
  const { uuid } = Route.useParams();
  const { documentUuids } = Route.useSearch();

  return <MultiReviewStart uuid={uuid} documentUuids={documentUuids} />;
}
