import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from '../../utils';

const dividerVariants = cva('', {
  variants: {
    orientation: {
      horizontal: 'h-px w-full',
      vertical: 'w-px',
    },
    variant: {
      strong: 'bg-stroke-weak',
      weak: 'bg-stroke-weaker',
    },
  },
  defaultVariants: {
    orientation: 'horizontal',
    variant: 'strong',
  },
});

type Props = HTMLAttributes<HTMLDivElement> & VariantProps<typeof dividerVariants>;

const Divider = forwardRef<HTMLDivElement, Props>(
  ({ className, variant, orientation, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(dividerVariants({ variant, orientation, className }))}
        {...rest}
      />
    );
  },
);

Divider.displayName = 'Divider';

export { Divider };
