import type { MouseEvent, ReactNode } from 'react';
import type React from 'react';
import { createContext, useContext, useState } from 'react';

import { IconButton } from '@legalfly/ui/button';
import type { IconName } from '@legalfly/ui/icon';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';

const MAX_SNIPPETS = 5;

interface SourceItem {
  title: string;
  url: string | null;
  snippets: string[];
}

interface ConversationMessageSourceContextType {
  result: SourceItem;
  currentSnippetIndex: number;
  direction: 'left' | 'right';
  handlePrevSnippet: (e: MouseEvent<HTMLButtonElement>) => void;
  handleNextSnippet: (e: MouseEvent<HTMLButtonElement>) => void;
}

const ConversationMessageSourceContext = createContext<
  ConversationMessageSourceContextType | undefined
>(undefined);

const useConversationMessageSource = () => {
  const context = useContext(ConversationMessageSourceContext);
  if (!context) {
    throw new Error(
      'ConversationMessageSource components must be used within a ConversationMessageSource.Root',
    );
  }
  return context;
};

export const ConversationMessageSource = ({
  result,
  children,
}: {
  result: SourceItem;
  children: ReactNode;
}) => {
  const [currentSnippetIndex, setCurrentSnippetIndex] = useState(0);
  const [direction, setDirection] = useState<'left' | 'right'>('left');

  const handlePrevSnippet = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (currentSnippetIndex > 0) {
      setDirection('right');
      setCurrentSnippetIndex((prev) => prev - 1);
    }
  };

  const handleNextSnippet = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (currentSnippetIndex < result.snippets.length - 1) {
      setDirection('left');
      setCurrentSnippetIndex((prev) => prev + 1);
    }
  };

  const value = {
    currentSnippetIndex,
    direction,
    handlePrevSnippet,
    handleNextSnippet,
    result,
  };

  return (
    <ConversationMessageSourceContext.Provider value={value}>
      <a
        className='flex flex-col bg-fill-strongest p-6 transition-all duration-300 ease-in-out'
        href={result.url ?? ''}
        target='_blank'
        rel='noreferrer'
      >
        {children}
      </a>
    </ConversationMessageSourceContext.Provider>
  );
};

interface TitleProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: IconName;
}

const Title = ({ icon, ...props }: TitleProps) => {
  const { result } = useConversationMessageSource();

  return (
    <div className='flex justify-between' {...props}>
      <div className='flex gap-2'>
        <Icon name={icon} className='flex-shrink-0' />
        <Text variant='body' className='line-clamp-1'>
          {result.title}
        </Text>
      </div>
    </div>
  );
};

interface SnippetProps {
  children: (snippet: string) => ReactNode;
}

const Snippet = ({ children }: SnippetProps) => {
  const { currentSnippetIndex, direction, result } = useConversationMessageSource();

  const snippets = result.snippets.slice(0, MAX_SNIPPETS);

  return (
    <div className={cn('w-full overflow-hidden', snippets.length > 1 && 'h-24')}>
      <div
        key={currentSnippetIndex}
        className={cn(
          'line-clamp-5 w-full text-content-body-strong duration-300 animate-in',
          direction === 'left' ? 'slide-in-from-right' : 'slide-in-from-left',
        )}
      >
        {children(snippets[currentSnippetIndex])}
      </div>
    </div>
  );
};

const Navigation = () => {
  const { currentSnippetIndex, handlePrevSnippet, handleNextSnippet, result } =
    useConversationMessageSource();

  if (result.snippets.length <= 1) return null;

  return (
    <div className='flex items-center gap-2'>
      <IconButton
        name='chevron-left'
        variant='tertiary'
        size='sm'
        onClick={handlePrevSnippet}
        disabled={currentSnippetIndex === 0}
      />
      <Text variant='body' className='mx-2 text-content-body-strong'>
        {currentSnippetIndex + 1} / {Math.min(result.snippets.length, MAX_SNIPPETS)}
      </Text>
      <IconButton
        name='chevron-right'
        variant='tertiary'
        size='sm'
        onClick={handleNextSnippet}
        disabled={currentSnippetIndex === MAX_SNIPPETS - 1}
      />
    </div>
  );
};

interface ContentProps extends React.HTMLAttributes<HTMLDivElement> {}

const Content = ({ children, ...props }: ContentProps) => {
  const { result } = useConversationMessageSource();

  if (result.snippets.length === 0) return null;

  return (
    <div className='mt-6 flex flex-col gap-5' {...props}>
      {children}
    </div>
  );
};

ConversationMessageSource.Title = Title;
ConversationMessageSource.Snippet = Snippet;
ConversationMessageSource.Navigation = Navigation;
ConversationMessageSource.Content = Content;
