import { createFileRoute } from '@tanstack/react-router';

import { withSelectedDocuments } from 'components/documents/documentPicker/withSelectedDocuments';
import { documentsQueryOptions } from 'core/modules/documents';

import { DocumentsFolderRoute } from './index';

export const Route = createFileRoute('/_auth/_layout/documents/folder/$uuid')({
  loader: ({ context, params }) =>
    context.queryClient.ensureQueryData(documentsQueryOptions.folder(params.uuid)),
  component: withSelectedDocuments(DocumentsNestedFolderRoute),
});

function DocumentsNestedFolderRoute() {
  const { uuid } = Route.useParams();
  return <DocumentsFolderRoute uuid={uuid} />;
}
