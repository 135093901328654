import { useQuery } from '@tanstack/react-query';

import type { WebSocketApi } from '@legalfly/api/websocket';

import { websocketQueryOptions } from './websocketQueryOptions';

export const createWebsocketModule = ({ websocketApi }: { websocketApi: WebSocketApi }) => {
  const queryOptions = websocketQueryOptions({ websocketApi });

  const useWebsocketPolling = (isEnabled: boolean) => {
    const { data } = useQuery(queryOptions.polling(isEnabled));

    return {
      messages: data ?? [],
    };
  };

  return {
    useWebsocketPolling,
    websocketQueryOptions: queryOptions,
  };
};
