import type { PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { posthog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { DialogProvider } from '@legalfly/ui/dialog';
import { Toaster } from '@legalfly/ui/toast';
import { UserPilotInitializer } from 'core/userpilot/UserPilotInitializer';

import { AppRouter } from './AppRouter';
import { AuthProvider } from './AuthProvider';

const queryClient = new QueryClient();

export const AppProviders = ({ children }: PropsWithChildren) => {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <PostHogProvider client={posthog}>
          <DialogProvider>
            <ReactQueryDevtools initialIsOpen={false} />
            <AppRouter queryClient={queryClient}>
              <UserPilotInitializer />
            </AppRouter>
            <Toaster />
            {children}
          </DialogProvider>
        </PostHogProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
};
