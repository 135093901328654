import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { Jurisdiction } from '@legalfly/api/core';
import { Icon } from '@legalfly/ui/icon';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@legalfly/ui/select';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type Props = {
  value: Jurisdiction | undefined;
  onChange: (v: Jurisdiction) => void;
  triggerProps?: ComponentProps<typeof SelectTrigger>;
  contentProps?: ComponentProps<typeof SelectContent>;
};

export const JurisdictionIcon = ({ jurisdiction }: { jurisdiction: Jurisdiction }) => {
  return <Icon name={jurisdiction === Jurisdiction.NONE ? 'globe' : `flag-${jurisdiction}`} />;
};

const SelectJurisdiction = ({ value, onChange, triggerProps, contentProps }: Props) => {
  const { t } = useTranslation();

  return (
    <Select
      value={value}
      onValueChange={(v) => {
        const jurisdiction = v as Jurisdiction;
        onChange(jurisdiction);
      }}
    >
      <SelectTrigger className={cn('flex w-fit items-center gap-2', triggerProps?.className)}>
        <SelectValue placeholder={t('discovery.actions.selectJurisdiction')}>
          <div className='flex items-center gap-2'>
            <JurisdictionIcon jurisdiction={value ?? Jurisdiction.NONE} />
            <Text variant='body'>{`${value ? t(`config.country.${value}`) : ''}`}</Text>
          </div>
        </SelectValue>
      </SelectTrigger>
      <SelectContent {...contentProps}>
        {Object.values(Jurisdiction).map((jurisdiction) => (
          <SelectItem key={jurisdiction} value={jurisdiction}>
            <div className='flex items-center gap-2'>
              <JurisdictionIcon jurisdiction={jurisdiction} />
              <Text variant='body'>{`${t(`config.country.${jurisdiction}`)}`}</Text>
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export { SelectJurisdiction };
