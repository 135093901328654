import type { ButtonHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from 'utils';

import type { IconName } from '../icon';
import { Icon } from '../icon';

const iconButtonVariants = cva(
  'flex items-center justify-center self-center focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-4 focus-visible:outline-stroke-weak',
  {
    variants: {
      variant: {
        primary:
          'bg-icon-strongest text-icon-invert disabled:bg-icon-disabled disabled:text-icon-strongest',
        secondary:
          'border border-stroke-weak bg-transparent text-icon-strongest hover:border-transparent hover:bg-fill-hover-weak focus-visible:border-stroke-strong active:border-transparent active:bg-fill-pressed-weak disabled:border-stroke-disabled disabled:bg-transparent disabled:text-icon-disabled',
        tertiary:
          'border-none bg-transparent text-icon-strongest hover:bg-fill-hover-strong focus-visible:bg-fill-focus-weak active:bg-fill-pressed-strong disabled:bg-transparent disabled:text-icon-disabled',
        soft: 'border border-stroke-weaker bg-fill-strongest text-content-body-strong hover:bg-fill-strong focus-visible:outline-stroke-weak active:bg-fill-pressed-strong disabled:border-stroke-weaker',
        unstyled: '',
      },
      size: {
        xs: 'h-6 w-6',
        sm: 'h-9 w-9',
        md: 'h-12 w-12',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  },
);

interface Props
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof iconButtonVariants> {
  name: IconName;
  className?: string;
}

const IconButton = forwardRef<HTMLButtonElement, Props>(
  ({ name, className, size, variant, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        type='button'
        className={cn(iconButtonVariants({ variant, size, className }))}
        aria-label={`${name} button`}
        {...rest}
      >
        <Icon name={name} size={size} />
        <span className='sr-only'>{name}</span>
      </button>
    );
  },
);

IconButton.displayName = 'IconButton';

export { IconButton };
