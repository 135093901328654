import { useTranslation } from 'react-i18next';
import { Link, useLocation } from '@tanstack/react-router';

import { Tabs, TabsList, TabsTrigger } from '@legalfly/ui/tabs';
import { cn } from '@legalfly/ui/utils';
import { ContentRow } from 'components/common/content/ContentRow';

export const DraftingTabs = () => {
  const { t } = useTranslation();
  const pathname = useLocation({ select: (location) => location.pathname });
  const activeTab = pathname.split('/').filter(Boolean)[1] ?? 'drafts';

  return (
    <ContentRow
      className='p-0'
      style={{
        height: 'var(--lf-tabs-list-height)',
      }}
    >
      <Tabs value={activeTab}>
        <TabsList className='items-stretch gap-0 bg-fill-maximal p-0' noAnimation>
          <TabsTrigger
            value='drafts'
            className={cn(
              'border-b-2 border-stroke-frail',
              'text-content-body-weak hover:bg-fill-hover-strong',
              'data-[state=active]:border-fill-lemon data-[state=active]:bg-fill-hover-strongest data-[state=active]:text-content-body-strong',
            )}
            asChild
          >
            <Link to='/drafting/drafts'>{t('nav.drafts')}</Link>
          </TabsTrigger>
          <TabsTrigger
            value='templates'
            className={cn(
              'border-b-2 border-stroke-frail',
              'text-content-body-weak hover:bg-fill-hover-strong',
              'data-[state=active]:border-fill-lemon data-[state=active]:bg-fill-hover-strongest data-[state=active]:text-content-body-strong',
            )}
            asChild
          >
            <Link to='/drafting/templates'>{t('nav.templates')}</Link>
          </TabsTrigger>
        </TabsList>
      </Tabs>
    </ContentRow>
  );
};
