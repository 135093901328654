import type { PropsWithChildren, RefObject } from 'react';
import { createContext, useContext, useMemo, useRef } from 'react';

import type { ApiConversationDetail, ConversationTypeEnum } from '@legalfly/api/conversations';
import { useConversation } from 'core/modules/conversations';

interface CopilotContextType {
  conversationUuid: string | undefined;
  onSendMessage: (message: string) => void;
  conversationType: ConversationTypeEnum;
  containerRef: RefObject<HTMLDivElement>;
  isConversationLoaded: boolean;
  isLoadingConversation: boolean;
  conversation: ApiConversationDetail | undefined;
}

const CopilotContext = createContext<CopilotContextType | null>(null);

const CopilotProvider = ({
  children,
  conversationUuid,
  conversationType,
  ...props
}: PropsWithChildren<
  Omit<
    CopilotContextType,
    'containerRef' | 'isConversationLoaded' | 'isLoadingConversation' | 'conversation'
  >
>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { conversation, isSuccess, isLoading } = useConversation({
    uuid: conversationUuid,
    type: conversationType,
  });

  const value = useMemo(
    () => ({
      ...props,
      containerRef,
      conversation,
      conversationUuid,
      conversationType,
      isConversationLoaded: isSuccess,
      isLoadingConversation: isLoading,
    }),
    [props, containerRef, conversation, conversationUuid, conversationType, isSuccess, isLoading],
  );

  return <CopilotContext.Provider value={value}>{children}</CopilotContext.Provider>;
};

const useCopilotContext = () => {
  const context = useContext(CopilotContext);
  if (context === null) {
    throw new Error('useCopilotContext must be used within a CopilotProvider');
  }
  return context;
};

export { CopilotProvider, useCopilotContext };
