import AppInitializer from './AppInitializer';
import { AppProviders } from './AppProviders';

export const App = () => {
  return (
    <AppInitializer>
      <AppProviders />
    </AppInitializer>
  );
};
