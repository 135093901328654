import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf, ParamsOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';

export const createAnonymizationApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const getAnonymizations = () => {
    return client(api.anonymization.list, { method: 'GET' });
  };

  const createAnonymization = ({ body }: { body: BodyOf<'anonymizations/post'> }) => {
    return client(api.anonymization.create, { method: 'POST', body });
  };

  const getAnonymization = ({ uuid }: ParamsOf<'anonymizations/:uuid/get'>) => {
    return client(api.anonymization.detail, { method: 'GET', params: { uuid } });
  };

  const deleteAnonymization = ({ uuid }: ParamsOf<'anonymizations/:uuid/delete'>) => {
    return client(api.anonymization.delete, { method: 'DELETE', params: { uuid } });
  };

  const updateAnonymization = ({
    uuid,
    body,
  }: {
    uuid: string;
    body: BodyOf<'anonymizations/:uuid/put'>;
  }) => {
    return client(api.anonymization.update, { method: 'PUT', params: { uuid }, body });
  };

  return {
    getAnonymization,
    createAnonymization,
    getAnonymizations,
    deleteAnonymization,
    updateAnonymization,
  };
};

export type AnonymizationApi = ReturnType<typeof createAnonymizationApi>;
export type ApiAnonymization = ResponseOf<'anonymizations/:uuid/get'>;
