import type { ApiPlaybook } from '@legalfly/api/playbooks';
import { trackEvent } from '@legalfly/reporting/tracking';
import { withToasts } from '@legalfly/ui/toast';
import { EditableValueForm } from 'components/common/form/EditableValueForm';
import { useUpdatePlaybook } from 'core/modules/playbooks';
import { playbookToasts } from 'core/modules/playbooks/toasts';

interface Props {
  playbook: ApiPlaybook;
}

export const EditablePlaybookName = ({ playbook }: Props) => {
  const { updatePlaybook } = useUpdatePlaybook();

  const handleSubmit = async (newName: string) => {
    trackEvent({
      action: 'submit',
      category: 'playbooks',
      label: 'editName',
    });

    await withToasts(
      updatePlaybook({
        playbookUuid: playbook.uuid,
        body: { name: newName },
      }),
    )(playbookToasts.updatePlaybook());
  };

  return <EditableValueForm value={playbook.name} onsubmit={handleSubmit} />;
};
