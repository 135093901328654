import { trackEvent } from '@legalfly/reporting/tracking';

export const changeTheme = () => {
  const theme = document.body.className === 'dark' ? 'light' : 'dark';
  localStorage.setItem('theme', theme);
  document.documentElement.style.setProperty('color-scheme', theme);
  document.body.className = theme;

  // Dispatch a StorageEvent
  window.dispatchEvent(
    new StorageEvent('storage', {
      key: 'theme',
      newValue: theme,
      oldValue: theme === 'dark' ? 'light' : 'dark',
      storageArea: localStorage,
    }),
  );

  trackEvent({
    action: 'click',
    category: 'profile',
    label: theme,
  });
};
