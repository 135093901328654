export enum Jurisdiction {
  NONE = 'none',
  BELGIUM = 'belgium',
  FRANCE = 'france',
  GERMANY = 'germany',
  NETHERLANDS = 'netherlands',
  SWITZERLAND = 'switzerland',
  UNITED_KINGDOM = 'united_kingdom',
  UNITED_STATES = 'united_states',
  CANADA = 'canada',
  LUXEMBOURG = 'luxembourg',
}

const JurisdictionToCountryCode: Record<Jurisdiction, string> = {
  [Jurisdiction.NONE]: '',
  [Jurisdiction.BELGIUM]: 'BE',
  [Jurisdiction.FRANCE]: 'FR',
  [Jurisdiction.GERMANY]: 'DE',
  [Jurisdiction.NETHERLANDS]: 'NL',
  [Jurisdiction.SWITZERLAND]: 'CH',
  [Jurisdiction.UNITED_KINGDOM]: 'UK',
  [Jurisdiction.UNITED_STATES]: 'US',
  [Jurisdiction.CANADA]: 'CA',
  [Jurisdiction.LUXEMBOURG]: 'LU',
};

export const mapJurisdictionToCountryCode = (jurisdiction: Jurisdiction): string =>
  JurisdictionToCountryCode[jurisdiction] ?? '';

export const featureFlags = ['custom-agents', 'archive'] as const;
export type FeatureFlagType = (typeof featureFlags)[number];
