import { useTranslation } from 'react-i18next';

import type { ApiDocument, ApiDocumentTypeFile } from '@legalfly/api/documents';
import type { ApiReviewItem } from '@legalfly/api/reviews';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { MultiReviewItem } from 'components/multi-reviews/grid/MultiReviewItem';
import { getDocumentName } from 'core/modules/documents/helpers';

interface Props {
  item: ApiReviewItem;
  document: ApiDocument;
}

export const PlaybookItemSandboxMultiReview = ({ item, document }: Props) => {
  const { t } = useTranslation();

  return (
    <div className='grid grid-cols-[1fr_2fr] bg-fill-strong [word-break:break-word]'>
      <div className='flex items-center gap-2 border-r border-stroke-weaker p-4'>
        <Icon name='file-2' />
        <Text className='text-content-body-strong'>{t('documents.title')}</Text>
      </div>
      <div className='flex items-center p-4'>
        <Text>{item.text}</Text>
      </div>
      <div className='flex items-center break-all border-r border-stroke-weaker bg-surface-base p-4'>
        <Text className='text-content-body-strong'>{getDocumentName(document)}</Text>
      </div>
      <div className='bg-fill-maximal'>
        <MultiReviewItem
          className='h-full border-none'
          item={item}
          document={document as ApiDocumentTypeFile}
        />
      </div>
    </div>
  );
};
