import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiDocument } from '@legalfly/api/documents';
import { trackEvent } from '@legalfly/reporting/tracking';
import { IconButton } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { Spinner } from '@legalfly/ui/spinner';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { documentsToasts, useDeleteFile, useDeleteFolder } from 'core/modules/documents';
import { isDocumentFolder } from 'core/modules/documents/helpers';
import { useFavorite } from 'core/modules/favorites';

import { useSelectedDocuments } from './documentPicker/SelectedDocumentsProvider';

interface Props {
  document: ApiDocument;
}

const DocumentListActionPopover = ({ document }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { clearSelectedDocuments } = useSelectedDocuments();
  const { favoriteDocument } = useFavorite();

  const { deleteFile, isLoading: isLoadingDeleteFile } = useDeleteFile({
    folderUuid: document.parent?.uuid,
  });
  const { deleteFolder, isLoading: isLoadingDeleteFolder } = useDeleteFolder();

  const isFolder = isDocumentFolder(document);

  const handleDelete = async () => {
    trackEvent(
      {
        action: 'click',
        category: 'documents',
        label: 'delete',
      },
      { type: document.type },
    );
    if (isFolder) {
      await withToasts(deleteFolder({ uuid: document.uuid }))(documentsToasts.deleteFolder());
    } else {
      await withToasts(deleteFile({ uuid: document.uuid }))(documentsToasts.deleteFile());
    }

    clearSelectedDocuments();
  };

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <IconButton
          name='dots-horizontal'
          className={cn('rotate-90', isOpen && 'bg-fill-hover-strong')}
          variant='tertiary'
          size='sm'
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className='flex flex-col gap-2' align='end'>
        <DropdownMenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
        >
          {isLoadingDeleteFile || isLoadingDeleteFolder ? (
            <Spinner />
          ) : (
            <Icon name='trash-2' className='text-icon-weak' />
          )}
          {isFolder ? t('action.deleteFolder') : t('action.deleteFile')}
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={(e) => {
            e.stopPropagation();
            favoriteDocument(document);
          }}
        >
          <Icon
            name='star'
            size='md'
            className={cn(
              document.favorited ? 'fill-icon-strongest' : 'fill-transparent stroke-icon-strongest',
            )}
          />
          {document.favorited ? t('action.unfavorite') : t('action.favorite')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default DocumentListActionPopover;
