import { Fragment } from 'react';

import type { ApiMultiReview } from '@legalfly/api/multi-reviews';
import type { ApiReview, ApiReviewItem } from '@legalfly/api/reviews';
import { useListenToMultiReviewItem } from 'core/modules/multi-reviews';

const MultiReviewItemListener = ({
  multiReviewUuid,
  itemUuid,
}: {
  multiReviewUuid: ApiMultiReview['uuid'];
  itemUuid: ApiReviewItem['uuid'];
}) => {
  useListenToMultiReviewItem({ multiReviewUuid, itemUuid });

  return null;
};

export const MultiReviewItemsListener = ({
  reviews,
  multiReviewUuid,
}: {
  reviews: ApiReview[];
  multiReviewUuid: ApiMultiReview['uuid'];
}) =>
  reviews.map((review) => (
    <Fragment key={review.uuid}>
      {review.items.map((item) => (
        <MultiReviewItemListener
          key={item.uuid}
          multiReviewUuid={multiReviewUuid}
          itemUuid={item.uuid}
        />
      ))}
    </Fragment>
  ));
