import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

import { fontSizes } from '../theme/fontSizes';

const tailwindMergeConfig = {
  extend: {
    // avoid merge conflicts between font sizes and text colors 'text-*'
    classGroups: {
      'font-size': Object.keys(fontSizes).map((key) => `text-${key}`),
    },
  },
};

const twMergeExtended = extendTailwindMerge(tailwindMergeConfig);

export const cn = (...inputs: ClassValue[]) => twMergeExtended(clsx(inputs));

export function hexToRGB(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}
