import { useForm } from '@tanstack/react-form';

import type { ApiAnonymization } from '@legalfly/api/anonymization';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Icon } from '@legalfly/ui/icon';
import { Input } from '@legalfly/ui/input';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { useUpdateAnonymization } from 'core/modules/anonymization';
import { anonymizationToasts } from 'core/modules/anonymization/toasts';

import { getAnonymizationName } from '../helpers/getAnonymizationName';

interface Props {
  anonymization: ApiAnonymization;
}

export const EditableAnonymizationName = ({ anonymization }: Props) => {
  const { updateAnonymization } = useUpdateAnonymization();

  const defaultValues = {
    name: anonymization.name,
  };

  type FormData = typeof defaultValues;

  const form = useForm<FormData>({
    defaultValues,
    onSubmit: async ({ value }) => {
      if (value.name !== anonymization.name) {
        trackEvent({
          action: 'submit',
          category: 'anonymisation',
          label: 'editName',
        });
        await withToasts(
          updateAnonymization({ uuid: anonymization.uuid, body: { name: value.name } }),
        )(anonymizationToasts.editName());
      }
    },
  });

  return (
    <form
      className='w-full self-end pe-2'
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <form.Field name='name'>
        {(field) => (
          <div className='flex w-full gap-2'>
            <Icon name='edit' className='text-icon-weak' />
            <Input
              containerClassName='w-full'
              id={field.name}
              name={field.name}
              value={field.state.value}
              onChange={(e) => field.handleChange(e.target.value)}
              onBlur={() => {
                field.handleBlur();
                form.handleSubmit();
              }}
              placeholder={getAnonymizationName(anonymization)}
              underlineOnError={field.state.meta.errors.length > 0}
              className={cn(
                'm-0 h-auto w-full truncate border-none p-0 text-body-large text-content-body-strong focus-visible:outline-offset-8',
              )}
            />
          </div>
        )}
      </form.Field>
    </form>
  );
};
