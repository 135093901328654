import type { ElementRef } from 'react';
import { forwardRef } from 'react';
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';

import { cn } from 'utils';

import { Button } from '../button';
import { Icon } from '../icon';
import './styles.css';

const Collapsible = CollapsiblePrimitive.Root;

const CollapsibleTrigger = forwardRef<
  ElementRef<typeof CollapsiblePrimitive.CollapsibleTrigger>,
  React.ComponentPropsWithoutRef<typeof CollapsiblePrimitive.CollapsibleTrigger>
>(({ className, children, ...props }, ref) => (
  <CollapsiblePrimitive.CollapsibleTrigger {...props} ref={ref} asChild>
    <Button
      variant='unstyled'
      className={cn('group flex items-center gap-2', className)}
      renderLeft={
        <Icon
          name='chevron-up'
          className='transition duration-300 group-data-[state=open]:rotate-180'
        />
      }
    >
      {children}
    </Button>
  </CollapsiblePrimitive.CollapsibleTrigger>
));
CollapsibleTrigger.displayName = 'CollapsibleTrigger';

const CollapsibleContent = forwardRef<
  ElementRef<typeof CollapsiblePrimitive.CollapsibleContent>,
  React.ComponentPropsWithoutRef<typeof CollapsiblePrimitive.CollapsibleContent>
>(({ className, ...props }, ref) => (
  <CollapsiblePrimitive.CollapsibleContent
    {...props}
    ref={ref}
    className={cn('CollapsibleContent', className)}
  />
));
CollapsibleContent.displayName = 'CollapsibleContent';

export { Collapsible, CollapsibleTrigger, CollapsibleContent };
