import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { Spinner } from '@legalfly/ui/spinner';

interface DownloadButtonProps extends ComponentProps<typeof Button> {
  isDownloading: boolean;
}

export const DownloadButton = ({ isDownloading, children, ...props }: DownloadButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button renderLeft={isDownloading ? <Spinner /> : <Icon name='download-1' />} {...props}>
      {isDownloading ? t('action.cancel') : children}
    </Button>
  );
};
