import { useCallback, useEffect, useState } from 'react';

function getInitialDarkMode(): boolean {
  if (typeof window === 'undefined') return false;

  const savedTheme = localStorage.getItem('theme');
  if (savedTheme) return savedTheme === 'dark';

  return window.matchMedia('(prefers-color-scheme: dark)').matches;
}

export function useIsDarkMode() {
  const [isDarkMode, setIsDarkMode] = useState(getInitialDarkMode);

  const updateDarkMode = useCallback((isDark: boolean) => {
    setIsDarkMode(isDark);
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    function handleMediaQueryChange(event: MediaQueryListEvent) {
      if (!localStorage.getItem('theme')) {
        updateDarkMode(event.matches);
      }
    }

    function handleStorageChange(event: StorageEvent) {
      if (event.key === 'theme') {
        updateDarkMode(event.newValue === 'dark');
      }
    }

    mediaQuery.addEventListener('change', handleMediaQueryChange);
    window.addEventListener('storage', handleStorageChange);

    // Initial setup
    const savedTheme = localStorage.getItem('theme');
    updateDarkMode(savedTheme ? savedTheme === 'dark' : mediaQuery.matches);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [updateDarkMode]);

  return isDarkMode;
}
