import type { ComponentProps } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseCopilotStopRespondingButton } from '@legalfly/components/copilot';
import { trackEvent } from '@legalfly/reporting/tracking';
import { withToasts } from '@legalfly/ui/toast';
import { useStopConversationMessage } from 'core/modules/conversations';
import { conversationToasts } from 'core/modules/conversations/toasts';

import { useCopilotContext } from './CopilotProvider';

const useConversationMessageInProgress = () => {
  const { conversation } = useCopilotContext();

  const conversationMessageInProgress = useMemo(() => {
    return conversation?.messages.find((message) => message.status === 'in_progress');
  }, [conversation]);

  return conversationMessageInProgress;
};

export const CopilotStopRespondingButton = (
  props: Omit<ComponentProps<typeof BaseCopilotStopRespondingButton>, 'onStopResponding' | 'label'>,
) => {
  const { t } = useTranslation();
  const { conversationType, conversationUuid } = useCopilotContext();

  const conversationMessageInProgress = useConversationMessageInProgress();
  const { stopConversationMessage } = useStopConversationMessage({
    type: conversationType,
  });

  const handleStopResponding = () => {
    if (!conversationMessageInProgress || !conversationUuid) {
      return;
    }

    trackEvent(
      {
        action: 'click',
        category: 'discovery',
        label: 'stopResponding',
      },
      { conversationType },
    );

    withToasts(
      stopConversationMessage({
        uuid: conversationUuid,
        messageUuid: conversationMessageInProgress.uuid,
      }),
    )(conversationToasts.stopConversationMessage());
  };

  if (!conversationMessageInProgress) {
    return null;
  }

  return (
    <BaseCopilotStopRespondingButton
      onStopResponding={handleStopResponding}
      label={t('copilot.stop')}
      {...props}
    />
  );
};
